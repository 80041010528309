/**
 * Footer.js - React component Footer
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import React from 'react';

/* eslint-disable */
const links = {
  email: 'asiakaspalvelu@rakennustieto.fi',
  androidApp:
    'https://play.google.com/store/apps/details?id=fi.taitounited.rtutmobileandroid',
  iOSApp: 'https://itunes.apple.com/fi/app/rt-tuotetieto/id1112920113?l=fi',
  tietopalvelut:
    'https://www.rakennustieto.fi/index/tuotteet/tietopalveluiden_esittely.html',
  sopimuslomake: 'https://www.sopimuslomake.net',
  tuotetieto: 'https://www.rttuotetieto.fi',
  tuoteseloste: 'http://www.rakennuksentuoteseloste.fi',
  tietokauppa: 'https://www.rakennustietokauppa.fi',
  support:
    'https://www.rakennustieto.fi/index/yhteystiedot/asiakaspalvelu.html',
  sales: 'https://www.rakennustieto.fi/index/yhteystiedot/tuotemyynti.html',
  newsletter: 'https://www.rakennustieto.fi/uutiskirje',
  facebook: 'https://www.facebook.com/rakennustieto',
  linkedin: 'https://www.linkedin.com/company/rakennustieto',
  twitter: 'https://twitter.com/rakennustieto',
  blog: 'https://www.rakennustieto.fi/ajankohtaista/blogi',
  tietosuojaseloste:
    'https://www.rakennustieto.fi/index/tietosuojaseloste.html',
};

/* eslint-enable */

class Footer extends React.Component {
  render() {
    return (
      <div className='Footer'>
        <footer>
          <div className='row'>
            <div className='column header'>
              <img className='logo' src='/images/rt-logo-negative.png'></img>
              <p className='m-t-md m-b-0'>Rakennustieto Oy</p>
              <p className='m-y-0'>Malminkatu 16 A, PL 1004, 00101 Helsinki</p>
              <p className='m-y-0'>Puh. 045 4900 747</p>
              <p className='m-y-0'>Y-tunnus 0113188-9</p>
              <a
                className='m-y-0'
                href={`mailto:${links.email}`}
                rel='external'
                target='_top'
              >
                {links.email}
              </a>
            </div>

            <div className='column border-styles'>
              <p className='sub-header'>Seuraa meitä</p>
              <div className='media-links'>
                <div>
                  <a className='share-icon' href={links.newsletter}>
                    <img src='/images/icon-mail.png' />
                  </a>
                  <a className='share-icon-label' href={links.newsletter}>
                    Tilaa uutiskirjeitä
                  </a>
                </div>
                <div>
                  <a className='share-icon' href={links.facebook}>
                    <img src='/images/icon-facebook.png' />
                  </a>
                  <a className='share-icon-label' href={links.facebook}>
                    Facebook
                  </a>
                </div>
                <div>
                  <a className='share-icon' href={links.linkedin}>
                    <img src='/images/icon-linkedin.png' />
                  </a>
                  <a className='share-icon-label' href={links.linkedin}>
                    LinkedIn
                  </a>
                </div>
                <div>
                  <a className='share-icon' href={links.twitter}>
                    <img src='/images/icon-twitter.png' />
                  </a>
                  <a className='share-icon-label' href={links.twitter}>
                    Twitter
                  </a>
                </div>
                <div>
                  <a className='share-icon' href={links.blog}>
                    <img src='/images/icon-wordpress.png' />
                  </a>
                  <a className='share-icon-label' href={links.blog}>
                    Blogi
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
    /* eslint-enable */
  }
}

export default Footer;
