/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedTime, FormattedNumber } from 'react-intl';

import classNames from 'classnames';
import ConfirmActionModal from '../ConfirmActionModal/ConfirmActionModal.tsx';
import ChemicalActions from './ChemicalActions.jsx';
import { ApproverSelector } from '../ApproverSelector/ApproverSelector.jsx';

class PackageInformationPanel extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      gwpLimit:
        props.package && props.package.gwp_limit ? props.package.gwp_limit : '',
    };
  }

  componentWillUnmount() {
    this._enablePageScroll();
  }

  unarchivePackage = (event) => {
    event.preventDefault();
    this.props.packageActionCreators.unarchivePackage(this.props.package.id);
  };

  generateReport = (event) => {
    event.preventDefault();
    this.props.packageActionCreators.generateReport(
      this.props.package.id,
      this.props.package.summary
    );
  };

  downloadSummaryReport = () => {
    this.props.packageActionCreators.setDownloadModalOpen();
  };

  downloadApprovalExcel = (event) => {
    event.preventDefault();
    this.props.packageActionCreators.downloadApprovalExcel(this.props.package);
  };

  // HACK: needed to stop page from scrolling when side menu is open
  _enablePageScroll = () => {
    $('html').removeClass('no-scroll');
  };

  _disablePageScroll = () => {
    $('html').addClass('no-scroll');
  };

  _closeMenu = () => {
    this.props.packageActionCreators.closeMobileMenu();

    this._enablePageScroll();
    //    this.props.packageActionCreators.clearPackage();
  };

  _setAddToParent = () => {
    this.props.packageActionCreators.setAddToParent(this.props.package.id);
  };

  _addSubpackageButton = () => {
    if (this.props.package.is_archived) return null;
    if (this.props.user && !this.props.user.licenseAccess) {
      return (
        <small className='text-muted m-t'>
          <FormattedMessage
            id='subpackage-license-required'
            description='License required to create a subpackage'
            defaultMessage='Tarvitset käyttöoikeuden lisätäksesi aliurakoita.'
          />
        </small>
      );
    }

    return (
      <div className='flexrow'>
        <button
          className='btn btn-primary-outline'
          onClick={this._setAddToParent}
          data-toggle='modal'
          data-target='#AddPackageModal'
        >
          <i className='fa fa-plus'></i>&nbsp;
          <FormattedMessage
            id='add-subpackage'
            description='Button for adding a subpackage'
            defaultMessage='Lisää aliurakka'
          />
        </button>
        {this.props.package.level === 1 && (
          <div>
            <a
              href={`/package/${this.props.package.id}/phases`}
              className='btn btn-primary-outline'
            >
              <i className='fa fa-plus' />
              &nbsp;
              <FormattedMessage
                id='add-phase'
                description='Button for adding construction phases'
                defaultMessage='Lisää rakennusvaiheita'
              />
            </a>
          </div>
        )}
      </div>
    );
  };

  completePackage = async () => {
    const data = {
      id: this.props.package.id,
    };

    this.props.messageActionCreators.clearMessages();
    await this.props.packageActionCreators.completePackage(data);
    await this.props.logActionCreators.getPackageLog(this.props.package.id);

    this.props.messageActionCreators._dispatchMessage('success', {
      id: 'package-completed',
      description: 'Successfully completing a package',
      defaultMessage: 'Urakka merkattiin valmiiksi!',
    });
  };

  incompletePackage = async () => {
    const data = {
      id: this.props.package.id,
      state: 0, // in progress state
    };

    this.props.messageActionCreators.clearMessages();
    await this.props.packageActionCreators.editPackage(data);
    await this.props.logActionCreators.getPackageLog(this.props.package.id);

    this.props.messageActionCreators._dispatchMessage('success', {
      id: 'package-incompleted',
      description: 'Successfully incompleting a package',
      defaultMessage: 'Urakka merkattiin keskeneräiseksi!',
    });
  };

  _packageStatus = () => {
    if (this.props.package.is_archived) {
      return null;
    }

    let status = null;
    if (this.props.package.state === 0) {
      status = (
        <FormattedMessage
          id='package-state-incomplete'
          description='Text for incomplete package state'
          defaultMessage='Keskeneräinen'
        >
          {(message) => (
            <span>
              <i className='fa fa-circle-o' /> {message}
            </span>
          )}
        </FormattedMessage>
      );
    } else if (this.props.package.state === 2) {
      status = (
        <FormattedMessage
          id='package-state-complete'
          description='Text for complete package state'
          defaultMessage='Valmis'
        >
          {(message) => (
            <span>
              <i className='fa fa-check-circle' /> {message}
            </span>
          )}
        </FormattedMessage>
      );
    }

    return [
      <dt className='col-xs-4' key='status'>
        <FormattedMessage
          id='package-state'
          description='Label for package state'
          defaultMessage='Tilanne'
        />
        :
      </dt>,
      <dd
        className='col-xs-8 PackageInformationPanel-content-packageinfo'
        key='status-info'
      >
        {status}
      </dd>,
    ];
  };

  _isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }
    return true;
  }

  _packageLog = (pkg, log) => {
    if (this._isEmpty(log) || pkg.state !== 2) {
      return null;
    }

    const timestamp = new Date(Date.parse(log.last_modified));
    let lastModified = timestamp.getDate() + '.';
    lastModified += timestamp.getMonth() + 1 + '.';
    lastModified += timestamp.getFullYear() + ' ';
    lastModified +=
      (timestamp.getHours() > 9
        ? timestamp.getHours()
        : '0' + timestamp.getHours()) + ':';

    lastModified +=
      timestamp.getMinutes() > 9
        ? timestamp.getMinutes()
        : '0' + timestamp.getMinutes();

    return [
      <dt className='col-xs-4' key='marked-done'>
        <FormattedMessage
          id='package-completed'
          description='When the package was completed'
          defaultMessage='Merkitty valmiiksi'
        />
        :
      </dt>,
      <dd
        className='col-xs-8 PackageInformationPanel-content-packageinfo'
        key='last-modified'
      >
        {lastModified}
      </dd>,
      <dt className='col-xs-4' key='marked-done-by'>
        <FormattedMessage
          id='package-completed-by'
          description='Who completed the package'
          defaultMessage='Merkinnyt valmiiksi'
        />
        :
      </dt>,
      <dd
        className='col-xs-8 PackageInformationPanel-content-packageinfo'
        key='marked-done-by-info'
      >
        {log.first_name && log.last_name ? (
          <div>
            {log.first_name.charAt(0)}. {log.last_name}
          </div>
        ) : (
          <div>{log.username}</div>
        )}

        <div>{log.email}</div>
        <div>{log.organization}</div>
      </dd>,
    ];
  };

  _getContent = () => {
    const subpackagesCompleted =
      this.props.package.stats.subpackages_total ===
      this.props.package.stats.subpackages_completed;

    return (
      <div>
        <h4 className='sidepanel-header'>{this.props.package.name}</h4>

        <div>
          <h5 className='pull-left'>
            <FormattedMessage
              id='information'
              description='Package information panel header'
              defaultMessage='Tietoja'
            />
          </h5>
        </div>

        <div className='clearfix' />

        <dl className='dl-horizontal row'>
          <dt className='col-xs-4'>
            <FormattedMessage
              id='contractor'
              description='Company name of the package owner'
              defaultMessage='Urakoitsija'
            />
            :
          </dt>
          <dd className='col-xs-8 PackageInformationPanel-content-packageinfo'>
            {this.props.package.company.name}
          </dd>

          {this.props.package.parent === null ? null : (
            <dt className='col-xs-4'>
              <FormattedMessage
                id='employer'
                description='Company name of the package employer'
                defaultMessage='Tilaaja'
              />
              :
            </dt>
          )}

          {this.props.package.parent === null ? null : (
            <dd className='col-xs-8 PackageInformationPanel-content-packageinfo'>
              {this.props.package.parent.company.name}
            </dd>
          )}

          {this._packageStatus()}

          {this._packageLog(this.props.package, this.props.log)}
        </dl>

        <div className='flexrow'>
          <div>
            {this.props.package.is_archived ? null : (
              <a
                className='btn btn-primary-outline'
                href={`/package/${this.props.package.id}`}
              >
                <i className='fa fa-info-circle' />
                &nbsp;
                <FormattedMessage
                  id='package-information-link'
                  description='Link to package information page'
                  defaultMessage='Katso kaikki tiedot'
                />
              </a>
            )}
          </div>
          {this.props.package.state !== 0 ? null : (
            <div className='PackageInformationBasic-actions-container'>
              <button
                className='btn btn-primary m-b'
                onClick={this.completePackage}
                disabled={!subpackagesCompleted}
              >
                <i className='fa fa-check-circle'></i>&nbsp;
                <FormattedMessage
                  id='complete-package'
                  description='Button text for completing a package'
                  defaultMessage='Merkitse valmiiksi'
                />
              </button>

              <div
                className={classNames(
                  'PackageInformationBasic-actions-container-top',
                  {
                    'hidden-xs-up': subpackagesCompleted,
                  }
                )}
                data-tip={'Kaikkia aliurakoita ei ole merkitty valmiiksi.'}
              ></div>
            </div>
          )}

          {this.props.package.state !== 2 ? null : (
            <button
              className='btn btn-warning'
              onClick={this.incompletePackage}
            >
              <i className='fa fa-circle-o'></i>&nbsp;
              <FormattedMessage
                id='incomplete-package'
                description='Button text for incompleting a package'
                defaultMessage='Merkitse keskeneräiseksi'
              />
            </button>
          )}
        </div>

        {this.props.package.parent === null ? null : (
          <div className='text-muted m-t'>
            <FormattedMessage
              id='package-construction-site-description'
              description='Describes the construction site of package'
              defaultMessage='Urakka kuuluu työmaahan {name} jonka omistaa {company}.'
              values={{
                name: (
                  <strong>
                    {!this.props.package.is_archived &&
                    this.props.package.ancestor.has_access ? (
                      <a href={`/package/${this.props.package.ancestor.id}`}>
                        {this.props.package.ancestor.name}
                      </a>
                    ) : (
                      this.props.package.ancestor.name
                    )}
                  </strong>
                ),

                company: (
                  <strong>{this.props.package.ancestor.company.name}</strong>
                ),
              }}
            />
          </div>
        )}

        <hr />
        <h5 className='m-t-md'>
          <FormattedMessage
            id='products'
            description='Header for product information'
            defaultMessage='Tuotteet'
          />
        </h5>

        <dl className='dl-horizontal row'>
          <dt className='col-xs-4'>
            <FormattedMessage
              id='total'
              description='Label for how many items there are in total'
              defaultMessage='Yhteensä'
            />
            :
          </dt>
          <dd className='col-xs-8 PackageInformationPanel-content-packageinfo'>
            <FormattedMessage
              id='pieces'
              description='How many items, e.g. 10 pcs.'
              defaultMessage='{count, number} kpl'
              values={{
                count: this.props.package.stats.agg_products_total,
              }}
            />
          </dd>

          <dt className='col-xs-4'>
            <FormattedMessage
              id='installed'
              description='Label for how many products are installed'
              defaultMessage='Asennettu'
            />
            :
          </dt>
          <dd className='col-xs-8 PackageInformationPanel-content-packageinfo'>
            <FormattedMessage
              id='pieces'
              description='How many items, e.g. 10 pcs.'
              defaultMessage='{count, number} kpl'
              values={{
                count: this.props.package.stats.agg_products_installed,
              }}
            />
          </dd>
        </dl>

        {this.props.package.state === 2 ||
        this.props.package.is_archived ? null : (
          <div className='flexrow'>
            <div>
              <a
                className='btn btn-primary-outline'
                href={`/package/${this.props.package.id}/products`}
              >
                <FormattedMessage
                  id='manage-products'
                  description='Manage link to the product management page'
                  defaultMessage='Hallinnoi tuotteita'
                />
              </a>
            </div>

            {!this.props.chemicalView && (
              <div>
                <a
                  className='btn btn-primary-outline'
                  href={`/package/${this.props.package.id}/chemicals`}
                >
                  <FormattedMessage
                    id='manage-products'
                    description='Manage link to the chemical management page'
                    defaultMessage='Hallinnoi kemikaaleja'
                  />
                </a>
              </div>
            )}

            {!this.props.chemicalView &&
              (this.props.user?.admin === true ||
                this.props.user?.role === 1 ||
                this.props.user?.role === 0 ||
                this.props.package.can_approve) && (
                <div>
                  <a
                    className='btn btn-primary-outline'
                    href={`/package/${this.props.package.id}/approval`}
                  >
                    <FormattedMessage
                      id='manage-products'
                      description='Link to the product approval management page'
                      defaultMessage='Tuotekelpoisuudet'
                    />
                  </a>
                </div>
              )}
          </div>
        )}

        {this.props.package.state !== 2 ||
        this.props.package.is_archived ? null : (
          <a
            className='btn btn-primary-outline m-t'
            href={`/package/${this.props.package.id}/products`}
          >
            <FormattedMessage
              id='view-products'
              description='View link to the product management page'
              defaultMessage='Katsele tuotteita'
            />
          </a>
        )}

        <ApproverSelector
          user={this.props.user}
          packageId={this.props.package?.id}
          approvers={this.props.approvers}
          getApproverData={() => {
            if (this.props.package) {
              return this.props.packageActionCreators.getPackageApprover(
                this.props.package.id
              );
            }
          }}
          setApproverData={(username, email) => {
            if (this.props.package) {
              return this.props.packageActionCreators.setPackageApprover(
                this.props.package.id,
                username,
                email
              );
            }
          }}
          refetchApproverIds={() => {
            if (this.props.productActionCreators) {
              this.props.productActionCreators.getUserApproverIds();
            }
          }}
        />

        {this.props.package.level === 1 && (
          <ChemicalActions
            level={this.props.package.level}
            loading={this.props.packageChemicalChartInfoLoading}
            chemicalInfo={this.props.packageChemicalChartInfo}
            user={this.props.user}
            onDownloadCodeClick={() =>
              this.props.packageActionCreators.downloadChemicalChartCode(
                this.props.packageChemicalChartInfo.public_uuid
              )
            }
            onOpenClick={() => {
              const uuid = this.props.packageChemicalChartInfo.public_uuid;
              const uri = `${window.location.origin}/chemicalchart/${uuid}`;
              window.open(uri, '_blank');
            }}
            onCreateClick={() =>
              this.props.packageActionCreators.createPublicChemicalChart(
                this.props.package.id
              )
            }
            onDeleteClick={() =>
              $('#DeletePublicChemicalChartModal').modal('show')
            }
          />
        )}

        <hr />
        <h5 className='m-t-md m-b'>
          <FormattedMessage
            id='subpackages'
            description='Plural subpackage header'
            defaultMessage='Aliurakat'
          />
        </h5>

        <dl className='dl-horizontal row'>
          {this.props.package.parent !== null ||
          !this.props.package.is_archived ? null : (
            <button
              className='btn btn-primary-outline m-t'
              onClick={this.unarchivePackage}
            >
              <i className='fa fa-folder-open' />
              &nbsp;
              <FormattedMessage
                id='unarchive-package'
                description='Button for unarchiving a package'
                defaultMessage='Palauta aktiiviseksi'
              />
            </button>
          )}
          {this._addSubpackageButton()}
        </dl>

        <div>
          <hr />
          <h5 className='m-t-md m-b'>
            <FormattedMessage
              id='carbon-footprint-header'
              description='Header text for carbon footprint info'
              defaultMessage='Hiilijalanjälki'
            />
          </h5>
          <FormattedMessage
            id='gwp-limit'
            description='gwp-limit'
            defaultMessage='GWP A1-A3 raja-arvo yksikköineen
            (lisäämällä arvon voit todentaa hiilijalanjäljen)'
          />
          <input
            className='m-t m-b maxpanel'
            type='text'
            value={this.state.gwpLimit}
            onChange={(e) => {
              this.setState({
                gwpLimit: e.currentTarget.value,
              });
            }}
          />
          <div className='flexrow'>
            <button
              className={classNames(
                'ProductInformationPanel-content-maximumStoredAmount',
                'btn btn-success'
              )}
              disabled={
                this.state.gwpLimit === this.props.package.gwp_limit ||
                (this.props.package.gwp_limit === null &&
                  this.state.gwpLimit === '')
              }
              onClick={() => {
                this.props.packageActionCreators.editPackage({
                  id: this.props.package.id,
                  gwpLimit: this.state.gwpLimit,
                });
              }}
            >
              <FormattedMessage
                id='carbonFootprint-product-submit'
                description='Submit carbon footprint info'
                defaultMessage='Tallenna tiedot'
              />
            </button>
            {!!this.props.package.gwp_limit &&
              this.props.package.gwp_can_approve && (
                <div>
                  {this.props.package.gwp_approved ? (
                    <button
                      className='btn btn-danger'
                      onClick={() => {
                        this.props.packageActionCreators.approvePackageCarbonFootprint(
                          this.props.package.id,
                          false
                        );
                      }}
                    >
                      <FormattedMessage
                        id='product-disapprove-carbon-footprint-button'
                        description={
                          'Button label for disapproving carbon footprint'
                        }
                        defaultMessage='Peru hiilijalanjälki'
                      />
                    </button>
                  ) : (
                    <button
                      className='btn btn-eco'
                      onClick={() => {
                        this.props.packageActionCreators.approvePackageCarbonFootprint(
                          this.props.package.id,
                          true
                        );
                      }}
                    >
                      <FormattedMessage
                        id='product-approve-carbon-footprint-button'
                        description='Button label for approving carbon footprint'
                        defaultMessage='Totea hiilijalanjälki'
                      />
                    </button>
                  )}
                </div>
              )}
          </div>
        </div>

        <div className='PackageInformationPanel-content-reports'>
          {this.props.packageSummaryLoading[this.props.package.id] && (
            <div className='PackageInformationPanel-content-reportLoading m-t m-b'>
              <hr />
              <div className='loading loading-green'>
                <div></div>
              </div>
              <span>
                <FormattedMessage
                  id='generating-report'
                  description='Generating report text'
                  defaultMessage='Luodaan yhteenvetoa'
                />
              </span>
            </div>
          )}

          {!this.props.packageSummaryLoading[this.props.package.id] &&
            this.props.package.summary && (
              <div className='PackageInformationPanel-content-oldReport m-t m-b'>
                <hr />
                <div>
                  <FormattedMessage
                    id='last-report-generated'
                    description='Button for generating a report'
                    defaultMessage='Edellinen yhteenveto tehty {time}.'
                    values={{
                      time: (
                        <FormattedTime
                          year='numeric'
                          month='numeric'
                          day='numeric'
                          hour='numeric'
                          minute='numeric'
                          value={this.props.package.summary.last_modified}
                        />
                      ),
                    }}
                  />
                </div>

                <div>
                  <FormattedMessage
                    id='last-report-size'
                    description='Report size text'
                    defaultMessage='Tiedoston koko on {sizeInMb} Mt.'
                    values={{
                      sizeInMb: (
                        <FormattedNumber
                          value={
                            this.props.package.summary.size_in_bytes / 1000000
                          }
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            )}

          {!this.props.package.summary &&
            !this.props.packageSummaryLoading[this.props.package] && <hr />}
          <div className='flexrow'>
            {!this.props.packageSummaryLoading[this.props.package.id] &&
              this.props.package.summary && (
                <button
                  className='btn btn-success'
                  onClick={this.downloadSummaryReport}
                  data-toggle='modal'
                  data-target='#DownloadSummaryModal'
                >
                  <i className='fa fa-file-archive-o'></i>&nbsp;
                  <FormattedMessage
                    id='create-report'
                    description='Button for downloading a report'
                    defaultMessage='Lataa tehty yhteenveto'
                  />
                </button>
              )}
            <button
              className='btn btn-success'
              onClick={this.generateReport}
              disabled={this.props.packageSummaryLoading[this.props.package.id]}
            >
              <i className='fa fa-file-archive-o'></i>&nbsp;
              <FormattedMessage
                id='generate-report'
                description='Button for generating a report'
                defaultMessage='Tee uusi yhteenveto'
              />
            </button>

            <button
              className='btn btn-success'
              onClick={this.downloadApprovalExcel}
              disabled={this.props.packageApprovalReportLoading}
            >
              {this.props.packageApprovalReportLoading ? (
                <div className='loading loading-sm pull-left' />
              ) : (
                <i className='fa fa-list'></i>
              )}
              &nbsp;
              <FormattedMessage
                id='download-approval-excel'
                description='Button for downloading approval excel'
                defaultMessage='Lataa tuotekelpoisuusyhteenveto'
              />
            </button>
          </div>

          {this.props.package.level !== 1 && (
            <ChemicalActions
              level={this.props.package.level}
              loading={this.props.packageChemicalChartInfoLoading}
              chemicalInfo={this.props.packageChemicalChartInfo}
              user={this.props.user}
              onDownloadCodeClick={() =>
                this.props.packageActionCreators.downloadChemicalChartCode(
                  this.props.packageChemicalChartInfo.public_uuid
                )
              }
              onOpenClick={() => {
                const uuid = this.props.packageChemicalChartInfo.public_uuid;
                const uri = `${window.location.origin}/chemicalchart/${uuid}`;
                window.open(uri, '_blank');
              }}
              onCreateClick={() =>
                this.props.packageActionCreators.createPublicChemicalChart(
                  this.props.package.id
                )
              }
              onDeleteClick={() =>
                $('#DeletePublicChemicalChartModal').modal('show')
              }
            />
          )}
        </div>
        <hr />
      </div>
    );
  };

  render() {
    let content = null;
    const classes = classNames(
      'PackageInformationPanel-content',
      'stretch-vert',
      'sidepanel',
      {
        open: !!this.props.activePackage && this.props.openMobileMenu,
      }
    );

    const backdropClasses = classNames('PackageInformationPanel-backdrop', {
      'hidden-sm-up': this.props.openMobileMenu,
      'hidden-xs-up': !this.props.openMobileMenu,
    });

    if (this.props.packageLoading) {
      content = <div className='loading loading-green loading-center m-t-md' />;
    } else if (!this.props.package || !this.props.activePackage) {
      content = (
        <p className='text-muted padding'>
          <FormattedMessage
            id='information-panel-placeholder'
            description='Placeholder for package information panel'
            defaultMessage='Valitse urakka vasemmalta nähdäksesi sen tiedot.'
          />
        </p>
      );
    } else {
      content = this._getContent();
    }

    const modalInfo = (
      <FormattedMessage
        id='info-message'
        description='Info message'
        defaultMessage={`Haluatko varmasti poistaa julkisen kemikaaliluettelon?
           Käytössä oleva QR-koodi lakkaa toimimasta.`}
      />
    );

    return (
      <div className='PackageInformationPanel col-xs-12 col-sm-4'>
        <ConfirmActionModal
          id='DeletePublicChemicalChartModal'
          title='Poista kemikaaliluettelo'
          info={modalInfo}
          onConfirm={() =>
            this.props.packageActionCreators.removeChemicalChart(
              this.props.package.id
            )
          }
        />

        <div onClick={this._closeMenu} className={backdropClasses} />

        <div className={classes}>
          <div className='PackageInformationPanel-content-menu'>
            <div
              className={classNames(
                'sidepanel-close-icon',
                'hidden-sm-up',
                'pull-left'
              )}
            >
              <i className='fa fa-times pull-left' onClick={this._closeMenu} />
            </div>

            {content}
          </div>
        </div>
      </div>
    );
  }
}

PackageInformationPanel.propTypes = {
  activePackage: PropTypes.number,
  package: PropTypes.object,
  packageLoading: PropTypes.bool.isRequired,
  packageSummaryLoading: PropTypes.object.isRequired,
  packageApprovalReportLoading: PropTypes.bool.isRequired,
  packageChemicalChartInfo: PropTypes.object,
  packageChemicalChartInfoLoading: PropTypes.bool,
  openMobileMenu: PropTypes.bool,
  log: PropTypes.object,
  user: PropTypes.object,
  productActionCreators: PropTypes.object,
  packageActionCreators: PropTypes.object.isRequired,
  fileActionCreators: PropTypes.object.isRequired,
  chemicalView: PropTypes.bool,
  approvers: PropTypes.array,
};

export default PackageInformationPanel;
