/**
 * AddProductModal.js - React component AddProductModal
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import FileTypes from '../../../shared/FileTypes.mjs';
import { getFileTypePath } from '../../utils/fileUtil';
import { gwpOptions } from '../../utils/productUtils';
import { ProductType } from '../../utils/enum';

const messages = defineMessages({
  emptyName: {
    id: 'product-manual-edit-no-name',
    description: 'Error when empty name while editing manual product',
    defaultMessage: 'Tuotteen nimi ei voi olla tyhjä',
  },

  emptyManufacturer: {
    id: 'product-manual-edit-no-manufacturer',
    description: 'Error when empty manufacturer while editing manual product',
    defaultMessage: 'Tuotteen valmistaja ei voi olla tyhjä',
  },

  eanNotValid: {
    id: 'product-manual-edit-ean-too-long',
    description: 'Error when GTIN code is too long',
    defaultMessage:
      'GTIN-koodin maksimipituus on 13 merkkiä ja se voi sisältää ainoastaan numeroita',
  },

  noFileNameError: {
    id: 'file-no-name',
    description: 'Error when empty name when adding a file',
    defaultMessage: 'Tiedoston nimi ei voi olla tyhjä',
  },

  noFileTypeError: {
    id: 'file-no-type',
    description: 'Error when empty type when adding a file',
    defaultMessage: 'Valitse tiedoston tyyppi',
  },

  fileTypeDOP: {
    id: 'filetype-dop',
    description: 'File type for DOP',
    defaultMessage: 'DOP - Suoritustasoilmoitus',
  },

  fileTypeKTT: {
    id: 'filetype-ktt',
    description: 'File type for KTT',
    defaultMessage: 'KTT - Käyttöturvallisuustiedote',
  },

  fileTypeINSTR: {
    id: 'filetype-instr',
    description: 'File type for INSTR',
    defaultMessage: 'Ohje',
  },

  fileTypeATT: {
    id: 'filetype-att',
    description: 'File type for ATT',
    defaultMessage: 'Muu liite',
  },

  fileTypeEPD: {
    id: 'filetype-epd',
    description: 'File type for EPD',
    defaultMessage: 'EPD - Ympäristöseloste',
  },

  productTooltip: {
    id: 'product',
    description: 'Tooltip for product',
    defaultMessage:
      'Urakkaan asennettava rakennustuote,' +
      ' joka ei ole kemikaali. Esimerkiksi laminaatti.',
  },

  chemicalProductTooltip: {
    id: 'chemical-product',
    description: 'Tooltip for chemical product',
    defaultMessage:
      'Urakkaan asennettava rakennustuote, joka on kemikaali.' +
      ' Esimerkiksi rakennusliima. Kemikaalille vaaditaan KTT-dokumentti.',
  },

  chemicalTooltip: {
    id: 'chemical',
    description: 'Tooltip for chemical',
    defaultMessage:
      'Urakan aikana käytössä oleva kemikaali, jota ei asenneta' +
      ' kohteeseen. Esimerkiksi polttoaine.' +
      ' Kemikaalille vaaditaan KTT-dokumentti.',
  },

  fileTypeM1: {
    id: 'filetype-m1',
    description: 'File type for M1',
    defaultMessage: 'M1 - Päästöluokitus',
  },

  fileTypeCERT: {
    id: 'filetype-cert',
    description: 'File type for CERT',
    defaultMessage: 'CERT - Tuotteen sertifikaatti',
  },
});

const initialState = {
  file: null,
  fileName: '',
  fileType: 'NO_CHOICE',
  addedFiles: [],
  variant: ProductType.PRODUCT,
  safetyCodes: [],
  hazardCodes: [],
  warningCodes: [],
  error: null,
  noNameError: null,
  noManufacturerError: null,
  eanNotValid: null,
  fileNameError: null,
  fileTypeError: null,
  gwpValueA1A3: '',
  gwpUnit: gwpOptions.gwpUnitOptions[0].value,
  gwpVerification: gwpOptions.gwpVerificationOptions[0].value,
  gwpStandard: gwpOptions.gwpStandardOptions[0].value,
};

class AddProductModal extends React.Component {
  static propTypes = {
    intl: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    addProduct: PropTypes.func.isRequired,
    safetyData: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.name = null;
    this.manufacturer = null;
    this.ean = null;
    this.addProductModal = null;

    this.state = initialState;
  }

  componentDidMount() {
    $(this.addProductModal).on('hidden.bs.modal', () => {
      this.setState(initialState);
      this.name.value = '';
      this.manufacturer.value = '';
      this.ean.value = '';
    });
  }

  componentWillUnmount() {
    $(this.addPackageModal).unbind('hidden.bs.modal');
  }

  _getErrors = () => {
    return {
      error: null,
      noNameError: null,
      noManufacturerError: null,
      eanNotValid: null,
      fileNameError: null,
      fileTypeError: null,
    };
  };

  addManualProduct = (event) => {
    event.preventDefault();

    const safetyCodes =
      this.state.variant !== ProductType.PRODUCT &&
      this.state.safetyCodes.length > 0
        ? this.state.safetyCodes.map((sc) => sc.value).join(',')
        : null;

    const hazardCodes =
      this.state.variant !== ProductType.PRODUCT &&
      this.state.hazardCodes &&
      this.state.hazardCodes.length > 0
        ? this.state.hazardCodes.map((hc) => hc.value).join(',')
        : null;

    const warningCodes =
      this.state.variant !== ProductType.PRODUCT &&
      this.state.warningCodes &&
      this.state.warningCodes.length > 0
        ? this.state.warningCodes.map((wc) => wc.value).join(',')
        : null;

    const data = {
      name: this.name.value,
      manufacturer: this.manufacturer.value,
      ean: this.ean.value,
      safety_codes: safetyCodes,
      hazard_codes: hazardCodes,
      warning_codes: warningCodes,
      variant: this.state.variant,
      gwpValueA1A3:
        this.state.gwpValueA1A3 !== '' ? this.state.gwpValueA1A3 : null,
      gwpUnit: this.state.gwpValueA1A3 !== '' ? this.state.gwpUnit : null,
      gwpVerification:
        this.state.gwpValueA1A3 !== '' ? this.state.gwpVerification : null,
      gwpStandard:
        this.state.gwpValueA1A3 !== '' ? this.state.gwpStandard : null,
    };

    const errors = this._getErrors();

    if (this.state.file && !this.state.fileName) {
      errors.error = true;
      errors.fileNameError = this.props.intl.formatMessage(
        messages.noFileNameError
      );
    }

    if (this.state.file && this.state.fileType === 'NO_CHOICE') {
      errors.error = true;
      errors.fileTypeError = this.props.intl.formatMessage(
        messages.noFileTypeError
      );
    }

    if (data.name.length <= 0) {
      errors.error = true;
      errors.noNameError = this.props.intl.formatMessage(messages.emptyName);
    }

    if (data.manufacturer.length <= 0) {
      errors.error = true;
      errors.noManufacturerError = this.props.intl.formatMessage(
        messages.emptyManufacturer
      );
    }

    if (data.ean.length > 13) {
      errors.error = true;
      errors.eanNotValid = this.props.intl.formatMessage(messages.eanNotValid);
    }

    for (let i = 0; i < data.ean.length; i += 1) {
      if (!/^\d$/.test(data.ean[i])) {
        errors.error = true;
        errors.eanNotValid = this.props.intl.formatMessage(
          messages.eanNotValid
        );
      }
    }

    this.setState(errors);
    if (errors.error) return;
    this.name.value = '';
    this.manufacturer.value = '';
    this.ean.value = '';

    const filesCopy =
      this.state.file !== null
        ? [
            ...this.state.addedFiles,
            {
              file: this.state.file,
              name: this.state.fileName,
              type: this.state.fileType,
            },
          ]
        : [...this.state.addedFiles];

    this.setState({
      addedFiles: [],
    });

    this.props.addProduct(data, filesCopy);

    $(this.addProductModal).modal('hide');
  };

  _onDrop = (files) => {
    this.setState({
      file: files[0],
      fileName: this.state.fileName || files[0].name,
    });
  };

  _addFile = (event) => {
    event.preventDefault();

    const errors = this._getErrors();

    if (!this.state.fileName) {
      errors.error = true;
      errors.fileNameError = this.props.intl.formatMessage(
        messages.noFileNameError
      );
    }

    if (this.state.fileType === 'NO_CHOICE') {
      errors.error = true;
      errors.fileTypeError = this.props.intl.formatMessage(
        messages.noFileTypeError
      );
    }

    const addedFiles = errors.error
      ? this.state.addedFiles
      : [
          ...this.state.addedFiles,
          {
            file: this.state.file,
            name: this.state.fileName,
            type: this.state.fileType,
          },
        ];

    const file = errors.error ? this.state.file : null;

    this.setState({
      ...errors,
      addedFiles,
      file,
      fileName: errors.error ? this.state.fileName : '',
      fileType: errors.error ? this.state.fileType : 'NO_CHOICE',
    });
  };

  _removeFile = (index) => {
    const addedFiles = [...this.state.addedFiles];
    addedFiles.splice(index, 1);
    this.setState({
      addedFiles,
    });
  };

  _fileRows = () => {
    const files = this.state.addedFiles;
    if (!files || files.length === 0) return null;
    return (
      <div>
        <hr />
        <ul className='file-list'>
          {files.map((file, index) => {
            return (
              <li className='file-list-item' key={index}>
                <a onClick={this._removeFile}>
                  <i className='text-danger fa fa-trash' />
                </a>
                {file.name}
                <img src={getFileTypePath(file.type)} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  _handleProductTypeChange = (event) => {
    this.setState({
      variant: Number(event.target.value),
    });
  };

  manualProductModal = () => {
    const classes = {
      productNameGroup: classNames('form-group', {
        'has-error': this.state.productNameError,
      }),

      productNameField: classNames('form-control', {
        'form-control-error': this.state.productNameError,
      }),

      productManufacturerGroup: classNames('form-group', {
        'has-error': this.state.productManufacturerError,
      }),

      productManufacturerField: classNames('form-control', {
        'form-control-error': this.state.productManufacturerError,
      }),

      productEANGroup: classNames('form-group', {
        'has-error': this.state.eanNotValid,
      }),

      productEANField: classNames('form-control', {
        'form-control-error': this.state.eanNotValid,
      }),

      fileNameGroup: classNames('form-group', {
        'has-error': this.state.fileNameError,
      }),

      fileNameField: classNames('form-control', {
        'form-control-error': this.state.fileNameError,
      }),

      fileTypeGroup: classNames('form-group', {
        'has-error': this.state.fileTypeError,
      }),

      fileTypeField: classNames('form-control', {
        'form-control-error': this.state.fileTypeError,
      }),
    };

    const safetyData = this.props.safetyData || {
      hazardCodes: [],
      safetyCodes: [],
      warningCodes: [],
    };

    return (
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <button type='button' className='close' data-dismiss='modal'>
              <span>&times;</span>
            </button>

            <h4 className='modal-title'>
              <FormattedMessage
                id='add-product-header'
                description='Add product modal header'
                defaultMessage={this.props.title}
                className='asd'
              />
            </h4>
          </div>
          <div className='modal-body'>
            <form onSubmit={(event) => event.preventDefault()}>
              <fieldset className={classes.productNameGroup}>
                <label>
                  *&nbsp;
                  <FormattedMessage
                    id='product-name'
                    description='Product name'
                    defaultMessage='Tuotteen nimi'
                  />
                </label>

                <input
                  type='text'
                  ref={(input) => {
                    this.name = input;
                  }}
                  className={classes.productNameField}
                  required='required'
                />

                <small className='text-danger'>{this.state.noNameError}</small>
              </fieldset>

              <fieldset className={classes.productManufacturerGroup}>
                <label>
                  *&nbsp;
                  <FormattedMessage
                    id='product-manufacturer'
                    description='Product manufacturer'
                    defaultMessage='Tuotteen yritys'
                  />
                </label>

                <input
                  type='text'
                  ref={(input) => {
                    this.manufacturer = input;
                  }}
                  className={classes.productManufacturerField}
                  required='required'
                />

                <small className='text-danger'>
                  {this.state.noManufacturerError}
                </small>
              </fieldset>

              <fieldset className={classes.productEANGroup}>
                <label>
                  <FormattedMessage
                    id='product-EAN'
                    description='Product EAN code'
                    defaultMessage='Tuotteen GTIN-koodi'
                  />
                </label>

                <input
                  type='text'
                  ref={(input) => {
                    this.ean = input;
                  }}
                  className={classes.productEANField}
                />

                <small className='text-danger'>{this.state.eanNotValid}</small>
              </fieldset>

              <fieldset className='form-group'>
                <label>
                  <FormattedMessage
                    id='product-type'
                    description='Product type'
                    defaultMessage='Tuotteen tyyppi'
                  />
                </label>
                <div className='radio'>
                  <label>
                    <input
                      type='radio'
                      value={ProductType.PRODUCT}
                      checked={this.state.variant === ProductType.PRODUCT}
                      onChange={this._handleProductTypeChange}
                    />{' '}
                    Rakennustuote
                  </label>
                  <a
                    className='m-l'
                    onClick={(evt) => evt.stopPropagation()}
                    data-toggle='tooltip'
                    data-placement='bottom'
                    title={this.props.intl.formatMessage(
                      messages.productTooltip
                    )}
                  >
                    <i className='fa fa-info-circle' />
                  </a>
                </div>
                <div className='radio'>
                  <label>
                    <input
                      type='radio'
                      value={ProductType.CHEMICAL_PRODUCT}
                      checked={
                        this.state.variant === ProductType.CHEMICAL_PRODUCT
                      }
                      onChange={this._handleProductTypeChange}
                    />{' '}
                    Rakennustuotekemikaali | menee kemikaaliluetteloon
                  </label>
                  <a
                    className='m-l'
                    onClick={(evt) => evt.stopPropagation()}
                    data-toggle='tooltip'
                    data-placement='bottom'
                    title={this.props.intl.formatMessage(
                      messages.chemicalProductTooltip
                    )}
                  >
                    <i className='fa fa-info-circle' />
                  </a>
                </div>
                <div className='radio'>
                  <label>
                    <input
                      type='radio'
                      value={ProductType.CHEMICAL}
                      checked={this.state.variant === ProductType.CHEMICAL}
                      onChange={this._handleProductTypeChange}
                    />{' '}
                    Kemikaali - ei rakennustuote | menee kemikaaliluetteloon
                  </label>
                  <a
                    className='m-l'
                    onClick={(evt) => evt.stopPropagation()}
                    data-toggle='tooltip'
                    data-placement='bottom'
                    title={this.props.intl.formatMessage(
                      messages.chemicalTooltip
                    )}
                  >
                    <i className='fa fa-info-circle' />
                  </a>
                </div>
              </fieldset>

              {this.state.variant !== ProductType.PRODUCT && (
                <div>
                  <hr />
                  <h5 className='m-b'>
                    <FormattedMessage
                      id='chemical-info-header'
                      description='Header text for chemical info'
                      defaultMessage='Kemikaalitiedot'
                    />
                  </h5>
                  <fieldset className='form-group'>
                    <FormattedMessage
                      id='danger-codes'
                      description='Multiselect danger codes'
                      defaultMessage='Vaaralauseke (yksi tai useampi)'
                    />
                    <Select
                      className='m-t'
                      isMulti
                      value={this.state.hazardCodes}
                      onChange={(value) => {
                        if (value.length > 1) {
                          value = value.filter((e) =>
                            value[value.length - 1].value !== '-'
                              ? e.value !== '-'
                              : e.value === '-'
                          );
                        }
                        this.setState({
                          hazardCodes: value,
                        });
                      }}
                      backspaceRemovesValue={false}
                      closeMenuOnSelect={false}
                      noOptionsMessage={() => 'None found'}
                      placeholder={'Vaaralauseke...'}
                      options={safetyData.hazardCodes}
                    />
                  </fieldset>

                  <fieldset className='form-group'>
                    <FormattedMessage
                      id='safety-codes'
                      description='Multiselect safety codes'
                      defaultMessage='Turvalauseke (yksi tai useampi)'
                    />
                    <Select
                      className='m-t'
                      isMulti
                      value={this.state.safetyCodes}
                      onChange={(value) => {
                        if (value.length > 1) {
                          value = value.filter((e) =>
                            value[value.length - 1].value !== '-'
                              ? e.value !== '-'
                              : e.value === '-'
                          );
                        }
                        this.setState({
                          safetyCodes: value,
                        });
                      }}
                      backspaceRemovesValue={false}
                      closeMenuOnSelect={false}
                      noOptionsMessage={() => 'None found'}
                      placeholder={'Turvalauseke...'}
                      options={safetyData.safetyCodes}
                    />
                  </fieldset>

                  <fieldset className='form-group'>
                    <FormattedMessage
                      id='danger-codes'
                      description='Multiselect warning sign codes'
                      defaultMessage='Varoitusmerkki (yksi tai useampi)'
                    />
                    <Select
                      className='m-t'
                      isMulti
                      value={this.state.warningCodes}
                      onChange={(value) => {
                        if (value.length > 1) {
                          value = value.filter((e) =>
                            value[value.length - 1].value !== '-'
                              ? e.value !== '-'
                              : e.value === '-'
                          );
                        }
                        this.setState({
                          warningCodes: value,
                        });
                      }}
                      backspaceRemovesValue={false}
                      closeMenuOnSelect={false}
                      noOptionsMessage={() => 'None found'}
                      placeholder={'Varoitusmerkki...'}
                      options={safetyData.warningCodes}
                    />
                  </fieldset>
                </div>
              )}

              <div>
                <hr />
                <h5 className='m-b'>
                  <FormattedMessage
                    id='chemical-info-header'
                    description='Header text for chemical info'
                    defaultMessage='Hiilijalanjälki'
                  />
                </h5>
                <fieldset className='form-group'>
                  <label>
                    <FormattedMessage
                      id='gwp-a1a3'
                      description='gwp-a1a3'
                      defaultMessage='GWP A1-A3'
                    />
                  </label>

                  <input
                    className='form-control'
                    type='text'
                    value={this.state.gwpValueA1A3}
                    onChange={(e) => {
                      this.setState({
                        gwpValueA1A3: e.currentTarget.value,
                      });
                    }}
                  />
                </fieldset>

                {this.state.gwpValueA1A3 !== '' && (
                  <div>
                    <fieldset className='form-group'>
                      <FormattedMessage
                        id='gwp-unit'
                        description='gwp-unit'
                        defaultMessage='Yksikkö'
                      />
                      <Select
                        className='m-t'
                        value={gwpOptions.gwpUnitOptions.filter(
                          (o) => o.value === this.state.gwpUnit
                        )}
                        onChange={(value) => {
                          this.setState({
                            gwpUnit: value.value,
                          });
                        }}
                        backspaceRemovesValue={false}
                        noOptionsMessage={() => 'None found'}
                        placeholder={'Yksikkö...'}
                        options={gwpOptions.gwpUnitOptions}
                      />
                    </fieldset>

                    <fieldset className='form-group'>
                      <FormattedMessage
                        id='gwp-verification'
                        description='gwp-verification'
                        defaultMessage='3. osapuolen verifiointi'
                      />
                      <Select
                        className='m-t'
                        value={gwpOptions.gwpVerificationOptions.filter(
                          (o) => o.value === this.state.gwpVerification
                        )}
                        onChange={(value) => {
                          this.setState({
                            gwpVerification: value.value,
                          });
                        }}
                        backspaceRemovesValue={false}
                        noOptionsMessage={() => 'None found'}
                        placeholder={'Verifikaatio...'}
                        options={gwpOptions.gwpVerificationOptions}
                      />
                    </fieldset>

                    <fieldset className='form-group'>
                      <FormattedMessage
                        id='gwp-standard'
                        description='gwp-standard'
                        defaultMessage='Standardi'
                      />
                      <Select
                        className='m-t m-b'
                        value={gwpOptions.gwpStandardOptions.filter(
                          (o) => o.value === this.state.gwpStandard
                        )}
                        onChange={(value) => {
                          this.setState({
                            gwpStandard: value.value,
                          });
                        }}
                        backspaceRemovesValue={false}
                        noOptionsMessage={() => 'None found'}
                        placeholder={'Standardi...'}
                        options={gwpOptions.gwpStandardOptions}
                      />
                    </fieldset>
                  </div>
                )}
              </div>

              <fieldset>
                <small className='text-muted'>
                  <FormattedMessage
                    id='asterisk-means-required'
                    description='Explanation that fields with * = required'
                    defaultMessage={
                      'Tähdellä (*) merkityt kentät ovat pakollisia.'
                    }
                  />
                </small>
              </fieldset>
            </form>
            <form>
              <div className='dropzone-container m-t'>
                <Dropzone
                  onDrop={this._onDrop}
                  className='dropzone'
                  activeStyle={{
                    border: '2px solid #02bc01',
                    backgroundColor: '#f2f2f2',
                  }}
                  style={{
                    width: this.state.file ? '50%' : '100%',
                    height: this.state.file ? '210px' : '150px',
                  }}
                >
                  <i
                    className='fa fa-file-text-o'
                    aria-hidden='true'
                    style={{
                      fontSize: 32,
                      color: this.state.file ? '#02bc01' : '#000',
                    }}
                  />
                  <br />
                  {!!this.state.file ? (
                    <p className='wrap'>{this.state.file.name}</p>
                  ) : (
                    <p>
                      Pudota tiedosto tähän tai klikkaa valitaksesi tiedosto
                    </p>
                  )}
                </Dropzone>
                {this.state.file && (
                  <div className='file-controls'>
                    <fieldset className={classes.fileNameGroup}>
                      <label>
                        <FormattedMessage
                          id='file-name'
                          description='File name'
                          defaultMessage='Tiedoston nimi'
                        />
                      </label>

                      <input
                        type='text'
                        className={classes.fileNameField}
                        value={this.state.fileName}
                        onChange={(e) => {
                          this.setState({
                            fileName: e.currentTarget.value,
                          });
                        }}
                      />
                    </fieldset>
                    <fieldset className={classes.fileTypeGroup}>
                      <label>
                        <FormattedMessage
                          id='file-type'
                          description='File type'
                          defaultMessage='Tiedoston tyyppi'
                        />
                      </label>
                      <select
                        className={classes.fileTypeField}
                        value={this.state.fileType}
                        onChange={(e) => {
                          this.setState({
                            fileType: e.currentTarget.value,
                          });
                        }}
                      >
                        <FormattedMessage
                          id='file-type-no-choice'
                          description='Label for no choice in file type list'
                          defaultMessage='--- Valitse tiedoston tyyppi ---'
                        >
                          {(message) => (
                            <option value='NO_CHOICE'>{message}</option>
                          )}
                        </FormattedMessage>

                        {Object.keys(FileTypes).map((key) => {
                          return (
                            <option key={key} value={key}>
                              {this.props.intl.formatMessage(
                                messages[FileTypes[key]]
                              )}
                            </option>
                          );
                        })}
                      </select>
                    </fieldset>
                    <button
                      className='btn btn-success pull-right'
                      onClick={this._addFile}
                    >
                      <FormattedMessage
                        id='add-file'
                        description='Add file'
                        defaultMessage='Lisää tiedosto'
                      />
                    </button>
                  </div>
                )}
              </div>
            </form>
            {this._fileRows()}
          </div>

          <div className='modal-footer'>
            <div className='row justify-content-center d-xs-block'>
              <button className='btn btn-warning' data-dismiss='modal'>
                <FormattedMessage
                  id='manual-product-cancel'
                  description='Cancel button text when adding manual product'
                  defaultMessage='Peruuta'
                />
              </button>

              <button
                className='btn btn-success'
                onClick={this.addManualProduct}
              >
                <FormattedMessage
                  id='manual-product-accept'
                  description={'Accept button text when adding manual product'}
                  defaultMessage='Luo tuote'
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div
        className='modal fade AddProductModal'
        ref={(div) => {
          this.addProductModal = div;
        }}
        id='addProductModal'
        tabIndex='-1'
        data-backdrop='static'
      >
        {this.manualProductModal()}
      </div>
    );
  }
}

export default injectIntl(AddProductModal);
