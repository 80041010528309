/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import HeaderSearch from '../HeaderSearch/HeaderSearch';
import HeaderUser from '../HeaderUser/HeaderUser';
import Navigation from '../Navigation/Navigation.jsx';
import MobileMenu from '../MobileMenu/MobileMenu';
import classNames from 'classnames';
import inject from '../../stores/Injector';

class Header extends React.Component {
  static propTypes = {
    packageActionCreators: PropTypes.object.isRequired,
    packages: PropTypes.array,
    rtActionCreators: PropTypes.object.isRequired,
    searchLoading: PropTypes.bool.isRequired,
    user: PropTypes.object,
  };

  state = {
    menuOpen: false,
    searchOpen: false,
  };

  _toggleMenu = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  };

  _toggleSearch = () => {
    this.setState({
      searchOpen: !this.state.searchOpen,
    });
  };

  render() {
    const headerSearchClasses = classNames('Header-search-container', {
      'hidden-xs-down': !this.state.searchOpen,
    });

    const searchIconClasses = classNames('fa', {
      'fa-search-plus': !this.state.searchOpen,
      'fa-search-minus': this.state.searchOpen,
    });

    return (
      <div className='Header row'>
        <nav
          className={classNames(
            'Header-top-bar',
            'col-xs-12',
            'navbar',
            'navbar-inverse',
            'navbar-static-top'
          )}
        >
          <button
            onClick={this._toggleMenu}
            className='Header-menu-button btn hidden-sm-up'
          >
            <i className='fa fa-bars'></i>
          </button>

          <a className='navbar-brand p-y-0' href='/'>
            <img
              className='hidden-md-down'
              style={{ height: 40 }}
              src='/images/rt-brand-full.jpg'
            />
            <img
              className='hidden-lg-up'
              style={{ height: 40 }}
              src='/images/rt-brand-full-mobile.png'
            />
          </a>

          <button
            onClick={this._toggleSearch}
            className='Header-search-button btn hidden-sm-up'
          >
            <i className={searchIconClasses}></i>
          </button>

          <div className={headerSearchClasses}>
            <div className='Header-search-row'>
              <HeaderSearch
                packageActionCreators={this.props.packageActionCreators}
                packages={this.props.packages}
                searchLoading={this.props.searchLoading}
                toggleSearch={this._toggleSearch}
              />
            </div>
          </div>
          <div className='Header-user-container hidden-xs-down'>
            <HeaderUser
              rtActionCreators={this.props.rtActionCreators}
              user={this.props.user}
            />
          </div>
        </nav>

        <div className='hidden-xs-down full-width'>
          <Navigation user={this.props.user} />
        </div>

        <MobileMenu
          user={this.props.user}
          toggleMenu={this._toggleMenu}
          menuOpen={this.state.menuOpen}
        />
      </div>
    );
  }
}

export default inject(Header);
