import PropTypes from 'prop-types';
import React from 'react';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Messages from '../Messages/Messages';
import CompanyRightsTable from '../CompanyRightsTable/CompanyRightsTable';

import { FormattedMessage } from 'react-intl';

class CompanyRightsView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    companyStore: PropTypes.object.isRequired,
    rtActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    companyActionCreators: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this._getStoreData();
  }

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );

    this._companyStoreSubscription = this.props.companyStore.addListener(
      this._onStoreChange
    );
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
    this._companyStoreSubscription.remove();
  }

  _onStoreChange = () => {
    this.setState(this._getStoreData());
  };

  _getStoreData = () => {
    return {
      companyState: this.props.companyStore.getState(),
      packageState: this.props.packageStore.getState(),
      rtState: this.props.rtStore.getState(),
    };
  };

  _getContent = () => {
    return (
      <div className='container-fluid'>
        <Header
          user={this.state.rtState.user}
          packages={this.state.packageState.packageSearch}
          searchLoading={this.state.packageState.packageSearchLoading}
          packageActionCreators={this.props.packageActionCreators}
        />

        <Messages
          messageStore={this.props.messageStore}
          messageActionCreators={this.props.messageActionCreators}
        />

        <div className='CompanyRightsView-content row'>
          <div className='col-xs-12 col-sm-10 col-sm-offset-1'>
            <h2 className='m-b-lg'>
              <FormattedMessage
                id='company-rights-header'
                description='Header for the company rights page'
                defaultMessage='Käyttöoikeuksien hallinta'
              />
            </h2>

            <div className='info'>
              <p>
                <strong>{'RT pääkäyttäjä'}</strong>
                {' = Lisenssienhallinnassa ' +
                  'asetettu Rakennustiedon pääkäyttäjäoikeus, ' +
                  'laajin mahdollinen ' +
                  'käyttöoikeus. THP pääkäyttäjä-roolin oikeuksien lisäksi ' +
                  'lisenssien hallinnointioikeudet. Oikeus myönnetään ' +
                  'Lisenssienhallinnassa.'}
              </p>

              <p>
                <strong>{'THP pääkäyttäjä'}</strong>
                {' = THP:n laajin ' +
                  'käyttöoikeus. THP-hallinnoija roolin lisäksi näkee ' +
                  'käyttäjähallinnan ja voi muokata THP pääkäyttäjä ja ' +
                  'THP-hallinnoija oikeuksia. Näkee kaikki yrityksen ' +
                  'työmaat. Oikeus myönnetään tällä sivulla. '}
              </p>

              <p>
                <strong>{'THP hallinnoija'}</strong>
                {' = Perusominaisuuksien' +
                  ' lisäksi voi antaa toiselle käyttäjälle oikeuden ' +
                  'tuotekelpoisuuden tarkastamiseen. ' +
                  'Lisäksi käyttäjä vaatii vähintään tämän käyttöoikeuden ' +
                  'arkistoidakseen hankkeen. Näkee vain omat ja hänelle lisätyt' +
                  ' työmaat. Oikeus myönnetään tällä sivulla.'}
              </p>
            </div>

            <div className='row'>
              <CompanyRightsTable
                rights={this.state.companyState.rights}
                packages={this.state.packageState.packageSearch}
                packageActionCreators={this.props.packageActionCreators}
                messageActionCreators={this.props.messageActionCreators}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let content = null;

    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null ||
      this.state.companyState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='CompanyRightsView'>
        {content}
        <Footer />
      </div>
    );
  }
}

export default CompanyRightsView;
