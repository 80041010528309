import classNames from 'classnames';

interface Props {
  subpackage: {
    id: string;
    state: 0 | 1 | 2;
    name: string;
    company: {
      name: string;
    };
  };
}

const SubpackageRow = ({ subpackage }: Props) => {
  const rowClasses = classNames(
    'PackageProductsLocal-row',
    'row-flex',
    'align-vert',
    {
      incomplete: subpackage.state === 0,
      complete: subpackage.state === 2,
    }
  );

  return (
    <div className='col-xs-12 no-padding' key={subpackage.id}>
      <a href={`/package/${subpackage.id}/products#!local`}>
        <li className={rowClasses}>
          <div className='PackageProductsLocal-row-content'>
            <span>{subpackage.name}</span>

            <div className='PackageProductsLocal-row-content---identifier'>
              {subpackage.company.name}
            </div>
          </div>
        </li>
      </a>
    </div>
  );
};

export default SubpackageRow;
