import _ from 'lodash';

import { ProductType } from './enum.js';

export const hasSdsFile = (product) => {
  const data =
    product.status === 1 ? product.snapshot_data || {} : product.data || {};

  const hasSdsFilesData =
    data &&
    data.files &&
    data.files.find(
      (file) =>
        file.type.toLowerCase() === 'sds' || file.type.toLowerCase() === 'ktt'
    );

  const hasSdsFilesRoot =
    product.files &&
    product.files &&
    product.files.find(
      (file) =>
        file.type.toLowerCase() === 'sds' || file.type.toLowerCase() === 'ktt'
    );

  const hasRootFileTypeSds =
    product.file_types &&
    product.file_types.find(
      (ft) => ft.toLowerCase() === 'sds' || ft.toLowerCase() === 'ktt'
    );

  return !!hasSdsFilesRoot || !!hasSdsFilesData || !!hasRootFileTypeSds;
};

export const getProductData = (product) => {
  const snapshotData =
    product.snapshot_data && product.snapshot_data.id
      ? product.snapshot_data
      : null;

  const productData1 = product.data;
  const productData2 = _.get(product, 'data.data');

  const data = snapshotData
    ? {
        ...snapshotData,
        url: productData1 && productData1.url ? productData1.url : null,
      }
    : productData2 || productData1 || {};

  if (!data.variant) {
    data.variant = hasSdsFile(product)
      ? ProductType.CHEMICAL_PRODUCT
      : ProductType.PRODUCT;
  }
  return data;
};

export const getSafetyCodes = (product, safetyData) => {
  const data = getProductData(product);

  // use product codes for package products
  let hazardCodes = product.hazard_codes ? product.hazard_codes.split(',') : [];

  let safetyCodes = product.safety_codes ? product.safety_codes.split(',') : [];

  let warningCodes = product.warning_codes
    ? product.warning_codes.split(',')
    : [];

  // use data codes for snapshots and company products
  if (product.status === 1 || (data.manual && data.from_company)) {
    hazardCodes = data.hazard_codes ? data.hazard_codes.split(',') : [];

    safetyCodes = data.safety_codes ? data.safety_codes.split(',') : [];

    warningCodes = data.warning_codes ? data.warning_codes.split(',') : [];
  }

  // Use boomi data if product not in plan
  if (data.safety_data && !product.id) {
    hazardCodes = data.safety_data.SafetyDataHazardCodes
      ? data.safety_data.SafetyDataHazardCodes.split(',')
      : [];
    safetyCodes = data.safety_data.SafetyDataSafetyCodes
      ? data.safety_data.SafetyDataSafetyCodes.split(',')
      : [];
    warningCodes = data.safety_data.SafetyDataWarningSign
      ? data.safety_data.SafetyDataWarningSign.split(',')
      : [];
  }

  return {
    hazardCodes: hazardCodes
      .map((code) =>
        safetyData.hazardCodes.find((entry) => entry.value === code)
      )
      .filter((entry) => !!entry),
    safetyCodes: safetyCodes
      .map((code) =>
        safetyData.safetyCodes.find((entry) => entry.value === code)
      )
      .filter((entry) => !!entry),
    warningCodes: warningCodes
      .map((code) =>
        safetyData.warningCodes.find((entry) => entry.value === code)
      )
      .filter((entry) => !!entry),
  };
};

export const gwpOptions = {
  gwpUnitOptions: [
    {
      value: 'KgCo2eKg',
      label: 'kg CO2e/kg',
    },
    {
      value: 'KgCo2eM2',
      label: 'kg CO2e/m2',
    },
    {
      value: 'KgCo2eM3',
      label: 'kg CO2e/m3',
    },
    {
      value: 'KgCo2eLtr',
      label: 'kg CO2e/ltr',
    },
    {
      value: 'KgCo2eM',
      label: 'kg CO2e/m',
    },
    {
      value: 'KgCo2ePce',
      label: 'kg CO2e/kpl',
    },
    {
      value: 'KgCo2eT',
      label: 'kg CO2e/t',
    },
  ],
  gwpStandardOptions: [
    {
      value: 'Std2019',
      label: 'EN 15804:2012+A2:2019',
    },
    {
      value: 'Std2013',
      label: 'EN 15804:2012+A1:2013',
    },
    {
      value: 'Std2012',
      label: 'EN 15804:2012',
    },
    {
      value: 'Other',
      label: 'Muu',
    },
  ],
  gwpVerificationOptions: [
    {
      value: 'YES',
      label: 'KYLLÄ',
    },
    {
      value: 'NO',
      label: 'EI',
    },
  ],
};
