const dropValidator = (type, item, target, data, packages) => {
  const from = packages[item.package] || packages[data.id];
  const to = packages[target.id];

  // packages must
  const packageCanBeMoved =
    type === 'package' &&
    from &&
    to &&
    item &&
    // - have same ancestor
    item.ancestor === to.ancestor &&
    // - not be parent of target package
    !to.path?.includes(item.id) &&
    // - not be completed/archived
    // item.state !== 2 && item.state !== 3 &&
    // - target not be completed/archived
    to.state !== 2 &&
    to.state !== 3 &&
    // not be moved inside itself
    item.id !== to.id &&
    // not be moved inside package with similarly
    // named subpackage (server side)
    !to.children.some((pkg) => pkg.name === item.name);

  // products must
  const productCanBeMoved =
    type === 'product' &&
    from &&
    to &&
    item &&
    // have same ancestor
    from.ancestor === to.ancestor &&
    // - not be inside completed/archived package
    from.state !== 2 &&
    from.state !== 3 &&
    // - target not be completed/archived
    to.state !== 2 &&
    to.state !== 3 &&
    // not be moved inside itself
    from.id !== to.id &&
    // note be moved inside package with the same product
    !to.products.some((prod) => prod.data.id === item.data.id);

  return packageCanBeMoved || productCanBeMoved;
};

export default dropValidator;
