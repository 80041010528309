import PropTypes from 'prop-types';
import React from 'react';
import page from 'page';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';

import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Messages from '../Messages/Messages';
import { getPhaseTrees, groupSelected, formCsv, flattenPhases } from './utils';

class ConstructionPhaseView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    rtActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this._getStoreData(),
      loading: true,
      selected: {},
      collapsed: new Set(),
    };
  }

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
    if (this._redirectTimer) clearTimeout(this._redirectTimer);
  }

  _redirectTimer = null;

  _onStoreChange = () => {
    this.setState({ ...this._getStoreData() });
  };

  _getStoreData = () => {
    return {
      packageState: this.props.packageStore.getState(),
      rtState: this.props.rtStore.getState(),
      errors: {},
    };
  };

  _collapse = (phase) => {
    const { collapsed } = this.state;
    const newCollapsed = new Set(collapsed.values());
    const { id } = phase;
    const collapse = $(`#${id}`);

    if (collapse.hasClass('collapsing')) return;

    if (collapsed.has(id)) {
      newCollapsed.delete(id);
      collapse.collapse('show');
    } else {
      newCollapsed.add(id);
      collapse.collapse('hide');
    }

    this.setState({ collapsed: newCollapsed });
  };

  _selectPhase = (phase) => {
    if (phase === null) return;

    const { selected } = this.state;
    const newSelected = Object.assign({}, selected);
    const adding = !selected[phase.id];

    const fn = (ph) => {
      if (!ph || ph.pkgId) return;

      if (adding) {
        fn(ph.parent);
        newSelected[ph.id] = ph;
      } else {
        delete newSelected[ph.id];
      }
    };

    fn(phase);

    if (!adding) {
      const deepChildren = flattenPhases(phase.children);
      deepChildren.forEach(fn);
    }

    this.setState({ selected: newSelected });
  };

  _selectAllPhases = (phases) => {
    const selected = {};

    phases.forEach((phase) => {
      if (phase.pkgId) return;
      selected[phase.id] = phase;
    });

    this.setState({ selected });
  };

  _phaseRow = (phase) => {
    const { collapsed, selected } = this.state;
    const { children, name, parent, id } = phase;

    const toggleClasses = classNames('fa', 'fa-fw', {
      'fa-caret-down': !collapsed.has(id),
      'fa-caret-right': collapsed.has(id),
      invisible: children.length === 0,
    });

    const selectClasses = classNames('fa', 'fa-lg', {
      'fa-check-square': selected[id],
      'fa-square-o': !selected[id],
    });

    return (
      <li key={id}>
        <div
          className='ConstructionPhaseView-item'
          onClick={() => children.length > 0 && this._collapse(phase)}
        >
          <span>
            <i className={toggleClasses} />
          </span>

          <div className='ConstructionPhaseView-item-name'>
            {!parent ? <strong>{name}</strong> : <span>{name}</span>}
          </div>

          <div className='ConstructionPhaseView-item-check pull-right'>
            {phase.pkgId ? (
              <span>
                <i className='fa fa-check m-r' />
                <FormattedMessage
                  id='construction-phase-view-phase-exists'
                  description='Label for existing phase'
                  defaultMessage='URAKKA ON JO OLEMASSA'
                />
              </span>
            ) : (
              <a
                onClick={(evt) => {
                  evt.stopPropagation();
                  if (collapsed.has(id)) this._collapse(phase);
                  this._selectPhase(phase);
                }}
              >
                <i className={selectClasses} />
              </a>
            )}
          </div>
        </div>

        {children.length > 0 && (
          <ul className='ConstructionPhaseView-list collapse in' id={id}>
            {children.map(this._phaseRow)}
          </ul>
        )}
      </li>
    );
  };

  createPackages = () => {
    const { selected, packageState } = this.state;
    const groups = groupSelected(selected);
    const csvs = {};

    Object.values(groups).forEach((group) => {
      csvs[group.pkgId || packageState.package.id] = formCsv(
        flattenPhases(group.pkgId ? group.children : [group])
      );
    });

    this.props.packageActionCreators.createConstructionPhases(
      packageState.package.id,
      csvs
    );

    this.setState({ selected: {} });
  };

  _getContent = () => {
    const { selected, rtState, packageState } = this.state;
    const phases = getPhaseTrees(Object.values(packageState.packages));
    const availablePhases = flattenPhases(phases).filter(
      (phase) => !phase.pkgId
    );

    const count = Object.values(selected).length;

    const selectClasses = classNames('fa', 'fa-lg', {
      'fa-check-square': availablePhases.length === count,
      'fa-square-o': availablePhases.length !== count,
    });

    return (
      <div className='container-fluid' onClick={this._closeSearch}>
        <Header
          user={rtState.user}
          packages={packageState.packageSearch}
          searchLoading={packageState.packageSearchLoading}
          packageActionCreators={this.props.packageActionCreators}
        />

        <Messages
          messageStore={this.props.messageStore}
          messageActionCreators={this.props.messageActionCreators}
        />

        <div className='ConstructionPhaseView-content'>
          <div className='ConstructionPhaseView-header m-a'>
            <div className='ConstructionPhaseView-header-title'>
              <FormattedMessage
                id='construction-phase-view-title'
                description='Title for ConstructionPhaseView'
                defaultMessage='Rakennusvaiheiden lisääminen urakkaan "{name}"'
                values={{ name: packageState.package.name }}
              />
            </div>

            <FormattedMessage
              id='construction-phase-view-description'
              description='Description for ConstructionPhaseView'
              defaultMessage={`Täällä voit lisätä työmaahan rakennusvaiheita ja niihin
                liittyviä urakoita.`}
            />
          </div>

          <div className='ConstructionPhaseView-subheader'>
            <strong>
              <FormattedMessage
                id='construction-phase-view-subheader'
                description='Subheader for ConstructionPhaseView'
                defaultMessage='Rakennusvaiheet'
              />
            </strong>
            <div>
              <FormattedMessage
                id='construction-phase-view-select-all'
                description='Select all label for ConstructionPhaseView'
                defaultMessage='Valitse kaikki'
              />
              <a
                onClick={(evt) => {
                  evt.stopPropagation();
                  if (availablePhases.length !== count) {
                    this._selectAllPhases(availablePhases);
                  } else {
                    this.setState({ selected: {} });
                  }
                }}
              >
                <i className={selectClasses} />
              </a>
            </div>
          </div>

          <ul className='ConstructionPhaseView-list'>
            {phases.map(this._phaseRow)}
          </ul>

          <div className='ConstructionPhaseView-actions'>
            <a
              className='btn btn-warning m-r'
              href={`/package/${packageState.package.id}`}
            >
              <FormattedMessage
                id='construction-phase-view-back'
                description='Back button text for ConstructionPhaseView'
                defaultMessage='Takaisin'
              />
            </a>
            <button
              className={classNames('btn', {
                'btn-muted': count === 0,
                'btn-primary': count > 0,
              })}
              onClick={this.createPackages}
              disabled={count === 0}
            >
              <FormattedMessage
                id='construction-phase-view-create'
                description='Create button for ConstructionPhaseView'
                defaultMessage={`Luo {count, number} {count, plural, one {urakka} other
                  {urakkaa}}`}
                values={{ count }}
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const packageState = this.state.packageState;

    if (
      packageState.packagesLoading ||
      packageState.packageLoading ||
      !packageState.package ||
      !packageState.packages
    ) {
      return (
        <div className='loading loading-green loading-center m-t-lg'>
          <div></div>
        </div>
      );
    }

    if (packageState.package.parent !== null) {
      const { id, name } = packageState.package.parent;
      this._redirectTimer = setTimeout(() => {
        this._redirectTimer = null;
        page.replace(`/package/${id}/phases`);
      }, 1000);
      return (
        <FormattedMessage
          id='construction-phase-view-redirect'
          description='Message when redirecting to main site'
          defaultMessage='Sinut ohjataan työmaan {name} rakennusvaiheisiin...'
          values={{ name }}
        />
      );
    }

    let content = null;

    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='ConstructionPhaseView'>
        {content}
        <Footer />
      </div>
    );
  }
}

export default AuthenticatedView(injectIntl(ConstructionPhaseView));
