/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import { FormattedMessage } from 'react-intl';

class PackageInformationProducts extends React.Component {
  static propTypes = {
    package: PropTypes.object,
    packageLoading: PropTypes.bool.isRequired,
  };

  render() {
    if (this.props.packageLoading || !this.props.package) {
      return (
        <div className='card'>
          <div className='card-block'>
            <h4 className='card-title'>
              <FormattedMessage
                id='products'
                description='Header for product information'
                defaultMessage='Tuotteet'
              />
            </h4>

            <div className='card-text'>
              <div className='loading loading-green loading-center'>
                <div></div>
              </div>
            </div>
          </div>

          <div className='card-footer'>
            <div className='loading loading-green loading-sm loading-center'>
              <div></div>
            </div>
          </div>
        </div>
      );
    }

    const installed = this.props.package.stats.products_installed;
    const total = this.props.package.stats.products_total;
    const aggInstalled = this.props.package.stats.agg_products_installed;
    const aggTotal = this.props.package.stats.agg_products_total;
    const subInstalled = aggInstalled - installed;
    const subTotal = aggTotal - total;
    const ownPercentage = total === 0 ? 0 : (installed / total) * 100;
    const subPercentage = subTotal === 0 ? 0 : (subInstalled / subTotal) * 100;

    return (
      <div className='card'>
        <div className='card-block'>
          <h4 className='card-title'>
            <FormattedMessage
              id='products'
              description='Header for product information'
              defaultMessage='Tuotteet'
            />
          </h4>

          <div className='card-text'>
            <dl className='dl-horizontal row'>
              <dt className='col-xs-6'>
                <FormattedMessage
                  id='total'
                  description='Label for how many items there are in total'
                  defaultMessage='Yhteensä'
                />
                :
              </dt>
              <dd className='col-xs-6'>
                <FormattedMessage
                  id='pieces'
                  description='How many items, e.g. 10 pcs.'
                  defaultMessage='{count, number} kpl'
                  values={{
                    count: aggTotal,
                  }}
                />
              </dd>

              <dt className='col-xs-6'>
                <FormattedMessage
                  id='installed'
                  description='Label for how many products are installed'
                  defaultMessage='Asennettu'
                />
                :
              </dt>
              <dd className='col-xs-6'>
                <FormattedMessage
                  id='pieces'
                  description='How many items, e.g. 10 pcs.'
                  defaultMessage='{count, number} kpl'
                  values={{
                    count: aggInstalled,
                  }}
                />
              </dd>
            </dl>

            <hr />

            <p className='text-center'>
              <FormattedMessage
                id='package-products-count'
                description='How many products the package itself has'
                defaultMessage='Omat tuotteet ({count, number})'
                values={{
                  count: total,
                }}
              />
            </p>

            <p className='pull-left'>
              <strong>
                <FormattedMessage
                  id='installed'
                  description='Label for how many products are installed'
                  defaultMessage='Asennettu'
                />
              </strong>
              : {installed}
            </p>

            <p className='pull-right'>
              <strong>
                <FormattedMessage
                  id='total'
                  description='Label for how many items there are in total'
                  defaultMessage='Yhteensä'
                />
              </strong>
              : {total}
            </p>

            <div className='clearfix'></div>

            <progress
              className='progress progress-success'
              value={ownPercentage}
              max='100'
            >
              {ownPercentage}%
            </progress>

            <p className='text-center'>
              <FormattedMessage
                id='subpackage-products-count'
                description='How many products subpackages have in total'
                defaultMessage='Aliurakoiden tuotteet ({count, number})'
                values={{
                  count: subTotal,
                }}
              />
            </p>

            <p className='pull-left'>
              <strong>
                <FormattedMessage
                  id='installed'
                  description='Label for how many products are installed'
                  defaultMessage='Asennettu'
                />
              </strong>
              : {subInstalled}
            </p>

            <p className='pull-right'>
              <strong>
                <FormattedMessage
                  id='total'
                  description='Label for how many items there are in total'
                  defaultMessage='Yhteensä'
                />
              </strong>
              : {subTotal}
            </p>

            <div className='clearfix'></div>

            <progress
              className='progress progress-warning'
              value={subPercentage}
              max='100'
            >
              {subPercentage}%
            </progress>
          </div>
        </div>

        <div className='card-footer text-center'>
          <a
            className='btn btn-primary-outline'
            href={`/package/${this.props.package.id}/products`}
          >
            <i className='fa fa-pencil-square-o'></i>&nbsp;
            {this.props.package.state === 2 ? (
              <FormattedMessage
                id='view-products'
                description='View link to the product management page'
                defaultMessage='Katsele tuotteita'
              />
            ) : (
              <FormattedMessage
                id='manage-products'
                description='Manage link to the product management page'
                defaultMessage='Hallinnoi tuotteita'
              />
            )}
          </a>
        </div>
      </div>
    );
  }
}

export default PackageInformationProducts;
