import { Store } from 'flux/utils';
import ActionTypes from '../actions/ActionTypes';

class StatsStore extends Store {
  constructor(dispatcher) {
    super(dispatcher);
    this._state = this.getInitialState();
  }

  getInitialState() {
    return {
      stats: null,
      error: null,
    };
  }

  getState() {
    return this._state;
  }

  clearState() {
    this._state = this.getInitialState();
  }

  __onDispatch(payload) {
    switch (payload.type) {
      case ActionTypes.CLEAR_STORES:
        this.clearState();
        this.__emitChange();
        break;
      case ActionTypes.STATS_RECEIVE:
        this._state.stats = payload.stats;
        this.__emitChange();
        break;
      default:
      // Ignoring other ActionTypes
    }
  }
}

export default StatsStore;
