import CommonActionCreators from './CommonActionCreators';
import ActionTypes from './ActionTypes';
import ErrorTypes from '../../shared/ErrorTypes.mjs';
// import queryParser from '../utils/query';

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  addFavouriteSuccess: {
    id: 'add-favourite-success',
    description: 'Favourited a product successfully',
    defaultMessage: 'Suosikin lisääminen onnistui!',
  },
  addFavouriteFail: {
    id: 'add-favourite-fail',
    description: 'Failed to favourite a product',
    defaultMessage: 'Suosikin lisääminen ei onnistunut!',
  },
  removeFavouriteSuccess: {
    id: 'remove-favourite-success',
    description: 'Removed a favourite successfully',
    defaultMessage: 'Suosikin poistaminen onnistui.',
  },
  removeFavouriteFail: {
    id: 'remove-favourite-fail',
    description: 'Failed to remove a favourite',
    defaultMessage: 'Suosikin poistaminen ei onnistunut!',
  },
  favouriteNotFound: {
    id: 'favourite-not-found',
    description: 'Favourite not found',
    defaultMessage: 'Suosikkia ei löytynyt.',
  },
  favouriteAlreadyAdded: {
    id: 'favourite-already-added',
    description: 'Favourite already added',
    defaultMessage: 'Tuote on jo suosikeissasi!',
  },
});

class FavouritesActionCreators extends CommonActionCreators {
  constructor(dispatcher, apiUtils) {
    super(dispatcher, apiUtils);
  }

  addFavourite(productId, isProductManual) {
    const data = { productId, isProductManual };

    this._apiUtils
      .post(`/favourites`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.FAVOURITES_RECEIVE,
          product: response.body,
        });
        this._dispatchMessage('success', messages.addFavouriteSuccess);
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_FAVOURITE_ALREADY_ADDED:
            this._dispatchMessage('danger', messages.favouriteAlreadyAdded);
            console.error(error);
            break;
          default:
            this._dispatchMessage('danger', messages.addFavouriteFail);
            console.error(error);
        }
      });
  }

  removeFavourite(productId) {
    this._apiUtils
      .delete(`/favourites/${productId}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.FAVOURITES_RECEIVE,
          product: response.body,
        });
        this._dispatchMessage('success', messages.removeFavouriteSuccess);
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.FAVOURITE_NOT_FOUND:
            this._dispatchMessage('danger', messages.favouriteNotFound);
            console.error(error);
            break;
          default:
            this._dispatchMessage('danger', messages.removeFavouriteFail);
            console.error(error);
        }
      });
  }
}

export default FavouritesActionCreators;
