/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';

import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  emptyName: {
    id: 'package-name-empty',
    description: 'Error when package name is empty',
    defaultMessage: 'Urakan nimi ei voi olla tyhjä!',
  },

  emptyFile: {
    id: 'import-no-file',
    description: 'Error when no file given in import',
    defaultMessage: 'Valitse tiedosto!',
  },

  cancel: {
    id: 'cancel',
    description: 'Generic cancel',
    defaultMessage: 'Peruuta',
  },

  create: {
    id: 'create',
    description: 'Generic create',
    defaultMessage: 'Luo',
  },
});

const getErrors = () => ({
  error: false,
  addPackageNameError: null,
  importPackageFileError: null,
});

class AddPackageModal extends React.Component {
  state = {
    error: null,
    addPackageNameError: null,
    importPackageFileError: null,
    import: false,
    file: null,
  };

  componentDidMount() {
    $(this.refs.addPackageModal).on('shown.bs.modal', () => {
      this.refs.name.value = '';
      $(this.refs.name).focus();
    });
  }

  componentWillUnmount() {
    $(this.refs.addPackageModal).unbind('shown.bs.modal');
  }

  addPackage = (event) => {
    event.preventDefault();
    const errors = getErrors();

    if (this.state.import) {
      if (!this.state.file) {
        errors.error = true;
        errors.importPackageFileError = this.props.intl.formatMessage(
          messages.emptyFile
        );
      }

      this.setState(errors);
      if (errors.error) {
        return;
      }

      this.props.messageActionCreators.clearMessages();
      this.props.packageActionCreators.importPackage(
        this.props.parent,
        this.state.file,
        !!this.refs.includeProducts.checked
      );
    } else {
      const data = {
        name: this.refs.name.value,
        parent: this.props.parent,
      };

      if (data.name.length <= 0) {
        errors.error = true;
        errors.addPackageNameError = this.props.intl.formatMessage(
          messages.emptyName
        );
      }

      this.setState(errors);
      if (errors.error) {
        return;
      }

      this.props.messageActionCreators.clearMessages();
      this.props.packageActionCreators.addPackage(data);
    }

    $(this.refs.addPackageModal).removeClass('fade');
    $(this.refs.addPackageModal).modal('hide');
  };

  _toggleImport = (event) => {
    event.preventDefault();
    if (this.props.parent) {
      this.setState((prevState) => ({ import: !prevState.import }));
    }
  };

  _onDrop = (files) => {
    this.setState({ file: files[0] });
  };

  render() {
    const addPackageNameGroupClasses = classNames('form-group', {
      'has-error': this.state.addPackageNameError,
    });

    const addPackageNameFieldClasses = classNames('form-control', {
      'form-control-error': this.state.addPackageNameError,
    });

    const importPackageGroupClasses = classNames('form-group');

    return (
      <div
        className='AddPackageModal modal fade'
        ref='addPackageModal'
        id='AddPackageModal'
        tabIndex='-1'
        data-backdrop='static'
      >
        <div className='modal-dialog'>
          <form className='modal-content' onSubmit={this.addPackage}>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                <span>&times;</span>
              </button>
              <h4 className='modal-title'>
                {this.props.parent ? (
                  <FormattedMessage
                    id='create-subpackage-header'
                    description='Create subpackage header'
                    defaultMessage='Luo aliurakka'
                  />
                ) : (
                  <FormattedMessage
                    id='create-construction-site-header'
                    description='Create construction site header'
                    defaultMessage='Luo työmaa'
                  />
                )}
              </h4>
            </div>

            <div className='AddPackageModal-content modal-body'>
              <div
                className={addPackageNameGroupClasses}
                style={{
                  visibility: this.state.import ? 'hidden' : 'visible',
                  position: this.state.import ? 'absolute' : 'relative',
                  top: this.state.import ? '-250px' : '0px',
                }}
              >
                <label>
                  {this.props.parent ? (
                    <FormattedMessage
                      id='subpackage-name'
                      description='Subpackage name'
                      defaultMessage='Aliurakan nimi'
                    />
                  ) : (
                    <FormattedMessage
                      id='construction-site-name'
                      description='Construction site name'
                      defaultMessage='Työmaan nimi'
                    />
                  )}
                </label>

                <input
                  type='text'
                  className={addPackageNameFieldClasses}
                  ref='name'
                />

                <small className='text-danger'>
                  {this.state.addPackageNameError}
                </small>
                {Boolean(this.props.parent) && (
                  <div>
                    <p className='m-t center'>tai</p>

                    <input
                      type='button'
                      className='btn btn-default center'
                      onClick={this._toggleImport}
                      value='Tuo urakkapohja'
                    />
                  </div>
                )}
              </div>
              <div
                className={importPackageGroupClasses}
                style={{
                  visibility: this.state.import ? 'visible' : 'hidden',
                  position: this.state.import ? 'relative' : 'absolute',
                  top: this.state.import ? '0px' : '250px',
                }}
              >
                <label>
                  <FormattedMessage
                    id='import-package-label'
                    description='Label for package import'
                    defaultMessage='Tuo urakkapohja'
                  />
                </label>

                <Dropzone className='dropzone' onDrop={this._onDrop}>
                  <i
                    className='fa fa-file-text-o'
                    aria-hidden='true'
                    style={{
                      fontSize: 32,
                      color: this.state.file ? '#02bc01' : '#000',
                    }}
                  />
                  <br />
                  {this.state.file ? (
                    <p className='wrap'>{this.state.file.name}</p>
                  ) : (
                    <p>
                      Pudota tiedosto tähän tai klikkaa valitaksesi tiedosto
                    </p>
                  )}
                </Dropzone>

                <div className='m-t'>
                  <input
                    className='m-r'
                    type='checkbox'
                    ref='includeProducts'
                    defaultChecked
                  />
                  <FormattedMessage
                    id='import-include-products'
                    description='Checkbox for including products in import'
                    defaultMessage='Sisällytä tuotteet'
                  />
                </div>

                <p className='m-t center'>tai</p>

                <input
                  type='button'
                  className='btn btn-default center'
                  onClick={this._toggleImport}
                  value='Luo tyhjä urakka'
                />
              </div>
            </div>

            <div className='modal-footer text-center'>
              <input
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                value={this.props.intl.formatMessage(messages.cancel)}
              />

              <input
                type='submit'
                className='btn btn-success'
                value={this.props.intl.formatMessage(messages.create)}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

AddPackageModal.proptTypes = {
  packageActionCreators: PropTypes.object.isRequired,
  messageActionCreators: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  parent: PropTypes.number,
};

export default injectIntl(AddPackageModal);
