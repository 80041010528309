/**
 * ConfirmActionModal.js - React component base
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';

class ConfirmActionModal extends React.Component {
  state = {
    dontShowAgain: false,
  };

  componentWillUnmount() {
    $(this.refs.confirmActionModal).unbind('shown.bs.modal');
  }

  confirmAction = (event) => {
    event.preventDefault();
    const actionName = event.nativeEvent.submitter.name;
    const { dontShowAgain } = this.state;
    this.props.onConfirm({ dontShowAgain, actionName });
    $(this.refs.confirmActionModal).removeClass('fade');
    $(this.refs.confirmActionModal).modal('hide');
  };

  toggleShowAgain = (event) => {
    if (event) event.preventDefault();
    this.state.dontShowAgain = !this.state.dontShowAgain;
  };

  render() {
    return (
      <div
        className='ConfirmActionModal modal fade'
        ref='confirmActionModal'
        id={this.props.id}
        tabIndex='-1'
        data-backdrop='static'
      >
        <div className='ConfirmActionModal-content modal-dialog'>
          <form className='modal-content' onSubmit={this.confirmAction}>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                <span>&times;</span>
              </button>
              <h4 className='modal-title'>{this.props.title}</h4>
            </div>
            <div className='modal-body'>{this.props.info}</div>
            {this.props.hideable && (
              <div className='showagain' onClick={this.toggleShowAgain}>
                {this.state.dontShowAgain ? (
                  <a
                    data-toggle='tooltip'
                    data-placement='bottom'
                    title='Näytä uudestaan'
                  >
                    <i className='fa fa-check-square-o fa-2x' />
                  </a>
                ) : (
                  <a
                    data-toggle='tooltip'
                    data-placement='bottom'
                    title='Älä näytä hetkeen uudestaan'
                  >
                    <i className='fa fa-square-o fa-2x' />
                  </a>
                )}
                <p>Älä näytä hetkeen uudestaan</p>
              </div>
            )}
            <div className='modal-footer text-center'>
              <input
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                value='Peruuta'
              />

              {this.props.confirmActions &&
              this.props.confirmActions.length > 0 ? (
                this.props.confirmActions
              ) : (
                <input
                  type='submit'
                  name='confirm'
                  className='btn btn-success'
                  value='Vahvista'
                />
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ConfirmActionModal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.node,
  onConfirm: PropTypes.func,
  hideable: PropTypes.bool,
  confirmActions: PropTypes.arrayOf(PropTypes.node),
};

export default ConfirmActionModal;
