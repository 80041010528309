/**
 * BulletinsView.js - React component base
 *
 * Copyright 2015-2016 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Messages from '../Messages/Messages';

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class BulletinView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    bulletinStore: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    bulletinActionCreators: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this._getStoreData();
  }

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );

    this._bulletinStoreSubscription = this.props.bulletinStore.addListener(
      this._onStoreChange
    );
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
    this._bulletinStoreSubscription.remove();
  }

  _onStoreChange = () => {
    this.setState(this._getStoreData());
  };

  _getStoreData = () => {
    return {
      packageState: this.props.packageStore.getState(),
      rtState: this.props.rtStore.getState(),
      bulletinState: this.props.bulletinStore.getState(),
      errors: {},
    };
  };

  _formatDate = (date) => {
    const notTooShortIdentifierName = new Date(date);
    return (
      notTooShortIdentifierName.getUTCDate() +
      '.' +
      (notTooShortIdentifierName.getUTCMonth() + 1) +
      '.' +
      notTooShortIdentifierName.getUTCFullYear()
    );
  };

  _bulletinRow = (bulletin) => {
    return (
      <li id={bulletin.id} key={bulletin.id} className='list-group-item'>
        <h4>{bulletin.title}</h4>
        <small>{this._formatDate(bulletin.validfrom)}</small>
        <p>
          <FormattedHTMLMessage
            id='bulletin-content'
            description='Bulletin text content, may contain HTML'
            defaultMessage={bulletin.content}
          />
        </p>
      </li>
    );
  };

  _getBulletins = () => {
    if (!this.state.bulletinState.bulletins) {
      return (
        <div className='loading loading-green loading-center m-t-md'>
          <div></div>
        </div>
      );
    }

    return (
      <ul className='BulletinsList list-group'>
        {this.state.bulletinState.bulletins.map(this._bulletinRow)}
      </ul>
    );
  };

  _getContent = () => {
    return (
      <div className='container-fluid'>
        <Header
          user={this.state.rtState.user}
          packages={this.state.packageState.packageSearch}
          searchLoading={this.state.packageState.packageSearchLoading}
          packageActionCreators={this.props.packageActionCreators}
        />

        <Messages
          messageStore={this.props.messageStore}
          messageActionCreators={this.props.messageActionCreators}
        />

        <div className='BulletinView-content row'>
          <div className='col-xs-12 col-sm-10 col-sm-offset-1'>
            <h2>
              <FormattedMessage
                id='bulletins'
                description='Bulletins header'
                defaultMessage='Tiedotteet'
              />
            </h2>
          </div>

          <div className='col-xs-12 col-sm-10 col-sm-offset-1'>
            {this._getBulletins()}
          </div>
        </div>
      </div>
    );
  };

  render() {
    let content = null;

    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null ||
      this.state.bulletinState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='BulletinView'>
        {content}
        <Footer />
      </div>
    );
  }
}

export default AuthenticatedView(BulletinView);
