import PropTypes from 'prop-types';
import React from 'react';
import { DragSource } from 'react-dnd';

const productSource = {
  beginDrag(props) {
    return props.product;
  },
  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }

    const drop = monitor.getDropResult();
    props.onDrop(drop);
  },
  canDrag(props) {
    return props.canDrag;
  },
};

class ProductDragSource extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    canDrag: PropTypes.bool,
    product: PropTypes.object.isRequired,
    preview: PropTypes.any,
    onDrop: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func,
    connectDragPreview: PropTypes.func,
    isDragging: PropTypes.bool,
  };

  static defaultProps = {
    canDrag: true,
    count: 0,
    preview: null,
  };

  componentWillReceiveProps(props) {
    const { preview, connectDragPreview } = props;
    if (preview) {
      connectDragPreview(preview);
    } else {
      // calling with nothing resets the preview
      connectDragPreview();
    }
  }

  render() {
    const {
      children,
      connectDragSource,
    } = this.props;

    return connectDragSource ? connectDragSource(children) : null;
  }
}

export default DragSource('product', productSource, (connect, monitor) => ({
  connectDragPreview: connect.dragPreview(),
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(ProductDragSource);
