import CommonActionCreators from './CommonActionCreators';
import ActionTypes from './ActionTypes';
import ErrorTypes from '../../shared/ErrorTypes.mjs';

class LogActionCreators extends CommonActionCreators {
  constructor(dispatcher, apiUtils) {
    super(dispatcher, apiUtils);
  }

  getLogs() {
    this._apiUtils
      .get('/logs')
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.LOGS_RECEIVE,
          logs: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        this._dispatcher.dispatch({
          type: ActionTypes.LOGS_RECEIVE_ERROR,
          error: error,
        });
      });
  }

  clearLog() {
    this._dispatcher.dispatch({
      type: ActionTypes.LOG_RECEIVE,
      log: null,
    });
  }

  getProductLog(id) {
    this._apiUtils
      .get(`/products/${id}/log`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.LOG_RECEIVE,
          log: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_LOG_NOT_FOUND:
            break;

          default:
            this._dispatcher.dispatch({
              type: ActionTypes.LOG_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  getPackageLog(id) {
    this._apiUtils
      .get(`/packages/${id}/log`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.LOG_RECEIVE,
          log: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_LOG_NOT_FOUND:
            break;

          default:
            this._dispatcher.dispatch({
              type: ActionTypes.LOG_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }
}

export default LogActionCreators;
