import React from 'react';
import PropTypes from 'prop-types';
import './ToggleSwitch.scss';

export const ToggleSwitch = ({ id, name, checked, onChange,
      optionLabels, small, disabled }) => {
  function handleKeyPress(event) {
    try {
      if (event.nativeEvent.code.toLowerCase() === 'space') {
        event.preventDefault();
        onChange(!checked);
      }
    } catch (exc) {
      // not sure if the event.nativeEvent.code.toLowerCase() is always safe
      // just pass
    }
  }

  return (
    <div className={'toggle-switch' + (small ? ' small-switch' : '')}>
      <input
        type='checkbox'
        name={name}
        className='toggle-switch-checkbox'
        id={id}
        checked={checked}
        onChange={event => onChange(event.target.checked)}
        disabled={disabled}
      />
        {id ? (
          <label className='toggle-switch-label'
            htmlFor={id}
            tabIndex={disabled ? -1 : 1}
            onKeyDown={(event) => {handleKeyPress(event); }}
          >
            <span
              className={
                disabled
                  ? 'toggle-switch-inner toggle-switch-disabled'
                  : 'toggle-switch-inner'
              }
              data-yes={optionLabels[0]}
              data-no={optionLabels[1]}
              tabIndex={-1}
            />
            <span
              className={
              disabled
                ? 'toggle-switch-switch toggle-switch-disabled'
                : 'toggle-switch-switch'
              }
              tabIndex={-1}
            />
          </label>
          ) : null
         }
      </div>
    );
};

ToggleSwitch.defaultProps = {
  optionLabels: ['Yes', 'No'],
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  optionLabels: PropTypes.array,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ToggleSwitch;
