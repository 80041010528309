/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import { FormattedMessage } from 'react-intl';

class PackageInformationRights extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    package: PropTypes.object,
    packageLoading: PropTypes.bool.isRequired,
    rights: PropTypes.array,
    rightsLoading: PropTypes.bool.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
  };

  render() {
    if (
      this.props.packageLoading ||
      !this.props.package ||
      this.props.rightsLoading ||
      !this.props.rights
    ) {
      return (
        <div className='PackageInformationRights card'>
          <div className='card-block'>
            <h4 className='card-title'>
              <FormattedMessage
                id='rights'
                description='Header for rights'
                defaultMessage='Oikeudet'
              />
            </h4>

            <div className='card-text'>
              <div className='loading loading-green loading-center'>
                <div></div>
              </div>
            </div>
          </div>

          <div className='card-footer'>
            <div className='loading loading-green loading-sm loading-center'>
              <div></div>
            </div>
          </div>
        </div>
      );
    }

    const rights = this.props.rights.filter((access) => {
      if (!access.package) return false;
      return access.package.id === this.props.package.id;
    });

    return (
      <div className='PackageInformationRights card'>
        <div className='card-block'>
          <h4 className='card-title'>
            <FormattedMessage
              id='rights'
              description='Header for rights'
              defaultMessage='Oikeudet'
            />
          </h4>

          <div className='card-text'>
            <p>
              <FormattedMessage
                id='package-specific-rights-explanation'
                description='Explanation text for package specific rights list'
                defaultMessage={
                  'Seuraavilla käyttäjillä on erillisoikeudet' +
                  ' tähän pakettiin'
                }
              />
              :
            </p>

            <ul className='list-group'>
              {!rights.length ? (
                <small className='m-l text-muted'>
                  <FormattedMessage
                    id='no-users-found'
                    description='Placeholder when no users where found'
                    defaultMessage='Ei käyttäjiä.'
                  />
                </small>
              ) : null}

              {rights.map((access) => {
                return (
                  <li className='list-group-item' key={access.username}>
                    <span>{access.username}</span>
                    <small style={{ display: 'block' }}>
                      {access.organization}
                    </small>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className='card-footer text-center'>
          <a
            className='btn btn-primary-outline'
            href={`/package/${this.props.package.id}/rights`}
          >
            <i className='fa fa-pencil-square-o'></i>&nbsp;
            <FormattedMessage
              id='manage-users'
              description='Link to the user management page'
              defaultMessage='Hallinnoi oikeuksia'
            />
          </a>
          {this.props.user &&
            this.props.package?.ancestor?.company?.id ===
              this.props.user?.organizationIds[0] &&
            (this.props.user.admin ||
              this.props.user.role === 0 ||
              this.props.user.role === 1) && (
              <button
                className='btn btn-primary-outline m-t'
                onClick={() => {
                  this.props.packageActionCreators.downloadRightsReport(
                    this.props.package
                  );
                }}
              >
                <i className='fa fa-download'></i>&nbsp;
                <FormattedMessage
                  id='download-rights-report'
                  description='Download rights report button'
                  defaultMessage='Lataa käyttöoikeusraportti'
                />
              </button>
            )}
        </div>
      </div>
    );
  }
}

export default PackageInformationRights;
