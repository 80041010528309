/**
 * AddFileModal.js - React component base
 *
 * Copyright 2023 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

import FileTypes from '../../../shared/FileTypes.mjs';

import Dropzone from 'react-dropzone';

const messages = defineMessages({
  emptyName: {
    id: 'file-upload-no-name',
    description: 'Error when empty name while uploading file',
    defaultMessage: 'Tiedoston nimi ei voi olla tyhjä!',
  },

  emptyFile: {
    id: 'file-upload-no-file',
    description: 'Error when no file selected',
    defaultMessage: 'Sinun on valittava tiedosto!',
  },

  emptyType: {
    id: 'file-upload-no-type',
    description: 'Error when no type selected',
    defaultMessage: 'Sinun on valittava tiedostotyyppi!',
  },

  fileTypeDOP: {
    id: 'filetype-dop',
    description: 'File type for DOP',
    defaultMessage: 'DOP - Suoritustasoilmoitus',
  },

  fileTypeKTT: {
    id: 'filetype-ktt',
    description: 'File type for KTT',
    defaultMessage: 'KTT - Käyttöturvallisuustiedote',
  },

  fileTypeINSTR: {
    id: 'filetype-instr',
    description: 'File type for INSTR',
    defaultMessage: 'Ohje',
  },

  fileTypeATT: {
    id: 'filetype-att',
    description: 'File type for ATT',
    defaultMessage: 'Muu liite',
  },

  fileTypeEPD: {
    id: 'filetype-epd',
    description: 'File type for EPD',
    defaultMessage: 'EPD - Ympäristöseloste',
  },

  fileTypeM1: {
    id: 'filetype-m1',
    description: 'File type for M1',
    defaultMessage: 'M1 - Päästöluokitus',
  },
  fileTypeCERT: {
    id: 'filetype-cert',
    description: 'File type for CERT',
    defaultMessage: 'CERT - Tuotteen sertifikaatti',
  },
});

class AddFileModal extends React.Component {
  static propTypes = {
    product: PropTypes.object,
    fileActionCreators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
 
    this.addFileModal = null;

    const errors = this.getErrors();

    let openMenu = false;
    if (window.matchMedia('screen and (max-width: 34em)').matches) {
      openMenu = !!props.product;
    }

    this.state = {
      openMenu,
      file: null,
      fileName: '',
      fileType: 'NO_CHOICE',
      ...errors,
    };
  }

  componentDidMount() {
    $(this.addFileModal).on('hidden.bs.modal', () => {
      this.setState({
        file: null,
        fileName: '',
        fileType: 'NO_CHOICE',
        ...this.getErrors()
      });
    });
  }

  componentWillUnmount() {
    $(this.addFileModal).unbind('hidden.bs.modal');
  }

  getErrors = () => {
    return {
      error: null,
      noNameError: null,
      noFileError: null,
      noTypeError: null,
    };
  };

  _onDrop = (files) => {
    this.setState({
      file: files[0],
      fileName: this.state.fileName || files[0].name
    });
  };

  _addFile = (event) => {
    event.preventDefault();

    const errors = this.getErrors();

    if (!this.state.fileName) {
      errors.error = true;
      errors.noNameError = this.props.intl.formatMessage(messages.emptyName);
    }

    if (!this.state.file) {
      errors.error = true;
      errors.noFileError = this.props.intl.formatMessage(messages.emptyFile);
    }

    if (this.state.fileType === 'NO_CHOICE') {
      errors.error = true;
      errors.noTypeError = this.props.intl.formatMessage(messages.emptyType);
    }

    this.setState(errors);
    if (errors.error) return;

    this.props.fileActionCreators.addFiles(
      this.props.product.id,
      this.props.product.package,
      [
        {
          file: this.state.file,
          name: this.state.fileName,
          type: this.state.fileType,
        },
      ]
    );

    this.setState({
      file: undefined,
      fileName: '',
      fileType: 'NO_CHOICE'
    });

    $(this.addFileModal).removeClass('fade');
    $(this.addFileModal).modal('hide');
  };

  render() {
    const addFileNameGroupClasses = classNames('form-group', {
      'has-error': this.state.noNameError,
    });

    const addFileNameFieldClasses = classNames('form-control', {
      'form-control-error': this.state.noNameError,
    });

    const addFileFileGroupClasses = classNames('form-group', {
      'has-error': this.state.noFileError,
    });
    const addFileTypeGroupClasses = classNames({
      'has-error': this.state.noTypeError,
    });

    const addFileTypeFieldClasses = classNames('form-control', {
      'form-control-error': this.state.noTypeError,
    });

    return (
      <div
        className='AddFileModal modal fade'
        ref={(div) => {
          this.addFileModal = div;
        }}
        id='AddFileModal'
        tabIndex='-1'
        data-backdrop='static'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                <span>&times;</span>
              </button>
              <h4
                className='modal-title'
                style={{ padding: '10px 0px 0px', margin: '0px 0px 10px' }}
              >
                <FormattedMessage
                  id='add-file-header'
                  description='File adding header'
                  defaultMessage='Lisää tiedosto tuotteeseen'
                />
              </h4>
            </div>

            <div className='modal-body'>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <fieldset className={addFileFileGroupClasses}>
                  <label>
                    <FormattedMessage
                      id='file'
                      description='Generic file'
                      defaultMessage='Tiedosto'
                    />
                    &nbsp;
                    <FormattedMessage
                      id='file_size_limit'
                      description='File size limit warning'
                      defaultMessage='(maksimikoko 20 megatavua)'
                    />
                  </label>

                  <section>
                    <div>
                      <Dropzone
                        onDrop={this._onDrop}
                        className='dropzone'
                        activeStyle={{
                          border: '2px solid #02bc01',
                          backgroundColor: '#f2f2f2',
                        }}
                      >
                        <i
                          className='fa fa-file-text-o'
                          aria-hidden='true'
                          style={{
                            fontSize: 32,
                            color: this.state.file ? '#02bc01' : '#000',
                          }}
                        />
                        <br />
                        {!!this.state.file ? (
                          <p>{this.state.file.name}</p>
                        ) : (
                          <p>
                            Pudota tiedosto tähän tai klikkaa valitaksesi
                            tiedosto
                          </p>
                        )}
                      </Dropzone>
                    </div>
                  </section>
                  <small className='text-danger'>
                    {this.state.noFileError}
                  </small>
                </fieldset>

                <fieldset className={addFileNameGroupClasses}>
                  <label>
                    <FormattedMessage
                      id='display_name'
                      description='Generic display name'
                      defaultMessage='Näytettävä nimi (pakollinen)'
                    />
                  </label>

                  <input
                    type='text'
                    value={this.state.fileName}
                    onChange={(event) => {
                      this.setState({
                        fileName: event.currentTarget.value,
                      });
                    }}
                    className={addFileNameFieldClasses}
                  />

                  <small className='text-danger'>
                    {this.state.noNameError}
                  </small>
                </fieldset>

                <fieldset className={addFileTypeGroupClasses}>
                  <label>
                    <FormattedMessage
                      id='type'
                      description='Generic type'
                      defaultMessage='Tyyppi'
                    />
                  </label>

                  <select
                    className={addFileTypeFieldClasses}
                    value={this.state.fileType}
                    onChange={(event) => {
                      this.setState({
                        fileType: event.currentTarget.value,
                      });
                    }}
                  >
                    <FormattedMessage
                      id='file-type-no-choice'
                      description='Label for no choice in file type list'
                      defaultMessage='--- Valitse tiedoston tyyppi ---'
                    >
                      {(message) => (
                        <option value='NO_CHOICE'>{message}</option>
                      )}
                    </FormattedMessage>

                    {Object.keys(FileTypes).map((key) => {
                      return (
                        <option key={key} value={key}>
                          {this.props.intl.formatMessage(
                            messages[FileTypes[key]]
                          )}
                        </option>
                      );
                    })}
                  </select>
                  <small className='text-danger'>
                    {this.state.noTypeError}
                  </small>
                </fieldset>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                className='btn btn-warning pull-right'
                data-dismiss='modal'
              >
                <FormattedMessage
                  id='add-file-cancel'
                  description='Cancel button text when adding a file'
                  defaultMessage='Peruuta'
                />
              </button>

              <button
                className='btn btn-success pull-right m-r'
                onClick={this._addFile}
              >
                <FormattedMessage
                  id='add-file-accept'
                  description='Accept button text when adding a file'
                  defaultMessage='Lisää'
                />
              </button>

              <div className='clearfix' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(AddFileModal);
