/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { naturalCompare } from '../../utils/packageUtils';

class PackageInformationSubpackages extends React.Component {
  static propTypes = {
    package: PropTypes.object,
    packageLoading: PropTypes.bool.isRequired,
    user: PropTypes.object,
    packageActionCreators: PropTypes.object.isRequired,
  };

  _setAddToParent = () => {
    this.props.packageActionCreators.setAddToParent(this.props.package.id);
  };

  _subpackages = () => {
    const { stats } = this.props.package;
    if (stats.subpackages_total === 0) {
      return (
        <small className='m-l text-muted'>
          <FormattedMessage
            id='no-subpackages'
            description='Message shown when there are no subpackages'
            defaultMessage='Ei aliurakoita'
          />
        </small>
      );
    }

    const mapped = Object.entries(stats.descendants ?? []).map((entry) => ({
      key: entry[0],
      name: entry[1],
    }));

    const sorted = mapped.sort((pkgA, pkgB) => {
      return naturalCompare(pkgA.name, pkgB.name);
    });

    return (
      <div className='list-group'>
        {sorted.map((pkg) => {
          return (
            <a
              key={pkg.key}
              className='list-group-item'
              href={`/package/${pkg.key}`}
            >
              {pkg.name}
            </a>
          );
        })}
      </div>
    );
  };

  _getFooter = () => {
    if (!this.props.user || !this.props.package) {
      return (
        <div className='loading loading-green loading-sm loading-center'>
          <div></div>
        </div>
      );
    }

    return this.props.user.licenseAccess ? (
      <div>
        <div>
          <a
            href='#'
            className='btn btn-primary-outline'
            onClick={this._setAddToParent}
            data-toggle='modal'
            data-target='#AddPackageModal'
          >
            <i className='fa fa-plus'></i>&nbsp;
            <FormattedMessage
              id='add-subpackage'
              description='Button for adding a subpackage'
              defaultMessage='Lisää aliurakka'
            />
          </a>
        </div>
        {this.props.package.level === 1 && (
          <div>
            <a
              href={`/package/${this.props.package.id}/phases`}
              className='btn btn-primary-outline m-t'
            >
              <i className='fa fa-plus'></i>&nbsp;
              <FormattedMessage
                id='add-phase'
                description='Button for adding construction phases'
                defaultMessage='Lisää rakennusvaiheita'
              />
            </a>
          </div>
        )}
      </div>
    ) : (
      <small className='text-muted'>
        <FormattedMessage
          id='subpackage-license-required'
          description='License required to create a subpackage'
          defaultMessage={
            'Tarvitset käyttöoikeuden ' + 'lisätäksesi aliurakoita.'
          }
        />
      </small>
    );
  };

  render() {
    if (this.props.packageLoading || !this.props.package) {
      return (
        <div className='card'>
          <div className='card-block'>
            <h4 className='card-title'>
              <FormattedMessage
                id='subpackages'
                description='Plural subpackage header'
                defaultMessage='Aliurakat'
              />
            </h4>

            <div className='card-text'>
              <div className='loading loading-green loading-center'>
                <div></div>
              </div>
            </div>
          </div>

          <div className='card-footer text-center'>{this._getFooter()}</div>
        </div>
      );
    }

    const count = this.props.package.stats.subpackages_total;
    const completed = this.props.package.stats.subpackages_completed;
    const percentage = count === 0 ? 0 : (completed / count) * 100;

    return (
      <div className='card'>
        <div className='card-block'>
          <h4 className='card-title'>
            <FormattedMessage
              id='subpackages'
              description='Plural subpackage header'
              defaultMessage='Aliurakat'
            />
          </h4>

          <div className='card-text'>
            <p className='pull-left'>
              <strong>
                <FormattedMessage
                  id='installed'
                  description='Label for how many subpackages are completed'
                  defaultMessage='Valmiita'
                />
              </strong>
              : {completed}
            </p>

            <p className='pull-right'>
              <strong>
                <FormattedMessage
                  id='total'
                  description='Label for how many items there are in total'
                  defaultMessage='Yhteensä'
                />
              </strong>
              : {count}
            </p>

            <div className='clearfix'></div>

            <progress
              className='progress progress-warning'
              value={percentage}
              max='100'
            >
              {percentage}%
            </progress>

            <p>
              <FormattedMessage
                id='subpackage-list-header'
                description='Header for a list of subpackages'
                defaultMessage='Katso aliurakoiden tiedot'
              />
            </p>

            {this._subpackages()}
          </div>
        </div>

        <div className='card-footer text-center'>{this._getFooter()}</div>
      </div>
    );
  }
}

export default PackageInformationSubpackages;
