import { Store } from 'flux/utils';
import ActionTypes from '../actions/ActionTypes';

class LogStore extends Store {
  constructor(dispatcher) {
    super(dispatcher);
    this._state = this.getInitialState();
  }

  getInitialState() {
    return {
      log: null,
      logs: null,
      error: null,
    };
  }

  getState() {
    return this._state;
  }

  clearState() {
    this._state = this.getInitialState();
  }

  __onDispatch(payload) {
    switch (payload.type) {
    case ActionTypes.LOGS_RECEIVE:
      this._state.logs = payload.logs;
      this.__emitChange();
      break;
    case ActionTypes.LOGS_RECEIVE_ERROR:
      this._state.error = payload.error;
      this.__emitChange();
      break;
    case ActionTypes.LOG_RECEIVE:
      this._state.log = payload.log;
      this.__emitChange();
      break;
    case ActionTypes.LOG_RECEIVE_ERROR:
      this._state.error = payload.error;
      this.__emitChange();
      break;
    default:
      // Ignoring other ActionTypes
    }
  }
}

export default LogStore;
