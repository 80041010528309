/**
 * EditProductModal.js - React component base
 *
 * Copyright 2023 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { ProductType } from '../../utils/enum';

const messages = defineMessages({
  emptyName: {
    id: 'product-no-name',
    description: 'Error when empty name while uploading file',
    defaultMessage: 'Tuotteen nimi ei voi olla tyhjä!',
  },

  emptyManufacturer: {
    id: 'product-no-manufacturer',
    description: 'Error when no file selected',
    defaultMessage: 'Tuotteen yritys ei voi olla tyhjä!',
  },

  productTooltip: {
    id: 'product',
    description: 'Tooltip for product',
    defaultMessage:
      'Urakkaan asennettava rakennustuote,' +
      ' joka ei ole kemikaali. Esimerkiksi laminaatti.',
  },

  chemicalProductTooltip: {
    id: 'chemical-product',
    description: 'Tooltip for chemical product',
    defaultMessage:
      'Urakkaan asennettava rakennustuote, joka on kemikaali.' +
      ' Esimerkiksi rakennusliima. Kemikaalille vaaditaan KTT-dokumentti.',
  },

  chemicalTooltip: {
    id: 'chemical',
    description: 'Tooltip for chemical',
    defaultMessage:
      'Urakan aikana käytössä oleva kemikaali, jota ei asenneta' +
      ' kohteeseen. Esimerkiksi polttoaine.' +
      ' Kemikaalille vaaditaan KTT-dokumentti.',
  },
});

class EditProductModal extends React.Component {
  static propTypes = {
    product: PropTypes.object,
    productActionCreators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.editProductModal = null;

    this.state = {
      product: null,
      name: '',
      manufacturer: '',
      ean: '',
      variant: ProductType.PRODUCT,
      ...this.getErrors(),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.product !== prevState.product) {
      return {
        product: nextProps.product,
        name: nextProps.product ? nextProps.product.data?.name ?? '' : '',
        manufacturer: nextProps.product
          ? nextProps.product.data?.manufacturer ?? ''
          : '',
        ean: nextProps.product ? nextProps.product.data?.ean ?? '' : '',
        variant: nextProps.product?.data?.variant || ProductType.PRODUCT,
        error: null,
        noNameError: null,
        noManufacturerError: null,
      };
    }
    return null;
  }

  getErrors = () => {
    return {
      error: null,
      noNameError: null,
      noManufacturerError: null,
    };
  };

  _editProduct = (event) => {
    event.preventDefault();

    const errors = this.getErrors();

    if (!this.state.name) {
      errors.error = true;
      errors.noNameError = this.props.intl.formatMessage(messages.emptyName);
    }

    if (!this.state.manufacturer) {
      errors.error = true;
      errors.noManufacturerError = this.props.intl.formatMessage(
        messages.emptyManufacturer
      );
    }

    this.setState(errors);

    if (errors.error) return;

    this.props.productActionCreators.patchProduct(this.props.product.id, {
      name: this.state.name,
      manufacturer: this.state.manufacturer,
      ean: this.state.ean,
      variant: this.state.variant,
    });

    $(this.editProductModal).modal('hide');
  };

  _handleProductTypeChange = (event) => {
    this.setState({
      variant: Number(event.target.value),
    });
  };

  render() {
    const editNameGroupClasses = classNames('form-group', {
      'has-error': this.state.noNameError,
    });

    const editNameFieldClasses = classNames('form-control', {
      'form-control-error': this.state.noNameError,
    });

    const editManufacturerGroupClasses = classNames('form-group', {
      'has-error': this.state.noManufacturerError,
    });

    const editManufacturerFieldClasses = classNames('form-control', {
      'form-control-error': this.state.noManufacturerError,
    });

    return (
      <div
        className='EditProductModal modal fade'
        ref={(div) => {
          this.editProductModal = div;
        }}
        id='EditProductModal'
        tabIndex='-1'
        data-backdrop='static'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                <span>&times;</span>
              </button>
              <h4
                className='modal-title'
                style={{ pediting: '10px 0px 0px', margin: '0px 0px 10px' }}
              >
                <FormattedMessage
                  id='edit-product-header'
                  description='Product editing header'
                  defaultMessage='Päivitä tuotteen "{name}" tietoja'
                  values={{
                    name: this.props.product?.data?.name,
                  }}
                />
              </h4>
            </div>

            <div className='modal-body'>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <fieldset className={editNameGroupClasses}>
                  <label>
                    <FormattedMessage
                      id='display_name'
                      description='Generic display name'
                      defaultMessage='* Tuotteen nimi'
                    />
                  </label>

                  <input
                    type='text'
                    value={this.state.name}
                    onChange={(event) => {
                      this.setState({
                        name: event.currentTarget.value,
                      });
                    }}
                    className={editNameFieldClasses}
                  />

                  <small className='text-danger'>
                    {this.state.noNameError}
                  </small>
                </fieldset>

                <fieldset className={editManufacturerGroupClasses}>
                  <label>
                    <FormattedMessage
                      id='company'
                      description='Product company name'
                      defaultMessage='* Tuotteen yritys'
                    />
                  </label>

                  <input
                    type='text'
                    value={this.state.manufacturer}
                    onChange={(event) => {
                      this.setState({
                        manufacturer: event.currentTarget.value,
                      });
                    }}
                    className={editManufacturerFieldClasses}
                  />

                  <small className='text-danger'>
                    {this.state.noManufacturerError}
                  </small>
                </fieldset>

                <fieldset className='form-group'>
                  <label>
                    <FormattedMessage
                      id='ean'
                      description='Product GTIN-code'
                      defaultMessage='Tuotteen GTIN-koodi'
                    />
                  </label>

                  <input
                    type='text'
                    value={this.state.ean}
                    onChange={(event) => {
                      this.setState({
                        ean: event.currentTarget.value,
                      });
                    }}
                    className='form-control'
                  />
                </fieldset>

                <fieldset className='form-group'>
                  <label>
                    <FormattedMessage
                      id='product-type'
                      description='Product type'
                      defaultMessage='Tuotteen tyyppi'
                    />
                  </label>
                  <div className='radio'>
                    <label>
                      <input
                        type='radio'
                        value={ProductType.PRODUCT}
                        checked={this.state.variant === ProductType.PRODUCT}
                        onChange={this._handleProductTypeChange}
                      />{' '}
                      Rakennustuote
                    </label>
                    <a
                      className='m-l'
                      onClick={(evt) => evt.stopPropagation()}
                      data-toggle='tooltip'
                      data-placement='bottom'
                      title={this.props.intl.formatMessage(
                        messages.productTooltip
                      )}
                    >
                      <i className='fa fa-info-circle' />
                    </a>
                  </div>
                  <div className='radio'>
                    <label>
                      <input
                        type='radio'
                        value={ProductType.CHEMICAL_PRODUCT}
                        checked={
                          this.state.variant === ProductType.CHEMICAL_PRODUCT
                        }
                        onChange={this._handleProductTypeChange}
                      />{' '}
                      Rakennustuotekemikaali | menee kemikaaliluetteloon
                    </label>
                    <a
                      className='m-l'
                      onClick={(evt) => evt.stopPropagation()}
                      data-toggle='tooltip'
                      data-placement='bottom'
                      title={this.props.intl.formatMessage(
                        messages.chemicalProductTooltip
                      )}
                    >
                      <i className='fa fa-info-circle' />
                    </a>
                  </div>
                  <div className='radio'>
                    <label>
                      <input
                        type='radio'
                        value={ProductType.CHEMICAL}
                        checked={this.state.variant === ProductType.CHEMICAL}
                        onChange={this._handleProductTypeChange}
                      />{' '}
                      Kemikaali - ei rakennustuote | menee kemikaaliluetteloon
                    </label>
                    <a
                      className='m-l'
                      onClick={(evt) => evt.stopPropagation()}
                      data-toggle='tooltip'
                      data-placement='bottom'
                      title={this.props.intl.formatMessage(
                        messages.chemicalTooltip
                      )}
                    >
                      <i className='fa fa-info-circle' />
                    </a>
                  </div>
                </fieldset>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                className='btn btn-warning pull-right'
                data-dismiss='modal'
              >
                <FormattedMessage
                  id='edit-product-cancel'
                  description='Cancel button text when editing a product'
                  defaultMessage='Peruuta'
                />
              </button>

              <button
                className='btn btn-success pull-right m-r'
                onClick={this._editProduct}
              >
                <FormattedMessage
                  id='edit-product-accept'
                  description='Accept button text when editing a product'
                  defaultMessage='Tallenna'
                />
              </button>

              <div className='clearfix' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(EditProductModal);
