import PropTypes from 'prop-types';

import ProductInformationPanel from '../ProductInformationPanel/ProductInformationPanel.js';
import PackageInformationPanel from '../PackageInformationPanel/PackageInformationPanel.js';

const SidePanel = ({
  isProductPanel,
  statePackage,
  packageState,
  productState,
  logState,
  rtState,
  packageActionCreators,
  productActionCreators,
  fileActionCreators,
  messageActionCreators,
  logActionCreators,
  approvers,
}) => {
  const productKey = productState.product ? productState.product.id : 'loading';
  const key = `${statePackage}-${productKey}`;

  return isProductPanel ? (
    <ProductInformationPanel
      key={key}
      package={statePackage}
      product={productState.product}
      productLoading={productState.productLoading}
      log={logState.log}
      externalProduct={productState.externalProduct}
      globalSearch={[productState.externalProduct]}
      packageActionCreators={packageActionCreators}
      productActionCreators={productActionCreators}
      fileActionCreators={fileActionCreators}
      messageActionCreators={messageActionCreators}
      logActionCreators={logActionCreators}
      safetyData={productState.safetyData.fi}
      approvers={approvers}
      user={rtState.user}
    />
  ) : (
    <PackageInformationPanel
      key={packageState.package ? packageState.package.id : null}
      package={packageState.package}
      packageLoading={packageState.packageLoading}
      packageSummaryLoading={packageState.packageSummaryLoading}
      packageApprovalReportLoading={packageState.packageApprovalReportLoading}
      packageChemicalChartInfo={packageState.packageChemicalChartInfo}
      packageChemicalChartInfoLoading={
        packageState.packageChemicalChartInfoLoading
      }
      log={logState.log}
      user={rtState.user}
      logActionCreators={logActionCreators}
      packageActionCreators={packageActionCreators}
      messageActionCreators={messageActionCreators}
      fileActionCreators={fileActionCreators}
      activePackage={packageState.activePackage}
      openMobileMenu={packageState.openMobileMenu}
      approvers={approvers}
    />
  );
};

SidePanel.propTypes = {
  isProductPanel: PropTypes.bool,
  statePackage: PropTypes.object.isRequired,
  packageState: PropTypes.shape({
    package: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    packageLoading: PropTypes.bool,
    packageSummaryLoading: PropTypes.bool,
    packageApprovalReportLoading: PropTypes.bool,
    packageChemicalChartInfo: PropTypes.any,
    packageChemicalChartInfoLoading: PropTypes.bool,
    activePackage: PropTypes.any,
    openMobileMenu: PropTypes.func.isRequired,
  }).isRequired,
  productState: PropTypes.shape({
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    productLoading: PropTypes.bool,
    externalProduct: PropTypes.any,
    safetyData: PropTypes.any,
  }).isRequired,
  logState: PropTypes.shape({
    log: PropTypes.any,
  }).isRequired,
  rtState: PropTypes.shape({
    user: PropTypes.any,
  }).isRequired,
  approvers: PropTypes.array,
};

export default SidePanel;
