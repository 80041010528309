/**
 * PackageInformationChemicals.js - React component PackageInformationChemicals
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import { FormattedMessage } from 'react-intl';

class PackageInformationChemicals extends React.Component {
  static propTypes = {
    package: PropTypes.object,
    packageLoading: PropTypes.bool.isRequired,
  };

  render() {
    if (this.props.packageLoading || !this.props.package) {
      return (
        <div className='card'>
          <div className='card-block'>
            <h4 className='card-title'>
              <FormattedMessage
                id='chemicals'
                description='Header for chemical information'
                defaultMessage='Kemikaalit'
              />
            </h4>

            <div className='card-text'>
              <div className='loading loading-green loading-center'>
                <div></div>
              </div>
            </div>
          </div>

          <div className='card-footer'>
            <div className='loading loading-green loading-sm loading-center'>
              <div></div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='card'>
        <div className='card-block'>
          <h4 className='card-title'>
            <FormattedMessage
              id='products'
              description='Header for product information'
              defaultMessage='Kemikaalit'
            />
          </h4>
        </div>

        <div className='card-footer text-center'>
          <a
            className='btn btn-primary-outline'
            href={`/package/${this.props.package.id}/chemicals`}
          >
            <i className='fa fa-pencil-square-o'></i>&nbsp;
            {this.props.package.state === 2 ?
              <FormattedMessage
                id='view-products'
                description='View link to the chemical management page'
                defaultMessage='Katsele tuotteita'
              /> :
              <FormattedMessage
                id='manage-products'
                description='Manage link to the chemical management page'
                defaultMessage='Hallinnoi kemikaaleja'
              />
            }
          </a>
        </div>
      </div>
    );
  }
}

export default PackageInformationChemicals;
