import { Store } from 'flux/utils';
import ActionTypes from '../actions/ActionTypes';
import { sortPackageTree } from '../utils/packageUtils';

class PackageStore extends Store {
  constructor(dispatcher) {
    super(dispatcher);
    this._state = this.getInitialState();
  }

  getInitialState() {
    return {
      package: null,
      packageLoading: false,
      packages: null,
      packagesLoading: false,
      packageSummaryLoading: {},
      packageSearch: null,
      packageSearchLoading: false,
      packageApprovalReportLoading: false,
      packageChemicalChartInfo: null,
      packageChemicalChartInfoLoading: false,
      products: null,
      productsLoading: false,
      packageProducts: {},
      invites: null,
      rights: null,
      rightsLoading: false,
      addToParent: null,
      archivedCount: null,
      error: null,
      openDownloadModal: false,
      openMassApproveModal: false,
      installMode: false,
      expanded: {},
      activePackage: null,
      openMobileMenu: false,
      chemicalChartData: null,
      chemicalChartDataLoading: false,
      memoizedPackages: null,
      validateImportProductsLoading: false,
      importProducts: null,
      importProductsLoading: false,
      packageProductSearchLoading: false,
      packageProductSearchResult: null,
      reportData: null,
      reportDataLoading: false,
      excelReportPending: false,
    };
  }

  getState() {
    return this._state;
  }

  clearState() {
    this._state = this.getInitialState();
  }

  __onDispatch(payload) {
    switch (payload.type) {
      case ActionTypes.CLEAR_STORES:
        this.clearState();
        this.__emitChange();
        break;
      case ActionTypes.PACKAGES_LOADING:
        this._state.packagesLoading = true;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RECEIVE_ALL:
        this._state.packagesLoading = false;
        this._state.packages = {};
        payload.packages.forEach(this._handlePackage.bind(this));
        this._memoizeTree();
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RECEIVE_ALL_ERROR:
        this._state.packages = null;
        this._state.error = payload.error;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_CLEAR:
        this._state.package = null;
        this.__emitChange();
        break;
      case ActionTypes.SET_ACTIVE_PACKAGE:
        this._state.activePackage = payload.id;
        this.__emitChange();
        break;
      case ActionTypes.CLOSE_ACTIVE_PACKAGE:
        this._state.activePackage = null;
        this.__emitChange();
        break;
      case ActionTypes.TOGGLE_EXPANDED:
        if (this._state.expanded[payload.packageId]) {
          this._state.expanded[payload.packageId] = false;
        } else {
          this._state.expanded[payload.packageId] = true;
        }
        this.__emitChange();
        break;
      case ActionTypes.EXPAND_SUBPACKAGES:
        {
          const pkg = this._state.packages[payload.package];

          const subpackages = Object.values(this._state.packages)
            .filter((p) => p.path?.includes(pkg.id))
            .map((p) => p.id);

          const expandPath = [pkg.id, ...subpackages];

          for (const id of expandPath) {
            this._state.expanded[id] = true;
          }

          this.__emitChange();
        }
        break;
      case ActionTypes.COLLAPSE_SUBPACKAGES: {
        const pkg = this._state.packages[payload.package];

        const subpackages = Object.values(this._state.packages)
          .filter((p) => p.path?.includes(pkg.id))
          .map((p) => p.id);

        const expandPath = [pkg.id, ...subpackages];

        for (const id of expandPath) {
          this._state.expanded[id] = false;
        }

        this.__emitChange();
        break;
      }
      case ActionTypes.OPEN_MOBILE_MENU:
        this._state.openMobileMenu = true;
        this.__emitChange();
        break;
      case ActionTypes.CLOSE_MOBILE_MENU:
        this._state.openMobileMenu = false;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RECEIVE:
        this._state.packageLoading = false;
        this._state.package = payload.package;
        // Also update to this._state.packages
        this._handlePackage(this._unifyPackage(payload.package));
        this._memoizeTree();
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RECEIVE_ERROR:
        this._state.package = null;
        this._state.error = payload.error;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_SUMMARY_LOADING:
        this._state.packageSummaryLoading[payload.packageId] = true;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_SUMMARY_RECEIVED:
      case ActionTypes.PACKAGE_SUMMARY_RECEIVE_ERROR:
        this._state.packageSummaryLoading[payload.packageId] = false;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_LOADING:
        this._state.packageLoading = true;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_PRODUCTS_LOADING:
        this._state.productsLoading = true;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_APPROVAL_REPORT_LOADING:
        this._state.packageApprovalReportLoading = payload.data;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RECEIVE_PRODUCTS:
        this._state.productsLoading = false;
        this._state.products = payload.products;
        this._state.packageProducts[payload.pkg] = payload.products;
        this._memoizeTree();
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RECEIVE_PRODUCTS_ERROR:
        this._state.error = payload.error;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_ADDED_PRODUCT:
        if (!this._state.products) break;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'PACKAGE_ADDED_PRODUCT',
          product_name: payload.product.data.name || 'ei tiedossa',
          manufacturer: payload.product.data.manufacturer || 'ei tiedossa',
          company_name: this._state.package.company.name || 'ei tiedossa',
        });
        this._state.products.push(payload.product);
        this._memoizeTree();
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_MOVED_PRODUCT:
        if (this._state.products && payload.product) {
          const index = this._state.products.findIndex(
            (product) => product.id === payload.product.id
          );
          if (index !== -1) this._state.products[index] = payload.product;
        }

        if (payload.from && payload.to && payload.product) {
          this._state.packageProducts[payload.from] =
            this._state.packageProducts[payload.from].filter(
              (prod) => prod.id !== payload.product.id
            );
          if (this._state.packageProducts[payload.to]) {
            this._state.packageProducts[payload.to].push(payload.product);
          }
        }
        this._memoizeTree();
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_MOVED:
        this._handlePackage(this._unifyPackage(payload.package));
        this._memoizeTree();
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_APPROVABLE_UPDATED: {
        const { product } = payload;
        if (this._state.packages[product.package]) {
          if (product.product_approvable) {
            this._state.packages[product.package].total_approvable_count =
              Number(
                this._state.packages[product.package].total_approvable_count
              ) + 1;
          } else {
            this._state.packages[product.package].total_approvable_count =
              Number(
                this._state.packages[product.package].total_approvable_count
              ) - 1;
          }
        }
        this.__emitChange();
        break;
      }
      case ActionTypes.PRODUCT_RECEIVE: {
        const { product } = payload;

        if (!product) {
          break;
        }

        if (this._state.products && product) {
          const index = this._state.products.findIndex(
            (stateProduct) => stateProduct.id === product.id
          );
          if (index !== -1) {
            this._state.products[index] = product;
          }
        }
        if (
          this._state.packageProducts &&
          this._state.packageProducts[product.package]
        ) {
          const index = this._state.packageProducts[product.package].findIndex(
            (stateProduct) => stateProduct && stateProduct.id === product.id
          );
          if (index !== -1) {
            this._state.packageProducts[product.package][index] = product;
          }
          this._memoizeTree();
        }

        if (this._state.packageProductSearchResult) {
          for (const pkg of this._state.packageProductSearchResult) {
            pkg.products = pkg.products.map((p) => {
              if (p.id === product.id) {
                return {
                  ...p,
                  data: {
                    ...p.data,
                    name: product.data.name,
                    manufacturer: product.data.manufacturer,
                    talo2000: product.data.talo2000,
                  },
                  status: product.status,
                };
              }
              return p;
            });
          }
        }

        this.__emitChange();
        break;
      }
      case ActionTypes.PRODUCT_INSTALLED:
        if (
          this._state.packageProducts &&
          this._state.packageProducts[payload.product.package]
        ) {
          const index = this._state.packageProducts[
            payload.product.package
          ].findIndex(
            (product) => product && product.id === payload.product.id
          );
          if (index !== -1) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'PRODUCT_INSTALLED',
              product_name: payload.product.data.name || 'ei tiedossa',
              manufacturer: payload.product.data.manufacturer || 'ei tiedossa',
              company_name: this._state.package.company.name || 'ei tiedossa',
            });
            this._state.packageProducts[payload.product.package][index] =
              payload.product;
            this._memoizeTree();
            this.__emitChange();
          }
        }

        if (this._state.packageProductSearchResult) {
          for (const pkg of this._state.packageProductSearchResult) {
            pkg.products = pkg.products.map((product) => {
              if (product.id === payload.product.id) {
                return {
                  ...product,
                  status: payload.product.status,
                };
              }
              return product;
            });
          }
          this.__emitChange();
        }

        break;
      case ActionTypes.PRODUCT_UNINSTALLED:
        if (
          this._state.packageProducts &&
          this._state.packageProducts[payload.product.package]
        ) {
          const index = this._state.packageProducts[
            payload.product.package
          ].findIndex((product) => product.id === payload.product.id);
          if (index !== -1) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'PRODUCT_UNINSTALLED',
              product_name: payload.product.data.name || 'ei tiedossa',
              manufacturer: payload.product.data.manufacturer || 'ei tiedossa',
              company_name: this._state.package.company.name || 'ei tiedossa',
            });
            this._state.packageProducts[payload.product.package][index] =
              payload.product;
            this._memoizeTree();
            this.__emitChange();
          }
        }

        if (this._state.packageProductSearchResult) {
          for (const pkg of this._state.packageProductSearchResult) {
            pkg.products = pkg.products.map((product) => {
              if (product.id === payload.product.id) {
                return {
                  ...product,
                  status: payload.product.status,
                };
              }
              return product;
            });
          }
          this.__emitChange();
        }

        break;
      case ActionTypes.PRODUCT_REMOVED:
        if (
          payload.packageID &&
          payload.product &&
          this._state.packageProducts &&
          this._state.packageProducts[payload.packageID]
        ) {
          const index = this._state.packageProducts[
            payload.packageID
          ].findIndex((product) => {
            return product && product.id === payload.product.id;
          });
          delete this._state.packageProducts[payload.packageID][index];
          this._memoizeTree();
        }
        this._state.products = this._state.products.filter((product) => {
          return product.id !== payload.product.id;
        });
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_OPEN_INFORMATION_PANEL:
        this._state.informationPanelPackage = payload.informationPanelPackage;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_SEARCH_LOADING:
        this._state.packageSearchLoading = true;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RECEIVE_SEARCH:
        this._state.packageSearchLoading = false;
        this._state.packageSearch = payload.packages;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RECEIVE_SEARCH_ERROR:
        this._state.error = payload.error;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_ADD_TO_PARENT:
        this._state.addToParent = payload.parent;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_OPEN_DOWNLOAD_MODAL:
        this._state.openDownloadModal = true;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_OPEN_MASS_APPROVE_MODAL:
        this._state.openMassApproveModal = true;
        this._state.installMode = payload.installMode;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_ADDED:
        this._handlePackage(this._unifyPackage(payload.data));
        this._state.package = payload.data;
        this._state.expanded[payload.data.id] = true;
        this._state.activePackage = payload.data.id;
        this._memoizeTree();
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_ADDED_ERROR:
        this._state.error = payload.error;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_EDITED:
        if (this._state.package && this._state.package.id === payload.data.id) {
          this._state.package = payload.data;
        }
        this._handlePackage(this._unifyPackage(payload.data));
        this._memoizeTree();
        this.__emitChange();
        break;
      case ActionTypes.CHEMICAL_CHART_DATA_LOADING:
        this._state.chemicalChartDataLoading = true;
        this.__emitChange();
        break;
      case ActionTypes.RECEIVE_CHEMICAL_CHART_DATA:
        this._state.chemicalChartDataLoading = false;
        this._state.chemicalChartData = payload.data;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RECEIVE_CHEMICAL_CHART_INFO:
        this._state.packageChemicalChartInfo = null;
        this._state.packageChemicalChartInfoLoading = false;
        if (payload.data.package) {
          this._state.packageChemicalChartInfo = payload.data;
        }
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_CREATE_CHEMICAL_CHART_LOADING:
        this._state.packageChemicalChartInfoLoading = true;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_ARCHIVED:
      case ActionTypes.PACKAGE_UNARCHIVED: {
        if (this._state.package && this._state.package.id === payload.data.id) {
          this._state.package = payload.data;
        }

        const isArchived = payload.type === ActionTypes.PACKAGE_ARCHIVED;

        this._handlePackage(this._unifyPackage(payload.data));
        Object.keys(this._state.packages).forEach((key) => {
          if (this._state.packages[key].ancestor !== payload.data.id) return;
          this._state.packages[key].is_archived = isArchived;
        });
        this._memoizeTree();
        this.__emitChange();
        break;
      }
      case ActionTypes.PACKAGE_REMOVED:
        delete this._state.packages[payload.package.id];
        if (
          this._state.package &&
          this._state.package.id === payload.package.id
        ) {
          this._state.package = null;
        }
        if (this._state.activePackage === payload.package.id) {
          this._state.activePackage = null;
        }
        this._memoizeTree();
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_EDITED_ERROR:
        this._state.error = payload.error;
        this.__emitChange();
        break;
      case ActionTypes.EXCEL_REPORT_PENDING:
        this._state.excelReportPending = payload.state;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RIGHTS_LOADING:
        this._state.rightsLoading = true;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RECEIVE_RIGHTS:
        this._state.rightsLoading = false;
        this._state.rights = payload.data.rights;
        this._state.invites = payload.data.invites;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RECEIVE_RIGHTS_ERROR:
        this._state.error = payload.error;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_UPDATE_RIGHTS:
        payload.rights.forEach((right) => {
          const index = this._state.rights.findIndex((access) => {
            return (
              access.package &&
              access.package.id === right.package.id &&
              access.username === right.username
            );
          });
          if (index === -1) return;
          this._state.rights[index].can_approve = right.can_approve;
        });
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_UPDATE_INVITE:
        this._state.invites = payload.data.invites;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_REMOVED_RIGHTS:
        if (!this._state.rights) break;
        this._state.rights = this._state.rights.filter((access) => {
          return (
            !access.package ||
            access.package.id !== payload.rights.package.id ||
            access.username !== payload.rights.username
          );
        });
        // TODO: Does the user get confirmation message?
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_INVITED:
        this._state.invites = [payload.data.invite, ...this._state.invites];
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_UNINVITED:
        if (!this._state.invites) break;
        this._state.invites = this._state.invites.filter((invite) => {
          return invite.id !== payload.data.invite.id;
        });
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RECEIVE_ARCHIVED_COUNT:
        this._state.archivedCount = payload.count;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_RECEIVE_ARCHIVED_COUNT_ERROR:
        this._state.error = payload.error;
        this.__emitChange();
        break;
      case ActionTypes.FILE_ADDED:
        if (this._state.products) {
          const fileTypes = [];
          payload.data.files.forEach((file) => {
            if (fileTypes.indexOf(file.type) !== -1) return;
            fileTypes.push(file.type);
          });

          const index = this._state.products.findIndex((product) => {
            return product.id === payload.data.product_id;
          });

          if (this._state.products[index]) {
            this._state.products[index].file_types = fileTypes;
          }
          this.__emitChange();
        }
        break;
      case ActionTypes.VALIDATE_IMPORT_PRODUCTS_LOADING:
        this._state.validateImportProductsLoading = true;
        this._state.importProducts = null;
        this.__emitChange();
        break;
      case ActionTypes.RECEIVE_VALIDATE_IMPORT_PRODUCTS:
        this._state.importProducts = payload.products;
        this._state.validateImportProductsLoading = false;
        this.__emitChange();
        break;
      case ActionTypes.IMPORT_PRODUCTS_LOADING:
        this._state.importProductsLoading = true;
        this.__emitChange();
        break;
      case ActionTypes.RECEIVE_IMPORT_PRODUCTS:
        this._state.importProductsLoading = false;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_PRODUCT_SEARCH_LOADING:
        this._state.packageProductSearchLoading = true;
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_PRODUCT_SEARCH_RECEIVE:
        this._state.packageProductSearchLoading = false;
        this._state.packageProductSearchResult = payload.result;
        this.__emitChange();
        break;
      case ActionTypes.REPORT_DATA_RECEIVE:
        this._state.reportData = payload.result;
        this._state.reportDataLoading = false;
        this.__emitChange();
        break;
      case ActionTypes.REPORT_DATA_LOADING:
        this._state.reportDataLoading = true;
        this.__emitChange();
        break;
      case ActionTypes.REPORT_DATA_ERROR:
        this._state.reportDataLoading = false;
        this.__emitChange();
        break;
      default:
      // Ignoring other ActionTypes
    }
  }

  _unifyPackage(pkg) {
    // Clone, since we modify
    const data = JSON.parse(JSON.stringify(pkg));
    if (data.parent !== null && data.parent.id !== undefined) {
      data.parent = data.parent.id;
    }

    if (data.ancestor !== null && data.ancestor.id !== undefined) {
      data.ancestor = data.ancestor.id;
    }

    delete data.stats;

    // Return data for inline use
    return data;
  }

  _handlePackage(data) {
    if (this._state.packages === null) return;
    if (data.id !== null) {
      this._state.packages[data.id] = data;
    }
  }

  _memoizeTree() {
    if (!this._state.packages) return;

    const nodes = this._state.packages;
    const roots = [];

    Object.keys(nodes).forEach((id) => {
      nodes[id].children = [];
    });

    Object.keys(nodes).forEach((id) => {
      const node = nodes[id];
      if (!node.has_access) return;

      node.products = this._state.packageProducts
        ? this._state.packageProducts[node.id] || []
        : [];

      node.approved_count =
        node.products.length > 0
          ? node.products.reduce(
              (sum, prod) => (prod.approved ? sum + 1 : sum),
              0
            )
          : node.approved_count;

      node.path = [];
      node.children = node.children || [];
      node.num_descendants = 0;
      node.num_descendants_complete = 0;
      node.num_descendants_approved_count = 0;
      node.num_descendants_total_count = 0;
      node.num_descendants_total_approvable_count = 0;

      let parent = nodes[node.parent];
      if (parent && parent.has_access) {
        parent.children = parent.children || [];
        parent.children.push(node);
      } else {
        roots.push(node);
      }

      while (parent && parent.has_access) {
        parent.num_descendants += 1;
        parent.num_descendants_complete += node.state === 2;
        parent.num_descendants_approved_count += Number(node.approved_count);
        parent.num_descendants_total_count += Number(node.total_count);
        parent.num_descendants_total_approvable_count += Number(
          node.total_approvable_count
        );
        node.path.push(parent.id);
        parent = nodes[parent.parent];
      }
    });

    this._state.memoizedPackages = sortPackageTree(roots);
  }
}

export default PackageStore;
