/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Messages from '../Messages/Messages';

import { FormattedMessage } from 'react-intl';

class ProductLinkView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    productStore: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    productActionCreators: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this._getStoreData();
    this.productID = null;
    this.externalProductID = null;
  }

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );

    this._messageStoreSubscription = this.props.messageStore.addListener(
      this._onStoreChange
    );

    this._productStoreSubscription = this.props.productStore.addListener(
      this._onStoreChange
    );
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
    this._messageStoreSubscription.remove();
    this._productStoreSubscription.remove();
  }

  _onStoreChange = () => {
    this.setState(this._getStoreData());
  };

  _getStoreData = () => {
    return {
      rtState: this.props.rtStore.getState(),
      packageState: this.props.packageStore.getState(),
      productState: this.props.productStore.getState(),
    };
  };

  _fetchProductInformation = () => {
    if (this.productID) {
      this.props.productActionCreators.getLocalProduct(this.productID.value);
    }
  };

  _fetchExternalProductInformation = () => {
    if (this.externalProductID) {
      this.props.productActionCreators.getExternalProduct(
        this.externalProductID.value
      );
    }
  };

  _handleProductBlur = (event) => {
    event.preventDefault();
    this._fetchProductInformation();
  };

  _handleProductPress = (event) => {
    if (event.keyCode !== 13) return;

    event.preventDefault();
    this._fetchProductInformation();
  };

  _handleExternalProductBlur = (event) => {
    event.preventDefault();
    this._fetchExternalProductInformation();
  };

  _handleExternalProductPress = (event) => {
    if (event.keyCode !== 13) return;

    event.preventDefault();
    this._fetchExternalProductInformation();
  };

  _linkProduct = (event) => {
    event.preventDefault();
    if (this.productID && this.externalProductID) {
      this.props.productActionCreators.linkProduct(
        this.productID.value,
        this.externalProductID.value
      );
    }
  };

  _getProductData = (product) => {
    return (
      <dl className='dl-horizontal row'>
        <dt className='col-xs-3 m-b'>Nimi:</dt>
        <dd className='col-xs-9 text-left m-b'>
          {product && product.data.name}
        </dd>

        <dt className='col-xs-3 m-b'>Yritys:</dt>
        <dd className='col-xs-9 text-left m-b'>
          {product && product.data.manufacturer}
        </dd>

        <dt className='col-xs-3 m-b'>GTIN:</dt>
        <dd className='col-xs-9 text-left m-b'>
          {product && product.data.ean}
        </dd>

        <dt className='col-xs-3 m-b'>Talo 2000 -tuotenimike:</dt>
        <dd className='col-xs-9 text-left m-b'>
          {product && product.data.talo2000}
        </dd>
      </dl>
    );
  };

  _getContent = () => (
    <div className='container-fluid'>
      <Header
        user={this.state.rtState.user}
        packages={this.state.packageState.packageSearch}
        searchLoading={this.state.packageState.packageSearchLoading}
        packageActionCreators={this.props.packageActionCreators}
      />

      <Messages
        messageStore={this.props.messageStore}
        messageActionCreators={this.props.messageActionCreators}
      />

      <div className='ProductLinkView-content row'>
        <div className='col-xs-12 col-sm-10 col-sm-offset-1'>
          <h2>Tuotteiden linkitys</h2>

          <form className='m-t' onSubmit={(event) => event.preventDefault()}>
            <div className='row'>
              <div className='col-xs-6'>
                <fieldset className='form-group'>
                  <label>RTUT tuotteen ID</label>
                  <input
                    type='text'
                    ref={(input) => {
                      this.productID = input;
                    }}
                    className='form-control'
                    onBlur={this._handleProductBlur}
                    onKeyUp={this._handleProductPress}
                  />
                </fieldset>

                {this._getProductData(this.state.productState.product)}
              </div>

              <div className='col-xs-6'>
                <fieldset className='form-group'>
                  <label>E21 rajapinnan tuote ID</label>
                  <input
                    type='text'
                    ref={(input) => {
                      this.externalProductID = input;
                    }}
                    className='form-control'
                    onBlur={this._handleExternalProductBlur}
                    onKeyUp={this._handleExternalProductPress}
                  />
                </fieldset>

                {this._getProductData(this.state.productState.externalProduct)}
              </div>
            </div>

            <fieldset className='form-group text-center m-t-md'>
              <button
                type='button'
                onClick={this._linkProduct}
                className='btn btn-primary btn-lg p-l-lg p-r-lg'
                disabled={
                  !this.productID ||
                  !this.externalProductID ||
                  !this.productID.value ||
                  !this.externalProductID.value
                }
              >
                Linkitä
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );

  render() {
    let content = null;

    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null ||
      this.state.productState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='ProductLinkView'>
        {content}
        <Footer />
      </div>
    );
  }
}

export default AuthenticatedView(ProductLinkView);
