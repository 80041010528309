import React from 'react';
import { FormattedMessage } from 'react-intl';

const ProductRemovalButton = ({ onRemoveClick }) => (
  <button
    className='btn btn-danger m-t'
    onClick={(e) => {
      e.preventDefault();
      onRemoveClick();
    }}
  >
    <FormattedMessage
      id='remove-product-button'
      description='Button text for removing a product from a package'
      defaultMessage='Poista urakasta'
    />
  </button>
);

export default ProductRemovalButton;
