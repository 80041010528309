/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';

class HeaderUser extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    rtActionCreators: PropTypes.object,
  };

  copyToken = (event) => {
    event.preventDefault();

    const hidden = $('<input />');
    $('body').append(hidden);

    $(hidden).val(localStorage.getItem('jwt-token')).select();
    document.execCommand('copy');
    $(hidden).remove();
  };

  _getContent = () => {
    return (
      <div className='HeaderUser'>
        <div className='HeaderUser-content'>
          <div className='dropdown hidden-xs-down'>
            {this.props.user.first_name && this.props.user.last_name ?
              <span className='dropdown-toggle' data-toggle='dropdown'>
                {this.props.user.first_name} {this.props.user.last_name}
              </span> :
              <span className='dropdown-toggle' data-toggle='dropdown'>
                {this.props.user.username}
              </span>
            }

            <div className='dropdown-menu dropdown-menu-right'>
              {!process.env.DEBUG ? null :
                <a
                  className='dropdown-item'
                  href='#'
                  onClick={this.copyToken}
                >
                  <FormattedMessage
                    id='debug-copy-token'
                    description='Link to copy JWT in debug mode'
                    defaultMessage='Kopioi JWT'
                  />
                </a>
              }

              <a className='dropdown-item' href='/logout'>
                <FormattedMessage
                  id='logout'
                  description='Link to logout'
                  defaultMessage='Kirjaudu ulos'
                />
              </a>
            </div>

            <div className='clearfix'></div>
          </div>
          <div
            className={classNames(
              'HeaderUser-content--mobile', 'navbar', 'navbar-inverse',
              'navbar-static-top', 'col-xs-12', 'hidden-sm-up'
            )}
          >
            <ul className='nav nav-pills'>
              <li className='nav-item no-hover'>
                {this.props.user.first_name && this.props.user.last_name ?
                  <strong className='nav-link no-hover'>
                    <i className='fa fa-user' />
                    {this.props.user.first_name} {this.props.user.last_name}
                  </strong> :
                  <strong className='nav-link no-hover'>
                    <i className='fa fa-user' />
                    {this.props.user.username}
                  </strong>
                }
              </li>

              {!process.env.DEBUG ? null :
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    href='#'
                    onClick={this.copyToken}
                  >
                    <i className='fa fa-clipboard' />
                    <FormattedMessage
                      id='debug-copy-token'
                      description='Link to copy the JWT in debug mode'
                      defaultMessage='Kopioi JWT'
                    />
                  </a>
                </li>
              }

              <li className='nav-item'>
                <a className='nav-link' href='/logout'>
                  <i className='fa fa-sign-out' />
                  <FormattedMessage
                    id='logout'
                    description='Link to logout'
                    defaultMessage='Kirjaudu ulos'
                  />
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.user) {
      return this._getContent();
    }

    return (
      <div className='HeaderUser'>
        <div className='loading'>
          <div></div>
        </div>
      </div>
    );
  }
}

export default HeaderUser;
