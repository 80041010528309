/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import debounce from 'debounce';
import page from 'page';
import classNames from 'classnames';

import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'search-for-packages',
    description: 'Placeholder for searching for packages',
    defaultMessage: 'Hae urakoita...',
  },
});

class HeaderSearch extends React.Component {
  static propTypes = {
    packageActionCreators: PropTypes.object.isRequired,
    packages: PropTypes.array,
    searchLoading: PropTypes.bool.isRequired,
    toggleSearch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  state = {
    searchValue: '',
  };

  _preventClosing = (event) => {
    event.stopPropagation();
  };

  _clearSearch = () => {
    this.props.packageActionCreators.searchPackages(null);
  };

  _handleSearch = (event) => {
    const query = event.target.value;
    this.setState({
      searchValue: query,
    });

    if (query.length < 3) {
      this.props.packageActionCreators.searchPackages(null);
      return;
    }

    // Debounce rate can be changed from 250 ms
    debounce((value) => {
      this.props.packageActionCreators.searchPackages(value);
    }, 250)(query);
  };

  _handleClick = (data, event) => {
    event.preventDefault();
    this.setState({
      searchValue: '',
    });
    this._clearSearch();

    const newPath = `/package/${data.id}`;
    return page.redirect(newPath);
  };

  _row = (element) => {
    return (
      <a key={element.id} onMouseDown={this._handleClick.bind(this, element)}>
        <li className='HeaderSearch-search-results-row row-flex align-vert'>
          <div className='HeaderSearch-search-results-row-content'>
            <span>{element.name}</span>
            <span>{`(${element.company.name})`}</span>

            <div className='HeaderSearch-search-results-row-content---identifier'>
              {element.ancestor.state !== 3 ? null : (
                <i className='fa fa-archive'></i>
              )}

              {element.parent ? (
                <FormattedMessage
                  id='subpackage-identifier'
                  description='Subpackage identifier'
                  defaultMessage='Aliurakka :: {site}'
                  values={{
                    site: element.ancestor.name,
                  }}
                />
              ) : (
                <FormattedMessage
                  id='construction-site'
                  description='Construction site'
                  defaultMessage='Työmaa'
                />
              )}
            </div>
          </div>
        </li>
      </a>
    );
  };

  render() {
    const searchLoadingClasses = classNames(
      'loading',
      'loading-green',
      'loading-center',
      'm-t-md',
      'm-b-md'
    );

    return (
      <div className='HeaderSearch' onClick={this._preventClosing}>
        <div className='HeaderSearch-search'>
          <input
            type='text'
            className='HeaderSearch-search-input'
            placeholder={this.props.intl.formatMessage(messages.placeholder)}
            value={this.state.searchValue}
            onChange={this._handleSearch}
            onFocus={this._handleSearch}
            onBlur={this._clearSearch}
          />

          {!this.props.searchLoading ? null : (
            <div className='HeaderSearch-search-results'>
              <ul className='row'>
                <li>
                  <div className={searchLoadingClasses}>
                    <div></div>
                  </div>
                </li>
              </ul>
            </div>
          )}

          {this.props.searchLoading ||
          !this.state.searchValue ||
          !this.props.packages ? null : (
            <div className='HeaderSearch-search-results'>
              <ul className='HeaderSearch-search-results-content row'>
                {this.props.packages.length ? (
                  this.props.packages.map(this._row)
                ) : (
                  <li className='text-muted'>
                    <FormattedMessage
                      id='search-no-results'
                      description='No results when searching'
                      defaultMessage='Ei tuloksia'
                    />
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>

        <div
          className='HeaderSearch-close hidden-sm-up'
          onClick={this.props.toggleSearch}
        >
          <i className='fa fa-times'></i>
        </div>
      </div>
    );
  }
}

export default injectIntl(HeaderSearch);
