/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import SubpackageRow from './SubpackageRow';
import ProductRow from './ProductRow';

interface Props {
  packageActionCreators: any;
  logActionCreators: any;
  productActionCreators: any;
  packages?: Object;
  currentPackage?: {
    id: string;
    name: string;
  };
  products?: any[];
  activeProduct: any;
  productsLoading: boolean;
}

const PackageProductsLocal = ({
  packageActionCreators,
  logActionCreators,
  productActionCreators,
  packages,
  currentPackage,
  products,
  activeProduct,
  productsLoading,
}: Props) => {
  const openPanel = (product) => {
    packageActionCreators.getPackageProduct(product.package, product);

    logActionCreators.clearLog();
    logActionCreators.getProductLog(product.id);

    // This should probably only be called for frends/old boomi products
    if (product.external_id) {
      productActionCreators.getExternalProduct(product);
    }
  };

  if (!packages || !products || productsLoading) {
    return (
      <div className='PackageProductsLocal'>
        <div className='PackageProductsLocal-content'>
          <div className='loading loading-green loading-center m-t-md m-b-md' />
        </div>
      </div>
    );
  }

  const subpackages = Object.values(packages).filter(
    (_package) => currentPackage && _package.parent === currentPackage.id
  );

  return (
    <div className='PackageProductsLocal'>
      {subpackages.length ? (
        <div className='PackageProductsLocal-header text-muted'>
          <FormattedMessage
            id='products-local-subpackages'
            description='Header text for subpackages in products'
            defaultMessage='Urakalla "{name}" on seuraavat aliurakat:'
            values={{
              name: currentPackage?.name ?? '',
            }}
          />
        </div>
      ) : null}

      <div className='PackageProductsLocal-content'>
        {subpackages.length ? (
          <ul className='PackageProductsLocal-subpackages'>
            {subpackages.map((subpackage) => (
              <SubpackageRow subpackage={subpackage} />
            ))}
          </ul>
        ) : null}
      </div>

      {products.length ? (
        <div className='PackageProductsLocal-header text-muted'>
          <FormattedMessage
            id='products-local-header'
            description='Header for local products'
            defaultMessage='Urakkaan "{name}" on lisätty seuraavat tuotteet:'
            values={{
              name: currentPackage?.name ?? '',
            }}
          />
        </div>
      ) : null}

      <div className='PackageProductsLocal-content'>
        {!products.length ? (
          <div className='m-t text-muted'>
            <FormattedMessage
              id='no-products'
              description='Placeholder when there are no products'
              defaultMessage='Ei tuotteita.'
            />
          </div>
        ) : (
          <ul className='PackageProductsLocal-products'>
            {products.map((product) => (
              <ProductRow
                key={product.id}
                product={product}
                isActive={activeProduct === product.id}
                openPanel={openPanel}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default PackageProductsLocal;
