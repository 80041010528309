/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Messages from '../Messages/Messages';
import Pagination from '../Pagination/Pagination';
import ProductBrowsePanel from '../ProductBrowsePanel/ProductBrowsePanel';
import ProductSearchFilter from '../ProductSearchFilter/ProductSearchFilter';
import FavouriteIcon from '../FavouriteIcon/FavouriteIcon';
import ProductSearch from '../ProductSearch/ProductSearch.tsx';

import classNames from 'classnames';

import { fileSorter, getFileTypePath } from '../../utils/fileUtil';

import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import MissingProductLink from '../MissingProductLink/MissingProductLink';

const messages = defineMessages({
  searchPlaceholder: {
    id: 'search-placeholder',
    description: 'Placeholder when searching products',
    defaultMessage:
      'Syötä tuotteen GTIN, Talo 2000 -tuotenimike, rakennustuotenumero, ' +
      'sähkönumero, LVI-numero, nimi tai yritys',
  },

  searchButton: {
    id: 'search-button',
    description: 'Search button text',
    defaultMessage: 'Hae',
  },

  searchTooltip: {
    id: 'search',
    description: 'Tooltip for phrase search',
    defaultMessage: 'Haussa voit käyttää lainausmerkkejä tarkentamaan hakua',
  },
});

class ProductBrowseView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    productStore: PropTypes.object.isRequired,
    rtActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    productActionCreators: PropTypes.object.isRequired,
    fileActionCreators: PropTypes.object.isRequired,
    favouritesActionCreators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const stores = this._getStoreData();

    this.state = {
      page: 1,
      query: null,
      activeProduct: null,
      filters: null,
      includeDeleted: false,
      fileFilters: [],
      searchText: '',
      ...stores,
    };
  }

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );

    this._productStoreSubscription = this.props.productStore.addListener(
      this._onStoreChange
    );

    if (
      this.state.productState.globalSearch &&
      this.state.productState.globalSearch.length
    ) {
      this.props.productActionCreators.clearGlobalProductSearch();
    }
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
    this._productStoreSubscription.remove();
  }

  componentDidUpdate(_prevProps, prevState) {
    // Refetch products when file filters change in state,
    // search button is pressed or when user navigates to a
    // different page.
    // Could be refactored into useEffect.
    if (
      prevState.fileFilters !== this.state.fileFilters ||
      prevState.query !== this.state.query ||
      prevState.page !== this.state.page
    ) {
      this.props.productActionCreators.searchGlobalProducts(
        -1,
        this.state.query || '',
        this.state.filters,
        this.state.page || 1,
        this.state.includeDeleted,
        false,
        false,
        this.state.fileFilters
      );
    }
  }

  _onStoreChange = () => {
    this.setState(this._getStoreData());
  };

  _getStoreData = () => ({
    packageState: this.props.packageStore.getState(),
    productState: this.props.productStore.getState(),
    rtState: this.props.rtStore.getState(),
  });

  _handleFilters = (event, filters) => {
    event.preventDefault();
    const page = 1;

    this.setState({
      page,
      filters,
    });
  };

  _handleSearch = (searchText) => {
    const page = 1;
    const query = searchText || this.state.searchText;

    this.setState({
      page,
      query,
      fileFilters: [], // Remove this if file filters should be kept when changing search words
    });
  };

  _navigate = (page) => {
    this.setState({
      page,
    });
  };

  _filterByFile(type) {
    this.setState((prevState) => ({
      page: 1,
      fileFilters: prevState.fileFilters.includes(type)
        ? prevState.fileFilters.filter((ft) => ft !== type)
        : [...prevState.fileFilters, type],
    }));
  }

  _productRow = (product) => {
    if (!product.data.from_company && product.data.manual) return null;
    // Differentiate construction sites in the list
    const { deleted } = product.data;
    const productClasses = classNames(
      'ProductBrowseView-row',
      'row-flex',
      'align-vert',
      {
        active: this.state.activeProduct === product.data.id,
        planned: product.status === 0,
        installed: product.status === 1,
        deleted,
      }
    );
    const productContentClasses = classNames('ProductBrowseView-row-content', {
      deleted,
    });
    const productContentIdentifierClasses = classNames(
      'ProductBrowseView-row-content---identifier',
      {
        deleted,
      }
    );

    const fileTypes = product.file_types
      ? product.file_types.filter(Boolean).sort(fileSorter)
      : [];
    const filePaths = [
      ...new Set(fileTypes.map((type) => getFileTypePath(type))),
    ];

    return (
      <div
        className='col-xs-12 no-padding'
        key={product.data.id}
        onClick={this.openPanel.bind(this, product)}
      >
        <li className={productClasses}>
          <div className={productContentClasses}>
            <span>{product.data.name}</span>
            <FavouriteIcon
              productData={product.data}
              favouritesActionCreators={this.props.favouritesActionCreators}
            />

            <div className={productContentIdentifierClasses}>
              {product.data.talo2000
                ? `${product.data.talo2000} :: ${product.data.manufacturer}`
                : product.data.manufacturer}
            </div>
          </div>

          <div className='ProductBrowseView-row-content-right' dir='rtl'>
            {filePaths.map((path) => (
              <span
                key={path}
                className='ProductBrowseView-row-content-filetype'
              >
                <img src={path} />
              </span>
            ))}
          </div>
        </li>
      </div>
    );
  };

  openPanel = (product, event) => {
    event.preventDefault();

    this.setState({
      activeProduct: product.data.id,
    });

    this.props.productActionCreators.getSearchProduct(-1, product);
  };

  _getSearch = () => {
    const productCount =
      !this.state.productState.globalSearchLoading &&
      this.state.productState.globalSearch &&
      this.state.productState.globalSearch.length
        ? this.state.productState.globalSearch[0].count
        : 0;
    const lastPage = this.state.page === Math.ceil(productCount / 20);
    if (this.state.productState.globalSearchLoading) {
      return (
        <div className='loading loading-green loading-center m-t-md'>
          <div />
        </div>
      );
    } else if (!this.state.productState.globalSearch) {
      return null;
    } else if (!this.state.productState.globalSearch.length) {
      return <MissingProductLink />;
    }
    // Get search results and sort so that deleted products are listed last
    const searchResults = this.state.productState.globalSearch;
    return (
      <div>
        <ul>{searchResults.map(this._productRow)}</ul>
        {lastPage && <MissingProductLink />}
      </div>
    );
  };

  _getContent = () => {
    const { rtState } = this.state;
    const { packageState } = this.state;
    const productCount =
      !this.state.productState.globalSearchLoading &&
      this.state.productState.globalSearch &&
      this.state.productState.globalSearch.length
        ? this.state.productState.globalSearch[0].count
        : 0;

    return (
      <div className='container-fluid'>
        <Header
          user={rtState.user}
          packages={packageState.packageSearch}
          searchLoading={packageState.packageSearchLoading}
          packageActionCreators={this.props.packageActionCreators}
        />

        <Messages
          messageStore={this.props.messageStore}
          messageActionCreators={this.props.messageActionCreators}
        />

        <div className='ProductBrowseView-content row'>
          <div className='ProductBrowseView-content--left col-xs-12 col-sm-8'>
            <div className='ProductBrowseView-content--left-content'>
              <h2>
                <FormattedMessage
                  id='browse-products-header'
                  description='Header for the product browse page'
                  defaultMessage='Tuotteiden selailu'
                />
                <a
                  className='m-l'
                  onClick={(evt) => evt.stopPropagation()}
                  data-toggle='tooltip'
                  data-placement='bottom'
                  title={this.props.intl.formatMessage(messages.searchTooltip)}
                >
                  <i className='fa fa-info-circle' />
                </a>
              </h2>

              <div className='m-t m-b'>
                <ProductSearch
                  searchText={this.state.searchText}
                  onSearchTextChange={(searchText) =>
                    this.setState({ searchText })
                  }
                  onScanResult={(res) => {
                    this.setState({ searchText: res });
                    this._handleSearch(res);
                  }}
                  onSubmit={this._handleSearch}
                  includeDeleted={this.state.includeDeleted}
                  toggleIncludeDeleted={this._handleShowDeletedProducts}
                  fileFilters={this.state.fileFilters}
                  onFileFilterClick={(type) => this._filterByFile(type)}
                />
              </div>

              <div className='PackageProductsGlobal-filters'>
                <ProductSearchFilter
                  query={this.state.query}
                  properties={this.state.productState.globalSearchProperties}
                  handler={this._handleFilters}
                />
              </div>

              <div>{this._getSearch()}</div>

              <Pagination
                page={this.state.page}
                count={productCount}
                perPage={20}
                handler={this._navigate}
              />
            </div>
            <Footer />
          </div>

          <ProductBrowsePanel
            packages={this.state.packageState.packages}
            product={this.state.productState.product}
            productLoading={this.state.productState.productLoading}
            globalSearch={this.state.productState.globalSearch}
            packageActionCreators={this.props.packageActionCreators}
            productActionCreators={this.props.productActionCreators}
            fileActionCreators={this.props.fileActionCreators}
            messageActionCreators={this.props.messageActionCreators}
            safetyData={this.state.productState.safetyData.fi}
          />
        </div>
      </div>
    );
  };

  _handleShowDeletedProducts = (event) => {
    const includeDeleted = event.target.checked;
    this.setState({ includeDeleted, page: 1 });

    const query = this.state.searchText ? this.state.searchText : null;
    if (query) {
      this.props.productActionCreators.searchGlobalProducts(
        -1,
        query,
        this.state.filters,
        1,
        includeDeleted,
        false,
        false,
        this.state.fileFilters
      );
    }
  };

  render() {
    let content = null;

    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null ||
      this.state.productState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return <div className='ProductBrowseView'>{content}</div>;
  }
}

export default AuthenticatedView(injectIntl(ProductBrowseView));
