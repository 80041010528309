/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getMetaMessages } from '../../../shared/MetaFields.mjs';

class PackageInformationMeta extends React.Component {
  static propTypes = {
    package: PropTypes.object,
    packageLoading: PropTypes.bool.isRequired,
    intl: PropTypes.object.isRequired,
  };

  render() {
    if (this.props.packageLoading || !this.props.package) {
      return (
        <div className='PackageInformationMeta card'>
          <div className='card-block'>
            <h4 className='card-title'>
              <FormattedMessage
                id='meta'
                description='Header for meta'
                defaultMessage='Lisätiedot'
              />
            </h4>

            <div className='card-text'>
              <div className='loading loading-green loading-center'>
                <div></div>
              </div>
            </div>
          </div>

          <div className='card-footer'>
            <div className='loading loading-green loading-sm loading-center'>
              <div></div>
            </div>
          </div>
        </div>
      );
    }

    const metaMessages = getMetaMessages();

    return (
      <div className='PackageInformationMeta card'>
        <div className='card-block'>
          <h4 className='card-title'>
            <FormattedMessage
              id='meta'
              description='Header for meta'
              defaultMessage='Lisätiedot'
            />
          </h4>

          <div className='card-text'>
            <ul className='list-group'>
              {!!this.props.package.meta ? (
                Object.keys(this.props.package.meta).map((field) => {
                  return (
                    <li key={field}>
                      <b>
                        {metaMessages.hasOwnProperty(field)
                          ? this.props.intl.formatMessage(metaMessages[field])
                          : field}
                      </b>
                      : {this.props.package.meta[field]}
                    </li>
                  );
                })
              ) : (
                <li>
                  <FormattedMessage
                    id='no-details'
                    description='No details available'
                    defaultMessage='Ei lisätietoja'
                  />
                </li>
              )}
            </ul>
          </div>
        </div>

        <div className='card-footer text-center'>
          <a
            className='btn btn-primary-outline'
            href={`/package/${this.props.package.id}/edit`}
          >
            <i className='fa fa-pencil-square-o'></i>&nbsp;
            <FormattedMessage
              id='edit-information'
              description='Button to edit package information'
              defaultMessage='Muokkaa tietoja'
            />
          </a>
        </div>
      </div>
    );
  }
}

export default injectIntl(PackageInformationMeta);
