/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';

import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Messages from '../Messages/Messages';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs.tsx';
import AddPackageModal from '../AddPackageModal/AddPackageModal.jsx';
import PackageInformationBasic from '../PackageInformationBasic/PackageInformationBasic';
import PackageInformationProducts from '../PackageInformationProducts/PackageInformationProducts';
import PackageInformationChemicals from '../PackageInformationChemicals/PackageInformationChemicals';
import PackageInformationSubpackages from '../PackageInformationSubpackages/PackageInformationSubpackages';
import PackageInformationRights from '../PackageInformationRights/PackageInformationRights';
import PackageInformationMeta from '../PackageInformationMeta/PackageInformationMeta';
import ReactTooltip from 'react-tooltip';

import { FormattedMessage } from 'react-intl';

class PackageInformationView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    productStore: PropTypes.object.isRequired,
    logStore: PropTypes.object.isRequired,
    rtActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    productActionCreators: PropTypes.object.isRequired,
    logActionCreators: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this._getStoreData();
  }

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );

    this._productStoreSubscription = this.props.productStore.addListener(
      this._onStoreChange
    );

    this._logStoreSubscription = this.props.logStore.addListener(
      this._onStoreChange
    );
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
    this._productStoreSubscription.remove();
    this._logStoreSubscription.remove();
  }

  _onStoreChange = () => {
    this.setState(this._getStoreData());
  };

  _getStoreData = () => ({
    rtState: this.props.rtStore.getState(),
    packageState: this.props.packageStore.getState(),
    productState: this.props.productStore.getState(),
    logState: this.props.logStore.getState(),
  });

  _getHeader = () => {
    if (
      this.state.packageState.packageLoading ||
      !this.state.packageState.package
    ) {
      return (
        <h3 className='PackageInformationView-header text-center'>
          <div className='loading loading-green'>
            <div />
          </div>
        </h3>
      );
    }

    return (
      <h3 className='PackageInformationView-header text-center'>
        <div className='PackageInformationView-header-content'>
          {this.state.packageState.package.parent ? (
            <FormattedMessage
              id='subpackage'
              description='Subpackage'
              defaultMessage='Aliurakka'
            >
              {(message) => (
                <strong>
                  <i className='fa fa-clone' /> {message}
                </strong>
              )}
            </FormattedMessage>
          ) : (
            <FormattedMessage
              id='construction-site'
              description='Construction site'
              defaultMessage='Työmaa'
            >
              {(message) => (
                <strong>
                  <i className='fa fa-square-o' /> {message}
                </strong>
              )}
            </FormattedMessage>
          )}
          : {this.state.packageState.package.name}
        </div>
      </h3>
    );
  };

  _getContent = () => {
    const { packageState } = this.state;

    return (
      <div className='container-fluid'>
        <Header
          user={this.state.rtState.user}
          packages={packageState.packageSearch}
          searchLoading={packageState.packageSearchLoading}
          packageActionCreators={this.props.packageActionCreators}
        />

        <Messages
          messageStore={this.props.messageStore}
          messageActionCreators={this.props.messageActionCreators}
        />

        <AddPackageModal
          messageActionCreators={this.props.messageActionCreators}
          packageActionCreators={this.props.packageActionCreators}
          parent={this.state.packageState.addToParent}
        />

        <ReactTooltip class='tooltip' />

        <div className='PackageInformationView-content row'>
          <div className='col-xs-12 col-sm-12 no-padding-xs'>
            {!packageState.packageLoading && (
              <Breadcrumbs
                currentPackage={packageState.package}
                packages={packageState.packages}
                path='/package/{id}'
              />
            )}

            {this._getHeader()}

            <div className='PackageInformationView-content-confined-content'>
              <div className='row'>
                <div className='col-xs-12 col-sm-6 col-md-4'>
                  <PackageInformationSubpackages
                    package={packageState.package}
                    packageLoading={packageState.packageLoading}
                    user={this.state.rtState.user}
                    packageActionCreators={this.props.packageActionCreators}
                  />
                </div>

                <div className='col-xs-12 col-sm-6 col-md-4'>
                  <PackageInformationProducts
                    package={packageState.package}
                    packageLoading={packageState.packageLoading}
                  />
                </div>

                <div className='col-xs-12 col-sm-6 col-md-4'>
                  <PackageInformationBasic
                    package={packageState.package}
                    packageLoading={packageState.packageLoading}
                    products={packageState.products}
                    log={this.state.logState.log}
                    packageActionCreators={this.props.packageActionCreators}
                    productActionCreators={this.props.productActionCreators}
                    messageActionCreators={this.props.messageActionCreators}
                  />
                </div>

                <div className='col-xs-12 col-sm-6 col-md-4'>
                  <PackageInformationRights
                    user={this.state.rtState.user}
                    package={packageState.package}
                    packageLoading={packageState.packageLoading}
                    rights={packageState.rights}
                    rightsLoading={packageState.rightsLoading}
                    packageActionCreators={this.props.packageActionCreators}
                  />
                </div>

                <div className='col-xs-12 col-sm-6 col-md-4'>
                  <PackageInformationChemicals
                    package={packageState.package}
                    packageLoading={packageState.packageLoading}
                  />
                </div>

                <div className='col-xs-12 col-sm-6 col-md-4'>
                  <PackageInformationMeta
                    package={packageState.package}
                    packageLoading={packageState.packageLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let content = null;
    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='PackageInformationView'>
        {content}
        <Footer />
      </div>
    );
  }
}

export default AuthenticatedView(PackageInformationView);
