/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

/* eslint-disable */

import PropTypes from 'prop-types';

import React from 'react';

import { injectIntl,
        FormattedMessage }
        from 'react-intl';

class ProductInformationModal extends React.Component {
  static propTypes = {
    productActionCreators: PropTypes.object.isRequired,
    product: PropTypes.object,
  };

  redirect = (event) => {
    event.preventDefault();
    window.open(this.props.product.data.url,'_blank');
    $(this.refs.ProductInformationModal).modal('hide');
  };

  render() {
    const message =
      'Klikkaamasi linkki vie sinut asennetun tuotteen ajantasaisiin tuotetietoihin RT:n tuotetietokannassa. Siellä näkyvät tiedot voivat poiketa tämän tuotteen tiedoista sen asennushetkellä!'

    return (
      <div
        className='ProductInformationModal modal fade'
        ref='ProductInformationModal'
        id='ProductInformationModal'
        tabIndex='-1'
        data-backdrop='static'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                <span>&times;</span>
              </button>
              <h4 className='modal-title'>
                  <FormattedMessage
                    id='create-construction-site-header'
                    description='Create construction site header'
                    defaultMessage='Tarkemmat tuotetiedot'
                  />
              </h4>
            </div>

            <div className='ProductInformationModal-content modal-body'>
              <div>
                <div style={{paddingBottom: '6px'}}>
                <FormattedMessage
                  id='info-message'
                  description='Info message'
                  defaultMessage={message}
                />
                </div>
              </div>
            </div>
            
            {!this.props.loading &&
            <div className='modal-footer text-center'>
              <input
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                value='Palaa'
              />

                <button
                  className='btn btn-success'
                  onClick={this.redirect}
                >
                <FormattedMessage
                  id='disapprove'
                  description='Button for redirect'
                  defaultMessage='Avaa linkki'
                />
                </button>
            </div>
            }
          </div>
        </div>
      </div>
    )}
}

export default injectIntl(ProductInformationModal);
