/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';

const Navigation = ({ user }) => {
  const navClasses = classNames(
    'navbar',
    'navbar-inverse',
    'navbar-static-top',
    'col-xs-12'
  );

  return (
    <div className='Navigation'>
      <nav className={navClasses}>
        <ul className='nav nav-pills'>
          <li className='nav-item'>
            <a className='nav-link' href='/'>
              <FormattedMessage
                id='navigation-all-packages'
                description='Navigation link to all packages page'
                defaultMessage='Kaikki urakat'
              />
              <i className='hidden-sm-up fa fa-angle-right' />
            </a>
          </li>

          {(user && user.admin) || (user && user.role === 0) ? (
            <li className='nav-item'>
              <a className='nav-link' href='/rights'>
                <FormattedMessage
                  id='manage-rights'
                  description='Link to the rights management page'
                  defaultMessage='Hallinnoi käyttäjiä'
                />
                <i className='hidden-sm-up fa fa-angle-right' />
              </a>
            </li>
          ) : null}

          {(user && user.admin) || (user && user.role === 0) ? (
            <li className='nav-item'>
              <a className='nav-link' href='/reports'>
                <FormattedMessage
                  id='navigation-reports'
                  description='Navigation link to reports'
                  defaultMessage='Raportit'
                />
                <i className='hidden-sm-up fa fa-angle-right' />
              </a>
            </li>
          ) : null}

          {(user && user.admin) || (user && user.licenseAccess) ? (
            <li className='nav-item'>
              <a className='nav-link' href='/products/manual'>
                <FormattedMessage
                  id='manage-products'
                  description='Link to the manually added products page'
                  defaultMessage='Yrityksen tuotteet'
                />
                <i className='hidden-sm-up fa fa-angle-right' />
              </a>
            </li>
          ) : null}

          <li className='nav-item'>
            <a className='nav-link' href='/products/browse'>
              <FormattedMessage
                id='navigation-browse-products'
                description='Navigation link to browse products'
                defaultMessage='Selaa tuotteita'
              />
              <i className='hidden-sm-up fa fa-angle-right' />
            </a>
          </li>

          <li className='nav-item'>
            <a
              className='nav-link'
              href='https://rakennustieto.notion.site/RT-urakoitsijan-tuotetieto-ohjeet-b6a2075aff024d50882b4192cebd92bd'
              target='_blank'
              rel='external'
            >
              <FormattedMessage
                id='navigation-support'
                description='Navigation link to support'
                defaultMessage='Tuki'
              />
              <i className='hidden-sm-up fa fa-angle-right' />
            </a>
          </li>

          {/* <li className='nav-item pull-right'>
              <a
                className='nav-link'
                href='http://rt.paranna.fi/conversation/45/view'
                target='_blank'
                rel='external'
              >
                <FormattedMessage
                  id='navigation-feedback'
                  description='Navigation link to give feedback'
                  defaultMessage='Anna palautetta'
                />
                <i className='hidden-sm-up fa fa-angle-right'/>
              </a>
            </li> */}

          <li className='nav-item'>
            <a className='nav-link' href='/bulletins'>
              <FormattedMessage
                id='navigation-bulletins'
                description='Navigation link to bulletins'
                defaultMessage='Tiedotteet'
              />
              <i className='hidden-sm-up fa fa-angle-right' />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

Navigation.propTypes = {
  user: PropTypes.object,
};

export default Navigation;
