import { Store } from 'flux/utils';
import ActionTypes from '../actions/ActionTypes';

class ProductStore extends Store {
  constructor(dispatcher) {
    super(dispatcher);
    this._state = this.getInitialState();
  }

  getInitialState() {
    return {
      product: null,
      productLoading: false,
      count: 0,
      commandInProgress: false,
      externalProduct: null,
      globalSearch: null,
      globalSearchLoading: false,
      globalSearchProperties: null,
      error: null,
      openProductInformationModal: false,
      safetyData: {},
      langCode: 'fi',
      approverIds: null,
    };
  }

  getState() {
    return this._state;
  }

  clearState() {
    this._state = this.getInitialState();
  }

  __onDispatch(payload) {
    let index = null;

    switch (payload.type) {
      case ActionTypes.CLEAR_STORES:
        this.clearState();
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_OPEN_INFORMATION_MODAL:
        this._state.openProductInformationModal = true;
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_GLOBAL_SEARCH_LOADING:
        this._state.globalSearchLoading = true;
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_RECEIVE_GLOBAL_SEARCH:
        this._state.globalSearchLoading = false;
        this._state.globalSearch = payload.products;
        this._state.globalSearchProperties = payload.properties;
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_RECEIVE_GLOBAL_SEARCH_ERROR:
        this._state.error = payload.error;
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_MANUAL_ADDED:
        this._state.product = payload.product;
        if (this._state.globalSearch) {
          this._state.globalSearch = [
            payload.product,
            ...this._state.globalSearch,
          ];
        }
        this.__emitChange();
        break;
      case ActionTypes.PACKAGE_ADDED_PRODUCT:
        if (this._state.globalSearch) {
          index = this._state.globalSearch.findIndex(
            (product) =>
              product.data.id === payload.product.data.id &&
              !!product.data.manual === !!payload.product.data.manual
          );
          if (index !== -1) {
            const { count } = this._state.globalSearch[index];
            this._state.globalSearch[index] = {
              count,
              ...payload.product,
            };
          }
        }

        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_INSTALLED:
        if (this._state.globalSearch) {
          index = this._state.globalSearch.findIndex(
            (product) => product.id === payload.product.id
          );

          if (index !== -1) {
            this._state.globalSearch[index] = payload.product;
          }
        }

        if (
          this._state.product &&
          this._state.product.id === payload.product.id
        ) {
          this._state.product = payload.product;
        }

        this._state.count += 1;

        if (payload.count && this._state.count === payload.count) {
          this._state.commandInProgress = false;
        }
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_UNINSTALLED:
        if (this._state.globalSearch) {
          index = this._state.globalSearch.findIndex(
            (product) => product.id === payload.product.id
          );

          if (index !== -1) {
            this._state.globalSearch[index] = payload.product;
          }
        }

        if (
          this._state.product &&
          this._state.product.id === payload.product.id
        ) {
          this._state.product = payload.product;
        }

        this._state.count += 1;

        if (payload.count && this._state.count === payload.count) {
          this._state.commandInProgress = false;
        }
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_REMOVED:
        if (
          this._state.product &&
          this._state.product.id === payload.product.id
        ) {
          this._state.product = null;
        }

        if (this._state.globalSearch) {
          index = this._state.globalSearch.findIndex(
            (product) => product.data.id === payload.product.data.id
          );

          if (index !== -1) {
            payload.product.id = null;
            const { count } = this._state.globalSearch[index];
            this._state.globalSearch[index] = {
              count,
              ...payload.product,
            };
          }
        }

        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_MANUAL_REMOVED:
        if (
          this._state.product &&
          this._state.product.data.id === payload.product.data.id
        ) {
          this._state.product = null;
        }

        if (this._state.globalSearch) {
          this._state.globalSearch = this._state.globalSearch.filter(
            (product) =>
              !product.data.manual ||
              Number(product.data.id) !== payload.product.data.id
          );
        }

        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_APPROVE:
        if (this._state.globalSearch) {
          index = this._state.globalSearch.findIndex(
            (product) => product.id === payload.product.id
          );

          if (index !== -1) {
            this._state.globalSearch[index].approved = payload.product.approved;
          }
        }

        if (
          this._state.product &&
          this._state.product.id === payload.product.id
        ) {
          this._state.product.approved = payload.product.approved;
        }

        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_LOADING:
        this._state.productLoading = true;
        this.__emitChange();
        break;
      case ActionTypes.RESET_COUNTER:
        this._state.count = 0;
        this.__emitChange();
        break;
      case ActionTypes.START_PROGRESS:
        this._state.commandInProgress = true;
        this.__emitChange();
        break;
      case ActionTypes.PROGRESS_FAILED:
        this._state.commandInProgress = false;
        this._state.count = 99999;
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_RECEIVE:
        this._state.product = payload.product;
        this._state.productLoading = false;

        if (this._state.globalSearch && payload.product) {
          const data =
            payload.product.data || payload.product.snapshot_data || {};
          this._state.globalSearch.forEach((product, idx) => {
            if (
              product.data &&
              product.data.id === data.id &&
              !!product.data.manual === !!data.manual
            ) {
              this._state.globalSearch[idx] = {
                ...product,
                ...payload.product,
                files: product.files,
                data: {
                  ...product.data,
                  ...data,
                },
              };
            }
          });
        }
        this._state.count += 1;

        if (payload.count && this._state.count === payload.count) {
          this._state.commandInProgress = false;
        }
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_RECEIVE_ERROR:
        this._state.productLoading = false;
        this._state.product = null;
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_RECEIVE_EXTERNAL:
        this._state.externalProduct = payload.product;
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_RECEIVE_EXTERNAL_ERROR:
        this._state.externalProduct = null;
        this.__emitChange();
        break;
      case ActionTypes.FILE_ADDED:
        if (
          this._state.product &&
          this._state.product.id === payload.data.product_id
        ) {
          this._state.product.files = payload.data.files;
          this.__emitChange();
        }
        break;
      case ActionTypes.FILE_UPDATED:
        if (
          this._state.product &&
          this._state.product.id === payload.data.product_id
        ) {
          this._state.product.files = this._state.product.files.map((file) =>
            file.id === payload.data.id ? payload.data : file
          );
          const fileTypes = [];
          this._state.product.file_types = this._state.product.files.forEach(
            (file) => {
              if (fileTypes.indexOf(file.type) !== -1) return;
              fileTypes.push(file.type);
            }
          );
          this._state.product.file_types = fileTypes;
          this.__emitChange();
        }
        break;
      case ActionTypes.FILE_MANUAL_UPDATED: {
        if (
          this._state.product &&
          this._state.product.data.id === payload.data.file.product_manual_id &&
          !!this._state.product.data.manual
        ) {
          this._state.product.data.files = this._state.product.data.files.map(
            (file) =>
              file.id === payload.data.file.id ? payload.data.file : file
          );
          const fileTypes = [];
          this._state.product.file_types =
            this._state.product.data.files.forEach((file) => {
              if (fileTypes.indexOf(file.type) !== -1) return;
              fileTypes.push(file.type);
            });
        }

        if (this._state.globalSearch) {
          this._state.globalSearch.forEach((product) => {
            if (
              product.data.id === payload.data.file.product_manual_id &&
              !!product.data.manual
            ) {
              product.data.files = product.data.files.map((file) =>
                file.id === payload.data.file.id ? payload.data.file : file
              );
              const fileTypes = [];
              product.data.files.forEach((file) => {
                if (fileTypes.indexOf(file.type) !== -1) return;
                fileTypes.push(file.type);
              });
              product.file_types = fileTypes;
            }
          });
        }
        this.__emitChange();
        break;
      }
      case ActionTypes.FILE_MANUAL_ADDED: {
        const fileTypes = payload.data.files
          .filter(
            (file, idx, self) =>
              self.findIndex((f2) => f2.type === file.type) === idx
          )
          .map((file) => file.type);

        if (
          this._state.product &&
          this._state.product.data.id === payload.data.product_id &&
          !!this._state.product.data.manual
        ) {
          this._state.product.data.files = payload.data.files;
          this._state.product.file_types = fileTypes;
        }

        if (this._state.globalSearch) {
          this._state.globalSearch.forEach((product) => {
            if (
              product.data.id === payload.data.product_id &&
              !!product.data.manual
            ) {
              product.data.files = payload.data.files;
              product.file_types = fileTypes;
            }
          });
        }

        this.__emitChange();
        break;
      }
      case ActionTypes.SAFETY_DATA_RECEIVE:
        this._state.safetyData[payload.langCode] = payload.safetyData;
        this.__emitChange();
        break;
      case ActionTypes.SAFETY_DATA_RECEIVE_ERROR:
        this._state.safetyData[payload.langCode] = null;
        this.__emitChange();
        break;
      case ActionTypes.FAVOURITES_RECEIVE:
        if (
          this._state.product &&
          `${this._state.product.data.id}` === `${payload.product.product_id}`
        ) {
          this._state.product.data.is_favourite = payload.product.is_favourite;
        }

        if (this._state.globalSearch) {
          index = this._state.globalSearch.findIndex(
            (product) =>
              `${product.data.id}` === `${payload.product.product_id}`
          );

          if (index !== -1) {
            this._state.globalSearch[index].data.is_favourite =
              payload.product.is_favourite;
          }
        }

        this.__emitChange();
        break;
      case ActionTypes.CHANGE_LANGUAGE:
        this._state.langCode = payload.langCode;
        this.__emitChange();
        break;
      case ActionTypes.APPROVER_IDS_RECEIVE:
        this._state.approverIds = payload.data?.productIds;
        this.__emitChange();
        break;
      default:
      // Ignoring other ActionTypes
    }
  }
}

export default ProductStore;
