import PropTypes from 'prop-types';
import React from 'react';
import debounce from 'debounce';
import classNames from 'classnames';

import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import ConfirmActionModal from '../ConfirmActionModal/ConfirmActionModal.tsx';

const messages = defineMessages({
  placeholder: {
    id: 'search-for-packages',
    description: 'Placeholder for searching for packages',
    defaultMessage: 'Hae urakoita...',
  },
  removeUser: {
    id: 'remove-user-info',
    description: 'Info about removing user from package',
    defaultMessage: `Olet poistamassa käyttäjän {user}
    urakasta {package}.`,
  },
});

class CompanyRightsTable extends React.Component {
  static propTypes = {
    rights: PropTypes.array,
    packages: PropTypes.array,
    packageActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  state = {
    username: null,
    pkg: null,
    packageChoice: null,
    collapsed: {},
    searchValue: '',
  };

  _clearSearch = () => {
    this.setState({
      packageChoice: null,
      searchValue: '',
    });

    this.props.packageActionCreators.searchPackages(null);
  };

  _handleSearch = (event) => {
    const query = event.target.value;
    this.setState({
      searchValue: query,
    });

    if (query.length < 3) {
      this.props.packageActionCreators.searchPackages(null);
      return;
    }

    // Debounce rate can be changed from 250 ms
    debounce((value) => {
      this.props.packageActionCreators.searchPackages(value);
    }, 250)(query);
  };

  _selectSearchRow = (pkg, event) => {
    event.preventDefault();
    this._clearSearch();

    this.setState({
      packageChoice: pkg,
    });
  };

  _openModal = (username) => {
    this.setState({
      username: username,
    });
  };

  _openRemoveUserModal = (username, pkg) => {
    this.setState({
      username,
      pkg,
    });
    $('#RemoveUserFromPackageModal').modal('show');
  };

  _searchRow = (pkg) => {
    return (
      <a key={pkg.id} href='#' onClick={this._selectSearchRow.bind(this, pkg)}>
        <li className='AddRightsModal-search-results-row row-flex align-vert'>
          <div className='AddRightsModal-search-results-row-content'>
            <span>{pkg.name}</span>
            <span>{`(${pkg.company.name})`}</span>

            <div className='AddRightsModal-search-results-row-content---identifier'>
              {pkg.ancestor.state !== 3 ? null : (
                <i className='fa fa-archive'></i>
              )}

              {pkg.parent ? (
                <FormattedMessage
                  id='subpackage-identifier'
                  description='Subpackage identifier'
                  defaultMessage='Aliurakka :: {site}'
                  values={{
                    site: pkg.ancestor.name,
                  }}
                />
              ) : (
                <FormattedMessage
                  id='construction-site'
                  description='Construction site'
                  defaultMessage='Työmaa'
                />
              )}
            </div>
          </div>
        </li>
      </a>
    );
  };

  _packageRow = (username, pkg) => {
    return (
      <div key={pkg.id}>
        <a
          className='m-r'
          href='#'
          onClick={() => this._openRemoveUserModal(username, pkg)}
        >
          <i className='fa fa-trash'></i>
        </a>
        <a href={`/package/${pkg.id}`}>{pkg.name}</a>
        {pkg.ancestor.id !== pkg.id && (
          <span>
            &nbsp;(
            <a href={`/package/${pkg.ancestor.id}`}>{pkg.ancestor.name}</a>)
          </span>
        )}
      </div>
    );
  };

  _expandPackages = (username, event) => {
    event.preventDefault();

    const collapsed = this.state.collapsed;
    if (!collapsed.hasOwnProperty(username)) {
      collapsed[username] = true;
    } else {
      collapsed[username] = !collapsed[username];
    }

    this.setState({
      collapsed: collapsed,
    });
  };

  _addRights = (event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    this.props.packageActionCreators.giveRights(
      this.state.packageChoice.id,
      this.state.username
    );
  };

  _removeRights = (username, packageID, event) => {
    if (event) event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    this.props.packageActionCreators.deleteRights(packageID, username);
  };

  _toggleAllRights = (user, event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();

    if (user.admin) {
      this.props.packageActionCreators.cantModifyRTMainUser();
    } else {
      if (user.role === 0) {
        this._addUTTLimitedRights(user.username);
      } else {
        this._addAllRights(user.username);
      }
    }
  };

  _toggleUTTLimitedRights = (user, event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();

    if (user.admin) {
      this.props.packageActionCreators.cantModifyRTMainUser();
    } else {
      if (user.role > 0) {
        this._removeAllRights(user.username);
      } else {
        this._addUTTLimitedRights(user.username);
      }
    }
  };

  _addAllRights = (username) => {
    this.props.packageActionCreators.giveAllRights(username, 0);
  };

  _removeAllRights = (username) => {
    this.props.packageActionCreators.removeAllRights(username);
  };

  _addUTTLimitedRights = (username) => {
    this.props.packageActionCreators.giveAllRights(username, 1);
  };

  render() {
    if (!this.props.rights) {
      return (
        <div className='CompanyRightsTable'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id='rights-table-header-username'
                    description='Username header text for the rights table'
                    defaultMessage='Käyttäjätunnus'
                  />
                </th>

                <th>
                  <FormattedMessage
                    id='rights-table-header-admin'
                    description='Admin header text for the rights table'
                    defaultMessage='RT pääkäyttäjä'
                  />
                </th>

                <th>
                  <FormattedMessage
                    id='rights-table-header-all-rights'
                    description='All rights header text for the rights table'
                    defaultMessage='THP pääkäyttäjä'
                  />
                </th>

                <th>
                  <FormattedMessage
                    id='rights-table-header-all-rights'
                    description='All rights header text for the rights table'
                    defaultMessage='THP hallinnoija'
                  />
                </th>

                <th>
                  <FormattedMessage
                    id='rights-table-header-specific-rights'
                    description={
                      'Specific rights header text for the rights table'
                    }
                    defaultMessage='Urakkakohtaiset oikeudet'
                  />
                </th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan='5'>
                  <div className='loading loading-green loading-center m-t'>
                    <div></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    const modalInfo =
      this.state.username && this.state.pkg ? (
        <FormattedMessage
          id='info-message'
          description='Info message'
          defaultMessage={messages.removeUser.defaultMessage}
          values={{
            user: <b>{this.state.username}</b>,
            package: <b>{this.state.pkg.name}</b>,
          }}
        />
      ) : null;

    return (
      <div className='CompanyRightsTable'>
        <ConfirmActionModal
          id={'RemoveUserFromPackageModal'}
          title='Poista käyttäjä urakasta'
          info={modalInfo}
          onConfirm={() =>
            this._removeRights(this.state.username, this.state.pkg.id)
          }
        />

        <div
          className='AddRightsModal modal fade'
          id='AddRightsModal'
          tabIndex='-1'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  onClick={this._clearSearch}
                >
                  <span>&times;</span>
                </button>

                <h4 className='modal-title'>
                  <FormattedMessage
                    id='add-rights-modal-header'
                    description='Header text for rights adding modal'
                    defaultMessage='Lisää käyttäjälle "{name}" erillisoikeus'
                    values={{
                      name: this.state.username,
                    }}
                  />
                </h4>
              </div>

              <div className='modal-body'>
                <input
                  type='text'
                  className='form-control'
                  placeholder={this.props.intl.formatMessage(
                    messages.placeholder
                  )}
                  value={this.state.searchValue}
                  onChange={this._handleSearch}
                />

                {!this.state.searchValue || !this.props.packages ? null : (
                  <div className='AddRightsModal-search-results'>
                    <ul className='row'>
                      {this.props.packages.length ? (
                        this.props.packages.map(this._searchRow)
                      ) : (
                        <li className='text-muted'>
                          <FormattedMessage
                            id='search-no-results'
                            description='No results when searching'
                            defaultMessage='Ei tuloksia'
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                )}

                <div className='AddRightsModal-footer m-t'>
                  <div className='AddRightsModal-footer-choice'>
                    {!this.state.packageChoice ? null : (
                      <span>
                        Valittu urakka:{' '}
                        <strong>{this.state.packageChoice.name}</strong>
                      </span>
                    )}
                  </div>

                  <div className='AddRightsModal-footer-actions'>
                    <button
                      className='btn btn-success m-r'
                      data-dismiss='modal'
                      onClick={this._addRights}
                      disabled={!this.state.packageChoice}
                    >
                      <FormattedMessage
                        id='add-rights-modal-add'
                        description='Button text for adding rights'
                        defaultMessage='Lisää'
                      />
                    </button>

                    <button
                      className='btn btn-warning'
                      data-dismiss='modal'
                      onClick={this._clearSearch}
                    >
                      <FormattedMessage
                        id='cancel'
                        description='Generic cancel'
                        defaultMessage='Peruuta'
                      />
                    </button>
                  </div>

                  <div className='clearfix'></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <table className='table table-striped'>
          <thead>
            <tr>
              <th>
                <FormattedMessage
                  id='rights-table-header-username'
                  description='Username header text for the rights table'
                  defaultMessage='Käyttäjätunnus'
                />
              </th>

              <th>
                <FormattedMessage
                  id='rights-table-header-admin'
                  description='Admin header text for the rights table'
                  defaultMessage='RT pääkäyttäjä'
                />
              </th>

              <th>
                <FormattedMessage
                  id='rights-table-header-all-rights'
                  description='All rights header text for the rights table'
                  defaultMessage='THP pääkäyttäjä'
                />
              </th>

              <th>
                <FormattedMessage
                  id='rights-table-header-rt-administrator'
                  description='RT-administrator header text for the
                  rights table'
                  defaultMessage='THP hallinnoija'
                />
              </th>

              <th>
                <FormattedMessage
                  id='rights-table-header-specific-rights'
                  description='Specific rights header text for the rights table'
                  defaultMessage='Urakkakohtaiset oikeudet'
                />
              </th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.rights.map((user) => {
              const expandButtonClasses = classNames({
                'hidden-xs-up': this.state.collapsed[user.username],
              });

              const expandBlockClasses = classNames({
                'hidden-xs-up': !this.state.collapsed[user.username],
              });

              const adminIconClasses = classNames('fa', {
                'fa-check': user.admin === true,
                'fa-times': user.admin === false,
              });

              const allAccessIconClasses = classNames('fa', {
                'fa-check': user.admin === true || user.role === 0,
                'fa-times': user.admin === false && user.role !== 0,
              });

              const UTTLimitedChecked =
                user.admin === true || user.role === 1 || user.role === 0;

              const UTTLimitedIconClasses = classNames('fa', {
                'fa-check': UTTLimitedChecked,
                'fa-times': !UTTLimitedChecked,
              });

              const topLevelRights = user.rights.filter(
                (r1) =>
                  r1.ancestor?.id === r1.id ||
                  !user.rights.some((r2) => r1.ancestor?.id === r2.id)
              );

              return (
                <tr key={user.username}>
                  <td>{user.username}</td>
                  <td className='text-center'>
                    <a
                      href={
                        'https://www.rakennustieto.fi' +
                        '/kortistot/index/valitse/admin.html.stx'
                      }
                    >
                      <i className={adminIconClasses}></i>
                    </a>
                  </td>

                  <td className='text-center'>
                    <a
                      href='#'
                      onClick={this._toggleAllRights.bind(this, user)}
                    >
                      <i className={allAccessIconClasses}></i>
                    </a>
                  </td>

                  <td className='text-center'>
                    <a
                      href='#'
                      onClick={this._toggleUTTLimitedRights.bind(this, user)}
                    >
                      <i className={UTTLimitedIconClasses}></i>
                    </a>
                  </td>

                  <td>
                    {topLevelRights.slice(0, 3).map((pkg) => {
                      return this._packageRow(user.username, pkg);
                    })}

                    {topLevelRights.length <= 3 ? null : (
                      <div>
                        <a
                          href='#'
                          className={expandButtonClasses}
                          onClick={this._expandPackages.bind(
                            this,
                            user.username
                          )}
                        >
                          ...
                        </a>

                        <div className={expandBlockClasses}>
                          {topLevelRights.slice(3).map((pkg) => {
                            return this._packageRow(user.username, pkg);
                          })}
                        </div>
                      </div>
                    )}
                  </td>

                  <td>
                    <a
                      className='m-l'
                      href='#'
                      data-toggle='modal'
                      data-target='#AddRightsModal'
                      onClick={this._openModal.bind(this, user.username)}
                    >
                      <i className='fa fa-plus'></i>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default injectIntl(CompanyRightsTable);
