import PropTypes from 'prop-types';
import React from 'react';

class NewFolderRow extends React.Component {
  static propTypes = {
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  state = {
    name: '',
  };

  render() {
    const { name } = this.state;
    const { onAdd, onCancel } = this.props;

    return (
      <li className='CompanyFolderTree-item'>
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            onAdd(name);
          }}
        >
          <i className='fa fa-caret-down invisible' />
          <i className='fa fa-lg fa-folder m-r' />
          <input
            type='text'
            value={name}
            onChange={(evt) => this.setState({ name: evt.target.value })}
            onBlur={onCancel}
            autoFocus
          />
        </form>
      </li>
    );
  }
}

export default NewFolderRow;
