/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import Messages from '../Messages/Messages';
import classNames from 'classnames';

import UnauthenticatedView from '../UnauthenticatedView/UnauthenticatedView';

import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class LandingView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    rtActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    inviteToken: PropTypes.string,
  };

  authenticate = (event) => {
    event.preventDefault();
    this.props.messageActionCreators.clearMessages();
    this.props.rtActionCreators.authenticate(this.props.inviteToken);
  };

  render() {
    return (
      <div className='LandingView'>
        <div className='container-fluid'>
          <div id='Header' className='row'>
            <nav
              className={classNames(
                'Header-top-bar',
                'col-xs-12',
                'navbar',
                'navbar-inverse',
                'navbar-static-top'
              )}
            >
              <a className='navbar-brand p-y-0' href='/'>
                <img style={{ height: 40 }} src='/images/rt-brand-full.jpg' />
              </a>
            </nav>
          </div>

          <div className='LandingView-content row rt-bg'>
            <div className='container'>
              <Messages
                messageStore={this.props.messageStore}
                messageActionCreators={this.props.messageActionCreators}
              />

              <div>
                <div className='card'>
                  <div className='card-block'>
                    <div className='container-fluid'>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <h4 className='card-title'>
                            <FormattedMessage
                              id='login-header'
                              description='Header shown in login form'
                              defaultMessage='Kirjaudu sisään'
                            />
                          </h4>

                          {this.props.inviteToken ? (
                            <p className='text-muted'>
                              <FormattedMessage
                                id='login-by-token-message'
                                description='Text when logging in with invite'
                                defaultMessage={
                                  'Olet saamassa oikeudet urakkaan tai ' +
                                  'vahvistamassa siirtoa. Kirjaudu sisään ' +
                                  'viimeistelläksesi prosessin.'
                                }
                              />
                            </p>
                          ) : null}

                          <button
                            type='submit'
                            className='d-block btn btn-primary-outline'
                            onClick={this.authenticate}
                          >
                            <FormattedMessage
                              id='login-button'
                              description='Login button text'
                              defaultMessage='Kirjaudu sisään'
                            />
                          </button>
                        </div>

                        <div className='col-sm-6'>
                          <h4 className='card-title text-right'>
                            <FormattedMessage
                              id='register-header'
                              description={
                                'Header for registration shown in login form'
                              }
                              defaultMessage='Ei tunnuksia?'
                            />
                          </h4>
                          <FormattedMessage
                            id='landing-registration'
                            description='registration link in landing view'
                            defaultMessage='Rekisteröidy'
                          >
                            {(message) => (
                              /* eslint-disable max-len */
                              <a
                                className='pull-right'
                                href='https://www.rakennustieto.fi/kirjautuminen/rekisteroidy.html.stx'
                              >
                                {message}
                              </a>
                            )}
                          </FormattedMessage>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div>
                  <p className='text-white'>
                    <FormattedHTMLMessage
                      id='landing-description'
                      description='Description shown in landing view'
                      defaultMessage={
                        'Tuotetiedon hallintapalvelu on rakennusurakoitsijoille tarkoitettu palvelu rakennus- ja infrahankkeiden tuotetiedon kokonaisvaltaiseen hallintaan. Palvelulla hoidat mm. työmaiden kemikaaliluettelot, tuotekelpoisuuden toteamisen, tuotedokumentoinnin ja luovutusaineistot.<br />' +
                        'Tuotetiedot haetaan kansallisesta Tuotetiedon tietokannasta. '
                      }
                    />

                    <FormattedMessage
                      id='landing-more-information'
                      description='More information link shown in landing view'
                      defaultMessage='Lue lisää'
                    >
                      {(message) => (
                        <a href='https://www.rakennustieto.fi/palvelut/tuotetieto'>
                          {message}
                        </a>
                      )}
                    </FormattedMessage>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UnauthenticatedView(injectIntl(LandingView));
