import classNames from 'classnames';

interface Props {
  currentPackage?: any;
  packages?: any;
  path: string;
}

const Breadcrumbs = ({ currentPackage, packages, path }: Props) => {
  if (!currentPackage || !packages) {
    return (
      <div className='Breadcrumbs'>
        <div className='loading loading-green' />
      </div>
    );
  }

  let node = packages[currentPackage.id];
  const breadcrumbs = [node];

  // Add ancestors to breadcrumbs by following parents
  while (node.parent) {
    if (!packages[node.parent]) {
      break;
    }
    node = packages[node.parent];
    breadcrumbs.push(node);
  }

  // Check that ancestor is in the path, add if not.
  if (breadcrumbs[breadcrumbs.length - 1].id !== node.ancestor) {
    // indicator for skipped packages
    breadcrumbs.push({ name: '...', id: null });
    breadcrumbs.push(packages[node.ancestor]);
  }

  breadcrumbs.reverse();

  return (
    <div className='Breadcrumbs'>
      <ol className='breadcrumb'>
        <li key='all'>
          <a href='/'>Kaikki urakat</a>
        </li>
        {breadcrumbs.map((crumb) => {
          const isActive = crumb.id === currentPackage.id;

          const classes = classNames({
            active: isActive,
          });

          return (
            <li key={crumb.id} className={classes}>
              {isActive || !crumb.has_access ? (
                crumb.name
              ) : (
                <a href={path.replace('{id}', crumb.id)}>{crumb.name}</a>
              )}
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default Breadcrumbs;
