/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import ConfirmActionModal from '../ConfirmActionModal/ConfirmActionModal.tsx';
import { FormattedMessage } from 'react-intl';

class PackageRightsList extends React.Component {
  static propTypes = {
    package: PropTypes.object,
    rights: PropTypes.array,
    invites: PropTypes.array,
    user: PropTypes.object,
    packageActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
  };

  state = {
    username: null,
    pkg: null,
  };

  deleteRights = (username, packageID) => {
    this.props.messageActionCreators.clearMessages();
    this.props.packageActionCreators.deleteRights(packageID, username);
  };

  deleteInvite = (invite, event) => {
    event.preventDefault();

    this.props.packageActionCreators.deleteInvite(invite.package, invite.id);
  };

  approvalRightsAccess = (access, event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    if (access.can_approve) {
      this.props.packageActionCreators.removeApprovalRight(
        access.package.id,
        access.username
      );
    } else {
      this.props.packageActionCreators.giveApprovalRight(
        access.package.id,
        access.username
      );
    }
  };

  approvalRightsInvite = (invite, event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    if (invite.can_approve) {
      this.props.packageActionCreators.removeApprovalRight(
        invite.package,
        invite.email
      );
    } else {
      this.props.packageActionCreators.giveInviteApprovalRight(
        invite.package,
        invite.email
      );
    }
  };

  render() {
    if (!this.props.package || !this.props.user || !this.props.rights) {
      return (
        <div className='PackageRightsList'>
          <ul>
            <div className='col-xs-12'>
              <div>
                <FormattedMessage
                  id='rights-package-header'
                  description='Header text for package rights'
                  defaultMessage='Tämän urakan erillisoikeudet'
                />
              </div>

              <div className='list-group m-t m-b'>
                <div className='loading loading-green'>
                  <div></div>
                </div>
              </div>

              <div>
                <FormattedMessage
                  id='rights-admin-header'
                  description='Header text for rights admins'
                  defaultMessage='Pääkäyttäjät'
                />
              </div>

              <div className='PackageRightsList-no-access list-group m-t m-b'>
                <div className='loading loading-green'>
                  <div></div>
                </div>
              </div>

              <div>
                <FormattedMessage
                  id='rights-other-rights-header'
                  description='Header text for other rights'
                  defaultMessage='Muut erillisoikeudet'
                />
              </div>

              <div className='PackageRightsList-no-access list-group m-t m-b'>
                <div className='loading loading-green'>
                  <div></div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      );
    }

    const packages = {};
    this.props.rights.forEach((access) => {
      const id = access.package ? access.package.id : null;

      if (!packages[id]) packages[id] = [];
      packages[id].push(access);
    });

    const separateRights = Object.keys(packages)
      .map((idString) => {
        const id = parseInt(idString, 10);
        if (isNaN(id) || id === this.props.package.id) return null;

        return packages[id].map((access) => {
          return (
            <div className='list-group-item' key={access.username}>
              <span>{access.username}</span>

              <div className='list-group-item---identifier'>
                {access.organization}
              </div>

              <i className='PackageRightsList-lock fa fa-lock'></i>
            </div>
          );
        });
      })
      .filter((element) => !!element);

    const modalInfo =
      this.state.username && this.state.pkg ? (
        <FormattedMessage
          id='info-message'
          description='Info message'
          defaultMessage={`Olet poistamassa käyttäjän {user} urakasta {package}.`}
          values={{
            user: <b>{this.state.username}</b>,
            package: <b>{this.state.pkg.name}</b>,
          }}
        />
      ) : null;

    return (
      <div className='PackageRightsList'>
        <ConfirmActionModal
          id={'RightsRemoveUserFromPackageModal'}
          title={'Poista käyttäjä urakasta'}
          info={modalInfo}
          onConfirm={() =>
            this.deleteRights(this.state.username, this.state.pkg.id)
          }
        />

        <ul>
          <div className='col-xs-12'>
            <div>
              <FormattedMessage
                id='rights-package-header'
                description='Header text for package rights'
                defaultMessage='Tämän urakan erillisoikeudet'
              />
            </div>

            <div className='list-group m-t m-b'>
              {packages[this.props.package.id] ? (
                packages[this.props.package.id].map((access) => {
                  return (
                    <div className='list-group-item' key={access.username}>
                      <span>{access.username}</span>

                      <div className='list-group-item---identifier'>
                        {access.organization}
                      </div>

                      <a
                        href='#'
                        className='PackageRightsList-delete'
                        onClick={(event) => {
                          event.preventDefault();
                          this.setState({
                            pkg: access.package,
                            username: access.username,
                          });
                          $('#RightsRemoveUserFromPackageModal').modal('show');
                        }}
                      >
                        <i className='fa fa-minus-square'></i>
                      </a>

                      <a
                        href='#'
                        className='PackageRightsList-right'
                        onClick={this.approvalRightsAccess.bind(this, access)}
                      >
                        {access.can_approve ? (
                          <i className='fa fa-check-square-o' />
                        ) : (
                          <i className='fa fa-square-o' />
                        )}
                        <FormattedMessage
                          id='rights-can-approve'
                          description='Label for product approval right'
                          defaultMessage='Tuotekelpoisuudet'
                        />
                      </a>
                    </div>
                  );
                })
              ) : (
                <small className='text-muted m-l'>
                  <FormattedMessage
                    id='rights-placeholder-package'
                    description='Placeholder text for package rights'
                    defaultMessage='Ei erillisoikeuksia.'
                  />
                </small>
              )}
            </div>

            <div>
              <FormattedMessage
                id='rights-invites-header'
                description='Header text for invited users'
                defaultMessage={
                  'Kutsutut, mutta vielä sisäänkirjautumista' +
                  ' odottavat käyttäjät'
                }
              />
            </div>

            <div className='list-group m-t m-b'>
              {this.props.invites.length ? (
                this.props.invites.map((invite) => {
                  const timestamp = new Date(invite.datetime);
                  let date = timestamp.getDate() + '.';
                  date += timestamp.getMonth() + 1 + '.';
                  date += timestamp.getFullYear() + ' ';
                  date +=
                    (timestamp.getHours() > 9
                      ? timestamp.getHours()
                      : '0' + timestamp.getHours()) + ':';

                  date +=
                    timestamp.getMinutes() > 9
                      ? timestamp.getMinutes()
                      : '0' + timestamp.getMinutes();

                  return (
                    <div className='list-group-item' key={invite.email}>
                      <span>{invite.email}</span>

                      <div className='list-group-item---identifier'>
                        <FormattedMessage
                          id='invite-inviter'
                          description='Label text for inviter'
                          defaultMessage='Kutsuja: {inviter} ({date})'
                          values={{
                            inviter: invite.inviter,
                            date: date,
                          }}
                        />
                      </div>
                      <a
                        href='#'
                        className='PackageRightsList-delete'
                        onClick={this.deleteInvite.bind(this, invite)}
                      >
                        <i className='fa fa-minus-square'></i>
                      </a>

                      <div
                        href='#'
                        className='list-group-item---identifier
                        PackageRightsList-right'
                      >
                        {invite.can_approve === true ? (
                          <i className='fa fa-check-square-o' />
                        ) : (
                          <i className='fa fa-square-o' />
                        )}
                        <FormattedMessage
                          id='rights-can-approve'
                          description='Label for product approval right'
                          defaultMessage='Tuotekelpoisuudet'
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <small className='text-muted m-l'>
                  <FormattedMessage
                    id='invites-placeholder-package'
                    description='Placeholder text for pending invites'
                    defaultMessage='Ei kutsuttuja käyttäjiä.'
                  />
                </small>
              )}
            </div>

            <div>
              <FormattedMessage
                id='rights-admin-header'
                description='Header text for rights admins'
                defaultMessage='Pääkäyttäjät'
              />
            </div>

            <div className='PackageRightsList-no-access list-group m-t m-b'>
              {packages.null ? (
                packages.null.map((access) => {
                  return (
                    <div className='list-group-item' key={access.username}>
                      <span>{access.username}</span>

                      <div className='list-group-item---identifier'>
                        {access.organization}
                      </div>

                      <i className='PackageRightsList-lock fa fa-lock'></i>
                    </div>
                  );
                })
              ) : (
                <small className='text-muted m-l'>
                  <FormattedMessage
                    id='rights-placeholder-admins'
                    description='Placeholder text for admin rights'
                    defaultMessage='Ei pääkäyttäjiä.'
                  />
                </small>
              )}
            </div>

            <div>
              <FormattedMessage
                id='rights-other-rights-header'
                description='Header text for other rights'
                defaultMessage='Muut erillisoikeudet'
              />
            </div>

            <div className='PackageRightsList-no-access list-group m-t m-b'>
              {separateRights.length ? (
                separateRights
              ) : (
                <small className='text-muted m-l'>
                  <FormattedMessage
                    id='rights-placeholder-other'
                    description='Placeholder text for other rights'
                    defaultMessage='Ei erillisoikeuksia.'
                  />
                </small>
              )}
            </div>
          </div>
        </ul>
      </div>
    );
  }
}

export default PackageRightsList;
