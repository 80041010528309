/**
 * StatsView.js - React component base
 *
 * Copyright 2015-2016 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Messages from '../Messages/Messages';
import classNames from 'classnames';

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class StatsView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    statsStore: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    statsActionCreators: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this._getStoreData();
  }

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );

    this._statsStoreSubscription = this.props.statsStore.addListener(
      this._onStoreChange
    );
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
    this._statsStoreSubscription.remove();
  }

  _onStoreChange = () => {
    this.setState(this._getStoreData());
  };

  _getStoreData = () => {
    return {
      packageState: this.props.packageStore.getState(),
      rtState: this.props.rtStore.getState(),
      statsState: this.props.statsStore.getState(),
      errors: {},
    };
  };

  _getStats = () => {
    if (!this.state.statsState.stats) {
      return (
        <div className='loading loading-green loading-center m-t-md'>
          <div></div>
        </div>
      );
    }

    return (
      <div className='StatisticsBox'>
        <FormattedHTMLMessage
          id='packageStatistics'
          description='Package statistics'
          defaultMessage={
            'Palvelussa on tällä hetkellä aktiivisena {root}' +
            ' työmaata joissa on yhteensä {sub}' +
            ' urakkaa.<br />'
          }
          values={{
            root: this.state.statsState.stats.packages.activeRoots,
            sub: this.state.statsState.stats.packages.activeSubs,
          }}
        />
        <FormattedHTMLMessage
          id='userStatistics'
          description='User statistics'
          defaultMessage={
            'Palveluun on käyttöoikeuksia {users} ' + ' käyttäjällä.<br />'
          }
          values={{
            users: this.state.statsState.stats.users.distinct,
          }}
        />
        <FormattedHTMLMessage
          id='productStatistics'
          description='Product statistics'
          defaultMessage={
            'Palvelun kautta on merkitty yhteensä ' +
            this.state.statsState.stats.products.installed +
            ' tuotetta asennetuksi sisältäen yhteensä ' +
            this.state.statsState.stats.products.distinct +
            ' eri tuotetyyppiä.<br />'
          }
        />
      </div>
    );
  };

  _exportUsers = () => {
    this.props.statsActionCreators.exportUsers();
  };

  _exportProducts = () => {
    this.props.statsActionCreators.exportProducts();
  };

  _getContent = () => {
    const exportData = classNames(
      'btn',
      'btn-primary-outline',
      'btn-wide-xs',
      'StatsView-button'
    );

    return (
      <div className='container-fluid'>
        <Header
          user={this.state.rtState.user}
          packages={this.state.packageState.packageSearch}
          searchLoading={this.state.packageState.packageSearchLoading}
          packageActionCreators={this.props.packageActionCreators}
        />

        <Messages
          messageStore={this.props.messageStore}
          messageActionCreators={this.props.messageActionCreators}
        />

        <div className='StatsView-content row'>
          <div className='col-xs-12 col-sm-10 col-sm-offset-1'>
            <h2>
              <FormattedMessage
                id='statistics'
                description='Statistics header'
                defaultMessage='Tilastot'
              />
            </h2>
          </div>

          <div className='col-xs-12 col-sm-10 col-sm-offset-1'>
            {this._getStats()}
          </div>

          <div className='col-xs-12 col-sm-10 col-sm-offset-1'>
            <button className={exportData} onClick={this._exportUsers}>
              <FormattedMessage
                id='export-user-stats'
                description='Export user stats button'
                defaultMessage='Lataa käyttäjätiedot'
              />
            </button>

            <button className={exportData} onClick={this._exportProducts}>
              <FormattedMessage
                id='export-product-stats'
                description='Export product stats button'
                defaultMessage='Lataa tuotetiedot'
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let content = null;

    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null ||
      this.state.statsState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='StatsView'>
        {content}
        <Footer />
      </div>
    );
  }
}

export default AuthenticatedView(StatsView);
