import { useState, useEffect } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';

import { getFileTypePath } from '../../utils/fileUtil';
import BarcodeScanner from '../BarcodeScanner/BarcodeScanner';

const messages = defineMessages({
  searchPlaceholder: {
    id: 'search-placeholder',
    description: 'Placeholder when searching products',
    defaultMessage:
      'Syötä tuotteen GTIN, Talo 2000 -tuotenimike, rakennustuotenumero, ' +
      'sähkönumero, LVI-numero, nimi tai yritys',
  },
  searchButton: {
    id: 'search-button',
    description: 'Search button text',
    defaultMessage: 'Hae',
  },
});

const fileTypes = [
  'ktt',
  'epd',
  'dop',
  'm1',
  'instr',
  'tds',
  'rt',
  'w',
  'cert',
] as const;

type FileType = (typeof fileTypes)[number];

interface Props {
  searchText: string;
  onSearchTextChange(newText: string): void;
  onScanResult(ean: string): void;
  onSubmit(): void;
  includeDeleted: boolean;
  toggleIncludeDeleted(): void;
  fileFilters: FileType[];
  onFileFilterClick(filter: FileType): void;
  minimal?: boolean;
}

const ProductSearch = ({
  searchText,
  onSearchTextChange,
  onScanResult,
  onSubmit,
  includeDeleted,
  toggleIncludeDeleted,
  fileFilters,
  onFileFilterClick,
  minimal,
}: Props) => {
  const [scanning, setScanning] = useState(false);
  const [scanResult, setScanResult] = useState('');

  useEffect(() => {
    if (scanResult) {
      onScanResult(scanResult);
    }
  }, [scanResult]);

  const { formatMessage } = useIntl();

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <fieldset className='form-group'>
          <div className='PackageProductsGlobal-controls'>
            <input
              type='search'
              className='PackageProductsGlobal-input form-control search-input'
              value={searchText}
              onChange={(e) => onSearchTextChange(e.target.value)}
              placeholder={formatMessage(messages.searchPlaceholder)}
            />

            <button
              type='button'
              className='btn btn-outline-secondary scan-button'
              onClick={() => setScanning(true)}
            >
              <i className='fa fa-barcode'></i>
            </button>

            <input
              type='submit'
              className='PackageProductsGlobal-submit btn btn-success search-button'
              value={formatMessage(messages.searchButton)}
            />
          </div>
        </fieldset>
      </form>

      {scanning ? (
        <BarcodeScanner
          setResult={(res) => {
            setScanResult(res);
            setScanning(false);
          }}
        />
      ) : null}

      {minimal ? null : (
        <div className='checkbox'>
          <label>
            <input
              type='checkbox'
              checked={includeDeleted}
              onChange={toggleIncludeDeleted}
              className='m-r'
            />
            <FormattedMessage
              id='show-deleted-products'
              description='Show deleted products'
              defaultMessage='Näytä myös poistuneet tuotteet'
            />
          </label>
        </div>
      )}

      {minimal ? null : (
        <div className='imagefilters'>
          <FormattedMessage
            id='filter-by-image-type'
            description='Filter by file type'
            defaultMessage='Suodata tiedostotyypeillä:'
          />
          {fileTypes.map((t) => (
            <img
              src={getFileTypePath(t)}
              onClick={() => onFileFilterClick(t)}
              className={fileFilters.includes(t) ? 'selected' : ''}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ProductSearch;
