import CommonActionCreators from './CommonActionCreators';
import ActionTypes from './ActionTypes';
import ErrorTypes from '../../shared/ErrorTypes.mjs';

import page from 'page';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  companyNotFound: {
    id: 'company-not-found',
    description: 'Company was not found while viewing',
    defaultMessage: 'Yritystä ei löytynyt!',
  },

  companyRightsNoAccess: {
    id: 'company-rights-no-access',
    description: 'User does not have access to view company rihgts',
    defaultMessage:
      'Sinulla ei ole oikeuksia katsella yrityksesi oikeusia! ' +
      'Kokeile päivittää sivu.',
  },

  folderExists: {
    id: 'company-folders-folder-exists',
    description: 'Error message when folder exists on level',
    defaultMessage: 'Tuoteryhmä "{name}" on jo olemassa',
  },
  productExists: {
    id: 'company-folders-product-exists',
    description: 'Error message when product exists on level',
    defaultMessage: 'Jokin siirtämäsi tuote on jo tuoteryhmässä',
  },
});

class CompanyActionCreators extends CommonActionCreators {
  constructor(dispatcher, apiUtils) {
    super(dispatcher, apiUtils);
  }

  getCompany(id) {
    this._apiUtils
      .get(`/companies/${id}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_RECEIVE,
          company: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_COMPANY_NOT_FOUND:
            this._dispatchFutureMessage('danger', messages.companyNotFound);
            page.redirect('/');
            break;

          case ErrorTypes.ERROR_COMPANY_RIGHTS_NO_ACCESS:
            this._dispatchFutureMessage(
              'danger',
              messages.companyRightsNoAccess
            );
            page.redirect('/');
            break;

          default:
            this._dispatcher.dispatch({
              type: ActionTypes.COMPANY_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  getCurrentCompanyRights() {
    this._apiUtils
      .get(`/companies/me/rights`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_RIGHTS_RECEIVE,
          rights: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.COMPANY_RIGHTS_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  getCompanyApproverOptions() {
    this._apiUtils
      .get(`/companies/me/approvers`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_APPROVERS_RECEIVE,
          approvers: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.COMPANY_RIGHTS_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  getCompanyFolders() {
    this._apiUtils
      .get(`/companies/me/folders`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_FOLDERS_RECEIVE,
          folders: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.COMPANY_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  addFolder(name, parent = null) {
    this._apiUtils
      .post('/companies/me/folders', { name, parent })
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_FOLDERS_RECEIVE,
          folders: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_COMPANY_FOLDER_EXISTS_ON_LEVEL:
            this._dispatchMessage('danger', messages.folderExists, { name });
            break;
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.COMPANY_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  patchFolder(folderId, data) {
    this._apiUtils
      .patch(`/companies/me/folders/${folderId}`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_FOLDERS_RECEIVE,
          folders: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.COMPANY_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  deleteFolder(folderId) {
    this._apiUtils
      .delete(`/companies/me/folders/${folderId}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_FOLDERS_RECEIVE,
          folders: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_COMPANY_FOLDER_EXISTS_ON_LEVEL:
            this._dispatchMessage('danger', messages.folderExists, { name });
            break;
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.COMPANY_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  addProductToFolder(folderId, data) {
    this._apiUtils
      .post(`/companies/me/folders/${folderId}/products`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_FOLDERS_RECEIVE,
          folders: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.COMPANY_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  moveFolderLinks(data) {
    data.action = 'MOVE';
    this._apiUtils
      .patch(`/companies/me/folders/products`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_FOLDERS_RECEIVE,
          folders: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PRODUCT_ALREADY_EXISTS:
            this._dispatchMessage('danger', messages.productExists);
            break;
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.COMPANY_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  removeFolderLinks(data) {
    data.action = 'DELETE';
    this._apiUtils
      .patch('/companies/me/folders/products', data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_FOLDERS_RECEIVE,
          folders: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.COMPANY_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  getCompanyChemicalProducts() {
    this._dispatcher.dispatch({
      type: ActionTypes.COMPANY_CHEMICALS_LOADING,
    });

    this._apiUtils
      .get(`/companies/me/chemicals`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_CHEMICALS_RECEIVE,
          products: response.body.products,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.COMPANY_CHEMICALS_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }
}

export default CompanyActionCreators;
