import { Store } from 'flux/utils';
import ActionTypes from '../actions/ActionTypes';

class RTStore extends Store {
  constructor(dispatcher) {
    super(dispatcher);
    this._state = this.getInitialState();
  }

  getInitialState() {
    return {
      user: null,
      error: null,
    };
  }

  getState() {
    return this._state;
  }

  clearState() {
    this._state = this.getInitialState();
  }

  __onDispatch(payload) {
    switch (payload.type) {
    case ActionTypes.CLEAR_STORES:
      this.clearState();
      this.__emitChange();
      break;
    case ActionTypes.RT_RECEIVE_CURRENT_USER:
      this._state.user = payload.user;
      this.__emitChange();
      break;
    case ActionTypes.RT_RECEIVE_CURRENT_USER_ERROR:
      this._state.error = payload.error;
      this.__emitChange();
      break;
    case ActionTypes.RT_USER_LOGGED_OUT:
      this._state.user = null;
      this.__emitChange();
      break;
    default:
      // Ignoring other ActionTypes
    }
  }
}

export default RTStore;
