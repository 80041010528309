import classNames from 'classnames';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  packageApproveTooltip: {
    id: 'package-approve-tooltip',
    description: 'Tooltip for approve packages',
    defaultMessage:
      'Valitsemalla voit merkitä kaikki tämän ' +
      'urakan tuotteet tuotekelpoisiksi tai poistaa niiden ' +
      'tuotekelpoisuuden kerralla.',
  },

  packageInstallTooltip: {
    id: 'package-install-tooltip',
    description: 'Tooltip for install packages',
    defaultMessage:
      'Valitsemalla voit merkitä kaikki ' +
      'tämän urakan tuotteet asennetuiksi.',
  },

  packageUninstallTooltip: {
    id: 'package-uninstall-tooltip',
    description: 'Tooltip for install packages',
    defaultMessage:
      'Valitsemalla voit perua kaikkien ' +
      'tämän urakan tuotteiden asennuksen.',
  },
});

interface Props {
  packages: any;
  packageProducts: any;
  data: any;
  packageActionCreators: any;
}

const MassUpdateCheckboxes = ({
  packages,
  packageProducts,
  data,
  packageActionCreators,
}: Props) => {
  const { formatMessage } = useIntl();

  const installedStyle = classNames(
    'PackageHierarchy-row-content-label',
    'label',
    'packageInstall'
  );

  const approvedStyle = classNames(
    'PackageHierarchy-row-content-label',
    'label',
    'packageApprove'
  );

  const install = () => {
    packageActionCreators.getPackage(data.id);
    packageActionCreators.getPackageProducts(data.id);
    packageActionCreators.setMassApproveModalOpen(true);
  };

  const approve = () => {
    packageActionCreators.getPackage(data.id);
    packageActionCreators.getPackageProducts(data.id);
    packageActionCreators.setMassApproveModalOpen(false);
  };

  const approved =
    packageProducts && packageProducts[data.id]
      ? !packageProducts[data.id].some(
          (product) => !product.approved && product.product_approvable
        )
      : packages[data.id].approved;

  const installed =
    packageProducts && packageProducts[data.id]
      ? !packageProducts[data.id].some((product) => product.status !== 1)
      : packages[data.id].installed;

  const showCheckbox =
    packages[data.id] &&
    packages[data.id].level !== 1 &&
    packages[data.id].approved !== null &&
    Number(packages[data.id].total_approvable_count) !== 0

  const showInstalledCheckbox =
    packages[data.id] &&
    packages[data.id].level !== 1 &&
    packages[data.id].approved !== null;

  return (
    <div>
      <div className={approvedStyle}>
        {showCheckbox &&
          (approved ? (
            <a
              onClick={approve}
              data-toggle='modal'
              data-target='#MassApproveModal'
              title={formatMessage(messages.packageApproveTooltip)}
            >
              <i className='fa fa-check-square-o fa-2x' />
            </a>
          ) : (
            <a
              onClick={approve}
              data-toggle='modal'
              data-target='#MassApproveModal'
              title={formatMessage(messages.packageApproveTooltip)}
            >
              <i className='fa fa-square-o fa-2x' />
            </a>
          ))}
      </div>

      <div className={installedStyle}>
        {showInstalledCheckbox &&
          (installed ? (
            <a
              onClick={install}
              data-toggle='modal'
              data-target='#MassApproveModal'
              title={formatMessage(messages.packageUninstallTooltip)}
            >
              <i className='fa fa-check-square-o fa-2x' />
            </a>
          ) : (
            <a
              onClick={install}
              data-toggle='modal'
              data-target='#MassApproveModal'
              title={formatMessage(messages.packageInstallTooltip)}
            >
              <i className='fa fa-square-o fa-2x' />
            </a>
          ))}
      </div>
    </div>
  );
};

export default MassUpdateCheckboxes;
