import { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

function formatTimestamp(datestring) {
  const timestamp = new Date(datestring);

  let formatted = timestamp.getDate() + '.';
  formatted += timestamp.getMonth() + 1 + '.';
  formatted += timestamp.getFullYear() + ' ';
  formatted +=
    (timestamp.getHours() > 9
      ? timestamp.getHours()
      : '0' + timestamp.getHours()) + ':';
  formatted +=
    timestamp.getMinutes() > 9
      ? timestamp.getMinutes()
      : '0' + timestamp.getMinutes();

  return formatted;
}

export default function ProductComments({
  disabled,
  product,
  productActionCreators,
  messageActionCreators,
}) {
  const [comment, setComment] = useState('');

  const scrollRef = useRef();

  if (!product.id) {
    return null;
  }

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scroll({
        top: 0,
      });
    }
  }, [product.comments]);

  const handleChange = (event) => {
    setComment(event.currentTarget.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    messageActionCreators.clearMessages();
    productActionCreators.commentProduct(product.id, comment);
    setComment('');
  };

  const legacyComment = product.comment_username
    ? {
        id: 0,
        comment: product.comment,
        username: product.comment_username,
        created_at: product.comment_timestamp,
      }
    : null;

  const comments = legacyComment
    ? [...product.comments, legacyComment]
    : product.comments ?? [];

  return (
    <div>
      <div className='ProductInformationPanel-content-comment'>
        <div ref={scrollRef} className='comments-card'>
          {comments.map((c) => (
            <div key={c.id} className='comment'>
              <div>{c.comment}</div>
              <div className='comment-meta'>
                {c.username} ({formatTimestamp(c.created_at)})
              </div>
            </div>
          ))}
        </div>

        <textarea
          disabled={disabled}
          maxLength='500'
          value={comment}
          onChange={handleChange}
        />

        <button
          disabled={comment === product.comment || !comment}
          className={
            'ProductInformationPanel-content-comment btn btn-success m-t'
          }
          onClick={handleSubmit}
        >
          <FormattedMessage
            id='comment-product-submit'
            description='Submit comment for product'
            defaultMessage='Lisää kommentti'
          />
        </button>

        <div className='ProductInformationPanel-content-comment' />
      </div>
    </div>
  );
}
