import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { DragSource, DropTarget } from 'react-dnd';

const packageSource = {
  beginDrag(props) {
    return props.package;
  },
  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }

    const drop = monitor.getDropResult();
    props.onDrop(drop);
  },
  canDrag(props) {
    return !!props.package.parent;
  },
};

const packageTarget = {
  drop(props, monitor) {
    if (monitor.isOver() === monitor.isOver({ shallow: true })) {
      return props.package;
    }
  },
  canDrop(props, monitor) {
    if (monitor.isOver() !== monitor.isOver({ shallow: true })) return false;

    const item = monitor.getItem();
    const itemType = monitor.getItemType();

    const dropValid = props.dropValidator
      ? props.dropValidator(itemType, item, props.package)
      : true;

    return dropValid;
  },
};

class PackageDragTarget extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    package: PropTypes.object.isRequired,
    onDrop: PropTypes.func.isRequired,
    dropValidator: PropTypes.func,
    isOver: PropTypes.bool,
    canDrag: PropTypes.bool,
    connectDropTarget: PropTypes.func,
    connectDragSource: PropTypes.func,
    onOver: PropTypes.func,
  };

  overTimer = null;

  render() {
    const {
      children,
      isOver,
      onOver,
      canDrop,
      connectDropTarget,
      connectDragSource,
    } = this.props;

    if (isOver && onOver) {
      this.overTimer = setTimeout(() => {
        this.overTimer = null;
        onOver();
      }, 500);
    } else if (this.overTimer) {
      clearTimeout(this.overTimer);
      this.overTimer = null;
    }

    const comp = (
      <div
        className={classNames({
          'PackageHierarchy-package-target': isOver,
          success: isOver && canDrop,
          warning: isOver && !canDrop,
        })}
      >
        {children}
      </div>
    );

    return connectDropTarget && connectDragSource
      ? connectDropTarget(connectDragSource(comp))
      : null;
  }
}

export default DropTarget(
  ['package', 'product'],
  packageTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
    dragSource: monitor.getItem(),
    dragSourceType: monitor.getItemType(),
    canDrop: monitor.canDrop(),
  }
))(DragSource('package', packageSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(injectIntl(PackageDragTarget)));
