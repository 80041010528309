import { languageOptions } from '../../../shared/Languages.mjs';

export default function LanguageSelect({
  lang,
  onChange,
}: {
  lang: string;
  onChange: (langCode: string) => void;
}) {
  const langLabel =
    languageOptions.find((l) => l.value === lang)?.label ?? lang;

  return (
    <div className='dropdown m-l'>
      <button
        className='btn btn-secondary dropdown-toggle lang-size'
        type='button'
        id='dropdownMenuButton'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <img src={`/images/flags/${lang}.svg`} alt={lang} className='flag' />
        <span className='lang-margins'>{langLabel}</span>
      </button>
      <div
        className='dropdown-menu menu-scroll lang-size lang-menu'
        aria-labelledby='dropdownMenuButton'
      >
        {languageOptions.map((opt) => (
          <button
            key={opt.value}
            className='dropdown-item'
            onClick={() => onChange(opt.value)}
          >
            <img
              src={`/images/flags/${opt.value}.svg`}
              alt={opt.label}
              className='flag'
            />
            <span className='m-l'>{opt.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
}
