import { Store } from 'flux/utils';
import ActionTypes from '../actions/ActionTypes';

class BulletinStore extends Store {
  constructor(dispatcher) {
    super(dispatcher);
    this._state = this.getInitialState();
  }

  getInitialState() {
    return {
      bulletins: null,
      error: null,
    };
  }

  getState() {
    return this._state;
  }

  clearState() {
    this._state = this.getInitialState();
  }

  __onDispatch(payload) {
    switch (payload.type) {
    case ActionTypes.CLEAR_STORES:
      this.clearState();
      this.__emitChange();
      break;
    case ActionTypes.BULLETINS_RECEIVE:
      this._state.bulletins = payload.bulletins;
      this.__emitChange();
      break;
    default:
      // Ignoring other ActionTypes
    }
  }
}

export default BulletinStore;
