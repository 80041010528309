import { FormattedMessage } from 'react-intl';
import { parseISO, format } from 'date-fns';

import { getProductData } from '../../utils/productUtils';

interface Props {
  product: {
    status: number;
    approved: boolean;
    approved_username: string;
  };
  log?: {
    last_modified: string;
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    organization: string;
  };
}

const ProductDetails = ({ product, log }: Props) => {
  const productData = getProductData(product);

  const lastModified = log?.last_modified
    ? format(parseISO(log.last_modified), 'doMoyyyy HH:mm')
    : null;

  const additionalInfo =
    product.additional_info ?? product.data?.additional_info;

  return (
    <dl className='dl-horizontal row'>
      <dt className='col-xs-12 col-sm-5'>
        <FormattedMessage
          id='product-manufacturer'
          description='Label text for product manufacturer'
          defaultMessage='Yritys'
        />
        :
      </dt>
      <dd className='col-xs-12 col-sm-7'>{productData.manufacturer}</dd>

      <dt className='col-xs-12 col-sm-5'>
        <FormattedMessage
          id='product-ean'
          description='Label text for product EAN code'
          defaultMessage='GTIN-koodi'
        />
        :
      </dt>
      <dd className='col-xs-12 col-sm-7'>
        {productData.ean || (
          <FormattedMessage
            id='unknown'
            description='Generic unknown'
            defaultMessage='Ei tiedossa'
          />
        )}
      </dd>

      {productData.validity_methods &&
        productData.validity_methods.length !== 0 && (
          <>
            <dt className='col-xs-12 col-sm-5' key='validity-methods'>
              <FormattedMessage
                id='product-validity-methods'
                description='Label text for product validity methods'
                defaultMessage='Tuotteen kelpoisuuden todentamisen menetelmät'
              />
              :
            </dt>
            <dd
              key='validity-methods-value'
              className='ProductInformationPanel-content-validity col-xs-12 col-sm-7'
            >
              <ul>
                {productData.validity_methods.map((method) => (
                  <li>{method}</li>
                ))}
              </ul>
            </dd>
          </>
        )}
      {productData.url ? (
        <>
          <dt key='information-modal' />
          <dd className='col-xs-12 col-sm-12' key='information-modal-value'>
            <a href={productData.url} target='_blank'>
              <i className='fa fa-external-link' />
              &nbsp;
              <FormattedMessage
                id='product-more-information'
                description='More information link for products'
                defaultMessage='Tarkemmat tuotetiedot'
              />
            </a>
          </dd>
        </>
      ) : null}

      {additionalInfo
        ? additionalInfo.map((link) => (
            <>
              <dt key={`${link.id}-title`} />
              <dd className='col-xs-12 col-sm-12' key={`${link.id}-desc`}>
                <a href={link.url} target='_blank'>
                  <i className='fa fa-external-link' />
                  &nbsp;
                  <FormattedMessage
                    id='product-more-information'
                    description='More information link for products'
                    defaultMessage={link.description}
                  />
                </a>
              </dd>
            </>
          ))
        : null}

      {log && log.last_modified && product.status === 1 ? (
        <>
          <dt className='col-xs-5' key='installed-timestamp'>
            <FormattedMessage
              id='product-completed'
              description='When the product was installed'
              defaultMessage='Merkitty asennetuksi'
            />
            :
          </dt>
          <dd className='col-xs-7' key='installed-timestamp-value'>
            {lastModified}
          </dd>
          <dt className='col-xs-5' key='installed-by'>
            <FormattedMessage
              id='product-completed-by'
              description='Who installed the product'
              defaultMessage='Merkinnyt asennetuksi'
            />
            :
          </dt>
          <dd className='col-xs-7' key='installed-by-value'>
            {log.first_name && log.last_name ? (
              <div>
                {log.first_name.charAt(0)}. {log.last_name}
              </div>
            ) : (
              <div>{log.username}</div>
            )}

            <div>{log.email}</div>
            <div>{log.organization}</div>
          </dd>
        </>
      ) : null}

      {product.approved ? (
        <>
          <dt className='col-xs-5' key='approved-by'>
            <FormattedMessage
              id='product-approved-by'
              description='Who approved the product'
              defaultMessage='Merkinnyt tuotekelpoiseksi'
            />
            :
          </dt>
          <dd className='col-xs-7' key='approved-by-value'>
            <div>{product.approved_username}</div>
          </dd>
        </>
      ) : null}
    </dl>
  );
};

export default ProductDetails;
