/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import createReactClass from 'create-react-class';
import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Messages from '../Messages/Messages';

import page from 'page';
import classNames from 'classnames';

import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

import {
  getMetaMessages,
  getMetaEmptyFields,
} from '../../../shared/MetaFields.mjs';

const messages = defineMessages({
  emptyName: {
    id: 'package-name-empty',
    description: 'Error when package name is empty',
    defaultMessage: 'Urakan nimi ei voi olla tyhjä!',
  },

  success: {
    id: 'package-edited',
    description: 'Successfully editing a package',
    defaultMessage: 'Urakkaa muokattiin onnistuneesti.',
  },

  edit: {
    id: 'edit',
    description: 'Generic edit',
    defaultMessage: 'Muokkaa',
  },
});

const PackageEditView = createReactClass({
  displayName: 'PackageEditView',

  propTypes: {
    rtStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    rtActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  },

  getInitialState() {
    return { ...this._getStoreData(), loading: true };
  },

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );
  },

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
  },

  _initializeFields(pkg) {
    if (!pkg) {
      return {};
    }

    const fields = {
      name: pkg.name,
      address: pkg.address,
      meta: getMetaEmptyFields(!pkg.parent),
    };

    return fields;
  },

  _onStoreChange() {
    this.setState({ ...this.state, ...this._getStoreData() });
    if (this.state.loading && this.state.packageState.package) {
      this.setState({
        ...this.state,
        loading: false,
        fields: {
          name: this.state.packageState.package.name,
          address: this.state.packageState.package.address,
          meta: { ...this.state.packageState.package.meta },
        },
      });
    }
  },

  _getStoreData() {
    return {
      packageState: this.props.packageStore.getState(),
      rtState: this.props.rtStore.getState(),
      errors: {},
    };
  },

  _onFieldChange(field, event) {
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: event.target.value,
      },
    });
  },

  _onMetaFieldChange(field, event) {
    this.setState({
      fields: {
        ...this.state.fields,
        meta: {
          ...this.state.fields.meta,
          [field]: event.target.value,
        },
      },
    });
  },

  editPackage(event) {
    event.preventDefault();

    const data = {
      ...this.state.fields,
      id: this.state.packageState.package.id,
    };

    const errors = this.getInitialState().errors;

    if (data.name.length <= 0) {
      errors.error = true;
      errors.editPackageName = this.props.intl.formatMessage(
        messages.emptyName
      );
    }

    this.setState({
      errors: errors,
    });

    if (errors.error) return;

    const packageState = this.state.packageState;
    this.props.packageActionCreators.editPackage(data);

    this.props.messageActionCreators.clearMessages();

    page.redirect(`/package/${packageState.package.id}`);
  },

  _getInnerContent() {
    const packageState = this.state.packageState;
    if (!packageState.package) {
      return (
        <div className='loading loading-green loading-center m-t-lg'>
          <div></div>
        </div>
      );
    }

    // Wait for package info to arrive before initializing fields
    const fields = this._initializeFields(this.state.packageState.package);

    const editPackageNameGroupClasses = classNames('form-group', {
      'has-error': this.state.errors.editPackageName,
    });

    const editPackageNameFieldClasses = classNames('form-control', {
      'form-control-error': this.state.errors.editPackageName,
    });

    return (
      <div className='col-xs-12 col-sm-10'>
        <h2>
          <FormattedMessage
            id='edit-package-header'
            description='Header for the edit package page'
            defaultMessage='Urakan "{name}" muokkaus'
            values={{ name: packageState.package.name }}
          />
        </h2>

        <form onSubmit={this.editPackage}>
          <fieldset className={editPackageNameGroupClasses}>
            <label>
              <FormattedMessage
                id='name'
                description='Generic name'
                defaultMessage='Nimi'
              />
            </label>

            <input
              type='text'
              className={editPackageNameFieldClasses}
              onChange={this._onFieldChange.bind(this, 'name')}
              defaultValue={packageState.package.name}
            />

            <small className='text-danger'>
              {this.state.errors.editPackageName}
            </small>
          </fieldset>
          <fieldset className='form-group'>
            <label>
              <FormattedMessage
                id='address'
                description='Generic address'
                defaultMessage='Osoite'
              />
            </label>

            <input
              type='text'
              className='form-control'
              onChange={this._onFieldChange.bind(this, 'address')}
              defaultValue={packageState.package.address}
            />
          </fieldset>

          {Object.keys(fields.meta).map((field) => (
            <fieldset className='form-group' key={field}>
              <label>
                <FormattedMessage
                  id={field}
                  description='Generic field'
                  defaultMessage={this.props.intl.formatMessage(
                    getMetaMessages()[field]
                  )}
                />
              </label>

              <input
                type='text'
                className='form-control'
                onChange={this._onMetaFieldChange.bind(this, field)}
                defaultValue={
                  packageState.package.meta
                    ? packageState.package.meta[field]
                    : ''
                }
              />
            </fieldset>
          ))}

          <fieldset className='form-group pull-right'>
            <input
              type='submit'
              className='btn btn-success'
              value={this.props.intl.formatMessage(messages.edit)}
            />

            <a
              href={`/package/${packageState.package.id}`}
              className='btn btn-warning m-l'
            >
              <FormattedMessage
                id='go-back'
                description='Link for closing and returning from a form'
                defaultMessage='Takaisin'
              />
            </a>
          </fieldset>
        </form>
      </div>
    );
  },

  _getContent() {
    const rtState = this.state.rtState;
    const packageState = this.state.packageState;

    return (
      <div className='container-fluid'>
        <Header
          user={rtState.user}
          packages={packageState.packageSearch}
          searchLoading={packageState.packageSearchLoading}
          packageActionCreators={this.props.packageActionCreators}
        />

        <Messages
          messageStore={this.props.messageStore}
          messageActionCreators={this.props.messageActionCreators}
        />

        <div className='PackageEditView-content row'>
          {this._getInnerContent()}
        </div>
      </div>
    );
  },

  render() {
    let content = null;

    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='PackageEditView'>
        {content}
        <Footer />
      </div>
    );
  },
});

export default AuthenticatedView(injectIntl(PackageEditView));
