/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';

import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  emailEmpty: {
    id: 'email-empty',
    description: 'Error when email is empty',
    defaultMessage: 'Sähköpostiosoite ei voi olla tyhjä!',
  },
});

const PackageRightsList = createReactClass({
  displayName: 'PackageRightsList',

  propTypes: {
    package: PropTypes.object,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  },

  getInitialState() {
    return {
      emailValue: '',
      emailError: null,
      error: false,
      canApprove: false,
    };
  },

  inviteUser(event) {
    event.preventDefault();
    if (!this.state.emailValue) {
      this.setState({
        error: true,
        emailError: this.props.intl.formatMessage(messages.emailEmpty),
      });

      return;
    }

    this.props.messageActionCreators.clearMessages();

    this.props.packageActionCreators.inviteUser(
      this.props.package.id,
      this.state.emailValue,
      this.state.canApprove
    );

    this.setState({
      emailValue: '',
      emailError: '',
      error: false,
      canApprove: false,
    });
  },

  render() {
    const emailGroupClasses = classNames({
      'has-error': !!this.state.emailError,
    });

    const emailFieldClasses = classNames(
      'form-control',
      'PackageRightsView-input',
      {
        'form-control-error': !!this.state.emailError,
      }
    );

    if (!this.props.package) {
      return (
        <div className='PackageRightsAdd'>
          <div className='loading loading-green loading-center m-b'>
            <div></div>
          </div>
        </div>
      );
    }

    return (
      <div className='PackageRightsAdd'>
        <form onSubmit={this.inviteUser}>
          <FormattedMessage
            id='rights-add-header'
            description='Header text for adding rights'
            defaultMessage='Kutsu käyttäjä urakkaan'
          />

          <fieldset className={emailGroupClasses}>
            <input
              type='text'
              className={emailFieldClasses}
              placeholder='Käyttäjätunnus, yleensä email esim: matti.meikalainen@oy.fi'
              value={this.state.emailValue}
              onChange={(event) => {
                this.setState({
                  emailValue: event.target.value,
                });
              }}
            />

            <small className='text-danger'>{this.state.emailError}</small>
          </fieldset>

          <div
            className='text-left'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <a href='#' className='btn btn-primary' onClick={this.inviteUser}>
              <i className='fa fa-key'></i>&nbsp;
              <FormattedMessage
                id='invite-user-button'
                description='Invite user button text'
                defaultMessage='Lisää oikeudet'
              />
            </a>

            <a
              href='#'
              className='PackageRightsList-right'
              onClick={() =>
                this.setState((prevState) => ({
                  canApprove: !prevState.canApprove,
                }))
              }
            >
              {this.state.canApprove ? (
                <i className='fa fa-check-square-o' />
              ) : (
                <i className='fa fa-square-o' />
              )}
              <FormattedMessage
                id='rights-can-approve'
                description='Label for product approval right'
                defaultMessage='Tuotekelpoisuudet'
              />
            </a>
          </div>
        </form>
      </div>
    );
  },
});

export default injectIntl(PackageRightsList);
