/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import * as PropTypes from 'prop-types';

import { Component } from 'react';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import classNames from 'classnames';
import path from 'path';

import {
  defineMessages,
  injectIntl,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';

import FileTypes from '../../../shared/FileTypes.mjs';
import { fileSorter, getFileTypePath } from '../../utils/fileUtil.js';
import {
  getProductData,
  getSafetyCodes,
  gwpOptions,
} from '../../utils/productUtils';
import { sortPackageTree } from '../../utils/packageUtils';
import { ProductType } from '../../utils/enum';
import ConfirmActionModal from '../ConfirmActionModal/ConfirmActionModal';
import EditFileModal from '../EditFileModal/EditFileModal';

const messages = defineMessages({
  emptyName: {
    id: 'product-manual-edit-no-name',
    description: 'Error when empty name while editing manual product',
    defaultMessage: 'Tuotteen nimi ei voi olla tyhjä',
  },

  emptyManufacturer: {
    id: 'product-manual-edit-no-manufacturer',
    description: 'Error when empty manufacturer while editing manual product',
    defaultMessage: 'Tuotteen valmistaja ei voi olla tyhjä',
  },

  emptyFilename: {
    id: 'file-upload-no-name',
    description: 'Error when empty name while uploading file',
    defaultMessage: 'Tiedoston nimi ei voi olla tyhjä!',
  },

  emptyFile: {
    id: 'file-upload-no-file',
    description: 'Error when no file selected',
    defaultMessage: 'Sinun on valittava tiedosto!',
  },

  emptyType: {
    id: 'file-upload-no-type',
    description: 'Error when no type selected',
    defaultMessage: 'Sinun on valittava tiedostotyyppi!',
  },

  viewOldFileTooltip: {
    id: 'view-old-file-toolip',
    description: 'Tooltip for viewing the old file',
    defaultMessage: 'Katsele vanhaa tiedostoa',
  },

  viewNewFileTooltip: {
    id: 'view-new-file-toolip',
    description: 'Tooltip for viewing the new file',
    defaultMessage: 'Katsele uutta tiedostoa',
  },

  fileTypeDOP: {
    id: 'filetype-dop',
    description: 'File type for DOP',
    defaultMessage: 'DOP - Suoritustasoilmoitus',
  },

  fileTypeKTT: {
    id: 'filetype-ktt',
    description: 'File type for KTT',
    defaultMessage: 'KTT - Käyttöturvallisuustiedote',
  },

  fileTypeINSTR: {
    id: 'filetype-instr',
    description: 'File type for INSTR',
    defaultMessage: 'Ohje',
  },

  fileTypeATT: {
    id: 'filetype-att',
    description: 'File type for ATT',
    defaultMessage: 'Muu liite',
  },

  fileTypeEPD: {
    id: 'filetype-epd',
    description: 'File type for EPD',
    defaultMessage: 'EPD - Ympäristöseloste',
  },

  fileTypeM1: {
    id: 'filetype-m1',
    description: 'File type for M1',
    defaultMessage: 'M1 -Päästöluokitus',
  },

  fileTypeCERT: {
    id: 'filetype-cert',
    description: 'File type for CERT',
    defaultMessage: 'CERT - Tuotteen sertifikaatti',
  },

  gwpUnitTooltip: {
    id: 'gwp-unit-tooltip',
    description: 'Tooltip for gwp unit',
    defaultMessage: 'Tarkista yksikön selite EPD-dokumentista.',
  },

  productTooltip: {
    id: 'product',
    description: 'Tooltip for product',
    defaultMessage:
      'Urakkaan asennettava rakennustuote,' +
      ' joka ei ole kemikaali. Esimerkiksi laminaatti.',
  },

  chemicalProductTooltip: {
    id: 'chemical-product',
    description: 'Tooltip for chemical product',
    defaultMessage:
      'Urakkaan asennettava rakennustuote, joka on kemikaali.' +
      ' Esimerkiksi rakennusliima. Kemikaalille vaaditaan KTT-dokumentti.',
  },

  chemicalTooltip: {
    id: 'chemical',
    description: 'Tooltip for chemical',
    defaultMessage:
      'Urakan aikana käytössä oleva kemikaali, jota ei asenneta' +
      ' kohteeseen. Esimerkiksi polttoaine.' +
      ' Kemikaalille vaaditaan KTT-dokumentti.',
  },
});

class ManualProductPanel extends Component {
  static propTypes = {
    packages: PropTypes.object,
    product: PropTypes.object,
    productLoading: PropTypes.bool.isRequired,
    globalSearch: PropTypes.array,
    fileActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    productActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    safetyData: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.name = null;
    this.manufacturer = null;
    this.ean = null;
    this.hide = null;
    this.removeModal = null;
    this.addManualFileModal = null;

    const safetyData = this.props.safetyData || {
      hazardCodes: [],
      safetyCodes: [],
      warningCodes: [],
    };

    const productSafetyData = props.product
      ? getSafetyCodes(props.product, safetyData)
      : null;

    this.state = {
      openMenu: false,
      hide: false,
      variant: props.product.data.variant || ProductType.PRODUCT,
      gwpValueA1A3: props.product.data.gwp_value_a1a3 || '',
      gwpUnit:
        props.product.data.gwp_unit || gwpOptions.gwpUnitOptions[0].value,
      gwpVerification:
        props.product.data.gwp_verification ||
        gwpOptions.gwpVerificationOptions[0].value,
      gwpStandard:
        props.product.data.gwp_standard ||
        gwpOptions.gwpStandardOptions[0].value,
      safetyCodes: productSafetyData ? productSafetyData.safetyCodes : null,
      hazardCodes: productSafetyData ? productSafetyData.hazardCodes : null,
      warningCodes: productSafetyData ? productSafetyData.warningCodes : null,
      file: null,
      fileName: '',
      fileType: 'NO_CHOICE',
      selectedFile: null,
      selectedValue: '',
    };
  }

  componentWillReceiveProps(props) {
    if (!this.state.openMenu) {
      // Check for mobile view, openMenu is only for mobile
      if (window.matchMedia('screen and (max-width: 34em)').matches) {
        this.setState({
          openMenu: !!props.product,
        });
      }
    }
  }

  componentDidMount() {
    $(this.addManualFileModal).on('hidden.bs.modal', () => {
      this.setState({
        file: null,
        fileName: '',
        fileType: 'NO_CHOICE',
        error: null,
        noFilenameError: null,
        noFileError: null,
        noTypeError: null,
      });
    });
  }

  componentWillUnmount() {
    this._enablePageScroll();
    $(this.addManualFileModal).unbind('hidden.bs.modal');
  }

  getErrors = () => {
    return {
      error: null,
      noNameError: null,
      noManufacturerError: null,
      noFilenameError: null,
      noFileError: null,
      noTypeError: null,
    };
  };

  _enablePageScroll = () => {
    $('html').removeClass('no-scroll');
  };

  _disablePageScroll = () => {
    $('html').addClass('no-scroll');
  };

  treeify = (nodes) => {
    const roots = [];

    Object.keys(nodes).forEach((id) => {
      const node = nodes[id];
      if (!node.has_access) return;

      node.children = [];
      node.num_descendants = 0;
      node.num_descendants_complete = 0;

      let parent = nodes[node.parent];
      if (parent && parent.has_access) {
        parent.children = parent.children || [];
        parent.children.push(node);
      } else {
        roots.push(node);
      }

      while (parent && parent.has_access) {
        parent.num_descendants += 1;
        parent.num_descendants_complete += node.state === 2;
        parent = nodes[parent.parent];
      }
    });

    return sortPackageTree(roots);
  };

  _closeMenu = () => {
    // Checks for mobile view
    if (window.matchMedia('screen and (max-width: 34em)').matches) {
      // HACK: willreceiveprops will run a bit too often, and if product
      //       is set, then the menu will open again... so clear the product.
      this.props.productActionCreators.clearProduct();
      this.setState({
        openMenu: false,
      });
    }
  };

  _addPackageProduct = (event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    this.props.packageActionCreators.addPackageProduct(
      this.state.selectedValue,
      this.props.product
    );

    this.setState({
      selectedValue: '',
    });
  };

  _instaddProduct = (event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    this.props.packageActionCreators.instaddProduct(
      this.state.selectedValue,
      this.props.product
    );

    this.setState({
      selectedValue: '',
    });
  };

  _updateGwpInfo() {
    this.props.productActionCreators.updateManualProduct(
      this.props.product.data.id,
      {
        gwpValueA1A3: this.state.gwpValueA1A3,
        gwpUnit: this.state.gwpUnit,
        gwpVerification: this.state.gwpVerification,
        gwpStandard: this.state.gwpStandard,
      }
    );
  }

  _packageRow = (pkg) => {
    const elements = [];
    const inset =
      pkg.level > 1
        ? new Array(pkg.level * 2).join('\u00A0') +
          new Array(pkg.level).join('-') +
          ' '
        : '';

    elements.push(
      <option key={pkg.id} value={pkg.id}>
        {inset}
        {pkg.name}
      </option>
    );

    elements.push(pkg.children.map(this._packageRow));
    return elements;
  };

  _removeProduct = (event) => {
    event.preventDefault();
    this.props.productActionCreators.removeManualProduct(
      this.props.product.data.id
    );
    $(this.removeModal).modal('hide');
  };

  _updateProduct = (event) => {
    event.preventDefault();

    const errors = this.getErrors();

    if (!this.name || !this.name.value) {
      errors.error = true;
      errors.noNameError = this.props.intl.formatMessage(messages.emptyName);
    }

    if (!this.manufacturer.value) {
      errors.error = true;
      errors.noManufacturerError = this.props.intl.formatMessage(
        messages.emptyManufacturer
      );
    }

    this.setState(errors);
    if (errors.error) return;

    this.props.productActionCreators.updateManualProduct(
      this.props.product.data.id,
      {
        name: this.name.value,
        ean: this.ean.value,
        manufacturer: this.manufacturer.value,
        hidden: this.hide.checked,
        variant: this.state.variant,
      }
    );

    $('#updateModal').modal('hide');
  };

  _downloadFile = (file, event) => {
    event.preventDefault();
    this.props.fileActionCreators.downloadFile(file);
  };

  _addFile = (event) => {
    event.preventDefault();

    const errors = this.getErrors();

    if (!this.state.fileName) {
      errors.error = true;
      errors.noFilenameError = this.props.intl.formatMessage(
        messages.emptyFilename
      );
    }

    if (!this.state.file) {
      errors.error = true;
      errors.noFileError = this.props.intl.formatMessage(messages.emptyFile);
    }

    if (this.state.fileType === 'NO_CHOICE') {
      errors.error = true;
      errors.noTypeError = this.props.intl.formatMessage(messages.emptyType);
    }

    this.setState(errors);
    if (errors.error) return;

    this.props.fileActionCreators.addManualFiles(this.props.product.data.id, [
      {
        name: this.state.fileName,
        file: this.state.file,
        type: this.state.fileType,
      },
    ]);

    this.setState({
      file: null,
      fileName: '',
      fileType: 'NO_CHOICE',
    });

    this.name.value = '';

    $(this.addManualFileModal).modal('hide');
  };

  _removeFile = (file) => {
    this.props.fileActionCreators.removeManualFile(
      this.props.product.data.id,
      file.id
    );
  };

  _onDrop = (files) => {
    this.setState({
      file: files[0],
      fileName: this.state.fileName || files[0].name,
    });
  };

  _handleProductTypeChange = (event) => {
    this.setState({
      variant: Number(event.target.value),
    });
  };

  _actions = () => {
    const editNameGroupClasses = classNames('form-group', {
      'has-error': this.state.noNameError,
    });

    const editNameFieldClasses = classNames('form-control', {
      'form-control-error': this.state.noNameError,
    });

    const editManufacturerGroupClasses = classNames('form-group', {
      'has-error': this.state.noManufacturerError,
    });

    const editManufacturerFieldClasses = classNames('form-control', {
      'form-control-error': this.state.noManufacturerError,
    });

    return (
      <div>
        <div className='modal fade' id='updateModal' tabIndex='-1'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='close' data-dismiss='modal'>
                  <span>&times;</span>
                </button>

                <h4 className='modal-title'>
                  <FormattedMessage
                    id='update-manual-product-modal-header'
                    description='Update manual product modal header'
                    defaultMessage='Päivitä tuotteen? "{name}" tietoja'
                    values={{
                      name: this.props.product.data.name,
                    }}
                  />
                </h4>
              </div>

              <div className='modal-body'>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  <fieldset className={editNameGroupClasses}>
                    <label>
                      <FormattedMessage
                        id='name'
                        description='Name'
                        defaultMessage='Nimi'
                      />
                    </label>

                    <input
                      type='text'
                      ref={(input) => {
                        this.name = input;
                      }}
                      className={editNameFieldClasses}
                      defaultValue={this.props.product.data.name}
                    />

                    <small className='text-danger'>
                      {this.state.noNameError}
                    </small>
                  </fieldset>

                  <fieldset className={editManufacturerGroupClasses}>
                    <label>
                      <FormattedMessage
                        id='manufacturer'
                        description='Manufacturer'
                        defaultMessage='Valmistaja'
                      />
                    </label>

                    <input
                      type='text'
                      ref={(input) => {
                        this.manufacturer = input;
                      }}
                      className={editManufacturerFieldClasses}
                      defaultValue={this.props.product.data.manufacturer}
                    />

                    <small className='text-danger'>
                      {this.state.noManufacturerError}
                    </small>
                  </fieldset>

                  <fieldset className='form-group'>
                    <label>
                      <FormattedMessage
                        id='ean'
                        description='Ean'
                        defaultMessage='GTIN'
                      />
                    </label>

                    <input
                      type='text'
                      ref={(input) => {
                        this.ean = input;
                      }}
                      className='form-control'
                      defaultValue={this.props.product.data.ean}
                    />
                  </fieldset>

                  <fieldset className='form-group'>
                    <label>
                      <FormattedMessage
                        id='product-type'
                        description='Product type'
                        defaultMessage='Tuotteen tyyppi'
                      />
                    </label>
                    <div className='radio'>
                      <label>
                        <input
                          type='radio'
                          defaultValue={ProductType.PRODUCT}
                          checked={this.state.variant === ProductType.PRODUCT}
                          onChange={this._handleProductTypeChange}
                        />{' '}
                        Rakennustuote
                      </label>
                      <a
                        className='m-l'
                        onClick={(evt) => evt.stopPropagation()}
                        data-toggle='tooltip'
                        data-placement='bottom'
                        title={this.props.intl.formatMessage(
                          messages.productTooltip
                        )}
                      >
                        <i className='fa fa-info-circle' />
                      </a>
                    </div>
                    <div className='radio'>
                      <label>
                        <input
                          type='radio'
                          value={ProductType.CHEMICAL_PRODUCT}
                          checked={
                            this.state.variant === ProductType.CHEMICAL_PRODUCT
                          }
                          onChange={this._handleProductTypeChange}
                        />{' '}
                        Rakennustuotekemikaali | menee kemikaaliluetteloon
                      </label>
                      <a
                        className='m-l'
                        onClick={(evt) => evt.stopPropagation()}
                        data-toggle='tooltip'
                        data-placement='bottom'
                        title={this.props.intl.formatMessage(
                          messages.chemicalProductTooltip
                        )}
                      >
                        <i className='fa fa-info-circle' />
                      </a>
                    </div>
                    <div className='radio'>
                      <label>
                        <input
                          type='radio'
                          value={ProductType.CHEMICAL}
                          checked={this.state.variant === ProductType.CHEMICAL}
                          onChange={this._handleProductTypeChange}
                        />{' '}
                        Kemikaali - ei rakennustuote | menee kemikaaliluetteloon
                      </label>
                      <a
                        className='m-l'
                        onClick={(evt) => evt.stopPropagation()}
                        data-toggle='tooltip'
                        data-placement='bottom'
                        title={this.props.intl.formatMessage(
                          messages.chemicalTooltip
                        )}
                      >
                        <i className='fa fa-info-circle' />
                      </a>
                    </div>
                  </fieldset>

                  <fieldset className='form-group'>
                    <label>
                      <input
                        type='checkbox'
                        ref={(input) => {
                          this.hide = input;
                        }}
                        className='m-r'
                        defaultChecked={this.props.product.data.hidden}
                      />
                      <FormattedMessage
                        id='hidden-from-search'
                        description='Hidden from search'
                        defaultMessage='Piilotettu hakutuloksista'
                      />
                    </label>
                  </fieldset>
                </form>
              </div>

              <div className='modal-footer'>
                <div className='m-b'>
                  <button
                    className='btn btn-muted pull-right'
                    data-dismiss='modal'
                  >
                    <FormattedMessage
                      id='close'
                      description='Close'
                      defaultMessage='Sulje'
                    />
                  </button>

                  <button
                    className='btn btn-success pull-right m-r'
                    onClick={this._updateProduct}
                  >
                    <FormattedMessage
                      id='save-changes'
                      description='Save changes'
                      defaultMessage='Tallenna muutokset'
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className='modal fade'
          ref={(div) => {
            this.removeModal = div;
          }}
          id='removeModal'
          tabIndex='-1'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='close' data-dismiss='modal'>
                  <span>&times;</span>
                </button>

                <h4 className='modal-title'>
                  <FormattedMessage
                    id='remove-manual-product-modal-header'
                    description='Remove manual product modal header'
                    defaultMessage='Poista tuote "{name}"'
                    values={{
                      name: this.props.product.data.name,
                    }}
                  />
                </h4>
              </div>

              <div className='modal-body'>
                <FormattedMessage
                  id='remove-manual-product-modal-disclaimer'
                  description='Disclaimer for removing manually added product'
                  defaultMessage={`Haluatko varmasti poistaa tuotteen "{name}"?
                    Tuotteen poistaminen ei poista sitä urakoista, joissa se on
                    asennettuna (suunnitelmassa olevat poistuvat).`}
                  values={{
                    name: this.props.product.data.name,
                  }}
                />
              </div>

              <div className='modal-footer'>
                <div className='m-b'>
                  <button
                    className='btn btn-muted pull-right'
                    data-dismiss='modal'
                  >
                    <FormattedMessage
                      id='cancel'
                      description='Cancel'
                      defaultMessage='Peruuta'
                    />
                  </button>

                  <button
                    className='btn btn-danger pull-right m-r'
                    onClick={this._removeProduct}
                  >
                    <FormattedMessage
                      id='delete'
                      description='Delete'
                      defaultMessage='Poista'
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='ManualProductPanel-content-actions'>
          <button
            className='btn btn-success'
            data-toggle='modal'
            data-target='#updateModal'
          >
            <i className='fa fa-pencil-square-o' />
            <FormattedMessage
              id='product-manual-edit'
              description='Label for manual product edit'
              defaultMessage='Muokkaa'
            />
          </button>

          <button
            className='btn btn-danger'
            data-toggle='modal'
            data-target='#removeModal'
          >
            <i className='fa fa-trash-o' />
            <FormattedMessage
              id='product-manual-remove'
              description='Label for manual product removal'
              defaultMessage='Poista'
            />
          </button>
        </div>

        <div>
          <hr />
          <h4 className='m-b'>
            <FormattedMessage
              id='files-header'
              description='Header text for product files'
              defaultMessage='Tiedostot'
            />
          </h4>

          {this._productFiles(this.props.product)}
        </div>
      </div>
    );
  };

  _productFiles = (product) => {
    if (!product) {
      return null;
    }

    let content = null;
    if (
      (!product.files || !product.files.length) &&
      (!product.data.files || !product.data.files.length)
    ) {
      content = (
        <small className='text-muted'>
          <FormattedMessage
            id='no-files-placeholder'
            description='Placeholder when there are no files'
            defaultMessage='Ei tiedostoja.'
          />
        </small>
      );
    } else {
      const deduplicate = {};
      content = (
        <div className='ProductInformationPanel-content-files list-group'>
          {(product.data.files || []).sort(fileSorter).map((file) => {
            const key = JSON.stringify({
              name: file.name,
              filename: path.basename(file.url),
              type: file.type,
            });

            if (deduplicate.hasOwnProperty(key)) return null;
            deduplicate[key] = true;

            return (
              <button
                onClick={this._downloadFile.bind(this, file)}
                key={file.id}
                className={classNames(
                  'ManualProductPanel-content-files-row',
                  'list-group-item'
                )}
              >
                <span>{file.name}</span>
                <div className='floater'>
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({
                        selectedFile: file,
                      });
                      $('#EditFileModal').modal('show');
                    }}
                  >
                    <i className='text-danger fa fa-pencil fa-lg'></i>
                  </a>
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({
                        selectedFile: file,
                      });
                      $('#RemoveCompanyFileModal').modal('show');
                    }}
                  >
                    <i className='text-danger fa fa-trash fa-lg'></i>
                  </a>
                  <img src={getFileTypePath(file.type)} />
                </div>
              </button>
            );
          })}
        </div>
      );
    }

    return (
      <div>
        {content}
        <div>
          <button
            className='btn btn-success m-t pull-left'
            data-toggle='modal'
            data-target='#AddManualFileModal'
          >
            <FormattedMessage
              id='add-file-button'
              description='Label for add file button'
              defaultMessage='Lisää tiedosto'
            />
          </button>
        </div>
        <div className='clearfix'></div>
      </div>
    );
  };

  _getContent = () => {
    const { product } = this.props;
    const data = getProductData(product);

    const safetyData = this.props.safetyData || {
      hazardCodes: [],
      safetyCodes: [],
      warningCodes: [],
    };

    const productSafetyData = getSafetyCodes(product, safetyData);

    const timestamp = new Date(Date.parse(data.last_modified));
    let lastModified = timestamp.getDate() + '.';
    lastModified += timestamp.getMonth() + 1 + '.';
    lastModified += timestamp.getFullYear() + ' ';
    lastModified +=
      (timestamp.getHours() > 9
        ? timestamp.getHours()
        : '0' + timestamp.getHours()) + ':';

    lastModified +=
      timestamp.getMinutes() > 9
        ? timestamp.getMinutes()
        : '0' + timestamp.getMinutes();

    const addFileNameGroupClasses = classNames('form-group', {
      'has-error': this.state.noFilenameError,
    });

    const addFileNameFieldClasses = classNames('form-control', {
      'form-control-error': this.state.noFilenameError,
    });

    const addFileFileGroupClasses = classNames('form-group', {
      'has-error': this.state.noFileError,
    });

    // const addFileFileFieldClasses = classNames(
    //   'form-control',
    //   {
    //     'form-control-error': this.state.noFileError,
    //   }
    // );

    const addFileTypeGroupClasses = classNames({
      'has-error': this.state.noTypeError,
    });

    const addFileTypeFieldClasses = classNames('form-control', {
      'form-control-error': this.state.noTypeError,
    });

    const productGwpValueA1A3 = this.props.product.data.gwp_value_a1a3 || '';
    const productGwpUnit =
      this.props.product.data.gwp_unit || gwpOptions.gwpUnitOptions[0].value;
    const productGwpVerification =
      this.props.product.data.gwp_verification ||
      gwpOptions.gwpVerificationOptions[0].value;
    const productGwpStandard =
      this.props.product.data.gwp_standard ||
      gwpOptions.gwpStandardOptions[0].value;

    const hazardCodesMatch =
      productSafetyData.hazardCodes.map(({ value }) => value).join(',') ===
      this.state.hazardCodes.map(({ value }) => value).join(',');

    const safetyCodesMatch =
      productSafetyData.safetyCodes.map(({ value }) => value).join(',') ===
      this.state.safetyCodes.map(({ value }) => value).join(',');

    const warningCodesMatch =
      productSafetyData.warningCodes.map(({ value }) => value).join(',') ===
      this.state.warningCodes.map(({ value }) => value).join(',');

    return (
      <div>
        <ConfirmActionModal
          id='RemoveCompanyFileModal'
          title='Poista tiedosto'
          info={
            <div>
              <FormattedMessage
                id='remove-company-file-info-message'
                description='Remove company file info message'
                defaultMessage={`Haluatko varmasti poistaa seuraavan tiedoston?`}
              />
              {this.state.selectedFile && (
                <div
                  className={classNames(
                    'ManualProductPanel-content-files-row',
                    'list-group-item',
                    'mt-10'
                  )}
                >
                  <span>{this.state.selectedFile.name}</span>
                  <div className='floater'>
                    <img src={getFileTypePath(this.state.selectedFile.type)} />
                  </div>
                </div>
              )}
            </div>
          }
          onConfirm={() => this._removeFile(this.state.selectedFile)}
        />

        <EditFileModal
          file={this.state.selectedFile}
          product={this.props.product}
          fileActionCreators={this.props.fileActionCreators}
          messageActionCreators={this.props.messageActionCreators}
        />

        <div
          className='AddManualFileModal modal fade'
          ref={(div) => {
            this.addManualFileModal = div;
          }}
          id='AddManualFileModal'
          tabIndex='-1'
          data-backdrop='static'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='close' data-dismiss='modal'>
                  <span>&times;</span>
                </button>

                <h4 className='modal-title'>
                  <FormattedMessage
                    id='add-file-header'
                    description='File adding header'
                    defaultMessage='Lisää tiedosto tuotteeseen'
                  />
                </h4>
              </div>

              <div className='modal-body'>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                >
                  <fieldset className={addFileFileGroupClasses}>
                    <label>
                      <FormattedMessage
                        id='file'
                        description='Generic file'
                        defaultMessage='Tiedosto'
                      />
                      &nbsp;
                      <FormattedMessage
                        id='file_size_limit'
                        description='File size limit warning'
                        defaultMessage='(maksimikoko 100 megatavua)'
                      />
                    </label>

                    <section>
                      <div>
                        <Dropzone
                          onDrop={this._onDrop}
                          className='dropzone'
                          activeStyle={{
                            border: '2px solid #02bc01',
                            backgroundColor: '#f2f2f2',
                          }}
                        >
                          <i
                            className='fa fa-file-text-o'
                            aria-hidden='true'
                            style={{
                              fontSize: 32,
                              color: this.state.file ? '#02bc01' : '#000',
                            }}
                          />
                          <br />
                          {!!this.state.file ? (
                            <p>{this.state.file.name}</p>
                          ) : (
                            <p>
                              Pudota tiedosto tähän tai klikkaa valitaksesi
                              tiedosto
                            </p>
                          )}
                        </Dropzone>
                      </div>
                    </section>
                    <small className='text-danger'>
                      {this.state.noFileError}
                    </small>
                  </fieldset>

                  <fieldset className={addFileNameGroupClasses}>
                    <label>
                      <FormattedMessage
                        id='display_name'
                        description='Generic display name'
                        defaultMessage='Näytettävä nimi (pakollinen)'
                      />
                    </label>

                    <input
                      type='text'
                      value={this.state.fileName}
                      onChange={(e) => {
                        this.setState({
                          fileName: e.currentTarget.value,
                        });
                      }}
                      className={addFileNameFieldClasses}
                    />

                    <small className='text-danger'>
                      {this.state.noFilenameError}
                    </small>
                  </fieldset>

                  <fieldset className={addFileTypeGroupClasses}>
                    <label>
                      <FormattedMessage
                        id='type'
                        description='Generic type'
                        defaultMessage='Tyyppi'
                      />
                    </label>

                    <select
                      className={addFileTypeFieldClasses}
                      value={this.state.fileType}
                      onChange={(e) => {
                        this.setState({
                          fileType: e.currentTarget.value,
                        });
                      }}
                    >
                      <FormattedMessage
                        id='file-type-no-choice'
                        description='Label for no choice in file type list'
                        defaultMessage='--- Valitse tiedoston tyyppi ---'
                      >
                        {(message) => (
                          <option value='NO_CHOICE'>{message}</option>
                        )}
                      </FormattedMessage>

                      {Object.keys(FileTypes).map((key) => {
                        return (
                          <option key={key} value={key}>
                            {this.props.intl.formatMessage(
                              messages[FileTypes[key]]
                            )}
                          </option>
                        );
                      })}
                    </select>

                    <small className='text-danger'>
                      {this.state.noTypeError}
                    </small>
                  </fieldset>
                </form>
              </div>

              <div className='modal-footer'>
                <button
                  className='btn btn-warning pull-right'
                  data-dismiss='modal'
                >
                  <FormattedMessage
                    id='add-file-cancel'
                    description='Cancel button text when adding a file'
                    defaultMessage='Peruuta'
                  />
                </button>

                <button
                  className='btn btn-success pull-right m-r'
                  onClick={this._addFile}
                >
                  <FormattedMessage
                    id='add-file-accept'
                    description='Accept button text when adding a file'
                    defaultMessage='Lisää'
                  />
                </button>

                <div className='clearfix' />
              </div>
            </div>
          </div>
        </div>
        <div className='BlancoModal modal fade' id='BlancoModal' tabIndex='-1'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='close' data-dismiss='modal'>
                  <span>&times;</span>
                </button>

                <h4 className='modal-title'>
                  <FormattedMessage
                    id='blanco-modal-header'
                    description='Blanco modal header'
                    defaultMessage='Tuotteen tarkemmat tiedot'
                  />
                </h4>
              </div>

              <div className='modal-body' />
            </div>
          </div>
        </div>

        <h4 className='sidepanel-header'>{data.name}</h4>

        <dl className='dl-horizontal row'>
          <dt className='col-xs-12 col-sm-5'>
            <FormattedMessage
              id='product-manufacturer'
              description='Label text for product manufacturer'
              defaultMessage='Yritys'
            />
            :
          </dt>
          <dd className='col-xs-12 col-sm-7'>{data.manufacturer}</dd>

          <dt className='col-xs-12 col-sm-5'>
            <FormattedMessage
              id='product-ean'
              description='Label text for product EAN code'
              defaultMessage='GTIN-koodi'
            />
            :
          </dt>
          <dd className='col-xs-12 col-sm-7'>
            {data.ean || (
              <FormattedMessage
                id='unknown'
                description='Generic unknown'
                defaultMessage='Ei tiedossa'
              />
            )}
          </dd>

          {/* <dt className='col-xs-12 col-sm-5'>
            <FormattedMessage
              id='product-talo2000'
              description='Label text for product Talo 2000 class'
              defaultMessage='Talo 2000 -tuotenimike'
            />:
          </dt>
          <dd className='col-xs-12 col-sm-7'>
            {data.talo2000 ||
              <FormattedMessage
                id='unknown'
                description='Generic unknown'
                defaultMessage='Ei tiedossa'
              />
            }
          </dd> */}

          <dt className='col-xs-12 col-sm-5'>
            <FormattedMessage
              id='product-added-by'
              description='Label text for product adder'
              defaultMessage='Tuotteen lisännyt'
            />
            :
          </dt>
          <dd className='col-xs-12 col-sm-7'>
            {data.username || (
              <FormattedMessage
                id='unknown'
                description='Generic unknown'
                defaultMessage='Ei tiedossa'
              />
            )}
          </dd>

          <dt className='col-xs-12 col-sm-5'>
            <FormattedMessage
              id='product-last_modified'
              description='Label text for product last modified'
              defaultMessage='Muokattu viimeksi'
            />
            :
          </dt>
          <dd className='col-xs-12 col-sm-7'>
            {lastModified || (
              <FormattedMessage
                id='unknown'
                description='Generic unknown'
                defaultMessage='Ei tiedossa'
              />
            )}
          </dd>
        </dl>

        <div className='dropdown' id='usageDropdown'>
          <a data-toggle='dropdown' data-target='#usageDropdown'>
            <FormattedMessage
              id='product-use-count'
              description='Tecxt for use count'
              defaultMessage='Tuote on käytössä {count} urakassa'
              values={{
                count: data.usage_data
                  ? data.usage_data.length.toString()
                  : '0',
              }}
            />
          </a>
          <div className='dropdown-menu'>
            {data.usage_data &&
              data.usage_data.map((pkg) => {
                return (
                  <a
                    className='dropdown-item'
                    href={`/package/${pkg.id}/products#!local`}
                    target='_blank'
                    key={pkg.id}
                  >
                    {pkg.name}:
                    {pkg.product_status === 1
                      ? ' asennettu'
                      : ' suunnitelmassa'}
                  </a>
                );
              })}
          </div>
        </div>
        {this._actions()}

        {(data.variant === ProductType.CHEMICAL ||
          data.variant === ProductType.CHEMICAL_PRODUCT) && (
          <div>
            <div>
              <hr />
              <h4 className='m-b'>
                <FormattedMessage
                  id='safety-codes-header'
                  description='Header text for safety codes'
                  defaultMessage='Kemikaalitiedot'
                />
              </h4>
            </div>

            <FormattedMessage
              id='hazard-codes'
              description='Multiselect hazard codes'
              defaultMessage='Vaaralauseke (yksi tai useampi)'
            />
            <Select
              className='m-t m-b maxpanel'
              isMulti
              value={this.state.hazardCodes}
              onChange={(value) => {
                if (value.length > 1) {
                  value = value.filter((e) =>
                    value[value.length - 1].value !== '-'
                      ? e.value !== '-'
                      : e.value === '-'
                  );
                }
                this.setState({
                  hazardCodes: value,
                });
              }}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              noOptionsMessage={() => 'None found'}
              placeholder='Vaaralauseke...'
              options={safetyData.hazardCodes}
            />

            <FormattedMessage
              id='safety-codes'
              description='Multiselect safety codes'
              defaultMessage='Turvalauseke (yksi tai useampi)'
            />
            <Select
              className='m-t m-b maxpanel'
              isMulti
              value={this.state.safetyCodes}
              onChange={(value) => {
                if (value.length > 1) {
                  value = value.filter((e) =>
                    value[value.length - 1].value !== '-'
                      ? e.value !== '-'
                      : e.value === '-'
                  );
                }
                this.setState({
                  safetyCodes: value,
                });
              }}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              noOptionsMessage={() => 'None found'}
              placeholder='Turvalauseke...'
              options={safetyData.safetyCodes}
            />

            <FormattedMessage
              id='warning-codes'
              description='Multiselect warning codes'
              defaultMessage='Varoitusmerkki (yksi tai useampi)'
            />
            <Select
              className='m-t m-b maxpanel'
              isMulti
              value={this.state.warningCodes}
              onChange={(value) => {
                if (value.length > 1) {
                  value = value.filter((e) =>
                    value[value.length - 1].value !== '-'
                      ? e.value !== '-'
                      : e.value === '-'
                  );
                }
                this.setState({
                  warningCodes: value,
                });
              }}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
              noOptionsMessage={() => 'None found'}
              placeholder='Varoitusmerkki...'
              options={safetyData.warningCodes}
            />
            <button
              className={'btn btn-success'}
              disabled={
                hazardCodesMatch && safetyCodesMatch && warningCodesMatch
              }
              onClick={() => {
                this.props.productActionCreators.updateCompanyProductChemicalInfo(
                  this.props.product.data.id,
                  {
                    hazard_codes: this.state.hazardCodes
                      .map(({ value }) => value)
                      .join(','),
                    safety_codes: this.state.safetyCodes
                      .map(({ value }) => value)
                      .join(','),
                    warning_codes: this.state.warningCodes
                      .map(({ value }) => value)
                      .join(','),
                  }
                );
              }}
            >
              <FormattedMessage
                id='chemical-product-submit'
                description='Submit chemical info'
                defaultMessage='Tallenna tiedot'
              />
            </button>
          </div>
        )}

        <hr />
        <h4 className='m-b'>
          <FormattedMessage
            id='carbon-footprint-header'
            description='Header text for carbon footprint info'
            defaultMessage='Hiilijalanjälki'
          />
        </h4>
        <FormattedMessage
          id='gwp-a1a3'
          description='gwp-a1a3'
          defaultMessage='GWP A1-A3'
        />
        <div>
          <input
            className='m-t m-b'
            type='text'
            value={this.state.gwpValueA1A3}
            onChange={(e) => {
              this.setState({
                gwpValueA1A3: e.currentTarget.value,
              });
            }}
          />
        </div>

        {this.state.gwpValueA1A3 !== '' && (
          <div>
            <FormattedMessage
              id='gwp-unit'
              description='gwp-unit'
              defaultMessage='Yksikkö'
            />
            <a
              className='m-l'
              onClick={(evt) => evt.stopPropagation()}
              data-toggle='tooltip'
              data-placement='bottom'
              title={this.props.intl.formatMessage(messages.gwpUnitTooltip)}
            >
              <i className='fa fa-info-circle' />
            </a>
            <Select
              className='m-t m-b maxpanel'
              value={gwpOptions.gwpUnitOptions.filter(
                (o) => o.value === this.state.gwpUnit
              )}
              onChange={({ value }) => {
                this.setState({
                  gwpUnit: value,
                });
              }}
              backspaceRemovesValue={false}
              noOptionsMessage={() => 'None found'}
              placeholder={'Yksikkö...'}
              options={gwpOptions.gwpUnitOptions}
            />
            <FormattedMessage
              id='gwp-verification'
              description='gwp-verification'
              defaultMessage='3. osapuolen verifiointi'
            />
            <Select
              className='m-t m-b maxpanel'
              value={gwpOptions.gwpVerificationOptions.filter(
                (o) => o.value === this.state.gwpVerification
              )}
              onChange={({ value }) => {
                this.setState({
                  gwpVerification: value,
                });
              }}
              backspaceRemovesValue={false}
              noOptionsMessage={() => 'None found'}
              placeholder={'Verifikaatio...'}
              options={gwpOptions.gwpVerificationOptions}
            />
            <FormattedMessage
              id='gwp-standard'
              description='gwp-standard'
              defaultMessage='Standardi'
            />
            <Select
              className='m-t m-b maxpanel'
              value={gwpOptions.gwpStandardOptions.filter(
                (o) => o.value === this.state.gwpStandard
              )}
              onChange={({ value }) => {
                this.setState({
                  gwpStandard: value,
                });
              }}
              backspaceRemovesValue={false}
              noOptionsMessage={() => 'None found'}
              placeholder={'Standardi...'}
              options={gwpOptions.gwpStandardOptions}
            />
          </div>
        )}
        <button
          className={'btn btn-success'}
          disabled={
            this.state.gwpValueA1A3 === productGwpValueA1A3 &&
            this.state.gwpUnit === productGwpUnit &&
            this.state.gwpVerification === productGwpVerification &&
            this.state.gwpStandard === productGwpStandard
          }
          onClick={() => this._updateGwpInfo()}
        >
          <FormattedMessage
            id='carbonFootprint-product-submit'
            description='Submit carbon footprint info'
            defaultMessage='Tallenna tiedot'
          />
        </button>

        <div>
          <hr />
          <select
            value={this.state.selectedValue}
            className='form-control m-t maxpanel'
            onChange={(e) => {
              this.setState({
                selectedValue: e.currentTarget.value,
              });
            }}
          >
            <option value='' disabled>
              Valitse urakka
            </option>
            {this.treeify(this.props.packages).map(this._packageRow)}
          </select>

          <button
            className='btn btn-warning m-t'
            onClick={this._addPackageProduct}
            disabled={!this.state.selectedValue}
          >
            <FormattedMessage
              id='add-product-button'
              description='Button text for adding a product to a package'
              defaultMessage='Lisää suunnitelmaan'
            />
          </button>

          <button
            className='btn btn-success m-t'
            onClick={this._instaddProduct}
            disabled={!this.state.selectedValue}
          >
            <FormattedMessage
              id='instadd-product-button'
              description='Button text for installing and adding a product'
              defaultMessage='Merkitse suoraan asennetuksi'
            />
          </button>
        </div>
      </div>
    );
  };

  render() {
    if (this.state.openMenu) {
      this._disablePageScroll();
    } else {
      this._enablePageScroll();
    }

    let content = null;
    const classes = classNames(
      'ManualProductPanel-content',
      'stretch-vert',
      'sidepanel',
      {
        open: !!this.props.product && this.state.openMenu,
      }
    );

    const backdropClasses = classNames('ManualProductPanel-backdrop', {
      'hidden-sm-up': this.state.openMenu,
      'hidden-xs-up': !this.state.openMenu,
    });

    if (this.props.productLoading) {
      content = <div className='loading loading-green loading-center m-t-md' />;
    } else if (!this.props.product) {
      content = (
        <p className='text-muted padding'>
          <FormattedMessage
            id='product-information-panel-placeholder'
            description='Placeholder for product information panel'
            defaultMessage='Klikkaa tuotetta nähdäksesi sen tiedot.'
          />
        </p>
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='ManualProductPanel col-xs-12 col-sm-4'>
        <div onClick={this._closeMenu} className={backdropClasses} />

        <div className={classes}>
          <div className='ManualProductPanel-content-menu'>
            <div
              className={classNames(
                'sidepanel-close-icon',
                'hidden-sm-up',
                'pull-left'
              )}
            >
              <i className='fa fa-times pull-left' onClick={this._closeMenu} />
            </div>

            {content}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(ManualProductPanel);
