/**
 * EditFileModal.js - React component base
 *
 * Copyright 2023 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

import FileTypes from '../../../shared/FileTypes.mjs';
import { getFileTypePath, getProductFilename } from '../../utils/fileUtil.js';

const messages = defineMessages({
  emptyName: {
    id: 'file-upload-no-name',
    description: 'Error when empty name while uploading file',
    defaultMessage: 'Tiedoston nimi ei voi olla tyhjä!',
  },

  emptyFile: {
    id: 'file-upload-no-file',
    description: 'Error when no file selected',
    defaultMessage: 'Sinun on valittava tiedosto!',
  },

  emptyType: {
    id: 'file-upload-no-type',
    description: 'Error when no type selected',
    defaultMessage: 'Sinun on valittava tiedostotyyppi!',
  },

  fileTypeDOP: {
    id: 'filetype-dop',
    description: 'File type for DOP',
    defaultMessage: 'DOP - Suoritustasoilmoitus',
  },

  fileTypeKTT: {
    id: 'filetype-ktt',
    description: 'File type for KTT',
    defaultMessage: 'KTT - Käyttöturvallisuustiedote',
  },

  fileTypeINSTR: {
    id: 'filetype-instr',
    description: 'File type for INSTR',
    defaultMessage: 'Ohje',
  },

  fileTypeATT: {
    id: 'filetype-att',
    description: 'File type for ATT',
    defaultMessage: 'Muu liite',
  },

  fileTypeEPD: {
    id: 'filetype-epd',
    description: 'File type for EPD',
    defaultMessage: 'EPD - Ympäristöseloste',
  },

  fileTypeM1: {
    id: 'filetype-m1',
    description: 'File type for M1',
    defaultMessage: 'M1 - Päästöluokitus',
  },

  fileTypeCERT: {
    id: 'filetype-cert',
    description: 'File type for CERT',
    defaultMessage: 'CERT - Tuotteen sertifikaatti',
  },
});

class EditFileModal extends React.Component {
  static propTypes = {
    file: PropTypes.object,
    product: PropTypes.object,
    fileActionCreators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.editFileModal = null;

    this.state = {
      file: null,
      fileName: '',
      fileType: 'NO_CHOICE',
      ...this.getErrors(),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.file !== prevState.file) {
      return {
        file: nextProps.file,
        fileName: nextProps.file ? nextProps.file.name : '',
        fileType: nextProps.file ? nextProps.file.type : 'NO_CHOICE',
        error: null,
        noNameError: null,
        noTypeError: null,
      };
    }
    return null;
  }

  getErrors = () => {
    return {
      error: null,
      noNameError: null,
      noTypeError: null,
    };
  };

  _editFile = (event) => {
    event.preventDefault();

    const errors = this.getErrors();

    if (!this.state.fileName) {
      errors.error = true;
      errors.noNameError = this.props.intl.formatMessage(messages.emptyName);
    }

    if (this.state.fileType === 'NO_CHOICE') {
      errors.error = true;
      errors.noTypeError = this.props.intl.formatMessage(messages.emptyType);
    }

    this.setState(errors);

    if (errors.error) return;

    this.props.fileActionCreators.editFileMetadata(
      this.props.file,
      this.state.fileName,
      this.state.fileType
    );

    this.setState({
      fileName: '',
      fileType: 'NO_CHOICE',
    });

    $(this.editFileModal).removeClass('fade');
    $(this.editFileModal).modal('hide');
  };

  render() {
    const editFileNameGroupClasses = classNames('form-group', {
      'has-error': this.state.noNameError,
    });

    const editFileNameFieldClasses = classNames('form-control', {
      'form-control-error': this.state.noNameError,
    });

    const editFileTypeGroupClasses = classNames({
      'has-error': this.state.noTypeError,
    });

    const editFileTypeFieldClasses = classNames('form-control', {
      'form-control-error': this.state.noTypeError,
    });

    return (
      <div
        className='EditFileModal modal fade'
        ref={(div) => {
          this.editFileModal = div;
        }}
        id='EditFileModal'
        tabIndex='-1'
        data-backdrop='static'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                <span>&times;</span>
              </button>
              <h4
                className='modal-title'
                style={{ pediting: '10px 0px 0px', margin: '0px 0px 10px' }}
              >
                <FormattedMessage
                  id='edit-file-header'
                  description='File editing header'
                  defaultMessage='Muokkaa tiedostoa'
                />
              </h4>
            </div>

            <div className='modal-body'>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <label>
                  <FormattedMessage
                    id='file-label'
                    description='File label'
                    defaultMessage='Tiedosto'
                  />
                </label>
                {this.props.file && (
                  <div
                    className={classNames(
                      'EditFileModal-content-files-row',
                      'list-group-item',
                      'mb-10'
                    )}
                  >
                    <span>
                      {getProductFilename(
                        this.props.product,
                        this.props.file,
                        0
                      )}
                    </span>
                    <div className='floater'>
                      <img src={getFileTypePath(this.props.file.type)} />
                    </div>
                  </div>
                )}

                <fieldset className={editFileNameGroupClasses}>
                  <label>
                    <FormattedMessage
                      id='display_name'
                      description='Generic display name'
                      defaultMessage='Näytettävä nimi (pakollinen)'
                    />
                  </label>

                  <input
                    type='text'
                    value={this.state.fileName}
                    onChange={(event) => {
                      this.setState({
                        fileName: event.currentTarget.value,
                      });
                    }}
                    className={editFileNameFieldClasses}
                  />

                  <small className='text-danger'>
                    {this.state.noNameError}
                  </small>
                </fieldset>

                <fieldset className={editFileTypeGroupClasses}>
                  <label>
                    <FormattedMessage
                      id='type'
                      description='Generic type'
                      defaultMessage='Tyyppi'
                    />
                  </label>

                  <select
                    className={editFileTypeFieldClasses}
                    value={this.state.fileType}
                    onChange={(event) => {
                      this.setState({
                        fileType: event.currentTarget.value,
                      });
                    }}
                  >
                    <FormattedMessage
                      id='file-type-no-choice'
                      description='Label for no choice in file type list'
                      defaultMessage='--- Valitse tiedoston tyyppi ---'
                    >
                      {(message) => (
                        <option value='NO_CHOICE'>{message}</option>
                      )}
                    </FormattedMessage>

                    {Object.keys(FileTypes).map((key) => {
                      return (
                        <option key={key} value={key}>
                          {this.props.intl.formatMessage(
                            messages[FileTypes[key]]
                          )}
                        </option>
                      );
                    })}
                  </select>
                  <small className='text-danger'>
                    {this.state.noTypeError}
                  </small>
                </fieldset>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                className='btn btn-warning pull-right'
                data-dismiss='modal'
              >
                <FormattedMessage
                  id='edit-file-cancel'
                  description='Cancel button text when editing a file'
                  defaultMessage='Peruuta'
                />
              </button>

              <button
                className='btn btn-success pull-right m-r'
                onClick={this._editFile}
              >
                <FormattedMessage
                  id='edit-file-accept'
                  description='Accept button text when editing a file'
                  defaultMessage='Tallenna'
                />
              </button>

              <div className='clearfix' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(EditFileModal);
