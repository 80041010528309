import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const messages = defineMessages({
  cancel: {
    id: 'add-folder-modal-cancel',
    description: 'Cancel folder modal button',
    defaultMessage: 'Peruuta',
  },
  create: {
    id: 'add-folder-modal-create',
    description: 'Create folder modal button',
    defaultMessage: 'Lisää',
  },
});

class AddFolderModal extends React.Component {
  static propTypes = {
    addFolder: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
  };

  constructor(props) {
    super(props);

    this.addFolderModal = null;
    this.name = null;

    this.state = {
      name: '',
      nameError: false,
    };
  }

  componentDidMount() {
    $(this.addFolderModal).on('shown.bs.modal', () => {
      this.name.focus();
    });
  }

  addFolder = (event) => {
    event.preventDefault();

    const { name } = this.state;

    if (!name) {
      this.setState({ nameError: true });
      return;
    }

    this.setState({ nameError: false });

    this.props.addFolder(name);
    $(this.addFolderModal).modal('hide');
  };

  render() {
    const { name, nameError } = this.state;
    const nameGroupClasses = classNames('form-group', {
      'has-error': nameError,
    });

    const nameFieldClasses = classNames('form-control', {
      'form-control-error': nameError,
    });

    return (
      <div
        className='modal fade'
        ref={(div) => {
          this.addFolderModal = div;
        }}
        id='addFolderModal'
        tabIndex='-1'
        data-backdrop='static'
      >
        <div className='modal-dialog'>
          <form className='modal-content' onSubmit={this.addFolder}>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                <span>&times;</span>
              </button>
              <h4 className='modal-title'>
                <FormattedMessage
                  id='add-folder-modal-title'
                  description='Add folder modal title'
                  defaultMessage='Lisää tuoteryhmä'
                />
              </h4>
            </div>

            <div className='modal-body'>
              <fieldset className={nameGroupClasses}>
                <label>
                  <FormattedMessage
                    id='folder-name'
                    description='Folder name'
                    defaultMessage='Tuoteryhmän nimi'
                  />
                </label>

                <input
                  type='text'
                  ref={(input) => {
                    this.name = input;
                  }}
                  className={nameFieldClasses}
                  onChange={(event) =>
                    this.setState({ name: event.target.value })
                  }
                />

                <small className='text-danger'>
                  {this.state.nameError && (
                    <FormattedMessage
                      id='folder-name-error'
                      description='Folder name error'
                      defaultMessage='Tuoteryhmän nimi ei voi olla tyhjä'
                    />
                  )}
                </small>
              </fieldset>
            </div>

            <div className='modal-footer text-center'>
              <input
                type='button'
                className='btn btn-warning'
                data-dismiss='modal'
                value={this.props.intl.formatMessage(messages.cancel)}
              />

              <input
                type='submit'
                className='btn btn-success'
                disabled={!name}
                value={this.props.intl.formatMessage(messages.create)}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default injectIntl(AddFolderModal);
