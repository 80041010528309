/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import path from 'path';

import { FormattedMessage } from 'react-intl';

import {
  getProductFilename,
  getFileTypePath,
  fileSorter,
  getRecyclabilityIcon,
} from '../../utils/fileUtil';
import {
  getProductData,
  getSafetyCodes,
  gwpOptions,
} from '../../utils/productUtils';
import { sortPackageTree } from '../../utils/packageUtils';
import { ProductType } from '../../utils/enum';

class ProductBrowsePanel extends React.Component {
  static propTypes = {
    packages: PropTypes.object,
    product: PropTypes.object,
    productLoading: PropTypes.bool.isRequired,
    globalSearch: PropTypes.array,
    packageActionCreators: PropTypes.object.isRequired,
    productActionCreators: PropTypes.object.isRequired,
    fileActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    safetyData: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      openMenu: false,
    };
  }

  state = {
    openMenu: false,
    selectedValue: '',
  };

  componentWillReceiveProps(props) {
    if (!this.state.openMenu) {
      // Check for mobile view, openMenu is only for mobile
      if (window.matchMedia('screen and (max-width: 34em)').matches) {
        this.setState({
          openMenu: !!props.product,
        });
      }
    }
  }

  componentWillUnmount() {
    this._enablePageScroll();
  }

  _enablePageScroll = () => {
    $('html').removeClass('no-scroll');
  };

  _disablePageScroll = () => {
    $('html').addClass('no-scroll');
  };

  /**
   * Creates a hierarchy of packages for display.
   * Does not return packages the user doesn't have access to.
   * @param {Object[]} nodes - list of package nodes
   * @returns {Object} - hierarchy of packages to display
   */
  treeify = (nodes) => {
    const roots = [];

    Object.keys(nodes).forEach((id) => {
      const node = nodes[id];
      if (!node.has_access) return;

      node.children = [];
      node.num_descendants = 0;
      node.num_descendants_complete = 0;

      let parent = nodes[node.parent];
      if (parent && parent.has_access) {
        parent.children = parent.children || [];
        parent.children.push(node);
      } else {
        roots.push(node);
      }

      while (parent && parent.has_access) {
        parent.num_descendants += 1;
        parent.num_descendants_complete += node.state === 2;
        parent = nodes[parent.parent];
      }
    });

    return sortPackageTree(roots);
  };

  _closeMenu = () => {
    // Checks for mobile view
    if (window.matchMedia('screen and (max-width: 34em)').matches) {
      // HACK: willreceiveprops will run a bit too often, and if product
      //       is set, then the menu will open again... so clear the product.
      this.props.productActionCreators.clearProduct();
      this.setState({
        openMenu: false,
      });
    }
  };

  _addPackageProduct = (event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    this.props.packageActionCreators.addPackageProduct(
      this.state.selectedValue,
      this.props.product
    );

    this.setState({
      selectedValue: '',
    });
  };

  _instaddProduct = (event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    this.props.packageActionCreators.instaddProduct(
      this.state.selectedValue,
      this.props.product
    );

    this.setState({
      selectedValue: '',
    });
  };

  _packageRow = (pkg) => {
    const elements = [];
    const inset =
      pkg.level > 1
        ? new Array(pkg.level * 2).join('\u00A0') +
          new Array(pkg.level).join('-') +
          ' '
        : '';

    elements.push(
      <option key={pkg.id} value={pkg.id}>
        {inset}
        {pkg.name}
      </option>
    );

    elements.push(pkg.children.map(this._packageRow));
    return elements;
  };

  _productStatus = (product) => {
    let content = null;
    if (!product.id) return content;

    if (product.status === 0) {
      content = (
        <FormattedMessage
          id='product-status-planned'
          description='Status text for a planned product'
          defaultMessage='Suunnitelmassa'
        />
      );
    } else if (product.status === 1) {
      content = (
        <FormattedMessage
          id='product-status-installed'
          description='Status text for a installed product'
          defaultMessage='Asennettu'
        />
      );
    }

    return [
      <dt key='product-status' className='col-xs-12 col-sm-5'>
        <FormattedMessage
          id='product-status'
          description='Label text for product status'
          defaultMessage='Tuotteen tila'
        />
        :
      </dt>,
      <dd key='product-status-value' className='col-xs-12 col-sm-7'>
        {content}
      </dd>,
    ];
  };

  _downloadFile = (file, event) => {
    event.preventDefault();
    this.props.fileActionCreators.downloadFile(file);
  };

  _productFiles = (product) => {
    if (!product) return null;

    let content = null;
    if (
      (!product.files || !product.files.length) &&
      (!product.data.files || !product.data.files.length)
    ) {
      content = (
        <small className='text-muted'>
          <FormattedMessage
            id='no-files-placeholder'
            description='Placeholder when there are no files'
            defaultMessage='Ei tiedostoja.'
          />
        </small>
      );
    } else {
      const deduplicate = {};
      content = (
        <div className='ProductBrowsePanel-content-files list-group'>
          {(product.files || []).sort(fileSorter).map((file, index) => {
            // if file.name contains %E4, replace all %E4 with %C3%A4 to fix decoding issue
            const fixedFile = {
              ...file,
              name: file.name.replaceAll('%E4', '%C3%A4'),
            };

            const key = JSON.stringify({
              name: fixedFile.name,
              filename: fixedFile.original_filename,
              type: fixedFile.type,
            });

            if (deduplicate.hasOwnProperty(key)) return null;
            deduplicate[key] = true;

            return (
              <button
                key={key}
                onClick={this._downloadFile.bind(this, fixedFile)}
                className={classNames(
                  'ProductBrowsePanel-content-files-row',
                  'list-group-item'
                )}
              >
                <span>{getProductFilename(product, fixedFile, index)}</span>
                <img src={getFileTypePath(fixedFile.type)} />
              </button>
            );
          })}

          {product.status !== 1 &&
            (product.data.files || []).sort(fileSorter).map((file, index) => {
              // if file.name contains %E4, replace all %E4 with %C3%A4 to fix decoding issue
              const fixedFile = {
                ...file,
                name: file.name.replaceAll('%E4', '%C3%A4'),
              };
              const key = JSON.stringify({
                name: fixedFile.name,
                filename: path.basename(fixedFile.url),
                type: fixedFile.type,
              });

              if (deduplicate.hasOwnProperty(key)) return null;
              deduplicate[key] = true;

              return (
                <button
                  key={key}
                  onClick={() => {
                    if (fixedFile.url) {
                      window.open(fixedFile.url, '_blank').focus();
                    } else {
                      this._downloadFile.bind(this, fixedFile);
                    }
                  }}
                  className={classNames(
                    'ProductBrowsePanel-content-files-row',
                    'list-group-item'
                  )}
                >
                  <span>{getProductFilename(product, fixedFile, index)}</span>
                  <img src={getFileTypePath(fixedFile.type)} />
                </button>
              );
            })}
        </div>
      );
    }

    return <div>{content}</div>;
  };

  _recyclabilityIcon(name) {
    const icon = getRecyclabilityIcon(name);

    return (
      <a
        onClick={(evt) => evt.stopPropagation()}
        data-toggle='tooltip'
        data-placement='bottom'
        title={icon.FI}
      >
        <img src={icon.icon} alt={icon.FI} height='40' />
      </a>
    );
  }

  _productKTT = (product) => {
    if (!product) return null;

    let content = null;
    if (product.data.safety_data) {
      content = (
        <div className='maxpanel'>
          <button className='btn btn-success m-t' onClick={this._downloadKTT}>
            <FormattedMessage
              id='download-ktt-button'
              description='Label for KTT download button'
              defaultMessage='Lataa KTT-tiivistelmä'
            />
          </button>
          <div className='clearfix' />
        </div>
      );
    }

    return content;
  };

  _downloadKTT = (event) => {
    event.preventDefault();
    this.props.fileActionCreators.downloadKTT(this.props.product.data.id);
  };

  _svhcWarning = (product) => {
    const data = getProductData(product);
    if (
      data.variant !== ProductType.CHEMICAL &&
      data.variant !== ProductType.CHEMICAL_PRODUCT
    ) {
      return null;
    }

    if (product.data?.safety_data?.SafetyDataSvhc !== 'YES') {
      return null;
    }

    return (
      <div className='container-fixed alert alert-warning'>
        <div className='row'>
          <div className='col-xs-1'>
            <i className='fa fa-exclamation-triangle'></i>
          </div>

          <div className='col-xs-11'>
            <FormattedMessage
              id='svhc-product-warning'
              description='Warning for svhcs chemical products'
              defaultMessage='Erityistä huolta aiheuttava.'
            />
          </div>
        </div>
      </div>
    );
  };

  _getContent = () => {
    const { product } = this.props;
    const data = getProductData(product);

    const safetyData = this.props.safetyData || {
      hazardCodes: [],
      safetyCodes: [],
      warningCodes: [],
    };

    const productSafetyData = getSafetyCodes(product, safetyData);

    const additionalInfo =
      product.additional_info ?? product.data?.additional_info;

    const hasMaterialRecyclabilityData =
      product.data?.material_recyclability !== undefined ||
      product.data?.material_recyclability_icon !== undefined;

    const hasPackageRecyclabilityData =
      product.data?.package_recyclability !== undefined ||
      product.data?.package_recyclability_icon !== undefined;

    return (
      <div>
        <h4 className='sidepanel-header'>{product.data.name}</h4>

        {this._svhcWarning(product)}

        <dl className='dl-horizontal row'>
          {this._productStatus(product)}

          <dt className='col-xs-12 col-sm-5'>
            <FormattedMessage
              id='product-manufacturer'
              description='Label text for product manufacturer'
              defaultMessage='Yritys'
            />
            :
          </dt>
          <dd className='col-xs-12 col-sm-7'>{product.data.manufacturer}</dd>

          <dt className='col-xs-12 col-sm-5'>
            <FormattedMessage
              id='product-ean'
              description='Label text for product EAN code'
              defaultMessage='GTIN-koodi'
            />
            :
          </dt>
          <dd className='col-xs-12 col-sm-7'>
            {product.data.ean || (
              <FormattedMessage
                id='unknown'
                description='Generic unknown'
                defaultMessage='Ei tiedossa'
              />
            )}
          </dd>

          {/* <dt className='col-xs-12 col-sm-5'>
            <FormattedMessage
              id='product-talo2000'
              description='Label text for product Talo 2000 class'
              defaultMessage='Talo 2000 -tuotenimike'
            />:
          </dt>
          <dd className='col-xs-12 col-sm-7'>
            {product.data.talo2000 ||
              <FormattedMessage
                id='unknown'
                description='Generic unknown'
                defaultMessage='Ei tiedossa'
              />
            }
          </dd> */}

          {product.data.validity_methods &&
            product.data.validity_methods.length !== 0 && [
              <dt className='col-xs-12 col-sm-5' key='validity-methods'>
                <FormattedMessage
                  id='product-validity-methods'
                  description='Label text for product validity methods'
                  defaultMessage='Tuotteen kelpoisuuden todentamisen menetelmät'
                />
                :
              </dt>,
              <dd
                key='validity-methods-value'
                className={
                  'ProductBrowsePanel-content-validity col-xs-12 col-sm-7'
                }
              >
                <ul>
                  {product.data.validity_methods.map((method) => (
                    <li key={method}>{method}</li>
                  ))}
                </ul>
              </dd>,
            ]}

          {!product.data.url
            ? null
            : [
                <dt key='more-information'></dt>,
                <dd
                  className='col-xs-12 col-sm-12'
                  key='more-information-value'
                >
                  <a href={product.data.url} target='_blank'>
                    <i className='fa fa-external-link' />
                    &nbsp;
                    <FormattedMessage
                      id='product-more-information'
                      description='More information link for products'
                      defaultMessage='Tarkemmat tuotetiedot'
                    />
                  </a>
                </dd>,
              ]}

          {additionalInfo
            ? additionalInfo.map((link) => (
                <>
                  <dt />
                  <dd className='col-xs-12 col-sm-12'>
                    <a href={link.url} target='_blank'>
                      <i className='fa fa-external-link' />
                      &nbsp;
                      <FormattedMessage
                        id='product-more-information'
                        description='More information link for products'
                        defaultMessage={link.description}
                      />
                    </a>
                  </dd>
                </>
              ))
            : null}
        </dl>

        <div>
          <div>
            <hr />
            <h4 className='m-b'>
              <FormattedMessage
                id='files-header'
                description='Header text for product files'
                defaultMessage='Tiedostot'
              />
            </h4>

            {this._productFiles(product)}
            {this._productKTT(product)}
          </div>

          {(hasMaterialRecyclabilityData || hasPackageRecyclabilityData) && (
            <>
              <hr />
              <h4 className='m-b'>
                <FormattedMessage
                  id='recyclability-header'
                  description='Header text for recyclability'
                  defaultMessage='Kierrätys'
                />
              </h4>

              {hasMaterialRecyclabilityData && (
                <>
                  <p>
                    <strong>Materiaali</strong>
                  </p>

                  {product.data.material_recyclability_icon && (
                    <div className='recyclability-icons'>
                      {product.data.material_recyclability_icon
                        .split(',')
                        .map((name) => this._recyclabilityIcon(name))}
                    </div>
                  )}

                  <p>{product.data.material_recyclability}</p>
                </>
              )}

              {hasPackageRecyclabilityData && (
                <>
                  <p>
                    <strong>Pakkaus</strong>
                  </p>

                  {product.data.package_recyclability_icon && (
                    <div className='recyclability-icons'>
                      {product.data.package_recyclability_icon
                        .split(',')
                        .map((name) => this._recyclabilityIcon(name))}
                    </div>
                  )}

                  <p>{product.data.package_recyclability}</p>
                </>
              )}
            </>
          )}

          {(data.variant === ProductType.CHEMICAL ||
            data.variant === ProductType.CHEMICAL_PRODUCT) && (
            <div>
              <hr />
              <h4 className='m-b'>
                <FormattedMessage
                  id='safety-codes-header'
                  description='Header text for safety codes'
                  defaultMessage='Kemikaalitiedot'
                />
              </h4>

              <FormattedMessage
                id='hazard-codes'
                description='Multiselect hazard codes'
                defaultMessage='Vaaralauseke (yksi tai useampi)'
              />
              <Select
                className='m-t m-b maxpanel'
                isMulti
                isDisabled
                value={productSafetyData.hazardCodes}
                onChange={() => {}}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                noOptionsMessage={() => 'None found'}
                placeholder={'Vaaralauseke...'}
                options={safetyData.hazardCodes}
              />

              <FormattedMessage
                id='safety-codes'
                description='Multiselect safety codes'
                defaultMessage='Turvalausekkeet'
              />
              <Select
                className='m-t m-b maxpanel'
                isMulti
                isDisabled
                value={productSafetyData.safetyCodes}
                onChange={() => {}}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                noOptionsMessage={() => 'None found'}
                placeholder={'Turvalauseke...'}
                options={safetyData.safetyCodes}
              />

              <FormattedMessage
                id='warning-codes'
                description='Multiselect warning codes'
                defaultMessage='Varoitusmerkki (yksi tai useampi)'
              />
              <Select
                className='m-t m-b maxpanel'
                isMulti
                isDisabled
                value={productSafetyData.warningCodes}
                onChange={() => {}}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                noOptionsMessage={() => 'None found'}
                placeholder={'Varoitusmerkki...'}
                options={safetyData.warningCodes}
              />
            </div>
          )}

          {!!product.data.gwp_value_a1a3 && (
            <div>
              <hr />
              <h4 className='m-b'>
                <FormattedMessage
                  id='carbon-footprint-header'
                  description='Header text for carbon footprint info'
                  defaultMessage='Hiilijalanjälki'
                />
              </h4>
              <FormattedMessage
                id='gwp-a1a3'
                description='gwp-a1a3'
                defaultMessage='GWP A1-A3'
              />
              <input
                style={{ display: 'block' }}
                className='m-t m-b'
                type='text'
                disabled={true}
                value={product.data.gwp_value_a1a3}
              />
              {!!product.data.gwp_value_a1a3 && (
                <div>
                  <FormattedMessage
                    id='gwp-unit'
                    description='gwp-unit'
                    defaultMessage='Yksikkö'
                  />
                  <a
                    className='m-l'
                    onClick={(evt) => evt.stopPropagation()}
                    data-toggle='tooltip'
                    data-placement='bottom'
                    title={'Tarkista yksikön selite EPD-dokumentista.'}
                  >
                    <i className='fa fa-info-circle' />
                  </a>
                  <Select
                    className='m-t m-b maxpanel'
                    isDisabled
                    value={gwpOptions.gwpUnitOptions.filter(
                      (o) => o.value === product.data.gwp_unit
                    )}
                    onChange={() => {}}
                    backspaceRemovesValue={false}
                    noOptionsMessage={() => 'None found'}
                    placeholder={'Yksikkö...'}
                    options={gwpOptions.gwpUnitOptions}
                  />
                  <FormattedMessage
                    id='gwp-verification'
                    description='gwp-verification'
                    defaultMessage='3. osapuolen verifiointi'
                  />
                  <Select
                    className='m-t m-b maxpanel'
                    isDisabled
                    value={gwpOptions.gwpVerificationOptions.filter(
                      (o) => o.value === product.data.gwp_verification
                    )}
                    onChange={() => {}}
                    backspaceRemovesValue={false}
                    noOptionsMessage={() => 'None found'}
                    placeholder={'Verifikaatio...'}
                    options={gwpOptions.gwpVerificationOptions}
                  />
                  <FormattedMessage
                    id='gwp-standard'
                    description='gwp-standard'
                    defaultMessage='Standardi'
                  />
                  <Select
                    className='m-t m-b maxpanel'
                    isDisabled
                    value={gwpOptions.gwpStandardOptions.filter(
                      (o) => o.value === product.data.gwp_standard
                    )}
                    onChange={() => {}}
                    backspaceRemovesValue={false}
                    noOptionsMessage={() => 'None found'}
                    placeholder={'Standardi...'}
                    options={gwpOptions.gwpStandardOptions}
                  />
                </div>
              )}
            </div>
          )}

          <div>
            <hr />
            <select
              defaultValue={''}
              value={this.state.selectedValue}
              className='form-control m-t maxpanel'
              onChange={(e) => {
                this.setState({
                  selectedValue: e.currentTarget.value,
                });
              }}
            >
              <option value='' disabled>
                Valitse urakka
              </option>
              {this.treeify(this.props.packages).map(this._packageRow)}
            </select>

            <button
              className='btn btn-warning m-t'
              onClick={this._addPackageProduct}
              disabled={!this.state.selectedValue}
            >
              <FormattedMessage
                id='add-product-button'
                description='Button text for adding a product to a package'
                defaultMessage='Lisää suunnitelmaan'
              />
            </button>

            <button
              className='btn btn-success m-t'
              onClick={this._instaddProduct}
              disabled={!this.state.selectedValue}
            >
              <FormattedMessage
                id='instadd-product-button'
                description='Button text for installing and adding a product'
                defaultMessage='Merkitse suoraan asennetuksi'
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (this.state.openMenu) {
      this._disablePageScroll();
    } else {
      this._enablePageScroll();
    }

    let content = null;
    const classes = classNames(
      'ProductBrowsePanel-content',
      'stretch-vert',
      'sidepanel',
      {
        open: !!this.props.product && this.state.openMenu,
      }
    );

    const backdropClasses = classNames('ProductBrowsePanel-backdrop', {
      'hidden-sm-up': this.state.openMenu,
      'hidden-xs-up': !this.state.openMenu,
    });

    if (this.props.productLoading) {
      content = (
        <div className='loading loading-green loading-center m-t-md'>
          <div></div>
        </div>
      );
    } else if (!this.props.product) {
      content = (
        <p className='text-muted padding'>
          <FormattedMessage
            id='product-information-panel-placeholder'
            description='Placeholder for product information panel'
            defaultMessage={'Klikkaa tuotetta nähdäksesi sen tiedot.'}
          />
        </p>
      );
    } else {
      content = this._getContent();
    }
    return (
      <div className='ProductBrowsePanel col-xs-12 col-sm-4'>
        <div onClick={this._closeMenu} className={backdropClasses} />

        <div className={classes}>
          <div className='ProductBrowsePanel-content-menu'>
            <div
              className={classNames(
                'sidepanel-close-icon',
                'hidden-sm-up',
                'pull-left'
              )}
            >
              <i
                className='fa fa-times pull-left'
                onClick={this._closeMenu}
              ></i>
            </div>

            {content}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductBrowsePanel;
