/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Messages from '../Messages/Messages';
import Pagination from '../Pagination/Pagination';
import ManualProductPanel from '../ManualProductPanel/ManualProductPanel.tsx';
import FavouriteIcon from '../FavouriteIcon/FavouriteIcon';

import { getFileTypePath } from '../../utils/fileUtil';

import classNames from 'classnames';

import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import CompanyFolderTree from '../CompanyFolderTree/CompanyFolderTree';
import AddProductModal from '../AddProductModal/AddProductModal';
import ProductDragSource from '../CompanyFolderTree/ProductDragSource';
import AddFolderModal from './AddFolderModal';
import ProductBrowsePanel from '../ProductBrowsePanel/ProductBrowsePanel';
import ImportProductsModal from '../ImportProductsModal/ImportProductsModal';

const messages = defineMessages({
  searchPlaceholder: {
    id: 'search-placeholder',
    description: 'Placeholder when searching products',
    defaultMessage:
      'Syötä tuotteen GTIN, Talo 2000 -tuotenimike, rakennustuotenumero, ' +
      'sähkönumero, LVI-numero, nimi tai yritys',
  },

  searchButton: {
    id: 'search-button',
    description: 'Search button text',
    defaultMessage: 'Hae',
  },

  emptyName: {
    id: 'product-manual-edit-no-name',
    description: 'Error when empty name while editing manual product',
    defaultMessage: 'Tuotteen nimi ei voi olla tyhjä',
  },

  emptyManufacturer: {
    id: 'product-manual-edit-no-manufacturer',
    description: 'Error when empty manufacturer while editing manual product',
    defaultMessage: 'Tuotteen valmistaja ei voi olla tyhjä',
  },

  noFileNameError: {
    id: 'file-no-name',
    description: 'Error when empty name when adding a file',
    defaultMessage: 'Tiedoston nimi ei voi olla tyhjä',
  },

  noFileTypeError: {
    id: 'file-no-type',
    description: 'Error when empty type when adding a file',
    defaultMessage: 'Valitse tiedoston tyyppi',
  },

  fileTypeDOP: {
    id: 'filetype-dop',
    description: 'File type for DOP',
    defaultMessage: 'DOP - Suoritustasoilmoitus',
  },

  fileTypeKTT: {
    id: 'filetype-ktt',
    description: 'File type for KTT',
    defaultMessage: 'KTT - Käyttöturvallisuustiedote',
  },

  fileTypeINSTR: {
    id: 'filetype-instr',
    description: 'File type for INSTR',
    defaultMessage: 'Ohje',
  },

  fileTypeATT: {
    id: 'filetype-att',
    description: 'File type for ATT',
    defaultMessage: 'Muu liite',
  },

  fileTypeEPD: {
    id: 'filetype-epd',
    description: 'File type for EPD',
    defaultMessage: 'EPD - Ympäristöseloste',
  },

  fileTypeM1: {
    id: 'filetype-m1',
    description: 'File type for M1',
    defaultMessage: 'M1 -Päästöluokitus',
  },

  fileTypeCERT: {
    id: 'filetype-cert',
    description: 'File type for CERT',
    defaultMessage: 'CERT - Tuotteen sertifikaatti',
  },
});

class ManualProductsView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    productStore: PropTypes.object.isRequired,
    companyStore: PropTypes.object.isRequired,
    rtActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    productActionCreators: PropTypes.object.isRequired,
    companyActionCreators: PropTypes.object.isRequired,
    fileActionCreators: PropTypes.object.isRequired,
    favouritesActionCreators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const stores = this._getStoreData();

    this.state = {
      page: 1,
      query: '',
      includeGlobal: false,
      activeProduct: null,
      file: null,
      addedFiles: [],
      ...stores,
    };
  }

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );

    this._productStoreSubscription = this.props.productStore.addListener(
      this._onStoreChange
    );

    this._companyStoreSubscription = this.props.companyStore.addListener(
      this._onStoreChange
    );

    if (
      this.state.productState.globalSearch &&
      this.state.productState.globalSearch.length
    ) {
      this.props.productActionCreators.clearGlobalProductSearch();
    }

    const { query, page, includeGlobal } = this.state;
    this.props.productActionCreators.searchGlobalProducts(
      -1,
      query,
      null,
      page,
      false,
      false,
      !includeGlobal
    );

    this.props.companyActionCreators.getCompanyFolders();
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
    this._productStoreSubscription.remove();
    this._companyStoreSubscription.remove();
  }

  _onStoreChange = () => {
    this.setState(this._getStoreData());
  };

  _getStoreData = () => {
    return {
      packageState: this.props.packageStore.getState(),
      productState: this.props.productStore.getState(),
      rtState: this.props.rtStore.getState(),
      companyState: this.props.companyStore.getState(),
    };
  };

  _handleSearch = (event) => {
    event.preventDefault();

    const { query, includeGlobal } = this.state;

    this.props.productActionCreators.searchGlobalProducts(
      -1,
      query,
      null,
      1,
      false,
      false,
      !includeGlobal
    );

    this.setState({
      page: 1,
    });
  };

  _navigate = (page) => {
    this.setState({
      page,
    });

    this.props.productActionCreators.searchGlobalProducts(
      -1,
      this.state.query,
      null,
      page,
      false,
      false,
      !this.state.includeGlobal
    );
  };

  _productRow = (product) => {
    if (!product.data.from_company && product.data.manual) return null;
    // Differentiate construction sites in the list
    const deleted = product.data.deleted;
    const productClasses = classNames(
      'ManualProductsView-row',
      'ManualProductsView-content-list-item',
      'row-flex',
      'align-vert',
      {
        active: this.state.activeProduct === product.data.id,
        planned: product.status === 0,
        installed: product.status === 1,
        deleted: deleted,
      }
    );
    const productContentClasses = classNames('ManualProductsView-row-content', {
      deleted: deleted,
    });
    const productContentIdentifierClasses = classNames(
      'ManualProductsView-row-content---identifier',
      {
        deleted: deleted,
      }
    );

    const fileTypes = product.file_types
      ? product.file_types.filter((type) => {
          return type.length > 0;
        })
      : [];
    const filePaths = [
      ...new Set(fileTypes.map((type) => getFileTypePath(type))),
    ];
    return (
      <ProductDragSource
        key={product.data.id}
        product={product}
        onDrop={(target) => {
          if (target.id === -1) return;
          this.props.companyActionCreators.addProductToFolder(target.id, {
            product_id: product.data.id,
            manual: product.data.manual,
          });
        }}
        canDrag
      >
        <div
          className='col-xs-12 no-padding'
          key={product.data.id}
          onClick={this.openPanel.bind(this, product)}
        >
          <li className={productClasses}>
            <div className={productContentClasses}>
              <span>{product.data.name}</span>
              <FavouriteIcon
                productData={product.data}
                favouritesActionCreators={this.props.favouritesActionCreators}
              />

              <div className='ManualProductsView-row-content-right'>
                {filePaths.map((path) => {
                  return (
                    <span
                      key={path}
                      className='ManualProductsView-row-content-filetype'
                    >
                      <img src={path} />
                    </span>
                  );
                })}
              </div>

              <div className={productContentIdentifierClasses}>
                {product.data.talo2000
                  ? `${product.data.talo2000} :: ${product.data.manufacturer}`
                  : product.data.manufacturer}
              </div>
            </div>
          </li>
        </div>
      </ProductDragSource>
    );
  };

  openPanel = (product, event) => {
    event.preventDefault();

    this.setState({
      activeProduct: product.data.id,
    });

    this.props.productActionCreators.getSearchProduct(-1, product);
  };

  _getSearch = () => {
    if (this.state.productState.globalSearchLoading) {
      return (
        <div className='loading loading-green loading-center m-t-md'>
          <div></div>
        </div>
      );
    } else if (!this.state.productState.globalSearch) {
      return null;
    } else if (!this.state.productState.globalSearch.length) {
      return (
        <p className='text-muted m-l'>
          <FormattedMessage
            id='no-products-found'
            description='No products found when searching'
            defaultMessage='Ei tuloksia'
          />
        </p>
      );
    }

    // Get search results and sort so that deleted products are listed last
    const searchResults = this.state.productState.globalSearch;
    return (
      <ul className='ManualProductsView-content-list'>
        {searchResults.map(this._productRow)}
      </ul>
    );
  };

  addProduct = (data, files) => {
    const productData = { ...data, from_company: true };
    this.props.productActionCreators
      .addManualProduct(productData)
      .then((product) => {
        this.props.fileActionCreators.addManualFiles(product.data.id, files);
      });
  };

  _getContent = () => {
    const rtState = this.state.rtState;
    const packageState = this.state.packageState;
    const productCount =
      !this.state.productState.globalSearchLoading &&
      this.state.productState.globalSearch &&
      this.state.productState.globalSearch.length
        ? this.state.productState.globalSearch[0].count
        : 0;

    return (
      <div className='container-fluid'>
        <Header
          user={rtState.user}
          packages={packageState.packageSearch}
          searchLoading={packageState.packageSearchLoading}
          packageActionCreators={this.props.packageActionCreators}
        />

        <Messages
          messageStore={this.props.messageStore}
          messageActionCreators={this.props.messageActionCreators}
        />

        <AddProductModal
          title='Luo uusi tuote yrityksen tuotelistaan'
          safetyData={this.state.productState.safetyData.fi}
          addProduct={this.addProduct}
        />

        <ImportProductsModal
          title='Lisää tuotteita tiedostosta'
          packageActionCreators={this.props.packageActionCreators}
          fileActionCreators={this.props.fileActionCreators}
          validateImportProductsLoading={
            this.state.packageState.validateImportProductsLoading
          }
          importProducts={this.state.packageState.importProducts}
          packageId={-1}
        />

        <AddFolderModal
          addFolder={(name) => {
            this.props.companyActionCreators.addFolder(name);
          }}
        />

        <div className='ManualProductsView-content row'>
          <div className='ManualProductsView-content--left col-xs-12 col-sm-8'>
            <div className='ManualProductsView-content--left-content'>
              <h2 className='pull-left m-r'>
                <FormattedMessage
                  id='manual-products-header'
                  description='Header for the product browse page'
                  defaultMessage='Yrityksen tuotteet'
                />
              </h2>

              <button
                className='btn btn-success btn-sm pull-left m-r'
                data-toggle='modal'
                data-target='#addProductModal'
              >
                <FormattedMessage
                  id='product-add'
                  description='Label for button to add product'
                  defaultMessage='Lisää tuote'
                />
              </button>

              <button
                className='btn btn-success btn-sm pull-left m-r'
                data-toggle='modal'
                data-target='#importProductsModal'
              >
                <FormattedMessage
                  id='product-add'
                  description='Label for button to add product'
                  defaultMessage='Tuo tiedostosta'
                />
              </button>

              <button
                className='btn btn-success btn-sm pull-left m-r'
                data-toggle='modal'
                data-target='#addFolderModal'
              >
                <FormattedMessage
                  id='folder-add'
                  description='Label for button to add folder'
                  defaultMessage='Lisää tuoteryhmä'
                />
              </button>
              <div className='clearfix' />

              <div className='m-t-md'>
                <CompanyFolderTree
                  folders={this.state.companyState.folders}
                  onProductClick={this.openPanel}
                  companyActionCreators={this.props.companyActionCreators}
                  editable
                  chemicalProducts={this.state.companyState.chemicalProducts}
                />
              </div>

              <hr className='m-t-0' />

              <form className='m-t' onSubmit={this._handleSearch}>
                <fieldset className='form-group'>
                  <div className='ManualProductsView-controls'>
                    <input
                      type='text'
                      className='ManualProductsView-input form-control'
                      value={this.state.query}
                      onChange={(evt) =>
                        this.setState({ query: evt.target.value })
                      }
                      placeholder={this.props.intl.formatMessage(
                        messages.searchPlaceholder
                      )}
                    />

                    <input
                      type='submit'
                      className='ManualProductsView-submit btn btn-success'
                      value={this.props.intl.formatMessage(
                        messages.searchButton
                      )}
                    />
                  </div>
                </fieldset>
              </form>

              <div className='checkbox'>
                <label>
                  <input
                    type='checkbox'
                    checked={this.state.includeGlobal}
                    onChange={(evt) =>
                      this.setState(
                        {
                          includeGlobal: evt.target.checked,
                        },
                        () => this.state.query && this._handleSearch(evt)
                      )
                    }
                  />
                  <FormattedMessage
                    id='show-all-products'
                    description='Show all products'
                    defaultMessage='Sisällytä hakuun tuotetietokannan tuotteet'
                  />
                </label>
              </div>

              <div className='ManualProductsView-content'>
                {this._getSearch()}
              </div>

              <Pagination
                page={this.state.page}
                count={productCount}
                perPage={20}
                handler={this._navigate}
              />
            </div>
            <Footer />
          </div>

          {this.state.productState.product &&
          this.state.productState.product.data.manual ? (
            <ManualProductPanel
              key={`${this.state.productState.product.data.id}`}
              packages={this.state.packageState.packages}
              product={this.state.productState.product}
              productLoading={this.state.productState.productLoading}
              globalSearch={this.state.productState.globalSearch}
              fileActionCreators={this.props.fileActionCreators}
              packageActionCreators={this.props.packageActionCreators}
              productActionCreators={this.props.productActionCreators}
              messageActionCreators={this.props.messageActionCreators}
              safetyData={this.state.productState.safetyData.fi}
            />
          ) : (
            <ProductBrowsePanel
              packages={this.state.packageState.packages}
              product={this.state.productState.product}
              productLoading={this.state.productState.productLoading}
              globalSearch={this.state.productState.globalSearch}
              packageActionCreators={this.props.packageActionCreators}
              productActionCreators={this.props.productActionCreators}
              fileActionCreators={this.props.fileActionCreators}
              messageActionCreators={this.props.messageActionCreators}
              safetyData={this.state.productState.safetyData.fi}
            />
          )}
        </div>
      </div>
    );
  };

  render() {
    let content = null;

    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null ||
      this.state.productState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return <div className='ManualProductsView'>{content}</div>;
  }
}

export default AuthenticatedView(injectIntl(ManualProductsView));
