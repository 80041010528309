import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  cancel: {
    id: 'delete-folder-modal-cancel',
    description: 'Cancel delete folder modal button',
    defaultMessage: 'Peruuta',
  },
  create: {
    id: 'delete-folder-modal-create',
    description: 'Delete folder modal button',
    defaultMessage: 'Poista',
  },
});

class DeleteFolderModal extends React.Component {
  static propTypes = {
    folder: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
  };

  constructor(props) {
    super(props);

    this.deleteFolderModal = null;
  }

  _onConfirm = (event) => {
    event.preventDefault();
    this.props.onConfirm();
    $(this.deleteFolderModal).modal('hide');
  };

  render() {
    if (!this.props.folder) return null;

    return (
      <div
        className='modal fade'
        ref={(div) => {
          this.deleteFolderModal = div;
        }}
        id='deleteFolderModal'
        tabIndex='-1'
        data-backdrop='static'
      >
        <div className='modal-dialog'>
          <form className='modal-content' onSubmit={this._onConfirm}>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                <span>&times;</span>
              </button>
              <h4 className='modal-title'>
                <FormattedMessage
                  id='delete-folder-modal-title'
                  description='Delete folder modal title'
                  defaultMessage='Poista tuoteryhmä'
                />
              </h4>
            </div>

            <div className='modal-body'>
              <FormattedMessage
                id='delete-folder-modal-content'
                description='Delete folder modal content'
                defaultMessage='Haluatko varmasti poistaa tuoteryhmän "{name}"?'
                values={{ name: this.props.folder.name }}
              />
            </div>

            <div className='modal-footer text-center'>
              <input
                type='button'
                className='btn btn-warning'
                data-dismiss='modal'
                value={this.props.intl.formatMessage(messages.cancel)}
              />

              <input
                type='submit'
                className='btn btn-danger'
                value={this.props.intl.formatMessage(messages.create)}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default injectIntl(DeleteFolderModal);
