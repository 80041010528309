/**
 * BulletinsView.js - React component base
 *
 * Copyright 2015-2016 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Messages from '../Messages/Messages';

import classNames from 'classnames';

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class BulletinEditView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    bulletinStore: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    bulletinActionCreators: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    const storeData = this._getStoreData();
    storeData.selected = {
      title: '',
      validfrom: new Date().toJSON().slice(0, 10),
      content: '',
      isNew: true,
    };
    this.state = storeData;
  }

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );

    this._bulletinStoreSubscription = this.props.bulletinStore.addListener(
      this._onStoreChange
    );
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
    this._bulletinStoreSubscription.remove();
  }

  _onStoreChange = () => {
    this.setState(this._getStoreData());
  };

  _getStoreData = () => {
    return {
      packageState: this.props.packageStore.getState(),
      rtState: this.props.rtStore.getState(),
      bulletinState: this.props.bulletinStore.getState(),
      errors: {},
    };
  };

  _handleRelevantChange = (event) => {
    const updated = this.state.selected;
    updated.relevant = event.target.checked;
    this.setState({ selected: updated });
  };

  _handleTitleChange = (event) => {
    const updated = this.state.selected;
    updated.title = event.target.value;
    this.setState({ selected: updated });
  };

  _handleValidFromChange = (event) => {
    const updated = this.state.selected;
    updated.validfrom = event.target.value;
    this.setState({ selected: updated });
  };

  _handleValidToChange = (event) => {
    const updated = this.state.selected;
    updated.validto = event.target.value;
    this.setState({ selected: updated });
  };

  _handleContentChange = (event) => {
    const updated = this.state.selected;
    updated.content = event.target.value;
    this.setState({ selected: updated });
  };

  _saveButton = () => {
    if (this.state.selected.isNew === false) {
      this.props.bulletinActionCreators.saveBulletin(this.state.selected);
    } else {
      this.props.bulletinActionCreators.createBulletin(this.state.selected);
      this._newButton();
    }
  };

  _deleteButton = () => {
    this.props.bulletinActionCreators.removeBulletin(this.state.selected.id);
    this._newButton();
  };

  _newButton = () => {
    this.setState({
      selected: {
        title: '',
        validfrom: new Date().toJSON().slice(0, 10),
        content: '',
        isNew: true,
      },
    });
  };

  _editSelected = () => {
    return (
      <div>
        <form>
          <div className='checkbox'>
            <label>
              <input
                type='checkbox'
                checked={this.state.selected.relevant}
                onChange={this._handleRelevantChange}
              />
              <FormattedMessage
                id='relevant'
                description='Relevant'
                defaultMessage='Näytetään etusivulla'
              />
            </label>
          </div>

          <div className='form-group'>
            <label htmlFor='title'>
              <FormattedMessage
                id='title'
                description='Title'
                defaultMessage='Otsikko'
              />
            </label>
            <input
              className='form-control'
              id='title'
              placeholder='Tiedotteen otsikko'
              value={this.state.selected.title}
              onChange={this._handleTitleChange}
            />

            <label htmlFor='valid'>
              <FormattedMessage
                id='pediod'
                description='Validity period'
                defaultMessage='Voimassa (alkupäivä pakollinen)'
              />
            </label>
            <div className='input-group'>
              <input
                type='date'
                className='form-control'
                id='validfrom'
                value={this.state.selected.validfrom}
                onChange={this._handleValidFromChange}
              />
              <div className='input-group-addon'>
                <i className='fa fa-calendar fa-2x' aria-hidden='true'></i>
              </div>
              <input
                type='date'
                className='form-control'
                id='validto'
                value={this.state.selected.validto}
                onChange={this._handleValidToChange}
              />
            </div>

            <label htmlFor='content'>
              <FormattedMessage
                id='content'
                description='Content'
                defaultMessage='Teksti (HTML sallittu)'
              />
            </label>
            <textarea
              className='form-control'
              rows='5'
              name='content'
              placeholder='Tiedotteen sisältö'
              value={this.state.selected.content}
              onChange={this._handleContentChange}
            />
          </div>

          <a href='#' className='btn btn-primary' onClick={this._saveButton}>
            <FormattedMessage
              id='save'
              description='Save button'
              defaultMessage='Tallenna'
            />
          </a>
          {this.state.selected.isNew === false ? (
            <a href='#' className='btn btn-danger' onClick={this._deleteButton}>
              <FormattedMessage
                id='delete'
                description='Delete button'
                defaultMessage='Poista'
              />
            </a>
          ) : (
            <div />
          )}
        </form>
      </div>
    );
  };

  _previewSelected = () => {
    return (
      <div>
        <br />
        <h4>
          <FormattedMessage
            id='preview'
            description='Preview'
            defaultMessage='Esikatselu'
          />
        </h4>

        <ul className='BulletinsList list-group'>
          <li className='list-group-item'>
            <div>
              <FormattedHTMLMessage
                id='esikatselu'
                description='Bulletin text content, may contain HTML'
                defaultMessage={this.state.selected.content}
              />
            </div>
          </li>
        </ul>
      </div>
    );
  };

  _select = (bid) => {
    this.state.bulletinState.bulletins.forEach(function select(bulletin) {
      if (bulletin.id === bid) {
        bulletin.isNew = false;
        bulletin.selected = true;
        const copy = jQuery.extend(true, {}, bulletin);
        this.setState({ selected: copy });
      } else {
        bulletin.selected = false;
      }
    }, this);
  };

  _bulletinRow = (bulletin) => {
    const bclassNames = classNames({
      'list-group-item': true,
      'bg-success': bulletin.selected,
    });

    return (
      <li
        className={bclassNames}
        key={bulletin.id}
        onClick={this._select.bind(this, bulletin.id)}
      >
        <h5>{bulletin.title}</h5>
        <small>
          {bulletin.validfrom.slice(0, 10)}&mdash;
          {bulletin.validto ? bulletin.validto.slice(0, 10) : ''}
        </small>
      </li>
    );
  };

  _editBulletins = () => {
    if (!this.state.bulletinState.bulletins) {
      return (
        <div className='loading loading-green loading-center m-t-md'>
          <div></div>
        </div>
      );
    }

    return (
      <div className='row'>
        <ul className='BulletinsList col-md-4 list-group'>
          {this.state.bulletinState.bulletins.map(this._bulletinRow)}
          <li className='list-group-item'>
            <a href='#' className='btn btn-primary' onClick={this._newButton}>
              Luo uusi
            </a>
          </li>
        </ul>
        <div className='BulletinEdit col-md-8'>
          {this._editSelected()}
          {this._previewSelected()}
        </div>
      </div>
    );
  };

  _getContent = () => {
    return (
      <div className='container-fluid'>
        <Header
          user={this.state.rtState.user}
          packages={this.state.packageState.packageSearch}
          searchLoading={this.state.packageState.packageSearchLoading}
          packageActionCreators={this.props.packageActionCreators}
        />

        <Messages
          messageStore={this.props.messageStore}
          messageActionCreators={this.props.messageActionCreators}
        />

        <div className='BulletinEditView-content row'>
          <div className='col-xs-12 col-sm-10 col-sm-offset-1'>
            <h2>
              <FormattedMessage
                id='edit-bulletins'
                description='Edit bulletins header'
                defaultMessage='Hallitse tiedotteita'
              />
            </h2>
          </div>

          <div className='col-xs-12 col-sm-10 col-sm-offset-1'>
            {this._editBulletins()}
          </div>
        </div>
      </div>
    );
  };

  render() {
    let content = null;

    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null ||
      this.state.bulletinState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='BulletinEditView'>
        {content}
        <Footer />
      </div>
    );
  }
}

export default AuthenticatedView(BulletinEditView);
