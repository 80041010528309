/**
 * ApiUtils.js - HTTP request utilities, mostly just adds console logging to
 *               requests (which is not completely necessary either when we
 *               have the network tab in the browser)
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import popsicle from 'popsicle';
import page from 'page';

import CommonActionCreators from '../actions/CommonActionCreators.js';

class ApiUtils {
  constructor(apiBaseUrl) {
    this._apiBaseUrl = apiBaseUrl;
  }

  get(path) {
    const promise = popsicle.get(
      this._addAuthorization({
        url: `${this._apiBaseUrl}${path}`,
      })
    );

    return this._addDebugging(promise).then(this._checkAuthorization);
  }

  getBinary(path) {
    const promise = popsicle.get(
      this._addAuthorization({
        url: `${this._apiBaseUrl}${path}`,
        options: {
          responseType: 'blob',
        },
      })
    );

    return this._addDebugging(promise).then(this._checkAuthorization);
  }

  post(path, data) {
    const promise = popsicle.post(
      this._addAuthorization({
        url: `${this._apiBaseUrl}${path}`,
        body: data,
      })
    );

    return this._addDebugging(promise).then(this._checkAuthorization);
  }

  put(path, data) {
    const promise = popsicle.put(
      this._addAuthorization({
        url: `${this._apiBaseUrl}${path}`,
        body: data,
      })
    );

    return this._addDebugging(promise).then(this._checkAuthorization);
  }

  patch(path, data) {
    const promise = popsicle.patch(
      this._addAuthorization({
        url: `${this._apiBaseUrl}${path}`,
        body: data,
      })
    );

    return this._addDebugging(promise).then(this._checkAuthorization);
  }

  delete(path) {
    const promise = popsicle.delete(
      this._addAuthorization({
        url: `${this._apiBaseUrl}${path}`,
      })
    );

    return this._addDebugging(promise).then(this._checkAuthorization);
  }

  _checkAuthorization(response) {
    const commonActionCreators = new CommonActionCreators(null, null);

    if (
      commonActionCreators._isAuthorizationError(response.body) &&
      !page.current.match('^/login') // Prevent redirection loop
    ) {
      localStorage.setItem('login-redirect-path', page.current);
      page.redirect('/login');
    }

    return commonActionCreators._isSuccess(response.status)
      ? Promise.resolve(response)
      : Promise.reject(response.body);
  }

  _addAuthorization(request) {
    const token = localStorage.getItem('jwt-token');
    if (!token) return request;

    if (!request.headers) request.headers = {};
    request.headers.authorization = `Bearer ${token}`;

    return request;
  }

  _addDebugging(promise) {
    if (process.env.DEBUG) {
      promise.use((request) => {
        console.log('REQUEST', request);
      });

      return promise.then((response) => {
        console.log('RESPONSE', response);
        return response;
      });
    }

    return promise;
  }
}

export default ApiUtils;
