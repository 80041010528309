/* eslint-disable max-len */
// NOTE: no translations, not final list
const constructionPhases = [
  {
    name: 'Maa- ja pohjarakenteet',
    children: [
      {
        name: 'Kalliolujituksen pultit',
        children: [],
      },
      {
        name: 'Kalliolujituksen, kallioinjektoinnin ja ruiskubetonoinnin sementit',
        children: [],
      },
      {
        name: 'Betonin, injektointilaastin ja ruiskubetonin lisäaineet',
        children: [],
      },
      {
        name: 'Maa- ja kallioankkurit',
        children: [],
      },
      {
        name: 'Geotekstiilit',
        children: [],
      },
      {
        name: 'Kiviainekset',
        children: [],
      },
    ],
  },
  {
    name: 'Perustukset ja alapohjat',
    children: [
      {
        name: 'Teräsbetonipaalut',
        children: [],
      },
      {
        name: 'Teräspaalut',
        children: [],
      },
      {
        name: 'Teräspaalun paalujatkokset ja -kärjet',
        children: [],
      },
      {
        name: 'Valmisbetoni',
        children: [],
      },
      {
        name: 'Betoniteräkset',
        children: [],
      },
      {
        name: 'Hitsatut raudoitteet',
        children: [],
      },
      {
        name: 'Erikoisjatkokset',
        children: [],
      },
      {
        name: 'Valuankkurit, kiinnityslevyt, urakiskot, peruspultit ja muut valuun tulevat osat',
        children: [],
      },
      {
        name: 'Geotekstiilit',
        children: [],
      },
      {
        name: 'Salaojatäytöt, alustäytöt, sokkelien vierustäytöt',
        children: [],
      },
      {
        name: 'Salaojaputket ja -tarkastuskaivot',
        children: [],
      },
      {
        name: 'Routasuojaukset ja alapohjan lämmöneristeet',
        children: [],
      },
      {
        name: 'Perustusten ja alapohjan vedeneristystarvikkeet',
        children: [],
      },
      {
        name: 'Perusmuurilevyt',
        children: [],
      },
      {
        name: 'Perustus- ja tukimuurielementit',
        children: [],
      },
      {
        name: 'Päällystekivet',
        children: [],
      },
    ],
  },
  {
    name: 'Betonirungot',
    children: [
      {
        name: 'Jänneteräkset',
        children: [],
      },
      {
        name: 'Jännemenetelmät',
        children: [],
      },
      {
        name: 'Betoniteräkset',
        children: [],
      },
      {
        name: 'Raudoitteet',
        children: [],
      },
      {
        name: 'Erikoisjatkokset',
        children: [],
      },
      {
        name: 'Jälkikiinnitteiset ankkuroinnit',
        children: [],
      },
      {
        name: 'Jälkikiinnitteiset harjaterästartunnat',
        children: [],
      },
      {
        name: 'Valuankkurit, kiinnityslevyt, urakiskot, parvekekannattimet, peruspultit, ym.',
        children: [],
      },
      {
        name: 'Valmisbetoni',
        children: [],
      },
      {
        name: 'Betonirakenteiden suojaus- ja korjausaineet',
        children: [],
      },
      {
        name: 'Kevytbetonielementit (AAC ja LAC)',
        children: [],
      },
      {
        name: 'Sokkelielementit',
        children: [],
      },
      {
        name: 'Ulkoseinäelementit',
        children: [],
      },
      {
        name: 'Väliseinäelementit',
        children: [],
      },
      {
        name: 'Massiivilaattaelementit',
        children: [],
      },
      {
        name: 'Ontelolaattaelementit',
        children: [],
      },
      {
        name: 'Kuorilaattaelementit',
        children: [],
      },
      {
        name: 'Pilarit, palkit, ripalaatat',
        children: [],
      },
      {
        name: 'LVI-hormielementit',
        children: [],
      },
      {
        name: 'Liittopalkit, -pilarit, -laatat',
        children: [],
      },
      {
        name: 'Betoniportaat',
        children: [],
      },
      {
        name: 'Kumilevylaakerit',
        children: [],
      },
    ],
  },
  {
    name: 'Puurungot',
    children: [
      {
        name: 'Puupilarit',
        children: [],
      },
      {
        name: 'Puupalkit ja puiset kevytpalkit',
        children: [],
      },
      {
        name: 'Ulkoseinäelementit',
        children: [],
      },
      {
        name: 'Väliseinäelementit',
        children: [],
      },
      {
        name: 'Ala-, väli- ja yläpohjaelementit',
        children: [],
      },
      {
        name: 'Naulalevyrakenteet',
        children: [],
      },
      {
        name: 'Sahatavaratuotteet',
        children: [],
      },
      {
        name: 'Kertopuutuotteet',
        children: [],
      },
      {
        name: 'Liimapuutuotteet',
        children: [],
      },
      {
        name: 'Kipsilevytuotteet',
        children: [],
      },
      {
        name: 'Puu- ja kuitulevytuotteet',
        children: [],
      },
      {
        name: 'CLT-tuotteet',
        children: [],
      },
      {
        name: 'Pultit, mutterit, naulauslevyt ja muut kiinnikkeet',
        children: [],
      },
      {
        name: 'Palosuojauslevyt ja suojaverhoukset',
        children: [],
      },
    ],
  },
  {
    name: 'Teräsrungot',
    children: [
      {
        name: 'Rakenteelliset teräkset ja profiilit (myös ruostumattomat)',
        children: [],
      },
      {
        name: 'Hitsausaineet',
        children: [],
      },
      {
        name: 'Teräs- ja alumiinirakennekokoonpanot ja -tuotejärjestelmät (myös ruostumattomat)',
        children: [],
      },
      {
        name: 'Seinä-, sisäkatto- ja kattoelementit (kantavat ja itsekantavat sandwich-elementit)',
        children: [],
      },
      {
        name: 'Liittopalkit, -pilarit, -laatat',
        children: [],
      },
      {
        name: 'Ruuvikokoonpanot (pultit ja mutterit) ja muut kiinnikkeet',
        children: [],
      },
      {
        name: 'Palosuojauslevyt, -maalit ja -massat',
        children: [],
      },
    ],
  },
  {
    name: 'Yläpohjat ja vesikatot',
    children: [
      {
        name: 'Kattoikkunat, kattovalokuvut, kattolyhdyt, valoläpäisevät levyt',
        children: [],
      },
      {
        name: 'Lämmöneristeet',
        children: [],
      },
      {
        name: 'Aluskate',
        children: [],
      },
      {
        name: 'Höyrynsulkutuotteet',
        children: [],
      },
      {
        name: 'Tuulensuojalevyt ja tuulenohjaimet',
        children: [],
      },
      {
        name: 'Vesikate (tiili/pelti/bitumikermi) ja vesikatteen kiinnikkeet',
        children: [],
      },
      {
        name: 'Geotekstiilit yläpohjiin',
        children: [],
      },
      {
        name: 'Saumausmassat sekä tiivistysmassat ja -aineet',
        children: [],
      },
      {
        name: 'Vesikatteen läpivientikappaleet',
        children: [],
      },
      {
        name: 'Talotikaat ja nousukiskot',
        children: [],
      },
      {
        name: 'Lapetikkaat ja kattosillat',
        children: [],
      },
      {
        name: 'Kattopollarit, turvakiskot ja turvavaijerit',
        children: [],
      },
    ],
  },
  {
    name: 'Julkisivut',
    children: [
      {
        name: 'Julkisivujärjestelmät',
        children: [],
      },
      {
        name: 'Kiinnitysjärjestelmät',
        children: [],
      },
      {
        name: 'Julkisivun palokatkot',
        children: [],
      },
      {
        name: 'Sisäänkäyntiovet, parvekeovet ja ikkunat sekä toimitilojen ovet ja portit',
        children: [],
      },
      {
        name: 'Avattavat (helat ja irtokahvat tai kiinteät kahvat) paloikkunat',
        children: [],
      },
      {
        name: 'Kiinteät paloikkunat',
        children: [],
      },
      {
        name: 'Sisäänkäyntipalo-ovet ja teollisuuspalo-ovet',
        children: [],
      },
      {
        name: 'Lämmöneristeet',
        children: [],
      },
      {
        name: 'Parvekelasitusjärjestelmät',
        children: [],
      },
      {
        name: 'Parvekkeen kaidelasit / turvalasit / lasitiilet',
        children: [],
      },
      {
        name: 'Verhouselementit ja -levyt eri materiaaleista',
        children: [],
      },
      {
        name: 'Puuverhous',
        children: [],
      },
      {
        name: 'Tiilet, harkot, muottiharkot, luonnonkivet',
        children: [],
      },
      {
        name: 'Tiilisiteet',
        children: [],
      },
      {
        name: 'Vaakasaumojen raudoitteet',
        children: [],
      },
      {
        name: 'Muurauslaasti',
        children: [],
      },
      {
        name: 'Rappausaineet ja suojauspinnoitteet',
        children: [],
      },
      {
        name: 'Eristerappausjärjestelmät (ETICS)',
        children: [],
      },
      {
        name: 'Elastiset saumasunauhat ja -massat',
        children: [],
      },
      {
        name: 'Palkit ja ylityspalkit',
        children: [],
      },
      {
        name: 'Tuulensuojalevyt (lukuunottamatta lämmöneristeitä)',
        children: [],
      },
    ],
  },
  {
    name: 'Sisävalmistusvaihe',
    children: [
      {
        name: 'Palosuojauslevyt, -kyllästeet, -maalit ja -massat',
        children: [],
      },
      {
        name: 'Seinä- katto- ja lattiapintamateriaalit',
        children: [],
      },
      {
        name: 'Alakattojärjestelmät',
        children: [],
      },
      {
        name: 'Seinä-, katto- ja lattiatasoitteet ja suojauspinnoitteet',
        children: [],
      },
      {
        name: 'Märkätilojen lattian ja seinien vedeneriste ja kosteussulku',
        children: [],
      },
      {
        name: 'Tiivistysmassat (saniteettisaumausaineet)',
        children: [],
      },
      {
        name: 'Höyrynsulkutuotteet',
        children: [],
      },
      {
        name: 'Kylypyhuone-elementit',
        children: [],
      },
      {
        name: 'Puu- tai teräsportaat',
        children: [],
      },
      {
        name: 'Sisäpalo-ovet',
        children: [],
      },
      {
        name: 'Seinien ja kaiteiden lasit',
        children: [],
      },
      {
        name: 'Rakennuslevyt ja -rangat',
        children: [],
      },
      {
        name: 'Ääneneristykset ja akustointiverhoukset',
        children: [],
      },
      {
        name: 'Kevytrunkoiset väliseinäjärjestelmät',
        children: [],
      },
      {
        name: 'Tiilet, harkot, laatat, verhouselementit, laastit',
        children: [],
      },
      {
        name: 'Palokatkotuotteet',
        children: [],
      },
      {
        name: 'Savusulut, savunpoistoluukut, -puhaltimet, -kanavat ja tehonlähteet',
        children: [],
      },
      {
        name: 'Palovaroittimet, -ilmaisimet ja -ilmoittimet',
        children: [],
      },
      {
        name: 'Automaattiset sammutusjärjestelmät',
        children: [],
      },
    ],
  },
  {
    name: 'Vesi- ja viemärilaitteet',
    children: [
      {
        name: 'Huoneistokohtaiset vesimittarit',
        children: [],
      },
      {
        name: 'Kattokaivot',
        children: [],
      },
      {
        name: 'Vesilaitteistojen messinkiset ja kupariset putkiyhteet',
        children: [],
      },
      {
        name: 'Vesilaitteistojen metalliputkien (muut kuin kupari) puserrus- ja puristusliittimet',
        children: [],
      },
      {
        name: 'Vesilaitteistojen monikerrosputket ja niiden liittimet',
        children: [],
      },
      {
        name: 'Vesilaitteistojen sulkuventtiilit',
        children: [],
      },
      {
        name: 'Vesilaitteistojen yksisuuntaventtiilit',
        children: [],
      },
      {
        name: 'Vesilaitteistojen joustavat kytkentäputket',
        children: [],
      },
      {
        name: 'Vesilaitteistojen hanat',
        children: [],
      },
      {
        name: 'Sisäpuolisten viemärilaitteiden venttiilit ja vesilukot',
        children: [],
      },
      {
        name: 'Tekniset eristeet',
        children: [],
      },
      {
        name: 'Talousvesiputket, putkenosat ja yhteet, putkielementit',
        children: [],
      },
      {
        name: 'Jätevesiputket, putkenosat ja yhteet, putkielementit',
        children: [],
      },
      {
        name: 'Eristettyjen putkistojen ja kanavien ym. päällysteet',
        children: [],
      },
      {
        name: 'Tarkastuskaivot, -putket ja kansistot',
        children: [],
      },
      {
        name: 'Sadevesikaivot ja perusvesikaivot',
        children: [],
      },
      {
        name: 'Pumput ja pumppaamot',
        children: [],
      },
      {
        name: 'Kiertovesipumput ja vesipumput',
        children: [],
      },
      {
        name: 'Vesilukot',
        children: [],
      },
      {
        name: 'Lattiakaitvot ja korokerenkaat',
        children: [],
      },
      {
        name: 'WC-istuimet ja urinaalit',
        children: [],
      },
      {
        name: 'Altaat ja allasvarusteet',
        children: [],
      },
      {
        name: 'Erottimet',
        children: [],
      },
      {
        name: 'Jäteveden käsittelyjärjestelmät',
        children: [],
      },
      {
        name: 'Muut vesi- ja viemärijärjestelmän osat',
        children: [],
      },
    ],
  },
  {
    name: 'Lämmitys- ja jäähdytyslaitteet',
    children: [
      {
        name: 'Putket ja putkenosat',
        children: [],
      },
      {
        name: 'Tekniset eristeet',
        children: [],
      },
      {
        name: 'Eristettyjen putkistojen ja kanavien ym. päällysteet',
        children: [],
      },
      {
        name: 'Lämmityskattilat ja polttimet',
        children: [],
      },
      {
        name: 'Vedenlämmittimet ja kuumavesisäiliöt',
        children: [],
      },
      {
        name: 'Sulku-, varo- ja säätöventtilit ja -laitteet',
        children: [],
      },
      {
        name: 'Patterit, konvektorit ja kattoon asennettavat vesikiertoiset säteilylämmittimet',
        children: [],
      },
      {
        name: 'Muut lämmityslaitejärjestelmän osat',
        children: [],
      },
      {
        name: 'Tulisijat',
        children: [],
      },
      {
        name: 'Savupiiput',
        children: [],
      },
      {
        name: 'Ilmalämmitys- ja jäähdytyslaitteet',
        children: [],
      },
      {
        name: 'Paikalliset tilalämmittimet',
        children: [],
      },
    ],
  },
  {
    name: 'Ilmanvaihtolaitteet',
    children: [
      {
        name: 'Ilmakanavat ja kanavaosat (kanavien paloeristys kohdassa tekniset eristeet)',
        children: [],
      },
      {
        name: 'Kokoojalaatikot ja kammiot',
        children: [],
      },
      {
        name: 'Tekniset eristeet',
        children: [],
      },
      {
        name: 'Eristettyjen putkistojen ja kanavien ym. päällysteet',
        children: [],
      },
      {
        name: 'Säätöpellit',
        children: [],
      },
      {
        name: 'Palopellit',
        children: [],
      },
      {
        name: 'Äänenvaimentimet',
        children: [],
      },
      {
        name: 'Puhdistusluukut',
        children: [],
      },
      {
        name: 'Tulo- ja poistoilmaventtiilit, ulkoilmaventtiilit',
        children: [],
      },
      {
        name: 'Liesikuvut ja liesituulettimet',
        children: [],
      },
      {
        name: 'Säleiköt',
        children: [],
      },
      {
        name: 'Ulospuhallushajoittimet',
        children: [],
      },
      {
        name: 'Ilmanvaihtokoneet, ilmanvaihtokoneiden suodattimet',
        children: [],
      },
      {
        name: 'Huoneilmastointilaitteet ja -tuulettimet',
        children: [],
      },
      {
        name: 'Huippuimurit',
        children: [],
      },
      {
        name: 'Kanavapuhaltimet',
        children: [],
      },
      {
        name: 'Säätölaitteet',
        children: [],
      },
      {
        name: 'Muut ilmanvaihtojärjestelmän osat',
        children: [],
      },
      {
        name: 'Radonin torjuntalaitteet ja putkitukset',
        children: [],
      },
    ],
  },
  {
    name: 'Sähkölaitteet',
    children: [
      {
        name: 'Kiinteistön sähkölaitteet',
        children: [],
      },
      {
        name: 'Huoneisto- / tilakohtaiset sähkölaitteet',
        children: [],
      },
    ],
  },
  {
    name: 'Tietoliikennelaitteet',
    children: [
      {
        name: 'Tieto- ja televerkkolaitteet, antenni- ja kaapeli TV -järjestelmät',
        children: [],
      },
    ],
  },
  {
    name: 'Piha-alueiden kalusteet ja varusteet',
    children: [
      {
        name: 'Jätehuolto, pysäköintialueiden laitteet ja varusteet, portit, aidat',
        children: [],
      },
      {
        name: 'Puut, pensaat ja muut istutukset',
        children: [],
      },
      {
        name: 'Pihakivet, pintavesien johtamisen varusteet jne.',
        children: [],
      },
    ],
  },
];
/* eslint-enable max-len */


export default constructionPhases;
