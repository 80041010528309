import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const CreateButton = ({ user, setAddToParent }) => {
  if (!user) {
    return (
      <div className='PackageHierarchy-add'>
        <div className='loading loading-green'>
          <div />
        </div>
      </div>
    );
  }

  const addPackageClasses = classNames(
    'btn',
    'btn-primary-outline',
    'btn-wide-xs',
    {
      'hidden-xs-up': !user.licenseAccess,
    }
  );

  const addPackageLicenseClasses = classNames(
    'PackageHierarchy-add-license',
    'text-muted',
    {
      'hidden-xs-up': user.licenseAccess,
    }
  );

  return (
    <div className='PackageHierarchy-add'>
      <button
        className={addPackageClasses}
        onClick={setAddToParent}
        data-toggle='modal'
        data-target='#AddPackageModal'
        type='button'
      >
        <FormattedMessage
          id='create-construction-site'
          description='Create construction site button'
          defaultMessage='Luo työmaa'
        />
      </button>

      <small className={addPackageLicenseClasses}>
        <FormattedMessage
          id='package-license-required'
          description='License required to create a construction site'
          defaultMessage='Tarvitset käyttöoikeuden lisätäksesi työmaita.'
        />
      </small>
    </div>
  );
};

CreateButton.propTypes = {
  user: PropTypes.shape({
    licenseAccess: PropTypes.bool,
  }),
  setAddToParent: PropTypes.func.isRequired,
};

export default CreateButton;
