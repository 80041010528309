/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

/* disable eslint */

import PropTypes from 'prop-types';

import React from 'react';

import { injectIntl, FormattedMessage, FormattedTime } from 'react-intl';

class DownloadSummaryModal extends React.Component {
  static propTypes = {
    packageActionCreators: PropTypes.object.isRequired,
    fileActionCreators: PropTypes.object.isRequired,
    package: PropTypes.object,
    packageSummaryLoading: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.downloadSummaryModal = null;
  }

  downloadSummaryReport = (event) => {
    event.preventDefault();
    this.props.fileActionCreators.downloadFile(this.props.package.summary);

    $(this.downloadSummaryModal).removeClass('fade');
    $(this.downloadSummaryModal).modal('hide');
  };

  generateReport = (event) => {
    event.preventDefault();
    this.props.packageActionCreators.generateReport(
      this.props.package.id,
      this.props.package.summary
    );
  };

  render() {
    return (
      <div
        className='DownloadSummaryModal modal fade'
        ref={(div) => {
          this.downloadSummaryModal = div;
        }}
        id='DownloadSummaryModal'
        tabIndex='-1'
        data-backdrop='static'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                <span>&times;</span>
              </button>
              <h4 className='modal-title'>
                <FormattedMessage
                  id='create-construction-site-header'
                  description='Create construction site header'
                  defaultMessage='Lataa yhteenveto'
                />
              </h4>
            </div>

            <div className='DownloadSummaryModal-content modal-body'>
              <div>
                {this.props.package && this.props.package.summary && (
                  <div>
                    <div>
                      <FormattedMessage
                        id='last-report-generated'
                        description='Button for generating a report'
                        defaultMessage='Olet lataamassa yhteenvetoa ajalta {time}.
                  Jos epäilet tietojen olevan vanhentuneet tai muuttuneet
                  luontiajankohdan jälkeen, {boldedText}.'
                        values={{
                          time: (
                            <b>
                              <FormattedTime
                                year='numeric'
                                month='numeric'
                                day='numeric'
                                hour='numeric'
                                minute='numeric'
                                value={this.props.package.summary.last_modified}
                              />
                            </b>
                          ),
                          boldedText: <b>tee uusi yhteenveto</b>,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='modal-footer text-center'>
              <input
                type='button'
                className='btn btn-danger'
                data-dismiss='modal'
                value='Sulje'
              />

              {this.props.packageSummaryLoading && this.props.package && (
                <button
                  className='btn btn-success'
                  onClick={this.generateReport}
                  disabled={
                    this.props.packageSummaryLoading[this.props.package.id]
                  }
                >
                  <i className='fa fa-file-archive-o'></i>&nbsp;
                  <FormattedMessage
                    id='generate-report'
                    description='Button for generating a report'
                    defaultMessage='Tee uusi yhteenveto'
                  />
                </button>
              )}
              <button
                className='btn btn-success'
                onClick={this.downloadSummaryReport}
              >
                <i className='fa fa-file-archive-o'></i>&nbsp;
                <FormattedMessage
                  id='generate-report'
                  description='Button for downloading a report'
                  defaultMessage='Lataa yhteenveto'
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(DownloadSummaryModal);
