export default {
  CLEAR_STORES: 'CLEAR_STORES',
  RT_RECEIVE_CURRENT_USER: 'RT_RECEIVE_CURRENT_USER',
  RT_RECEIVE_CURRENT_USER_ERROR: 'RT_RECEIVE_CURRENT_USER_ERROR',
  RT_USER_LOGGED_OUT: 'RT_USER_LOGGED_OUT',
  MESSAGES_ADD: 'MESSAGES_ADD',
  MESSAGES_CLEAR: 'MESSAGES_CLEAR',
  MESSAGES_EXPIRE: 'MESSAGES_EXPIRE',
  PACKAGE_SUMMARY_LOADING: 'PACKAGE_SUMMARY_LOADING',
  PACKAGE_SUMMARY_RECEIVED: 'PACKAGE_SUMMARY_RECEIVED',
  PACKAGE_SUMMARY_RECEIVE_ERROR: 'PACKAGE_SUMMARY_RECEIVE_ERROR',
  PACKAGES_LOADING: 'PACKAGES_LOADING',
  PACKAGE_RECEIVE_ALL: 'PACKAGE_RECEIVE_ALL',
  PACKAGE_RECEIVE_ALL_ERROR: 'PACKAGE_RECEIVE_ALL_ERROR',
  PACKAGE_RECEIVE_ARCHIVED_COUNT: 'PACKAGE_RECEIVE_ARCHIVED_COUNT',
  PACKAGE_RECEIVE_ARCHIVED_COUNT_ERROR: 'PACKAGE_RECEIVE_ARCHIVED_COUNT_ERROR',
  PACKAGE_RECEIVE: 'PACKAGE_RECEIVE',
  PACKAGE_RECEIVE_ERROR: 'PACKAGE_RECEIVE_ERROR',
  PACKAGE_LOADING: 'PACKAGE_LOADING',
  PACKAGE_APPROVAL_REPORT_LOADING: 'PACKAGE_APPROVAL_REPORT_LOADING',
  PACKAGE_CLEAR: 'PACKAGE_CLEAR',
  PACKAGE_PRODUCTS_LOADING: 'PACKAGE_PRODUCTS_LOADING',
  PACKAGE_RECEIVE_PRODUCTS: 'PACKAGE_RECEIVE_PRODUCTS',
  PACKAGE_RECEIVE_PRODUCTS_ERROR: 'PACKAGE_RECEIVE_PRODUCTS_ERROR',
  PACKAGE_ADDED_PRODUCT: 'PACKAGE_ADDED_PRODUCT',
  PACKAGE_MOVED_PRODUCT: 'PACKAGE_MOVED_PRODUCT',
  PACKAGE_MOVED: 'PACKAGE_MOVED',
  PACKAGE_SEARCH_LOADING: 'PACKAGE_SEARCH_LOADING',
  PACKAGE_RECEIVE_SEARCH: 'PACKAGE_RECEIVE_SEARCH',
  PACKAGE_RECEIVE_SEARCH_ERROR: 'PACKAGE_RECEIVE_SEARCH_ERROR',
  PACKAGE_ADD_TO_PARENT: 'PACKAGE_ADD_TO_PARENT',
  PACKAGE_ADDED: 'PACKAGE_ADDED',
  PACKAGE_ADDED_ERROR: 'PACKAGE_ADDED_ERROR',
  PACKAGE_EDITED: 'PACKAGE_EDITED',
  PACKAGE_EDITED_ERROR: 'PACKAGE_EDITED_ERROR',
  PACKAGE_REMOVED: 'PACKAGE_REMOVED',
  PACKAGE_RIGHTS_LOADING: 'PACKAGE_RIGHTS_LOADING',
  PACKAGE_RECEIVE_RIGHTS: 'PACKAGE_RECEIVE_RIGHTS',
  PACKAGE_RECEIVE_RIGHTS_ERROR: 'PACKAGE_RECEIVE_RIGHTS_ERROR',
  PACKAGE_UPDATE_RIGHTS: 'PACKAGE_UPDATE_RIGHTS',
  PACKAGE_UPDATE_INVITES: 'PACKAGE_UPDATE_INVITES',
  PACKAGE_REMOVED_RIGHTS: 'PACKAGE_REMOVED_RIGHTS',
  PACKAGE_INVITED: 'PACKAGE_INVITED',
  PACKAGE_UNINVITED: 'PACKAGE_UNINVITED',
  PACKAGE_ARCHIVED: 'PACKAGE_ARCHIVED',
  PACKAGE_UNARCHIVED: 'PACKAGE_UNARCHIVED',
  PACKAGE_RECEIVE_CHEMICAL_CHART_INFO: 'PACKAGE_RECEIVE_CHEMICAL_CHART_INFO',
  PACKAGE_CREATE_CHEMICAL_CHART_LOADING:
    'PACKAGE_CREATE_CHEMICAL_CHART_LOADING',
  CHEMICAL_CHART_DATA_LOADING: 'CHEMICAL_CHART_DATA_LOADING',
  RECEIVE_CHEMICAL_CHART_DATA: 'RECEIVE_CHEMICAL_CHART_DATA',
  COMPANY_RECEIVE: 'COMPANY_RECEIVE',
  COMPANY_RECEIVE_ERROR: 'COMPANY_RECEIVE_ERROR',
  COMPANY_RIGHTS_RECEIVE: 'COMPANY_RIGHTS_RECEIVE',
  COMPANY_RIGHTS_RECEIVE_ERROR: 'COMPANY_RIGHTS_RECEIVE_ERROR',
  COMPANY_RIGHTS_ADDED: 'COMPANY_RIGHTS_ADDED',
  COMPANY_ALL_RIGHTS_ADDED: 'COMPANY_ALL_RIGHTS_ADDED',
  COMPANY_ALL_RIGHTS_REMOVED: 'COMPANY_ALL_RIGHTS_REMOVED',
  COMPANY_FOLDERS_RECEIVE: 'COMPANY_FOLDERS_RECEIVE',
  COMPANY_CHEMICALS_LOADING: 'COMPANY_CHEMICALS_LOADING',
  COMPANY_CHEMICALS_RECEIVE: 'COMPANY_CHEMICALS_RECEIVE',
  COMPANY_CHEMICALS_RECEIVE_ERROR: 'COMPANY_CHEMICALS_RECEIVE_ERROR',
  COMPANY_APPROVERS_RECEIVE: 'COMPANY_APPROVERS_RECEIVE',
  PRODUCT_INSTALLED: 'PRODUCT_INSTALLED',
  PRODUCT_UNINSTALLED: 'PRODUCT_UNINSTALLED',
  PRODUCT_REMOVED: 'PRODUCT_REMOVED',
  PRODUCT_MANUAL_ADDED: 'PRODUCT_MANUAL_ADDED',
  PRODUCT_MANUAL_REMOVED: 'PRODUCT_MANUAL_REMOVED',
  PRODUCT_APPROVE: 'PRODUCT_APPROVE',
  PRODUCT_GLOBAL_SEARCH_LOADING: 'PRODUCT_GLOBAL_SEARCH_LOADING',
  PRODUCT_RECEIVE_GLOBAL_SEARCH: 'PRODUCT_RECEIVE_GLOBAL_SEARCH',
  PRODUCT_RECEIVE_GLOBAL_SEARCH_ERROR: 'PRODUCT_RECEIVE_GLOBAL_SEARCH_ERROR',
  PRODUCT_RECEIVE: 'PRODUCT_RECEIVE',
  PRODUCT_RECEIVE_ERROR: 'PRODUCT_RECEIVE_ERROR',
  PRODUCT_LOADING: 'PRODUCT_LOADING',
  PRODUCT_RECEIVE_EXTERNAL: 'PRODUCT_RECEIVE_EXTERNAL',
  PRODUCT_RECEIVE_EXTERNAL_ERROR: 'PRODUCT_RECEIVE_EXTERNAL_ERROR',
  FILE_ADDED: 'FILE_ADDED',
  FILE_MANUAL_ADDED: 'FILE_MANUAL_ADDED',
  LOGS_RECEIVE: 'LOGS_RECEIVE',
  LOGS_RECEIVE_ERROR: 'LOGS_RECEIVE_ERROR',
  LOG_RECEIVE: 'LOG_RECEIVE',
  LOG_RECEIVE_ERROR: 'LOG_RECEIVE_ERROR',
  STATS_RECEIVE: 'STATS_RECEIVE',
  STATS_RECEIVE_ERROR: 'STATS_RECEIVE_ERROR',
  BULLETINS_RECEIVE: 'BULLETINS_RECEIVE',
  BULLETINS_RECEIVE_ERROR: 'BULLETINS_RECEIVE_ERROR',
  BULLETIN_REMOVED: 'BULLETIN_REMOVED',
  BULLETIN_REMOVE_ERROR: 'BULLETIN_REMOVE_ERROR',
  BULLETIN_SAVED: 'BULLETIN_SAVED',
  BULLETIN_SAVE_ERROR: 'BULLETIN_SAVE_ERROR',
  FAVOURITES_RECEIVE: 'FAVOURITES_RECEIVE',
  PACKAGE_OPEN_DOWNLOAD_MODAL: 'PACKAGE_OPEN_DOWNLOAD_MODAL',
  PACKAGE_OPEN_MASS_APPROVE_MODAL: 'PACKAGE_OPEN_MASS_APPROVE_MODAL',
  PRODUCT_OPEN_INFORMATION_MODAL: 'PRODUCT_OPEN_INFORMATION_MODAL',
  START_PROGRESS: 'START_PROGRESS',
  PROGRESS_FAILED: 'PROGRESS_FAILED',
  RESET_COUNTER: 'RESET_COUNTER',
  SET_ACTIVE_PACKAGE: 'SET_ACTIVE_PACKAGE',
  CLOSE_ACTIVE_PACKAGE: 'CLOSE_ACTIVE_PACKAGE',
  OPEN_MOBILE_MENU: 'OPEN_MOBILE_MENU',
  CLOSE_MOBILE_MENU: 'CLOSE_MOBILE_MENU',
  SAFETY_DATA_RECEIVE: 'SAFETY_DATA_RECEIVE',
  SAFETY_DATA_RECEIVE_ERROR: 'SAFETY_DATA_RECEIVE_ERROR',
  TOGGLE_EXPANDED: 'TOGGLE_EXPANDED',
  EXPAND_SUBPACKAGES: 'EXPAND_SUBPACKAGES',
  COLLAPSE_SUBPACKAGES: 'COLLAPSE_SUBPACKAGES',
  VALIDATE_IMPORT_PRODUCTS_LOADING: 'VALIDATE_IMPORT_PRODUCTS_LOADING',
  RECEIVE_VALIDATE_IMPORT_PRODUCTS: 'RECEIVE_VALIDATE_IMPORT_PRODUCTS',
  IMPORT_PRODUCTS_LOADING: 'IMPORT_PRODUCTS_LOADING',
  RECEIVE_IMPORT_PRODUCTS: 'RECEIVE_IMPORT_PRODUCTS',
  FILE_UPDATED: 'FILE_UPDATED',
  FILE_MANUAL_UPDATED: 'FILE_MANUAL_UPDATED',
  PRODUCT_APPROVABLE_UPDATED: 'PRODUCT_APPROVABLE_UPDATED',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  APPROVER_IDS_RECEIVE: 'APPROVER_IDS_RECEIVE',
  PACKAGE_PRODUCT_SEARCH_LOADING: 'PACKAGE_PRODUCT_SEARCH_LOADING',
  PACKAGE_PRODUCT_SEARCH_RECEIVE: 'PACKAGE_PRODUCT_SEARCH_RECEIVE',
  REPORT_DATA_RECEIVE: 'REPORT_DATA_RECEIVE',
  REPORT_DATA_LOADING: 'REPORT_DATA_LOADING',
  REPORT_DATA_ERROR: 'REPORT_DATA_ERROR',
  EXCEL_REPORT_PENDING: 'EXCEL_REPORT_PENDING',
};
