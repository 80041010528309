import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';

const productSource = {
  beginDrag(props) {
    return props.product;
  },
  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }

    const drop = monitor.getDropResult();
    props.onDrop(drop);
  },
};

class ProductDragSource extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    product: PropTypes.object.isRequired,
    onDrop: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func,
  };

  render() {
    const { children, connectDragSource } = this.props;
    return connectDragSource ? connectDragSource(children) : null;
  }
}

export default DragSource('product', productSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(ProductDragSource);
