/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Messages from '../Messages/Messages';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs.tsx';
import ProductInformationPanel from '../ProductInformationPanel/ProductInformationPanel';
import PackageProductsLocal from '../PackageProductsLocal/PackageProductsLocal.tsx';
import PackageProductsLocalSearch from '../PackageProductsLocalSearch/PackageProductsLocalSearch.jsx';
import PackageProductsGlobal from '../PackageProductsGlobal/PackageProductsGlobal';
import { FormattedMessage } from 'react-intl';
import AddFileModal from '../AddFileModal/AddFileModal';
import AddProductModal from '../AddProductModal/AddProductModal';

class PackageProductsView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    productStore: PropTypes.object.isRequired,
    companyStore: PropTypes.object.isRequired,
    logStore: PropTypes.object.isRequired,
    rtActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    productActionCreators: PropTypes.object.isRequired,
    companyActionCreators: PropTypes.object.isRequired,
    fileActionCreators: PropTypes.object.isRequired,
    logActionCreators: PropTypes.object.isRequired,
    favouritesActionCreators: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this._getStoreData();
  }

  componentDidMount() {
    this._handleTabs();

    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );

    this._productStoreSubscription = this.props.productStore.addListener(
      this._onStoreChange
    );

    this._companyStoreSubscription = this.props.companyStore.addListener(
      this._onStoreChange
    );

    this._logStoreSubscription = this.props.logStore.addListener(
      this._onStoreChange
    );

    this.props.productActionCreators.clearGlobalProductSearch();

    // HACK: we want to load the products immediately on mount for certain tabs
    if (window.location.pathname !== '/') {
      // We don't have the package id yet, so we have to regex it from the path
      const pkg = /[0-9]+/.exec(window.location.pathname)[0];
      const tab = window.location.hash.replace('#!', '');
      if (tab === 'favourites' || tab === 'company') {
        if (tab === 'company') {
          this.props.companyActionCreators.getCompanyFolders();
        }
        this.props.productActionCreators.searchGlobalProducts(
          pkg,
          '',
          [],
          1,
          false,
          tab === 'favourites',
          tab === 'company'
        );
      }
    }
  }

  componentDidUpdate() {
    // We have to do this on update too because content might not be rendered
    // on initial mounting.
    this._handleTabs();
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
    this._productStoreSubscription.remove();
    this._companyStoreSubscription.remove();
    this._logStoreSubscription.remove();
  }

  _onStoreChange = () => {
    this.setState(this._getStoreData());
  };

  _getStoreData = () => ({
    rtState: this.props.rtStore.getState(),
    packageState: this.props.packageStore.getState(),
    productState: this.props.productStore.getState(),
    companyState: this.props.companyStore.getState(),
    logState: this.props.logStore.getState(),
  });

  _clearPanel = (event, newPanel) => {
    event.preventDefault();
    this.props.productActionCreators.clearProduct();
    this.props.productActionCreators.clearGlobalProductSearch();
    // Show the user all their favourites right away instead of an empty search
    if (newPanel === 'favourites') {
      this.props.productActionCreators.searchGlobalProducts(
        this.state.packageState.package.id,
        '',
        [],
        1,
        false,
        true,
        false
      );
    }

    // NOTE: this is not good practise, the whole app could use refactoring
    if (newPanel === 'company') {
      this.props.productActionCreators.clearProduct();
      this.props.companyActionCreators.getCompanyFolders();
      this.props.productActionCreators.searchGlobalProducts(
        this.state.packageState.package.id,
        '',
        [],
        1,
        false,
        false,
        true
      );
    }

    if (newPanel === 'local') {
      // Fetch products whenever tab changes to package product tab, I'm sick of
      // these state bugs
      this.props.packageActionCreators.getPackageProducts(
        this.state.packageState.package.id
      );
    }
  };

  _handleTabs = () => {
    const hash = window.location.hash.replace('!', '');
    const element = $(`a[data-toggle="tab"][href="${hash}"]`);
    if (element.length) {
      $(element).tab('show');
    }

    $('a[data-toggle="tab"]').on('shown.bs.tab', (event) => {
      history.pushState({}, '', `#!${event.target.hash.replace('#', '')}`);
    });
  };

  _getInnerContent = () => {
    if (!this.state.packageState.package) {
      return (
        <div className='col-xs-12'>
          <div className='loading loading-green loading-center m-t-md'>
            <div />
          </div>
        </div>
      );
    }

    return (
      <div className='col-xs-12'>
        <ul className='nav nav-tabs'>
          <li className='nav-item'>
            <a
              className={classNames('nav-link', {
                active: this.state.packageState.package.state === 2,
              })}
              href='#local'
              onClick={(event) => this._clearPanel(event, 'local')}
              data-toggle='tab'
            >
              <FormattedMessage
                id='local-products-tab'
                description='Tab link for searching local products'
                defaultMessage='Urakan sisältö'
              />
            </a>
          </li>

          <li className='nav-item'>
            <a
              className={classNames('nav-link', {
                active: this.state.packageState.package.state === 2,
              })}
              href='#local-search'
              onClick={(event) => this._clearPanel(event, 'local-search')}
              data-toggle='tab'
            >
              <FormattedMessage
                id='local-products-search-tab'
                description='Tab link for searching local products in package'
                defaultMessage='Hae urakasta'
              />
            </a>
          </li>

          {this.state.packageState.package.state === 2 ? null : (
            <li className='nav-item'>
              <a
                className='nav-link active'
                href='#global'
                onClick={(event) => this._clearPanel(event, null)}
                data-toggle='tab'
              >
                <FormattedMessage
                  id='global-products-tab'
                  description='Tab link for searching product database'
                  defaultMessage='Lisää tuotteita'
                />
              </a>
            </li>
          )}

          {this.state.packageState.package.state === 2 ? null : (
            <li className='nav-item'>
              <a
                className='nav-link'
                href='#favourites'
                onClick={(event) => this._clearPanel(event, 'favourites')}
                data-toggle='tab'
              >
                <FormattedMessage
                  id='favourites-products-tab'
                  description='Tab link for browsing favourite products'
                  defaultMessage='Omat suosikit'
                />
              </a>
            </li>
          )}

          {this.state.packageState.package.state === 2 ? null : (
            <li className='nav-item'>
              <a
                className='nav-link'
                href='#company'
                onClick={(event) => this._clearPanel(event, 'company')}
                data-toggle='tab'
              >
                <FormattedMessage
                  id='company-products-tab'
                  description='Tab link for browsing company products'
                  defaultMessage='Yrityksen tuotteet'
                />
              </a>
            </li>
          )}

          <small className='PackageProductsView-content-legend'>
            <i className='PackageProductsView-content-legend--planned' />
            &nbsp;
            <FormattedMessage
              id='legend-planned'
              description='Legend text for a planned product'
              defaultMessage='suunnitelmassa'
            />
            <i className='PackageProductsView-content-legend--installed' />
            &nbsp;
            <FormattedMessage
              id='legend-installed'
              description='Legend text for an installed product'
              defaultMessage='asennettu'
            />
          </small>
        </ul>

        <div className='tab-content'>
          <div
            className={classNames('tab-pane', {
              active: this.state.packageState.package.state === 2,
            })}
            id='local'
          >
            <PackageProductsLocal
              currentPackage={this.state.packageState.package}
              packages={this.state.packageState.packages}
              products={this.state.packageState.products}
              activeProduct={this.state.productState.product?.id}
              productsLoading={this.state.packageState.productsLoading}
              packageActionCreators={this.props.packageActionCreators}
              productActionCreators={this.props.productActionCreators}
              logActionCreators={this.props.logActionCreators}
            />
          </div>

          <div
            className={classNames('tab-pane', {
              active: this.state.packageState.package.state === 2,
            })}
            id='local-search'
          >
            <PackageProductsLocalSearch
              currentPackage={this.state.packageState.package}
              packageProductSearchResult={
                this.state.packageState.packageProductSearchResult
              }
              packageProductSearchLoading={
                this.state.packageState.packageProductSearchLoading
              }
              activeProduct={this.state.productState.product?.id}
              packageActionCreators={this.props.packageActionCreators}
              productActionCreators={this.props.productActionCreators}
              logActionCreators={this.props.logActionCreators}
            />
          </div>

          {this.state.packageState.package.state === 2 ? null : (
            <div className='tab-pane active' id='global'>
              <PackageProductsGlobal
                user={this.state.rtState.user}
                package={this.state.packageState.package}
                products={this.state.productState.globalSearch}
                properties={this.state.productState.globalSearchProperties}
                searchLoading={this.state.productState.globalSearchLoading}
                packageActionCreators={this.props.packageActionCreators}
                productActionCreators={this.props.productActionCreators}
                logActionCreators={this.props.logActionCreators}
                favouritesActionCreators={this.props.favouritesActionCreators}
                fileActionCreators={this.props.fileActionCreators}
                safetyData={this.state.productState.safetyData.fi}
                validateImportProductsLoading={
                  this.state.packageState.validateImportProductsLoading
                }
                importProducts={this.state.packageState.importProducts}
              />
            </div>
          )}

          {this.state.packageState.package.state === 2 ? null : (
            <div className='tab-pane' id='favourites'>
              <PackageProductsGlobal
                user={this.state.rtState.user}
                package={this.state.packageState.package}
                products={this.state.productState.globalSearch}
                properties={null}
                onlyFavourites
                searchLoading={this.state.productState.globalSearchLoading}
                packageActionCreators={this.props.packageActionCreators}
                productActionCreators={this.props.productActionCreators}
                logActionCreators={this.props.logActionCreators}
                favouritesActionCreators={this.props.favouritesActionCreators}
                fileActionCreators={this.props.fileActionCreators}
                safetyData={this.state.productState.safetyData.fi}
                validateImportProductsLoading={
                  this.state.packageState.validateImportProductsLoading
                }
                importProducts={this.state.packageState.importProducts}
              />
            </div>
          )}

          {this.state.packageState.package.state === 2 ? null : (
            <div className='tab-pane' id='company'>
              <PackageProductsGlobal
                user={this.state.rtState.user}
                package={this.state.packageState.package}
                packageProducts={this.state.packageState.products}
                products={this.state.productState.globalSearch}
                folders={this.state.companyState.folders}
                properties={null}
                onlyCompanyProducts
                searchLoading={this.state.productState.globalSearchLoading}
                packageActionCreators={this.props.packageActionCreators}
                productActionCreators={this.props.productActionCreators}
                companyActionCreators={this.props.companyActionCreators}
                logActionCreators={this.props.logActionCreators}
                favouritesActionCreators={this.props.favouritesActionCreators}
                fileActionCreators={this.props.fileActionCreators}
                safetyData={this.state.productState.safetyData.fi}
                chemicalProducts={this.state.companyState.chemicalProducts}
                validateImportProductsLoading={
                  this.state.packageState.validateImportProductsLoading
                }
                importProducts={this.state.packageState.importProducts}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  _getContent = () => {
    let productKey = 'loading';
    if (this.state.productState.product) {
      productKey = JSON.stringify(this.state.productState.product);
    }

    const key = `${this.state.packageState.package}-${productKey}`;

    return (
      <div className='container-fluid'>
        <Header
          user={this.state.rtState.user}
          packages={this.state.packageState.packageSearch}
          searchLoading={this.state.packageState.packageSearchLoading}
          packageActionCreators={this.props.packageActionCreators}
        />

        <Messages
          messageStore={this.props.messageStore}
          messageActionCreators={this.props.messageActionCreators}
        />

        <ReactTooltip class='tooltip' />

        <div className='PackageProductsView-content row'>
          <div className='PackageProductsView-content--left col-xs-12 col-sm-8'>
            <div className='content'>
              <Breadcrumbs
                currentPackage={this.state.packageState.package}
                packages={this.state.packageState.packages}
                path='/package/{id}/products#!local'
              />

              {this._getInnerContent()}
            </div>
            <Footer />
          </div>

          <ProductInformationPanel
            key={key}
            package={this.state.packageState.package}
            product={this.state.productState.product}
            productLoading={this.state.productState.productLoading}
            log={this.state.logState.log}
            externalProduct={this.state.productState.externalProduct}
            globalSearch={this.state.productState.globalSearch}
            packageActionCreators={this.props.packageActionCreators}
            productActionCreators={this.props.productActionCreators}
            fileActionCreators={this.props.fileActionCreators}
            messageActionCreators={this.props.messageActionCreators}
            safetyData={this.state.productState.safetyData.fi}
            logActionCreators={this.props.logActionCreators}
            approvers={this.state.companyState.approvers}
            user={this.state.rtState.user}
          />
        </div>
      </div>
    );
  };

  addProduct = async (data, files) => {
    const packageId = this.state.packageState.package.id;

    const productData = { ...data, from_company: false };
    const product =
      await this.props.packageActionCreators.addManualProductToPackage(
        packageId,
        productData
      );
    await this.props.fileActionCreators.addFiles(product.id, packageId, files);
  };

  render() {
    let content = null;
    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null ||
      this.state.productState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='PackageProductsView'>
        {content}
        <AddFileModal
          product={this.state.productState.product}
          fileActionCreators={this.props.fileActionCreators}
          messageActionCreators={this.props.messageActionCreators}
        />
        <AddProductModal
          title='Lisää puuttuva tuote urakkaan'
          safetyData={this.state.productState.safetyData.fi}
          addProduct={this.addProduct}
        />
      </div>
    );
  }
}

export default AuthenticatedView(PackageProductsView);
