import React from 'react';
import classNames from 'classnames';

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class NotFoundView extends React.Component {
  render() {
    return (
      <div className='NotFoundView'>
        <div className='container-fluid'>
          <div id='Header' className='row'>
            <nav
              className={classNames(
                'Header-top-bar', 'col-xs-12', 'navbar',
                'navbar-inverse', 'navbar-static-top'
              )}
            >
              <a className='navbar-brand' href='/'>
                <img src='/images/rt-brand.png'></img>
              </a>
            </nav>
          </div>

          <div className='NotFoundView-content row text-center'>
            <div className='col-xs-12 col-sm-10 col-sm-offset-1'>
              <div className='jumbotron'>
                <h1 className='display-3'>
                  <FormattedMessage
                    id='page-not-found-header'
                    description='Header text for the 404 page'
                    defaultMessage='Sivua ei löytynyt'
                  />
                </h1>

                <hr className='m-y-md' />

                <p>
                  <FormattedHTMLMessage
                    id='page-not-found-content'
                    description='Full explanation on the 404 page'
                    defaultMessage={'Sivua ei valitettavasti löytynyt.' +
                      ' Klikkaa itsesi yläpalkista tai <a href="/">tästä</a>' +
                      ' etusivulle ja kokeile uudelleen. <br />' +
                      ' Ota yhteyttä ylläpitäjään mikäli ongelma toistuu.'}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundView;
