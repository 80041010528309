/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import ProductSearchFilterRow from '../ProductSearchFilterRow/ProductSearchFilterRow';

const propTypes = {
  query: PropTypes.string,
  properties: PropTypes.object,
  handler: PropTypes.func.isRequired,
};

class ProductSearchFilter extends Component {
  constructor(props) {
    super(props);
    this.addFilter = this.addFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this.state = {
      filters: {},
      filterRows: [],
      query: '',
    };
  }

  componentWillReceiveProps(props) {
    if (props.query !== this.state.query) {
      this.setState({
        filters: {},
        filterRows: [],
        query: props.query,
      });
    }
  }

  addFilter() {
    this.setState({
      filterRows: [
        ...this.state.filterRows,
        { key: '', type: null, value: '' },
      ],
    });
  }

  removeFilter(event, index) {
    event.preventDefault();
    const filterRows = this.state.filterRows;
    const newRows = [
      ...filterRows.slice(0, index),
      ...filterRows.slice(index + 1, filterRows.length),
    ];
    this.setState({ filterRows: newRows });
    this.props.handler(event, newRows);
  }

  _handleChange(event, index, row) {
    const filterRows = this.state.filterRows;
    const newRows = [
      ...filterRows.slice(0, index),
      row,
      ...filterRows.slice(index + 1, filterRows.length),
    ];
    this.setState({ filterRows: newRows });
    this.props.handler(event, newRows);
  }

  render() {
    if (!this.props.properties) return null;
    return (
      <div>
        <p>Rajaa hakutuloksia:</p>
        {this.state.filterRows.map((row, index) => {
          return (
            <ProductSearchFilterRow
              key={index.toString()}
              index={index}
              pair={row}
              data={this.props.properties}
              onDelete={this.removeFilter}
              onSelect={this._handleChange}
            />
          );
        }, this)}
        {this.props.properties && (
          <a className='m-l' onClick={this.addFilter}>
            <i className='fa fa-plus-square-o fa-lg' />
            Lisää hakuehto
          </a>
        )}
      </div>
    );
  }
}

ProductSearchFilter.propTypes = propTypes;

export default ProductSearchFilter;
