/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import page from 'page';
import classNames from 'classnames';

import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';

const messages = defineMessages({
  completed: {
    id: 'package-completed',
    description: 'Successfully completing a package',
    defaultMessage: 'Urakka merkattiin valmiiksi!',
  },

  incompleted: {
    id: 'package-incompleted',
    description: 'Successfully incompleting a package',
    defaultMessage: 'Urakka merkattiin keskeneräiseksi!',
  },

  completeTooltip: {
    id: 'complete-tooltip',
    description: 'Tooltip when package cannot be completed due to children',
    defaultMessage: 'Kaikkia aliurakoita ei ole merkitty valmiiksi.',
  },

  notEmptyTooltip: {
    id: 'package-not-empty-tooltip',
    description: 'Tooltip when package is not empty',
    defaultMessage: 'Urakalla on vielä aliurakoita tai tuotteita.',
  },

  viewOldFileTooltip: {
    id: 'view-old-file-toolip',
    description: 'Tooltip for viewing the old file',
    defaultMessage: 'Katsele vanhaa tiedostoa',
  },

  viewNewFileTooltip: {
    id: 'view-new-file-toolip',
    description: 'Tooltip for viewing the new file',
    defaultMessage: 'Katsele uutta tiedostoa',
  },
});

class PackageInformationBasic extends React.Component {
  static propTypes = {
    package: PropTypes.object,
    packageLoading: PropTypes.bool.isRequired,
    products: PropTypes.array,
    log: PropTypes.object,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    productActionCreators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  incompletePackage = () => {
    const data = {
      id: this.props.package.id,
      state: 0, // in progress state
    };

    this.props.messageActionCreators.clearMessages();
    this.props.packageActionCreators.editPackage(data);

    this.props.messageActionCreators._dispatchMessage(
      'success',
      messages.incompleted
    );
  };

  completePackage = () => {
    const data = {
      id: this.props.package.id,
    };

    this.props.messageActionCreators.clearMessages();
    this.props.packageActionCreators.completePackage(data);

    this.props.messageActionCreators._dispatchMessage(
      'success',
      messages.completed
    );
  };

  removePackage = async (event) => {
    event.preventDefault();

    $('.modal').removeClass('fade').modal('hide');
    this.props.messageActionCreators.clearMessages();
    await this.props.packageActionCreators.removePackage(this.props.package.id);

    page.redirect('/');
  };

  archivePackage = (event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    this.props.packageActionCreators.archivePackage(this.props.package.id);
  };

  exportPackage = (event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    this.props.packageActionCreators.exportPackage(this.props.package);
  };

  _packageLog = (pkg, log) => {
    if (!log || pkg.state !== 2) return null;

    const timestamp = new Date(Date.parse(log.last_modified));
    let lastModified = timestamp.getDate() + '.';
    lastModified += timestamp.getMonth() + 1 + '.';
    lastModified += timestamp.getFullYear() + ' ';
    lastModified +=
      (timestamp.getHours() > 9
        ? timestamp.getHours()
        : '0' + timestamp.getHours()) + ':';

    lastModified +=
      timestamp.getMinutes() > 9
        ? timestamp.getMinutes()
        : '0' + timestamp.getMinutes();

    return [
      <dt className='col-xs-5'>
        <FormattedMessage
          id='package-completed'
          description='When the package was installed'
          defaultMessage='Merkitty valmiiksi'
        />
        :
      </dt>,
      <dd className='col-xs-7'>{lastModified}</dd>,
      <dt className='col-xs-5'>
        <FormattedMessage
          id='package-completed-by'
          description='Who installed the package'
          defaultMessage='Merkinnyt valmiiksi'
        />
        :
      </dt>,
      <dd className='col-xs-7'>
        {log.first_name && log.last_name ? (
          <div>
            {log.first_name.charAt(0)}. {log.last_name}
          </div>
        ) : (
          <div>{log.username}</div>
        )}

        <div>{log.email}</div>
        <div>{log.organization}</div>
      </dd>,
    ];
  };

  render() {
    if (this.props.packageLoading || !this.props.package) {
      return (
        <div className='card'>
          <div className='card-block'>
            <h4 className='card-title'>
              <FormattedMessage
                id='basic-information'
                description='Header for basic information'
                defaultMessage='Perustiedot'
              />
            </h4>

            <div className='card-text'>
              <div className='loading loading-green loading-center' />
            </div>
          </div>

          <div className='card-footer'>
            <div className='loading loading-green loading-sm loading-center' />
          </div>
        </div>
      );
    }

    const subpackagesCompleted =
      this.props.package.stats.subpackages_total ===
      this.props.package.stats.subpackages_completed;

    const packageEmpty =
      this.props.package.stats.subpackages_total === 0 &&
      this.props.package.stats.agg_products_total === 0;

    return (
      <div className='PackageInformationBasic'>
        <div
          className='RemovePackageModal modal fade'
          id='RemovePackageModal'
          tabIndex='-1'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='close' data-dismiss='modal'>
                  <span>&times;</span>
                </button>

                <h4 className='modal-title'>
                  <FormattedMessage
                    id='remove-package-header'
                    description='Remove package header'
                    defaultMessage='Urakan "{name}" poistaminen'
                    values={{
                      name: this.props.package.name,
                    }}
                  />
                </h4>
              </div>

              <div className='modal-body'>
                <FormattedHTMLMessage
                  id='remove-package-warning-text'
                  description='Warning text shown to user when removing package'
                  defaultMessage={
                    'Olet poistamassa <strong>urakkaa "{name}"</strong> ' +
                    'ja siihen liittyviä <strong>käyttöoikeuksia</strong>.' +
                    '<br /><br />' +
                    'Oletko varma, että haluat poistaa nämä tiedot?'
                  }
                  values={{
                    name: this.props.package.name,
                  }}
                />
              </div>

              <div className='modal-footer text-center'>
                <button
                  className='btn btn-warning btn-wide-xs'
                  data-dismiss='modal'
                >
                  <FormattedMessage
                    id='remove-package-cancel'
                    description='Cancel button text when removing package'
                    defaultMessage='Ei, älä poista urakkaa'
                  />
                </button>

                <button
                  className='btn btn-danger btn-wide-xs'
                  onClick={this.removePackage}
                >
                  <FormattedMessage
                    id='remove-package-accept'
                    description='Accept button text when removing package'
                    defaultMessage={'Kyllä, poista urakka'}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='card'>
          <div className='card-block'>
            <h4 className='card-title'>
              <FormattedMessage
                id='basic-information'
                description='Header for basic information'
                defaultMessage='Perustiedot'
              />
            </h4>

            <div className='card-text'>
              <dl className='dl-horizontal row'>
                <dt className='col-xs-5'>
                  <FormattedMessage
                    id='name'
                    description='Generic name'
                    defaultMessage='Nimi'
                  />
                  :
                </dt>
                <dd className='col-xs-7'>{this.props.package.name}</dd>

                <dt className='col-xs-5'>
                  <FormattedMessage
                    id='contractor'
                    description='Company name of the package owner'
                    defaultMessage='Urakoitsija'
                  />
                  :
                </dt>
                <dd className='col-xs-7'>{this.props.package.company.name}</dd>

                {this.props.package.parent === null ? null : (
                  <dt className='col-xs-5'>
                    <FormattedMessage
                      id='employer'
                      description='Company name of the package employer'
                      defaultMessage='Tilaaja'
                    />
                    :
                  </dt>
                )}

                {this.props.package.parent === null ? null : (
                  <dd className='col-xs-7'>
                    {this.props.package.parent.company.name}
                  </dd>
                )}

                <dt className='col-xs-5'>
                  <FormattedMessage
                    id='type'
                    description='Generic type'
                    defaultMessage='Tyyppi'
                  />
                  :
                </dt>
                <dd className='col-xs-7'>
                  {this.props.package.parent ? (
                    <div>
                      <i className='fa fa-clone'></i>&nbsp;
                      <FormattedMessage
                        id='package-type-subpackage'
                        description='Package type subpackage'
                        defaultMessage='Aliurakka'
                      />
                    </div>
                  ) : (
                    <div>
                      <i className='fa fa-square-o'></i>&nbsp;
                      <FormattedMessage
                        id='package-type-construction-site'
                        description='Package type construction site'
                        defaultMessage='Työmaa'
                      />
                    </div>
                  )}
                </dd>

                <dt className='col-xs-5'>
                  <FormattedMessage
                    id='package-state'
                    description='Label for package state'
                    defaultMessage='Tilanne'
                  />
                  :
                </dt>
                <dd className='col-xs-7'>
                  {this.props.package.state !== 0 ? null : (
                    <FormattedMessage
                      id='package-state-incomplete'
                      description='Text for incomplete package state'
                      defaultMessage='Keskeneräinen'
                    >
                      {(message) => (
                        <span>
                          <i className='fa fa-circle-o'></i> {message}
                        </span>
                      )}
                    </FormattedMessage>
                  )}

                  {this.props.package.state !== 2 ? null : (
                    <FormattedMessage
                      id='package-state-complete'
                      description='Text for complete package state'
                      defaultMessage='Valmis'
                    >
                      {(message) => (
                        <span>
                          <i className='fa fa-check-circle'></i> {message}
                        </span>
                      )}
                    </FormattedMessage>
                  )}
                </dd>

                {this._packageLog(this.props.package, this.props.log)}
              </dl>

              {this.props.package.parent === null ? null : (
                <p className='text-muted'>
                  <FormattedMessage
                    id='package-construction-site-description'
                    description='Describes the construction site of package'
                    defaultMessage={
                      'Urakka kuuluu työmaahan {name} jonka omistaa {company}.'
                    }
                    values={{
                      name: (
                        <strong>
                          {this.props.package.ancestor.has_access ? (
                            <a
                              href={`/package/${this.props.package.ancestor.id}`}
                            >
                              {this.props.package.ancestor.name}
                            </a>
                          ) : (
                            this.props.package.ancestor.name
                          )}
                        </strong>
                      ),

                      company: (
                        <strong>
                          {this.props.package.ancestor.company.name}
                        </strong>
                      ),
                    }}
                  />
                </p>
              )}

              <div className='PackageInformationBasic-actions m-t'>
                {this.props.package.state !== 0 ? null : (
                  <div className='PackageInformationBasic-actions-container'>
                    <button
                      className='btn btn-primary m-b'
                      onClick={this.completePackage}
                      disabled={!subpackagesCompleted}
                    >
                      <i className='fa fa-check-circle'></i>&nbsp;
                      <FormattedMessage
                        id='complete-package'
                        description='Button text for completing a package'
                        defaultMessage='Merkitse valmiiksi'
                      />
                    </button>

                    <div
                      className={classNames(
                        'PackageInformationBasic-actions-container-top',
                        {
                          'hidden-xs-up': subpackagesCompleted,
                        }
                      )}
                      data-tip={this.props.intl.formatMessage(
                        messages.completeTooltip
                      )}
                    ></div>
                  </div>
                )}

                {this.props.package.state !== 2 ? null : (
                  <button
                    className='btn btn-warning m-t'
                    onClick={this.incompletePackage}
                  >
                    <i className='fa fa-circle-o'></i>&nbsp;
                    <FormattedMessage
                      id='incomplete-package'
                      description='Button text for incompleting a package'
                      defaultMessage='Merkitse keskeneräiseksi'
                    />
                  </button>
                )}

                <div className='PackageInformationBasic-actions-container m-t'>
                  <button
                    className='btn btn-danger'
                    data-toggle='modal'
                    data-target='#RemovePackageModal'
                    disabled={!packageEmpty}
                  >
                    <i className='fa fa-trash-o'></i>&nbsp;
                    <FormattedMessage
                      id='remove-package'
                      description='Button text for removing a package'
                      defaultMessage='Poista urakka'
                    />
                  </button>

                  <div
                    className={classNames(
                      'PackageInformationBasic-actions-container-top',
                      {
                        'hidden-xs-up': packageEmpty,
                      }
                    )}
                    data-tip={this.props.intl.formatMessage(
                      messages.notEmptyTooltip
                    )}
                  ></div>
                </div>

                {this.props.package.parent !== null ||
                this.props.package.state === 3 ? null : (
                  <button
                    className='btn btn-default m-t'
                    onClick={this.archivePackage}
                  >
                    <i className='fa fa-archive'></i>&nbsp;
                    <FormattedMessage
                      id='archive-package'
                      description='Button text for archiving a package'
                      defaultMessage='Arkistoi'
                    />
                  </button>
                )}

                <button
                  className='btn btn-default m-t'
                  onClick={this.exportPackage}
                >
                  <i className='fa fa-download'></i>&nbsp;
                  <FormattedMessage
                    id='export-package'
                    description='Button text for exporting a package hierarchy'
                    defaultMessage='Lataa urakkapohjaksi'
                  />
                </button>
              </div>
            </div>
          </div>
          <div className='card-footer text-center'>
            <a
              className='btn btn-primary-outline'
              href={`/package/${this.props.package.id}/edit`}
            >
              <i className='fa fa-pencil-square-o'></i>&nbsp;
              <FormattedMessage
                id='edit-information'
                description='Button to edit package information'
                defaultMessage='Muokkaa tietoja'
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(PackageInformationBasic);
