import React from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';

const ChemicalActions = ({
  user,
  level,
  loading,
  chemicalInfo,
  onDownloadCodeClick,
  onOpenClick,
  onCreateClick,
  onDeleteClick,
}) => (
  <div>
    <div style={{ flex: 1 }}>
      {loading && (
        <div className='PackageInformationPanel-content-reportLoading m-t'>
          <hr />
          <div className='loading loading-green'>
            <div></div>
          </div>
          <span>
            <FormattedMessage
              id='generating-chemical-chart'
              description='Generating chemical chart text'
              defaultMessage='Luodaan julkista kemikaaliluetteloa'
            />
          </span>
        </div>
      )}

      {user && chemicalInfo && !loading && (
        <div className='m-t'>
          <hr />
          <div className='m-b'>
            <FormattedMessage
              id='last-qrcode-generated'
              description='qrcode-info-text'
              defaultMessage='Julkinen kemikaaliluettelo luotu {time}.'
              values={{
                time: (
                  <FormattedTime
                    year='numeric'
                    month='numeric'
                    day='numeric'
                    hour='numeric'
                    minute='numeric'
                    value={chemicalInfo.created_at}
                  />
                ),
              }}
            />
          </div>

          <div className='flexrow'>
            <button className='btn btn-success' onClick={onDownloadCodeClick}>
              <i className='fa fa-qrcode' />
              &nbsp;
              <FormattedMessage
                id='download-chemical-chart-code'
                description={
                  'Button for downloading public chemical chart code'
                }
                defaultMessage='Lataa QR-koodi'
              />
            </button>

            <button className='btn btn-success' onClick={onOpenClick}>
              <i className='fa fa-external-link' />
              &nbsp;
              <FormattedMessage
                id='open-chemical-chart'
                description='Button for opening public chemical chart code'
                defaultMessage='Avaa kemikaaliluettelo'
              />
            </button>

            {(user.admin || user.role === 0 || user.role === 1) && (
              <div>
                <button className='btn btn-danger' onClick={onDeleteClick}>
                  <FormattedMessage
                    id='remove-chemical-chart'
                    description='Button for removing public chemical chart'
                    defaultMessage='Poista kemikaaliluettelo'
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {user &&
        (user.admin || user.role === 0 || user.role === 1) &&
        !chemicalInfo &&
        !loading && (
          <div className='m-t'>
            <hr />
            <button className='btn btn-success' onClick={onCreateClick}>
              <i className='fa fa-flask' />
              &nbsp;
              <FormattedMessage
                id='generate-chemical-chart'
                description='Button for generating public chemical chart'
                defaultMessage='Luo kemikaaliluettelo'
              />
            </button>
          </div>
        )}
    </div>
  </div>
);

export default ChemicalActions;
