/**
 * MobileMenu.js - React component MobileMenu
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import HeaderUser from '../HeaderUser/HeaderUser';
import Navigation from '../Navigation/Navigation.jsx';

import classNames from 'classnames';

class MobileMenu extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    toggleMenu: PropTypes.func.isRequired,
    menuOpen: PropTypes.bool.isRequired,
  };

  render() {
    const mobileMenuClasses = classNames('MobileMenu-content', 'hidden-sm-up', {
      open: this.props.menuOpen,
    });

    const backdropClasses = classNames('MobileMenu-backdrop', {
      'hidden-sm-up': this.props.menuOpen,
      'hidden-xs-up': !this.props.menuOpen,
    });

    return (
      <div className='MobileMenu'>
        <div className={backdropClasses} onClick={this.props.toggleMenu} />
        <div className={mobileMenuClasses}>
          <div className='MobileMenu-close hidden-sm-up'>
            <i
              className='fa fa-times pull-right'
              onClick={this.props.toggleMenu}
            ></i>
          </div>

          <HeaderUser user={this.props.user} />
          <Navigation user={this.props.user} />
        </div>
      </div>
    );
  }
}

export default MobileMenu;
