/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';

import Footer from '../Footer/Footer';
import PackageDragTarget from '../Draggable/PackageDragTarget';
import ProductDragSource from '../Draggable/ProductDragSource';
import ConfirmActionModal from '../ConfirmActionModal/ConfirmActionModal.tsx';
import dropValidator from '../Draggable/dropValidator';
import { ProductType } from '../../utils/enum';
import { hasSdsFile } from '../../utils/productUtils';

class ChemicalHierarchy extends React.Component {
  static propTypes = {
    packageID: PropTypes.number.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    productActionCreators: PropTypes.object.isRequired,
    logActionCreators: PropTypes.object.isRequired,
    packages: PropTypes.object,
    packagesLoading: PropTypes.bool.isRequired,
    package: PropTypes.object,
    packageLoading: PropTypes.bool.isRequired,
    onSelectPackage: PropTypes.func.isRequired,
    product: PropTypes.object,
    productLoading: PropTypes.bool.isRequired,
    products: PropTypes.array,
    packageProducts: PropTypes.object,
    productsLoading: PropTypes.bool.isRequired,
    onSelectProduct: PropTypes.func.isRequired,
    isProductPanel: PropTypes.bool.isRequired,
    archivedCount: PropTypes.number,
    openSidePanel: PropTypes.func.isRequired,
    user: PropTypes.object,
    intl: PropTypes.object.isRequired,
    activePackage: PropTypes.number,
  };

  state = {
    showArchived: false,
    item: null,
    from: null,
    to: null,
    dontShowAgain: false,
    expandedPackages: {},
  };

  componentDidMount() {
    document.getElementById('content').addEventListener('scroll', (event) => {
      if (event.target.scrollTop > 100) {
        document.getElementById('header-row').style.display = 'initial';
      } else if (event.target.scrollTop < 100) {
        document.getElementById('header-row').style.display = 'none';
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.packagesLoading !== prevProps.packagesLoading) {
      const expanded = {};

      for (const pkgId of Object.keys(this.props.packages)) {
        if (!this.props.packages[pkgId]?.has_access) {
          continue;
        }
        expanded[pkgId] = true;
        const el = document.getElementById('collapse' + pkgId);
        if (el) el.className = 'collapse';
        if (!this.props.packageProducts[pkgId]) {
          setTimeout(() => {
            this.props.packageActionCreators.getPackageProducts(pkgId);
          }, 0);
        }
        setTimeout(() => {
          if (el) {
            $(`#collapse${pkgId}`).collapse('show');
          }
        }, 100);
      }

      this.setState({ expandedPackages: expanded });
    }

    if (
      this.props.packagesLoading !== prevProps.packagesLoading &&
      this.props.activePackage !== null
    ) {
      let rootPackageId = this.props.package.id;
      if (this.props.package && this.props.package.level !== 1) {
        rootPackageId = this.props.package.ancestor.id;
      }
      if (
        this.props.activePackage &&
        rootPackageId &&
        document.getElementById('package' + rootPackageId)
      ) {
        const scrollPosition = document.getElementById(
          'package' + rootPackageId
        ).offsetTop;
        document.getElementById('content').scrollTop = scrollPosition - 60;
      }
    }
  }

  /**
   * Creates a hierarchy of packages for display.
   * Does not return packages the user doesn't have access to.
   * @param {Object[]} nodes - list of package nodes
   * @returns {Object} - hierarchy of packages to display
   */
  treeify = (nodes) => {
    const roots = [];

    Object.keys(nodes).forEach((id) => {
      nodes[id].children = [];
    });

    Object.keys(nodes).forEach((id) => {
      const node = nodes[id];
      if (!node.has_access) return;

      node.products = this.props.packageProducts
        ? this.props.packageProducts[node.id] || []
        : [];

      node.approved_count =
        node.products.length > 0
          ? node.products.reduce(
              (sum, prod) => (prod.approved ? sum + 1 : sum),
              0
            )
          : node.approved_count;

      node.path = [];
      node.children = node.children || [];
      node.num_descendants = 0;
      node.num_descendants_complete = 0;
      node.num_descendants_approved_count = 0;
      node.num_descendants_total_count = 0;
      node.num_descendants_total_approvable_count = 0;

      let parent = nodes[node.parent];
      if (
        parent &&
        parent.has_access &&
        Object.values(nodes).some((other) => other.level < node.level)
      ) {
        parent.children = parent.children || [];
        parent.children.push(node);
      } else {
        roots.push(node);
      }

      while (parent && parent.has_access) {
        parent.num_descendants += 1;
        parent.num_descendants_complete += node.state === 2;
        parent.num_descendants_approved_count += Number(node.approved_count);
        parent.num_descendants_total_count += Number(node.total_count);
        parent.num_descendants_total_approvable_count += Number(
          node.total_approvable_count
        );
        node.path.push(parent.id);
        parent = nodes[parent.parent];
      }
    });

    return roots.filter((root) => root.id === this.props.packageID);
  };

  _productRows = (data) => {
    const elements = [];

    // If the package has products, list them
    if (data.products && data.products.length) {
      let products = data.products.length
        ? data.products
        : this.state.products[data.id];

      products = products.filter((prod) => {
        const pData = (prod.snapshot && prod.snapshot_data) || prod.data || {};
        if (!pData.variant) {
          pData.variant = hasSdsFile(prod)
            ? ProductType.CHEMICAL_PRODUCT
            : ProductType.PRODUCT;
        }
        return (
          pData.variant === ProductType.CHEMICAL ||
          pData.variant === ProductType.CHEMICAL_PRODUCT
        );
      });

      products.forEach((prod, index) => {
        /* HACK: most likely a hack to pull the product from product store
         *       to correctly update the product status
         */
        let product = prod;
        if (product.data && !product.data.id && !product.snapshot) return null;
        const information =
          product.snapshot && product.snapshot_data
            ? product.snapshot_data
            : product.data;
        if (this.props.product && prod.id === this.props.product.id) {
          product = this.props.product;
          products[index] = this.props.product;
        }

        const hazardCodes = product.hazard_codes || information.hazard_codes;
        const safetyCodes = product.safety_codes || information.safety_codes;
        const warningCodes = product.warning_codes || information.warning_codes;

        const statusStyle = classNames(
          'ChemicalHierarchy-row-content-label-chemicalStatus',
          'pull-right'
        );

        elements.push(
          <ProductDragSource
            key={product.id}
            product={product}
            onDrop={(tgt) => {
              const from = this.props.packages[product.package];
              const to = this.props.packages[tgt.id];

              this.setState({
                item: product,
                from: from,
                to: to,
              });

              if (this.state.dontShowAgain) {
                this.movePackageProduct();
              } else {
                $('#ChemicalMovePackageProductModal').modal('show');
              }
            }}
          >
            <li
              key={product.id}
              className={classNames('product', {
                active:
                  this.props.isProductPanel &&
                  !this.props.productLoading &&
                  this.props.product &&
                  this.props.product.id === product.id,
              })}
              style={{ paddingLeft: '15px' }}
              onClick={() => this.selectProductRow(data, product)}
            >
              <span>{information.name}</span>

              <div className={statusStyle}>
                <span className={hazardCodes ? 'ok' : 'missing'}>
                  {hazardCodes ? 'OK' : 'PUUTTUU'}
                </span>
                <span className={safetyCodes ? 'ok' : 'missing'}>
                  {safetyCodes ? 'OK' : 'PUUTTUU'}
                </span>
                <span className={warningCodes ? 'ok' : 'missing'}>
                  {warningCodes ? 'OK' : 'PUUTTUU'}
                </span>
              </div>

              <div className='ChemicalHierarchy-row-content---identifier'>
                {information.manufacturer ? (
                  `${information.manufacturer}`
                ) : (
                  <br />
                )}
              </div>
            </li>
          </ProductDragSource>
        );
      });
    }

    return elements;
  };

  selectProductRow = (pkg, product) => {
    this.props.packageActionCreators.getPackageProduct(pkg.id, product);
    this.props.logActionCreators.clearLog();
    this.props.logActionCreators.getProductLog(product.id);
    this.props.productActionCreators.getExternalProduct(product);
    this.props.onSelectProduct(pkg);
  };

  _packageRow = (data) => {
    const elements = [];
    const toggleClasses = classNames({
      fa: true,
      'fa-angle-up': this.state.expandedPackages[data.id] ?? false,
      'fa-angle-down': !this.state.expandedPackages[data.id] ?? false,
      'fa-lg': true,
    });

    const doubleToggleClasses = classNames({
      fa: true,
      'fa-angle-double-up': this.state.expandedPackages[data.id] ?? false,
      'fa-angle-double-down': !this.state.expandedPackages[data.id] ?? false,
      'fa-lg': true,
    });

    // Differentiate construction sites in the list
    const packageClasses = classNames({
      'ChemicalHierarchy-construction-site': !data.parent,
      'ChemicalHierarchy-has-children': data.num_descendants > 0,
      'ChemicalHierarchy-row': true,
      'row-flex': true,
      'align-vert': true,
      active:
        !this.props.isProductPanel &&
        !this.props.packageLoading &&
        this.props.activePackage === data.id,
      incomplete: !data.is_archived && data.state === 0,
      complete: !data.is_archived && data.state === 2,
      archived: data.is_archived || data.state === 3,
    });

    const subpackagesComplete =
      data.num_descendants !== 0 &&
      data.num_descendants === data.num_descendants_complete;

    const labelClasses = classNames(
      'ChemicalHierarchy-row-content-label',
      'label',
      {
        'label-default': !subpackagesComplete,
        'label-success': subpackagesComplete,
      }
    );

    elements.push(
      <li
        key={'package' + data.id}
        id={'package' + data.id}
        className={packageClasses}
      >
        <div
          className='ChemicalHierarchy-row-content'
          onClick={this.selectRow.bind(this, data.id)}
        >
          <div className='row-flex'>
            {data.children.length ||
            this.props.packages[data.id].approved !== null ? (
              <i
                className={toggleClasses}
                style={{
                  padding: '0 10px 0 10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={this.collapseRow.bind(this, data.id)}
              />
            ) : null}
            {data.children.length ||
            this.props.packages[data.id].approved !== null ? (
              <i
                className={doubleToggleClasses}
                style={{
                  paddingRight: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={this.collapseNestedRow.bind(this, data.id)}
              />
            ) : null}
            <span
              style={
                data.children.length ||
                this.props.packages[data.id].approved !== null
                  ? null
                  : { marginLeft: '15px' }
              }
            >
              {data.name}
            </span>
            <span>{`(${data.company.name})`}</span>
            {data.is_archived || data.children.length === 0 ? null : (
              <span className={labelClasses}>
                <FormattedMessage
                  id='completed-subpackages'
                  description='Label for completed subpackages'
                  defaultMessage={`Valmiit aliurakat: {completed, number}/{total, number}`}
                  values={{
                    completed: data.num_descendants_complete,
                    total: data.num_descendants,
                  }}
                />
              </span>
            )}

            {this.state.activePackage === data.id &&
              this.props.productsLoading && (
                <div className='loading loading-sm loading-green m-l'>
                  <div></div>
                </div>
              )}
          </div>
        </div>
        <div
          className='ChemicalHierarchy-row-content-info-button text-center'
          onClick={this.openPanel.bind(this, data.id)}
        >
          {this.props.packageLoading && this.state.activePackage === data.id ? (
            <a
              className='ChemicalHierarchy-row-content-info-button--loading'
              href='#'
            >
              <div className='loading loading-green loading-center loading-sm'>
                <div></div>
              </div>
            </a>
          ) : (
            <a href='#'>
              <i className='fa fa-info-circle'></i>
            </a>
          )}
        </div>
      </li>
    );

    elements.push(
      <ul
        key={`collapse${data.id}`}
        className='collapse'
        id={`collapse${data.id}`}
      >
        {data.children.map(this._packageRow)}
        {this._productRows(data)}
      </ul>
    );

    return (
      <PackageDragTarget
        key={data.id}
        package={data}
        dropValidator={(type, item, target) =>
          dropValidator(type, item, target, data, this.props.packages)
        }
        onOver={() => {
          if (!this.state.expandedPackages[data.id]) {
            this.collapseRow(data.id);
          }
        }}
        onDrop={(tgt) => {
          const to = this.props.packages[tgt.id];

          this.setState({
            item: data,
            from: null,
            to: to,
          });

          if (this.state.dontShowAgain) {
            this.movePackageProduct();
          } else {
            $('#ChemicalMovePackageProductModal').modal('show');
          }
        }}
      >
        {elements}
      </PackageDragTarget>
    );
  };

  openPanel = (packageID, event) => {
    event.preventDefault();

    this.props.packageActionCreators.setActivePackage(packageID);
    this.props.packageActionCreators.openMobileMenu();
    this.props.logActionCreators.clearLog();
    this.props.logActionCreators.getPackageLog(packageID);
    this.props.packageActionCreators.getPackage(packageID);
    this.props.packageActionCreators.getPackageProducts(packageID);
  };

  _setAddToParent = () => {
    this.props.onSelectPackage();
    this.props.packageActionCreators.setAddToParent(null);
  };

  collapseRow = (packageID, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const isExpanded = this.state.expandedPackages[packageID];
    if (!isExpanded) {
      this.props.packageActionCreators.setActivePackage(packageID);
      this.props.logActionCreators.clearLog();
      this.props.logActionCreators.getPackageLog(packageID);
      this.props.packageActionCreators.getPackage(packageID);
      this.props.packageActionCreators.getPackageProducts(packageID);
      this.props.packageActionCreators.getChemicalChartInfo(packageID);
      this.props.onSelectPackage();
    }
    const newExpanded = {
      ...this.state.expandedPackages,
      [packageID]: !this.state.expandedPackages[packageID],
    };
    this.setState({ expandedPackages: newExpanded });
    $(`#collapse${packageID}`).collapse(!isExpanded ? 'show' : 'hide');
  };

  collapseNestedRow = (packageId, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const expanded = { ...this.state.expandedPackages };

    const subpackages = Object.values(this.props.packages)
      .filter((p) => p.path?.includes(packageId))
      .map((p) => p.id);

    const collapsePath = [packageId, ...subpackages];

    const isExpanded = expanded[packageId];

    if (!isExpanded) {
      this.props.packageActionCreators.setActivePackage(packageId);
      this.props.logActionCreators.clearLog();
      this.props.logActionCreators.getPackageLog(packageId);
      this.props.packageActionCreators.getPackage(packageId);
      this.props.packageActionCreators.getPackageProducts(packageId);
      this.props.packageActionCreators.getChemicalChartInfo(packageId);
      this.props.onSelectPackage();

      for (const id of collapsePath) {
        if (!this.props.packageProducts[id]) {
          this.props.packageActionCreators.getPackageProducts(id);
        }
        expanded[id] = true;
        $(`#collapse${id}`).collapse('show');
      }
    } else {
      for (const id of collapsePath) {
        expanded[id] = false;
        $(`#collapse${id}`).collapse('hide');
      }
    }

    this.setState({ expandedPackages: expanded });
  };

  selectRow = (packageID, event) => {
    if (event) event.preventDefault();

    this.props.packageActionCreators.setActivePackage(packageID);
    this.props.logActionCreators.clearLog();
    this.props.logActionCreators.getPackageLog(packageID);
    this.props.packageActionCreators.getPackage(packageID);
    this.props.packageActionCreators.getPackageProducts(packageID);
    this.props.packageActionCreators.getChemicalChartInfo(packageID);
    this.props.onSelectPackage();
  };

  movePackageProduct = () => {
    if (!this.state.item.level) {
      this.props.packageActionCreators.movePackageProduct(
        this.state.item.id,
        this.state.item.package,
        this.state.to.id
      );
    } else {
      this.props.packageActionCreators.movePackage(
        this.state.item.id,
        this.state.to.id
      );
    }

    this.setState({
      item: null,
      from: null,
      to: null,
    });
  };

  _getHierarchy = (packages) => {
    return !this.props.packagesLoading && packages ? (
      <ul className='row-margins'>
        {this.treeify(packages).map(this._packageRow)}
      </ul>
    ) : (
      <div className='loading loading-green loading-center m-t-md'>
        <div></div>
      </div>
    );
  };

  _formatDate = (date) => {
    const notTooShortIdentifierName = new Date(date);
    return (
      notTooShortIdentifierName.getUTCDate() +
      '.' +
      (notTooShortIdentifierName.getUTCMonth() + 1) +
      '.' +
      notTooShortIdentifierName.getUTCFullYear()
    );
  };

  render() {
    let activePackages = null;

    if (!this.props.packagesLoading && this.props.packages) {
      activePackages = {};

      const rootPackage = this.props.packages[this.props.packageID];

      Object.keys(this.props.packages).forEach((key) => {
        const pkg = this.props.packages[key];
        if (
          (pkg.level > rootPackage.level || pkg.id === rootPackage.id) &&
          !pkg.is_archived
        ) {
          activePackages[pkg.id] = pkg;
        }
      });
    }

    let modalInfo = null;
    if (this.state.item && this.state.to) {
      modalInfo = this.state.item.level ? (
        <FormattedMessage
          id='info-message'
          description='Info message'
          defaultMessage={`Olet siirtämässä urakan {item} ja
              kaikki siihen kuuluvat tuotteet sekä aliurakat urakkaan
              {target}
            `}
          values={{
            item: <b>{this.state.item.name}</b>,
            target: <b>{this.state.to.name}</b>,
          }}
        />
      ) : (
        <FormattedMessage
          id='info-message-product'
          description='Info message'
          defaultMessage={`Olet siirtämässä tuotetta {item}
              urakasta {from} urakkaan {to}.
            `}
          values={{
            item: <b>{this.state.item.data.name}</b>,
            from: <b>{this.state.from.name}</b>,
            to: <b>{this.state.to.name}</b>,
          }}
        />
      );
    }

    return (
      <div id='content' className='ChemicalHierarchy col-xs-12 col-sm-8'>
        <ConfirmActionModal
          id={'ChemicalMovePackageProductModal'}
          title={
            this.state.item && this.state.item.level
              ? 'Siirrä urakka'
              : 'Siirrä tuote'
          }
          info={modalInfo}
          onConfirm={({ dontShowAgain }) => {
            this.setState({
              dontShowAgain,
            });
            this.movePackageProduct();
          }}
          hideable
        />
        <div id='header-row' className='ChemicalHierarchy-content'>
          <div
            id='header-content'
            className='ChemicalHierarchy-header-legend col-xs-12 no-padding'
          >
            <small>
              <i className='ChemicalHierarchy-header-legend--incomplete'></i>{' '}
              keskeneräinen
              <i className='ChemicalHierarchy-header-legend--complete'></i>{' '}
              valmis
            </small>
            <small className='pull-right'>
              Vaaralauseke | Turvalauseke | Varoitusmerkki
            </small>
          </div>
        </div>
        <div className='ChemicalHierarchy-content'>
          <div
            id='header-row-crollable'
            className='ChemicalHierarchy-header row'
          >
            <div className='ChemicalHierarchy-header-legend col-xs-12 no-padding'>
              <small>
                <i className='ChemicalHierarchy-header-legend--incomplete'></i>{' '}
                keskeneräinen
                <i className='ChemicalHierarchy-header-legend--complete'></i>{' '}
                valmis
              </small>
              <small className='pull-right'>
                Vaaralauseke | Turvalauseke | Varoitusmerkki
              </small>
              <div className='clearfix' />
            </div>
          </div>
          <div>
            <div className='flexrow m-b'>
              <h3 className='ChemicalHierarchy-header-title'>
                <FormattedMessage
                  id='all-packages'
                  description='Package hierarchy header'
                  defaultMessage='Kemikaaliluettelo'
                />
              </h3>
            </div>

            {this._getHierarchy(activePackages)}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default injectIntl(ChemicalHierarchy);
