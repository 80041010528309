/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Messages from '../Messages/Messages';
import LogTypes from '../../../shared/LogTypes.mjs';

import { FormattedMessage } from 'react-intl';

class LogView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    logStore: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this._getStoreData();
  }

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );

    this._logStoreSubscription = this.props.logStore.addListener(
      this._onStoreChange
    );
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
    this._logStoreSubscription.remove();
  }

  _onStoreChange = () => {
    this.setState(this._getStoreData());
  };

  _getStoreData = () => {
    return {
      packageState: this.props.packageStore.getState(),
      rtState: this.props.rtStore.getState(),
      logState: this.props.logStore.getState(),
      errors: {},
    };
  };

  _getLogs = () => {
    if (!this.state.logState.logs) {
      return (
        <tr>
          <td colSpan='6'>
            <div className='loading loading-green loading-center m-t-md'>
              <div></div>
            </div>
          </td>
        </tr>
      );
    }

    return this.state.logState.logs.map((log) => {
      const type = Object.keys(LogTypes).find((key) => {
        return LogTypes[key] === log.type;
      });

      return (
        <tr key={log.timestamp}>
          <td>{log.username}</td>
          <td>{log.company}</td>
          <td>{type}</td>
          <td>{log.timestamp}</td>
          <td>{log.ip_address}</td>
          <td>{JSON.stringify(log.data)}</td>
        </tr>
      );
    });
  };

  _getContent = () => {
    return (
      <div className='container-fluid'>
        <Header
          user={this.state.rtState.user}
          packages={this.state.packageState.packageSearch}
          searchLoading={this.state.packageState.packageSearchLoading}
          packageActionCreators={this.props.packageActionCreators}
        />

        <Messages
          messageStore={this.props.messageStore}
          messageActionCreators={this.props.messageActionCreators}
        />

        <div className='LogView-content row'>
          <div className='col-xs-12 col-sm-10 col-sm-offset-1'>
            <h2>Lokien katselu</h2>

            <table className='table'>
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Company</th>
                  <th>Type</th>
                  <th>Timestamp</th>
                  <th>IP address</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>{this._getLogs()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let content = null;

    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='LogView'>
        {content}
        <Footer />
      </div>
    );
  }
}

export default AuthenticatedView(LogView);
