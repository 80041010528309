import PropTypes from 'prop-types';
import React from 'react';
import page from 'page';

import { FormattedMessage } from 'react-intl';

export default (ComposedView) => {
  return class extends React.Component {
    static propTypes = {
      rtStore: PropTypes.object.isRequired,
    };

    constructor(props) {
      super(props);
      this.state = this._getStoreData();
    }

    componentDidMount() {
      this._rtStoreSubscription = this.props.rtStore.addListener(
        this._onStoreChange
      );
    }

    componentWillUnmount() {
      this._rtStoreSubscription.remove();
    }

    _getStoreData = () => {
      return {
        rtState: this.props.rtStore.getState(),
      };
    };

    _onStoreChange = () => {
      this.setState(this._getStoreData());
    };

    _getContent = () => {
      return <ComposedView {...this.props} />;
    };

    render() {
      let content = null;

      if (this.state.rtState.error !== null) {
        content = (
          <p className='text-danger'>
            <FormattedMessage
              id='redirect-unauthenticated-user'
              description='Redirect unauthenticated user to login'
              defaultMessage='Et ole kirjautunut sisään, uudelleenohjataan...'
            />
          </p>
        );

        page.redirect('/login');
      } else {
        content = this._getContent();
      }

      return content;
    }
  };
};
