import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';

import { getProductData } from '../../utils/productUtils';
import { getFileTypePath } from '../../utils/fileUtil';

const messages = defineMessages({
  manualProductTooltip: {
    id: 'manual-product-tooltip',
    description: 'Tooltip message for manual products',
    defaultMessage:
      'Tuote on käsin lisätty, eikä sen tietojen ' +
      'oikeellisuudesta ole takuita.',
  },
});

interface Props {
  product: {
    id: string;
    status: 0 | 1;
    data?: {
      deleted?: boolean;
      id?: string;
    };
    external_id?: string | null;
    snapshot: any;
    file_types?: any[];
  };
  isActive: boolean;
  openPanel(product: any): void;
}

const ProductRow = ({ product, isActive, openPanel }: Props) => {
  // Differentiate construction sites in the list
  const data = getProductData(product);

  const fullyDeleted =
    product.external_id &&
    !product.snapshot &&
    (!product.data || !product.data.id);

  const productClasses = classNames(
    'PackageProductsLocal-row',
    'row-flex',
    'align-vert',
    {
      active: isActive,
      planned: product.status === 0,
      installed: product.status === 1,
      deleted: fullyDeleted,
    }
  );

  const productContentClasses = classNames('PackageProductsLocal-row-content', {
    deleted: fullyDeleted,
  });
  const productContentIdentifierClasses = classNames(
    'PackageProductsLocal-row-content---identifier',
    {
      deleted: fullyDeleted,
    }
  );
  const productContentRight = classNames(
    'PackageProductsLocal-row-content-right',
    {
      deleted: fullyDeleted,
    }
  );

  const manualClasses = classNames(
    'PackageProductsLocal-row-content-manual',
    'pull-right',
    'm-l',
    {
      'hidden-xs-up': product.external_id,
    }
  );

  const fileTypes = product.file_types
    ? product.file_types.filter((type) => type.length > 0)
    : [];

  const { formatMessage } = useIntl();

  return (
    <div
      className='col-xs-12 no-padding'
      key={product.id}
      onClick={(e) => {
        e.preventDefault();
        openPanel(product);
      }}
    >
      <li className={productClasses}>
        <div className={productContentClasses}>
          {fullyDeleted ? (
            <span>Tuote on poistunut tuotetietokannasta</span>
          ) : (
            <span>{data.name}</span>
          )}
          <div className={productContentRight}>
            <div
              className='PackageProductsGlobal-row-content-filetypes'
              dir='rtl'
            >
              {fileTypes.map((type) => (
                <span
                  key={type}
                  className='PackageProductsLocal-row-content-filetype'
                >
                  <img src={getFileTypePath(type)} />
                </span>
              ))}
            </div>
            <span
              className={manualClasses}
              data-tip={formatMessage(messages.manualProductTooltip)}
            >
              <i className='fa fa-exclamation-triangle'></i>
            </span>
          </div>
          <div className={productContentIdentifierClasses}>
            {data.talo2000
              ? `${data.talo2000} :: ${data.manufacturer}`
              : data.manufacturer}
          </div>
        </div>
      </li>
    </div>
  );
};

export default ProductRow;
