/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';

class Pagination extends React.Component {
  static propTypes = {
    page: PropTypes.number,
    count: PropTypes.number,
    perPage: PropTypes.number.isRequired,
    handler: PropTypes.func.isRequired,
  };

  _navigate = (page, event) => {
    event.preventDefault();
    this.props.handler(page);
  };

  _paginationLink = (page, inner, condition = true) => {
    if (!condition) return null;

    const handler = this._navigate.bind(this, page);
    const classes = classNames(
      'page-item',
      {
        'active': (this.props.page === page),
      }
    );

    return (
      <li className={classes}>
        <a href='#' className='page-link' onClick={handler}>
          {inner}
        </a>
      </li>
    );
  };

  render() {
    if (!this.props.page || !this.props.count) return null;

    const page = this.props.page;
    const pages = Math.ceil(this.props.count / this.props.perPage);
    if (pages === 1) return null;

    return (
      <div className='Pagination text-center'>
        <ul className='pagination'>
          {this._paginationLink(
            page - 1,
            <FormattedMessage
              id='navigation-previous'
              description='Link for navigating to previous page'
              defaultMessage='Edellinen'
            />,
            page > 1
          )}

          {this._paginationLink(1, <span>1</span>)}

          {page > 3 && pages > 3 &&
            <li className='page-item disabled'>
              <span>...</span>
            </li>
          }

          {this._paginationLink(
            page - 2,
            <span>{page - 2}</span>,
            page > 2 && page === pages && pages > 3
          )}

          {this._paginationLink(page - 1, <span>{page - 1}</span>, page > 2)}

          {this._paginationLink(
            page,
            <span>{page}</span>,
            page !== 1 && page !== pages
          )}

          {this._paginationLink(
            page + 1,
            <span>{page + 1}</span>,
            page < pages - 1
          )}

          {this._paginationLink(
            page + 2,
            <span>{page + 2}</span>,
            page < pages - 1 && page === 1 && pages > 3
          )}

          {pages > 3 && page < pages - 2 &&
            <li className='page-item disabled'>
              <span>...</span>
            </li>
          }

          {this._paginationLink(pages, <span>{pages}</span>)}

          {this._paginationLink(
            page + 1,
            <FormattedMessage
              id='navigation-next'
              description='Link for navigating to next page'
              defaultMessage='Seuraava'
            />,
            page < pages
          )}
        </ul>
      </div>
    );
  }
}

export default Pagination;
