export const formatDate = (date) => {
  const notTooShortIdentifierName = new Date(date);
  return (
    notTooShortIdentifierName.getUTCDate() +
    '.' +
    (notTooShortIdentifierName.getUTCMonth() + 1) +
    '.' +
    notTooShortIdentifierName.getUTCFullYear()
  );
};
