import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import ProductDragSource from './ProductDragSource';
import { sortFolderTree } from '../../utils/packageUtils';
import { getFileTypePath } from '../../utils/fileUtil';
import FolderRow from './FolderRow';
import DeleteFolderModal from './DeleteFolderModal';

const messages = defineMessages({
  rootFolderName: {
    id: 'company-folder-tree-root-folder-name',
    description: 'Company root folder name',
    defaultMessage: 'Tuoteryhmät',
  },
  chemicalFolderName: {
    id: 'company-folder-tree-chemical-folder-name',
    description: 'Company chemical folder name',
    defaultMessage: 'Kemikaalit',
  },
  selectTooltip: {
    id: 'product-select-tooltip',
    description: 'Tooltip for selecting product',
    defaultMessage: 'Valitse tuote',
  },
  unselectAllTooltip: {
    id: 'product-unselect-all-tooltip',
    description: 'Tooltip for unselecting all products',
    defaultMessage: 'Peruuta valinnat',
  },
  productDeleteTooltip: {
    id: 'product-delete-tooltip',
    description: 'Tooltip for deleting a folder',
    defaultMessage: 'Poista tuote ryhmästä',
  },
  deleteSelectionsTooltip: {
    id: 'product-delete-selections-tooltip',
    description: 'Tooltip for deleting all selected products',
    defaultMessage: 'Poista valitut tuotteet',
  },
  dragPreview: {
    id: 'product-drag-preview-text',
    description: 'Text for the drag preview when dragging multiple',
    defaultMessage: `Siirretään {count, number} {count, plural, one {tuote}
                    other {tuotetta}}`,
  },
});

class CompanyFolderTree extends React.Component {
  static propTypes = {
    editable: PropTypes.bool,
    folders: PropTypes.array.isRequired,
    packageProducts: PropTypes.array,
    onProductClick: PropTypes.func.isRequired,
    companyActionCreators: PropTypes.object.isRequired,
    chemicalProducts: PropTypes.array,
    intl: PropTypes.object.isRequired,
  };

  state = {
    collapsed: {},
    selectedProducts: {},
    selectedFolders: {},
    collidingProducts: {},
    aboutToAddFolder: null,
    editingFolderName: null,
    deletingFolder: null,
    hoverProduct: null,
    hoverFolder: null,
  };

  onProductDrop = (target, product) => {
    const { selectedProducts } = this.state;
    const links = [];

    if (Object.keys(selectedProducts).length) {
      Object.values(selectedProducts).forEach((prod) => {
        links.push(prod.folder_link);
      });

      this.props.companyActionCreators.moveFolderLinks({
        destination_id: target.id,
        folder_links: links,
      });
    } else if (product.folder_link) {
      this.props.companyActionCreators.moveFolderLinks({
        destination_id: target.id,
        folder_links: [product.folder_link],
      });
    } else {
      this.props.companyActionCreators.addProductToFolder(target.id, {
        product_id: product.data.id,
        manual: product.data.manual,
      });
    }

    this.setState({
      selectedProducts: {},
      collidingProducts: {},
      productDragPreview: null,
    });
  };

  onProductSelect = (product) => {
    const { selectedProducts, collidingProducts } = this.state;
    const folderlessId = `${product.data.id}_${product.data.manual}`;
    const id = `${product.folder_id}_${folderlessId}`;
    const selected = this.isProductSelected(product);

    if (selected) {
      delete selectedProducts[id];
    } else {
      selectedProducts[id] = product;
    }

    const collisions = Object.keys(selectedProducts).filter(
      (key) => id !== key && key.split('_').slice(1).join('_') === folderlessId
    );

    if (collidingProducts[folderlessId] && collisions.length <= 1 && selected) {
      delete collidingProducts[folderlessId];
    } else if (collisions.length >= 1 && !selected) {
      collidingProducts[folderlessId] = product;
    }

    const productDragPreview = this.renderDragPreview(
      Object.keys(selectedProducts).length
    );

    this.setState({ selectedProducts, collidingProducts, productDragPreview });
  };

  isProductSelected = (product) => {
    const { selectedProducts } = this.state;
    const folderlessId = `${product.data.id}_${product.data.manual}`;
    const id = `${product.folder_id}_${folderlessId}`;
    return !!selectedProducts[id];
  };

  treeify = (folders) => {
    const rootFolder = folders.find((folder) => folder.parent === null);

    const nodes = folders.reduce((acc, folder) => {
      folder.children = [];
      acc[folder.id] = folder;
      return acc;
    }, {});

    Object.values(nodes).forEach((node) => {
      const parent = nodes[node.parent];
      if (parent) {
        parent.children.push(node);
      }
    });

    return sortFolderTree([rootFolder])[0];
  };

  collapseRow = (folderId) => {
    const { collapsed } = this.state;
    collapsed[folderId] = !collapsed[folderId];

    this.setState({
      collapsed,
    });

    $(`#collapse${folderId}`).collapse(collapsed[folderId] ? 'show' : 'hide');
  };

  deleteSelected = () => {
    const { selectedProducts } = this.state;
    const links = Object.values(selectedProducts).map(
      (product) => product.folder_link
    );

    this.props.companyActionCreators.removeFolderLinks({ folder_links: links });

    this.setState({
      selectedProducts: {},
      collidingProducts: {},
      renderDragPreview: null,
    });
  };

  _productRow = (product, editable) => {
    const { packageProducts } = this.props;
    const {
      hoverProduct,
      collidingProducts,
      selectedProducts,
      productDragPreview,
    } = this.state;

    const data = product.data;
    const selected = this.isProductSelected(product);
    const selectedCount = Object.keys(selectedProducts).length;
    const collisionCount = Object.keys(collidingProducts).length;
    const collides = !!collidingProducts[`${data.id}_${data.manual}`];

    const hovering =
      hoverProduct && hoverProduct.folder_link === product.folder_link;

    const selectClass = classNames('fa', 'fa-lg', 'fa-fw', {
      'fa-square-o': !selected,
      'fa-check-square-o': selected,
      'text-warning': collides && selected,
    });

    let rightComponent = null;
    const packageProduct =
      packageProducts &&
      packageProducts.find((pr) => {
        const prData = pr.data || pr.snapshot_data || {};
        return prData.id === data.id && !!prData.manual === !!data.manual;
      });

    const deleted = packageProduct && packageProduct.data.deleted;

    const productClasses = classNames(
      'CompanyFolderTree-item',
      'product',
      'row-flex',
      'align-vert',
      {
        planned: packageProduct && packageProduct.status === 0,
        installed: packageProduct && packageProduct.status === 1,
        deleted: deleted,
      }
    );

    if (editable && (hovering || selected || !!selectedCount)) {
      rightComponent = (
        <div>
          {!selectedCount && (
            <a
              className='m-r-md'
              onClick={(evt) => {
                evt.stopPropagation();
                this.props.companyActionCreators.removeFolderLinks({
                  folder_links: [product.folder_link],
                });
              }}
              data-toggle='tooltip'
              data-placement='bottom'
              title={this.props.intl.formatMessage(
                messages.productDeleteTooltip
              )}
            >
              <i className='fa fa-trash fa-lg fa-fw text-danger' />
            </a>
          )}
          <a
            onClick={(evt) => {
              evt.stopPropagation();
              this.onProductSelect(product);
            }}
            data-toggle='tooltip'
            data-placement='bottom'
            title={this.props.intl.formatMessage(messages.selectTooltip)}
          >
            <i className={selectClass} />
          </a>
        </div>
      );
    } else if (product.file_types) {
      rightComponent = product.file_types.filter(Boolean).map((type) => {
        return (
          <span key={type} className='ManualProductsView-row-content-filetype'>
            <img src={getFileTypePath(type)} />
          </span>
        );
      });
    }

    return (
      <ProductDragSource
        key={`${product.folder_link}${product.data.id}`}
        product={product}
        canDrag={
          this.props.editable &&
          (!selectedCount || (selected && !collisionCount))
        }
        onDrop={(tgt) => {
          if (tgt.id === -1) return;
          this.onProductDrop(tgt, product);
        }}
        preview={productDragPreview}
      >
        <li
          className={productClasses}
          onClick={(evt) =>
            this.props.onProductClick(packageProduct || product, evt)
          }
          onMouseEnter={() => this.setState({ hoverProduct: product })}
          onMouseLeave={() => this.setState({ hoverProduct: null })}
        >
          <span>{data.name}</span>

          <div className='CompanyFolderTree-item-right'>{rightComponent}</div>
          <div className='CompanyFolderTree-item-details'>
            {product.data.talo2000
              ? `${product.data.talo2000} :: ${product.data.manufacturer}`
              : product.data.manufacturer}
          </div>
        </li>
      </ProductDragSource>
    );
  };

  _chemicalFolderRow = (folder) => {
    const { collapsed, selectedProducts } = this.state;

    const selectedProductsCount = Object.keys(selectedProducts).length;

    return (
      <FolderRow
        key={`folder${folder.id}`}
        folder={folder}
        editable={false}
        collapsed={collapsed[folder.id]}
        selecting={selectedProductsCount > 0}
        onClick={() => this.collapseRow(folder.id)}
        onAddFolder={() => {}}
        onAddingFolder={() => {}}
        onRename={() => {}}
        onDelete={() => {}}
        onOver={() => !collapsed[folder.id] && this.collapseRow(folder.id)}
        onDrop={() => {}}
      >
        {folder.products.map((prod) => this._productRow(prod, false))}
      </FolderRow>
    );
  };

  _folderRow = (folder) => {
    const { editable } = this.props;
    const { collapsed, selectedProducts } = this.state;

    const selectedProductsCount = Object.keys(selectedProducts).length;

    return (
      <FolderRow
        key={`folder${folder.id}`}
        folder={folder}
        editable={editable}
        collapsed={collapsed[folder.id]}
        selecting={selectedProductsCount > 0}
        onClick={() => this.collapseRow(folder.id)}
        onAddFolder={(name) =>
          this.props.companyActionCreators.addFolder(name, folder.id)
        }
        onAddingFolder={() =>
          !collapsed[folder.id] && this.collapseRow(folder.id)
        }
        onRename={(name) =>
          this.props.companyActionCreators.patchFolder(folder.id, { name })
        }
        onDelete={() => {
          if (!folder.children.length && !folder.products.length) {
            this.props.companyActionCreators.deleteFolder(folder.id);
          } else {
            this.setState({ deletingFolder: folder }, () =>
              $('#deleteFolderModal').modal('show')
            );
          }
        }}
        onOver={() => !collapsed[folder.id] && this.collapseRow(folder.id)}
        onDrop={(target) => {
          if (target.id === -1) return;
          this.props.companyActionCreators.patchFolder(folder.id, {
            parent: target.id,
          });
        }}
      >
        {folder.children.map(this._folderRow)}
        {folder.products.map((prod) => this._productRow(prod, editable))}
      </FolderRow>
    );
  };

  productDragPreview = null;

  renderDragPreview = (count) => {
    if (count <= 0) return null;
    const text = this.props.intl.formatMessage(messages.dragPreview, { count });
    const padding = 8;
    const fontSize = 14;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    ctx.font = `${fontSize}px Verdana,Geneva,sans-serif`;
    const width = ctx.measureText(text).width + 2 * padding;
    const height = fontSize + 2 * padding;

    // NOTE: Resizing resets the context
    canvas.width = width;
    canvas.height = height;

    ctx.fillStyle = '#000';
    ctx.strokeStyle = '#FFFFFF';
    ctx.fillRect(0, 0, width, height);
    ctx.strokeRect(0, 0, width - 1, height);

    ctx.font = `${fontSize}px Verdana,Geneva,sans-serif`;
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#FFF';
    ctx.fillText(text, padding, height / 2);

    const img = new Image();
    img.src = canvas.toDataURL();

    return img;
  };

  render() {
    if (this.props.folders.length === 0) return null;
    const { deletingFolder, selectedProducts, collidingProducts } = this.state;
    const selectedCount = Object.keys(selectedProducts).length;
    const collisionCount = Object.keys(collidingProducts).length;

    const tree = this.treeify(this.props.folders);
    tree.name = this.props.intl.formatMessage(messages.rootFolderName);

    const chemicalProducts = this.props.chemicalProducts
      ? this.props.chemicalProducts.map((prod) => ({
          ...prod,
          folder_id: -1,
        }))
      : [];

    const chemicalTree = {
      id: -1,
      level: 1,
      root: 1,
      parent: null,
      products: chemicalProducts,
      children: [],
      name: this.props.intl.formatMessage(messages.chemicalFolderName),
      company: null,
      created_at: null,
    };

    return (
      <div className='CompanyFolderTree'>
        {this.props.editable && (
          <div className='m-b'>
            {selectedCount > 0 && (
              <div>
                <FormattedMessage
                  id='company-folder-tree-selected-notice'
                  description='Notice for selected products'
                  defaultMessage={`{selectedCount, number} {selectedCount, plural, one {kohde}
                    other {kohdetta}} valittuna`}
                  values={{ selectedCount }}
                />
                {collisionCount > 0 && (
                  <FormattedMessage
                    id='company-folder-tree-collision-notice'
                    description='Notice for colliding selection'
                    defaultMessage={`, {collisionCount, number} {collisionCount, plural,
                      one {tuote} other {tuotetta}} estää siirtämisen`}
                    values={{ collisionCount }}
                  />
                )}
                <div className='CompanyFolderTree-tools pull-right'>
                  <a
                    className='m-r-md'
                    onClick={(evt) => {
                      evt.stopPropagation();
                      this.deleteSelected();
                    }}
                    data-toggle='tooltip'
                    data-placement='bottom'
                    title={this.props.intl.formatMessage(
                      messages.deleteSelectionsTooltip
                    )}
                  >
                    <i className='fa fa-trash fa-lg fa-fw text-danger' />
                  </a>
                  <a
                    onClick={(evt) => {
                      evt.stopPropagation();
                      this.setState({
                        selectedProducts: {},
                        collidingProducts: {},
                      });
                    }}
                    data-toggle='tooltip'
                    data-placement='bottom'
                    title={this.props.intl.formatMessage(
                      messages.unselectAllTooltip
                    )}
                  >
                    <i className='fa fa-minus-square-o fa-lg fa-fw' />
                  </a>
                </div>
              </div>
            )}
            {selectedCount === 0 && (
              <FormattedMessage
                id='company-folder-tree-select-tip'
                description='Tip for selecting products'
                defaultMessage={`Voit siirtää tuotteita ja tuoteryhmiä raahaamalla`}
              />
            )}
          </div>
        )}

        <DeleteFolderModal
          folder={deletingFolder}
          onConfirm={() =>
            deletingFolder &&
            this.props.companyActionCreators.deleteFolder(deletingFolder.id)
          }
        />

        <ul id='collapseParent' className='CompanyFolderTree-list'>
          {this._folderRow(tree)}
        </ul>

        <ul id='collapseParent' className='CompanyFolderTree-list'>
          {this._chemicalFolderRow(chemicalTree)}
        </ul>
      </div>
    );
  }
}

export default injectIntl(CompanyFolderTree);
