import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const RowLabels = ({ data }) => {
  const subpackagesComplete =
    data.num_descendants !== 0 &&
    data.num_descendants === data.num_descendants_complete;

  const approvedComplete =
    Number(data.num_descendants_approved_count) +
      Number(data.approved_count) ===
      Number(data.num_descendants_total_count + Number(data.total_count)) &&
    Number(data.num_descendants_total_count + Number(data.total_count)) > 0;

  const labelClasses = classNames(
    'PackageHierarchy-row-content-label',
    'label',
    {
      'label-default': !subpackagesComplete,
      'label-success': subpackagesComplete,
    }
  );

  const approvedLabelClasses = classNames(
    'PackageHierarchy-row-content-label',
    'label',
    {
      'label-default': !approvedComplete,
      'label-success': approvedComplete,
    }
  );

  return (
    <div>
      {data.is_archived || data.children.length === 0 ? null : (
        <span className={labelClasses}>
          <FormattedMessage
            id='completed-subpackages'
            description='Label for completed subpackages'
            defaultMessage='Valmiit aliurakat: {completed, number}/{total, number}'
            values={{
              completed: data.num_descendants_complete,
              total: data.num_descendants,
            }}
          />
        </span>
      )}

      {data.is_archived ||
      Number(data.num_descendants_total_approvable_count) +
        Number(data.total_approvable_count) ===
        0 ? null : (
        <span className={approvedLabelClasses}>
          <FormattedMessage
            id='completed-subpackages'
            description='Label for completed subpackages'
            defaultMessage='Tuotekelpoisuus: {completed, number}/{total, number}'
            values={{
              completed:
                Number(data.num_descendants_approved_count) +
                Number(data.approved_count),
              total:
                Number(data.num_descendants_total_approvable_count) +
                Number(data.total_approvable_count),
            }}
          />
        </span>
      )}
    </div>
  );
};

RowLabels.propTypes = {
  data: PropTypes.shape({
    num_descendants: PropTypes.number,
    num_descendants_complete: PropTypes.number,
    num_descendants_approved_count: PropTypes.number,
    num_descendants_total_count: PropTypes.number,
    num_descendants_total_approvable_count: PropTypes.number,
    approved_count: PropTypes.number,
    total_count: PropTypes.number,
    total_approvable_count: PropTypes.number,
    is_archived: PropTypes.bool,
    children: PropTypes.array,
  }).isRequired,
};

export default RowLabels;
