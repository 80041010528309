export default {
  ERROR_INVALID_CREDENTIALS: "ERROR_INVALID_CREDENTIALS",
  ERROR_INVALID_OIDC_CODE: "ERROR_INVALID_OIDC_CODE",
  ERROR_CURRENT_USER_MISSING: "ERROR_CURRENT_USER_MISSING",
  ERROR_USER_NOT_FOUND: "ERROR_USER_NOT_FOUND",
  ERROR_USER_USERNAME_MISSING: "ERROR_USER_USERNAME_MISSING",
  ERROR_USER_ROLE_MISSING: "ERROR_USER_ROLE_MISSING",
  ERROR_UNPRIVILEGED_USER: "ERROR_UNPRIVILEGED_USER",
  ERROR_PACKAGE_NOT_FOUND: "ERROR_PACKAGE_NOT_FOUND",
  ERROR_PACKAGE_NOT_EMPTY: "ERROR_PACKAGE_NOT_EMPTY",
  ERROR_PACKAGE_NO_ACCESS: "ERROR_PACKAGE_NO_ACCESS",
  ERROR_PACKAGE_NO_LICENSE: "ERROR_PACKAGE_NO_LICENSE",
  ERROR_PACKAGE_NOT_ROOT: "ERROR_PACKAGE_NOT_ROOT",
  ERROR_PACKAGE_IS_ARCHIVED: "ERROR_PACKAGE_IS_ARCHIVED",
  ERROR_PACKAGE_NOT_ARCHIVED: "ERROR_PACKAGE_NOT_ARCHIVED",
  ERROR_PACKAGE_ARCHIVE_NO_ACCESS: "ERROR_PACKAGE_ARCHIVE_NO_ACCESS",
  ERROR_PACKAGE_IS_COMPLETED: "ERROR_PACKAGE_IS_COMPLETED",
  ERROR_RIGHTS_GIVE_APPROVE_RIGHTS_NO_ACCESS:
    "ERROR_RIGHTS_GIVE_APPROVE_RIGHTS_NO_ACCESS",
  ERROR_PACKAGE_PARENT_NOT_FOUND: "ERROR_PACKAGE_PARENT_NOT_FOUND",
  ERROR_PACKAGE_PARENT_NOT_DEFINED_YET: "ERROR_PACKAGE_PARENT_NOT_DEFINED_YET",
  ERROR_PACKAGE_PARENT_NO_ACCESS: "ERROR_PACKAGE_PARENT_NO_ACCESS",
  ERROR_PACKAGE_NO_DATA_RECEIVED: "ERROR_PACKAGE_NO_DATA_RECEIVED",
  ERROR_PACKAGE_COMPANY_MISSING: "ERROR_PACKAGE_COMPANY_MISSING",
  ERROR_PACKAGE_NAME_MISSING: "ERROR_PACKAGE_NAME_MISSING",
  ERROR_PACKAGE_ADDRESS_MISSING: "ERROR_PACKAGE_ADDRESS_MISSING",
  ERROR_PACKAGE_EXISTS_ON_LEVEL: "ERROR_PACKAGE_EXISTS_ON_LEVEL",
  ERROR_SUBPACKAGES_NOT_COMPLETE: "ERROR_SUBPACKAGES_NOT_COMPLETE",
  ERROR_COMPANY_NOT_FOUND: "ERROR_COMPANY_NOT_FOUND",
  ERROR_COMPANY_RIGHTS_NO_ACCESS: "ERROR_COMPANY_RIGHTS_NO_ACCESS",
  ERROR_COMPANY_FOLDER_EXISTS_ON_LEVEL: "ERROR_COMPANY_FOLDER_EXISTS_ON_LEVEL",
  ERROR_COMPANY_FOLDER_INVALID_NAME: "ERROR_COMPANY_FOLDER_INVALID_NAME",
  ERROR_COMPANY_FOLDER_INVALID_PRODUCT: "ERROR_COMPANY_FOLDER_INVALID_PRODUCT",
  ERROR_COMPANY_FOLDER_INVALID_FOLDER_LINK:
    "ERROR_COMPANY_FOLDER_INVALID_FOLDER_LINK",
  ERROR_COMPANY_FOLDER_INVALID_PATCH_ACTION:
    "ERROR_COMPANY_FOLDER_INVALID_PATCH_ACTION",
  ERROR_COMPANY_FOLDER_NO_PARENT: "ERROR_COMPANY_FOLDER_NO_PARENT",
  ERROR_COMPANY_FOLDER_ROOT_MODIFICATION:
    "ERROR_COMPANY_FOLDER_ROOT_MODIFICATION",
  ERROR_COMPANY_FOLDER_NOT_FOUND: "ERROR_COMPANY_FOLDER_NOT_FOUND",
  ERROR_INVITE_NO_DATA_RECEIVED: "ERROR_INVITE_NO_DATA_RECEIVED",
  ERROR_INVITE_USERNAME_MISSING: "ERROR_INVITE_USERNAME_MISSING",
  ERROR_INVITE_ALREADY_HAS_RIGHTS: "ERROR_INVITE_ALREADY_HAS_RIGHTS",
  ERROR_INVITE_ALREADY_EXISTS: "ERROR_INVITE_ALREADY_EXISTS",
  ERROR_INVITE_MAIL_ERROR: "ERROR_INVITE_MAIL_ERROR",
  ERROR_INVITE_NOT_FOUND: "ERROR_INVITE_NOT_FOUND",
  ERROR_RIGHTS_NOT_FOUND: "ERROR_RIGHTS_NOT_FOUND",
  ERROR_RIGHTS_GIVE_ALREADY_EXISTS: "ERROR_RIGHTS_GIVE_ALREADY_EXISTS",
  ERROR_RIGHTS_GIVE_DATA_MISSING: "ERROR_RIGHTS_GIVE_DATA_MISSING",
  ERROR_RIGHTS_GIVE_USERNAME_MISSING: "ERROR_RIGHTS_GIVE_USERNAME_MISSING",
  ERROR_RIGHTS_GIVE_INVALID_USERNAME: "ERROR_RIGHTS_GIVE_INVALID_USERNAME",
  ERROR_RIGHTS_GIVE_INVALID_USER: "ERROR_RIGHTS_GIVE_INVALID_USER",
  ERROR_RIGHTS_GIVE_NO_ACCESS: "ERROR_RIGHTS_GIVE_NO_ACCESS",
  ERROR_RIGHTS_GIVE_ALL_NO_ACCESS: "ERROR_RIGHTS_GIVE_ALL_NO_ACCESS",
  ERROR_RIGHTS_GIVE_ALL_ALREADY_EXISTS: "ERROR_RIGHTS_GIVE_ALL_ALREADY_EXISTS",
  ERROR_RIGHTS_GIVE_ALL_INVALID_USERNAME:
    "ERROR_RIGHTS_GIVE_ALL_INVALID_USERNAME",
  ERROR_RIGHTS_REMOVE_ALL_NO_ACCESS: "ERROR_RIGHTS_REMOVE_ALL_NO_ACCESS",
  ERROR_RIGHTS_REMOVE_ALL_ALREADY_EXISTS:
    "ERROR_RIGHTS_REMOVE_ALL_ALREADY_EXISTS",
  ERROR_RIGHTS_REMOVE_ALL_INVALID_USERNAME:
    "ERROR_RIGHTS_REMOVE_ALL_INVALID_USERNAME",
  ERROR_RIGHTS_REMOVE_INVALID_USER: "ERROR_RIGHTS_REMOVE_INVALID_USER",
  ERROR_RIGHTS_REMOVE_ALL_MISSING: "ERROR_RIGHTS_REMOVE_ALL_MISSING",
  ERROR_PRODUCT_NOT_FOUND: "ERROR_PRODUCT_NOT_FOUND",
  ERROR_PRODUCT_ALREADY_EXISTS: "ERROR_PRODUCT_ALREADY_EXISTS",
  ERROR_PRODUCT_NO_ACCESS: "ERROR_PRODUCT_NO_ACCESS",
  ERROR_PRODUCT_NAME_EMPTY: "ERROR_PRODUCT_NAME_EMPTY",
  ERROR_PRODUCT_MANUFACTURER_EMPTY: "ERROR_PRODUCT_MANUFACTURER_EMPTY",
  ERROR_PRODUCT_INVALID: "ERROR_PRODUCT_INVALID",
  ERROR_PRODUCT_EXTERNAL_NOT_FOUND: "ERROR_PRODUCT_EXTERNAL_NOT_FOUND",
  ERROR_TRANSFER_NO_DATA_RECEIVED: "ERROR_TRANSFER_NO_DATA_RECEIVED",
  ERROR_TRANSFER_USERNAME_MISSING: "ERROR_TRANSFER_USERNAME_MISSING",
  ERROR_TRANSFER_ALREADY_EXISTS: "ERROR_TRANSFER_ALREADY_EXISTS",
  ERROR_TRANSFER_MAIL_ERROR: "ERROR_TRANSFER_MAIL_ERROR",
  ERROR_FILE_PARSE_FAILED: "ERROR_FILE_PARSE_FAILED",
  ERROR_FILE_NOT_FOUND: "ERROR_FILE_NOT_FOUND",
  ERROR_FILE_NO_ACCESS: "ERROR_FILE_NO_ACCESS",
  ERROR_FILE_COULD_NOT_BE_RETRIEVED: "ERROR_FILE_COULD_NOT_BE_RETRIEVED",
  ERROR_FILE_GENERATED_TOKEN: "ERROR_FILE_GENERATED_TOKEN",
  ERROR_FILE_INVALID_TOKEN: "ERROR_FILE_INVALID_TOKEN",
  ERROR_FILE_EMPTY_NAME: "ERROR_FILE_EMPTY_NAME",
  ERROR_FILE_INVALID_TYPE: "ERROR_FILE_INVALID_TYPE",
  ERROR_MESSAGE_QUEUE_DOWN: "ERROR_MESSAGE_QUEUE_DOWN",
  ERROR_LOG_NOT_FOUND: "ERROR_LOG_NOT_FOUND",
  ERROR_FORBIDDEN_META_FIELD: "ERROR_FORBIDDEN_META_FIELD",
  ERROR_FAVOURITE_NOT_FOUND: "ERROR_FAVOURITE_NOT_FOUND",
  ERROR_FAVOURITE_ALREADY_ADDED: "ERROR_FAVOURITE_ALREADY_ADDED",
  ERROR_FILE_PRODUCT_ALREADY_INSTALLED: "ERROR_FILE_PRODUCT_ALREADY_INSTALLED",
  ERROR_MULTIPLE_MATCHING_PACKAGES: "ERROR_MULTIPLE_MATCHING_PACKAGES",
};
