/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import Pagination from '../Pagination/Pagination';
import ProductSearchFilter from '../ProductSearchFilter/ProductSearchFilter';
import FavouriteIcon from '../FavouriteIcon/FavouriteIcon';

import classNames from 'classnames';

import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

import { fileSorter, getFileTypePath } from '../../utils/fileUtil';
import CompanyFolderTree from '../CompanyFolderTree/CompanyFolderTree';
import ImportProductsModal from '../ImportProductsModal/ImportProductsModal';
import ProductSearch from '../ProductSearch/ProductSearch.tsx';
import MissingProductLink from '../MissingProductLink/MissingProductLink';

const messages = defineMessages({
  nameEmpty: {
    id: 'product-name-empty',
    description: 'Error when product name is empty',
    defaultMessage: 'Tuotteen nimi ei voi olla tyhjä!',
  },

  manufacturerEmpty: {
    id: 'product-manufacturer-empty',
    description: 'Error when product manufacturer is empty',
    defaultMessage: 'Tuotteen yritys ei voi olla tyhjä!',
  },

  manualProductTooltip: {
    id: 'add-manual-product-tooltip',
    description: 'Tooltip when adding a manual product',
    defaultMessage:
      'Voit lisätä järjestelmästä puuttuvan tuotteen itse ' +
      '<br />syöttämällä mahdollisimman tarkat tuotetiedot.' +
      '<br /><br />' +
      'Rakennustieto pyrkii lisäämään puuttuvat <br />' +
      'tuotteet järjestelmään tietojesi perusteella. <br />' +
      'Saat sähköposti-ilmoituksen kun tuote on lisätty.',
  },

  manualProductRowTooltip: {
    id: 'manual-product-tooltip',
    description: 'Tooltip message for manual products',
    defaultMessage:
      'Tuote on käsin lisätty, eikä sen tietojen ' +
      'oikeellisuudesta ole takuita.',
  },

  searchTooltip: {
    id: 'search',
    description: 'Tooltip for phrase search',
    defaultMessage: 'Haussa voit käyttää lainausmerkkejä tarkentamaan hakua',
  },
});

class PackageProductsGlobal extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    package: PropTypes.object.isRequired,
    packageProducts: PropTypes.array,
    products: PropTypes.array,
    folders: PropTypes.array,
    properties: PropTypes.object,
    onlyFavourites: PropTypes.bool,
    onlyCompanyProducts: PropTypes.bool,
    searchLoading: PropTypes.bool.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    productActionCreators: PropTypes.object.isRequired,
    companyActionCreators: PropTypes.object,
    logActionCreators: PropTypes.object.isRequired,
    favouritesActionCreators: PropTypes.object.isRequired,
    fileActionCreators: PropTypes.object.isRequired,
    safetyData: PropTypes.object,
    intl: PropTypes.object.isRequired,
    chemicalProducts: PropTypes.array,
    validateImportProductsLoading: PropTypes.bool.isRequired,
    importProducts: PropTypes.array,
  };

  constructor(props, context) {
    super(props, context);
    this.search = null;

    const errors = this._getErrors();

    this.state = {
      page: 1,
      activeProduct: null,
      searchPackageID: null,
      filters: null,
      includeDeleted: false,
      fileFilters: [],
      searchText: '',
      ...errors,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      searchPackageID: nextProps.package.id,
    });
  }

  _getErrors = () => ({
    error: null,
    productNameError: null,
    productManufacturerError: null,
  });

  _handleFilters = (event, filters) => {
    event.preventDefault();
    const page = 1;

    this.setState({
      page,
      filters,
    });

    this.props.productActionCreators.searchGlobalProducts(
      this.props.package.id,
      this.state.searchText,
      filters,
      page,
      this.state.includeDeleted,
      this.props.onlyFavourites,
      false,
      this.state.fileFilters
    );
  };

  _filterByFile(type) {
    const newFileFilters = this.state.fileFilters.includes(type)
      ? this.state.fileFilters.filter((ft) => ft !== type)
      : [...this.state.fileFilters, type];

    const page = 1;

    this.setState({
      page,
      fileFilters: newFileFilters,
    });

    this.props.productActionCreators.searchGlobalProducts(
      this.props.package.id,
      this.state.searchText || '',
      this.state.filters,
      page,
      this.state.includeDeleted,
      this.props.onlyFavourites,
      this.props.onlyCompanyProducts,
      newFileFilters
    );
  }

  _handleSearch = (searchText) => {
    const page = 1;
    this.setState({
      page,
    });

    this.props.productActionCreators.searchGlobalProducts(
      this.props.package.id,
      searchText || this.state.searchText,
      this.state.filters,
      page,
      this.state.includeDeleted,
      this.props.onlyFavourites,
      this.props.onlyCompanyProducts,
      this.state.fileFilters
    );
  };

  _navigate = (page) => {
    this.setState({
      page,
    });

    this.props.productActionCreators.searchGlobalProducts(
      this.props.package.id,
      this.state.searchText,
      this.state.filters,
      page,
      this.state.includeDeleted,
      this.props.onlyFavourites,
      this.props.onlyCompanyProducts,
      this.state.fileFilters
    );
  };

  _productRow = (product) => {
    if (!product.data || (!product.data.from_company && product.data.manual))
      return null;
    // Differentiate construction sites in the list
    const { deleted } = product.data;

    const productClasses = classNames(
      'PackageProductsGlobal-content-list-item',
      'PackageProductsGlobal-row',
      'row-flex',
      'align-vert',
      {
        active: this.state.activeProduct === product.data.id,
        planned: product.status === 0,
        installed: product.status === 1,
        deleted,
      }
    );

    const productContentClasses = classNames(
      'PackageProductsGlobal-row-content',
      {
        deleted,
      }
    );
    const productContentIdentifierClasses = classNames(
      'PackageProductsGlobal-row-content---identifier',
      {
        deleted,
      }
    );

    const manualClasses = classNames(
      'PackageProductsLocal-row-content-manual',
      'pull-right',
      'm-l',
      {
        'hidden-xs-up': !product.data.manual,
      }
    );

    const fileTypes = product.file_types
      ? product.file_types
          .filter((type) => {
            return type.length > 0;
          })
          .reduce((ft, ff) => (ft.includes(ff) ? ft : [...ft, ff]), [])
          .sort(fileSorter)
      : [];

    return (
      <div
        className='col-xs-12 no-padding'
        key={product.data.id}
        onClick={this.openPanel.bind(this, product)}
      >
        <li className={productClasses}>
          <div className={productContentClasses}>
            <span>{product.data.name}</span>
            <FavouriteIcon
              productData={product.data}
              favouritesActionCreators={this.props.favouritesActionCreators}
            />
            <div className='PackageProductsGlobal-row-content-right'>
              <div
                className='PackageProductsGlobal-row-content-filetypes'
                dir='rtl'
              >
                {product.file_types
                  .reduce((ft, ff) => (ft.includes(ff) ? ft : [...ft, ff]), [])
                  .filter((type) => {
                    return type.length > 0;
                  })
                  .sort(fileSorter)
                  .map((type, idx) => (
                    <span
                      key={`${type}-${idx}`}
                      className='PackageProductsGlobal-row-content-filetype'
                    >
                      <img src={getFileTypePath(type)} />
                    </span>
                  ))}
              </div>
              <span
                className={manualClasses}
                data-tip={this.props.intl.formatMessage(
                  messages.manualProductRowTooltip
                )}
              >
                <i className='fa fa-exclamation-triangle' />
              </span>
            </div>

            <div className={productContentIdentifierClasses}>
              {product.data.talo2000
                ? `${product.data.talo2000} :: ${product.data.manufacturer}`
                : product.data.manufacturer}
            </div>
          </div>
        </li>
      </div>
    );
  };

  openPanel = (product, event) => {
    event.preventDefault();

    this.setState({
      activeProduct: product.data.id,
    });

    if (product.id) {
      this.props.logActionCreators.clearLog();
      this.props.logActionCreators.getProductLog(product.id);
      this.props.packageActionCreators.getPackageProduct(
        this.props.package.id,
        product
      );
    } else {
      this.props.productActionCreators.getSearchProduct(
        this.props.package.id,
        product
      );
    }

    if (product.external_id) {
      this.props.productActionCreators.getExternalProduct(product);
    }
  };

  _getSearch = () => {
    const productCount =
      !this.props.searchLoading &&
      this.props.products &&
      this.props.products.length
        ? this.props.products[0].count
        : 0;
    const lastPage = this.state.page === Math.ceil(productCount / 20);
    if (this.props.searchLoading) {
      return (
        <div className='loading loading-green loading-center m-t-md m-b-md'>
          <div />
        </div>
      );
    } else if (!this.props.products) {
      return null;
    }

    let content = null;
    if (!this.props.products.length) {
      content = (
        <div className='m-t'>
          <MissingProductLink />
        </div>
      );
    } else {
      content = (
        <div>
          {this.props.products.map(this._productRow)}
          {lastPage && (
            <div className='m-t'>
              <MissingProductLink />
            </div>
          )}
        </div>
      );
    }

    return <ul className='PackageProductsGlobal-content-list'>{content}</ul>;
  };

  _handleShowDeletedProducts = () => {
    const includeDeleted = !this.state.includeDeleted;
    this.setState({ includeDeleted, page: 1 });

    const query = this.state.searchText;
    if (query) {
      this.props.productActionCreators.searchGlobalProducts(
        -1,
        query,
        [],
        1,
        includeDeleted,
        false,
        false,
        this.state.fileFilters
      );
    }
  };

  render() {
    const classes = {
      productNameGroup: classNames('form-group', {
        'has-error': this.state.productNameError,
      }),

      productNameField: classNames('form-control', {
        'form-control-error': this.state.productNameError,
      }),

      productManufacturerGroup: classNames('form-group', {
        'has-error': this.state.productManufacturerError,
      }),

      productManufacturerField: classNames('form-control', {
        'form-control-error': this.state.productManufacturerError,
      }),
    };

    const productCount =
      !this.props.searchLoading &&
      this.props.products &&
      this.props.products.length
        ? this.props.products[0].count
        : 0;

    return (
      <div className='PackageProductsGlobal'>
        <ImportProductsModal
          title='Tuo tuotteita tiedostosta'
          packageActionCreators={this.props.packageActionCreators}
          fileActionCreators={this.props.fileActionCreators}
          validateImportProductsLoading={
            this.props.validateImportProductsLoading
          }
          importProducts={this.props.importProducts}
          packageId={this.props.package.id}
        />

        <div className='search-header-row'>
          <h2>
            <a
              className='search-tooltip'
              onClick={(evt) => evt.stopPropagation()}
              data-toggle='tooltip'
              data-placement='bottom'
              title={this.props.intl.formatMessage(messages.searchTooltip)}
            >
              <i className='fa fa-info-circle' />
            </a>
          </h2>

          <div className='modal-button-container'>
            <button
              className='btn btn-primary-outline btn-sm m-r m-b'
              data-toggle='modal'
              data-target='#addProductModal'
            >
              <FormattedMessage
                id='product-add'
                description='Label for button to add product'
                defaultMessage='Lisää tuote'
              />
            </button>

            <button
              className='btn btn-primary-outline btn-sm m-b'
              data-toggle='modal'
              data-target='#importProductsModal'
            >
              <FormattedMessage
                id='product-add'
                description='Label for button to add product'
                defaultMessage='Tuo tiedostosta'
              />
            </button>
          </div>
        </div>

        <ProductSearch
          searchText={this.state.searchText}
          onSearchTextChange={(searchText) => this.setState({ searchText })}
          onScanResult={(res) => {
            this.setState({ searchText: res });
            this._handleSearch(res);
          }}
          onSubmit={this._handleSearch}
          includeDeleted={this.state.includeDeleted}
          toggleIncludeDeleted={() => this._handleShowDeletedProducts()}
          fileFilters={this.state.fileFilters}
          onFileFilterClick={(type) => this._filterByFile(type)}
        />

        {this.props.user &&
          this.props.user.admin &&
          this.props.onlyCompanyProducts && (
            <div className='pull-right m-t'>
              <a href='/products/manual' target='_blank'>
                <button className='btn btn-warning'>
                  <FormattedMessage
                    id='manage-manual-products'
                    description='Label for managing manual products'
                    defaultMessage='Hallinnoi tuotteita'
                  />
                </button>
              </a>
            </div>
          )}

        <div className='clearfix' />

        <div className='PackageProductsGlobal-filters'>
          <ProductSearchFilter
            query={this.state.searchText}
            properties={this.props.properties}
            handler={this._handleFilters}
          />
        </div>

        <div className='PackageProductsGlobal-content'>
          {this.props.folders && (
            <div>
              <CompanyFolderTree
                folders={this.props.folders}
                packageProducts={this.props.packageProducts}
                onProductClick={this.openPanel}
                companyActionCreators={this.props.companyActionCreators}
                chemicalProducts={this.props.chemicalProducts}
              />

              <hr />
            </div>
          )}

          {this._getSearch()}

          <Pagination
            page={this.state.page}
            count={productCount}
            perPage={20}
            handler={this._navigate}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(PackageProductsGlobal);
