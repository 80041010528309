/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';

import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  emailEmpty: {
    id: 'email-empty',
    description: 'Error when email is empty',
    defaultMessage: 'Sähköpostiosoite ei voi olla tyhjä!',
  },
});

const PackageRightsTransfer = createReactClass({
  displayName: 'PackageRightsTransfer',

  propTypes: {
    package: PropTypes.object,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  },

  getInitialState() {
    return {
      emailValue: '',
      emailError: null,
      error: false,
    };
  },

  transferPackage(event) {
    event.preventDefault();
    if (!this.state.emailValue) {
      this.setState({
        error: true,
        emailError: this.props.intl.formatMessage(messages.emailEmpty),
      });

      return;
    }

    this.props.messageActionCreators.clearMessages();

    this.props.packageActionCreators.transferPackage(
      this.props.package.id,
      this.state.emailValue
    );

    this.setState({
      emailValue: '',
      emailError: '',
      error: false,
    });
  },

  render() {
    const emailGroupClasses = classNames({
      'has-error': !!this.state.emailError,
    });

    const emailFieldClasses = classNames(
      'form-control',
      {
        'form-control-error': !!this.state.emailError,
      }
    );

    if (!this.props.package) {
      return (
        <div className='PackageRightsTransfer'>
          <div className='loading loading-green loading-center m-t'>
            <div></div>
          </div>
        </div>
      );
    }

    return (
      <div className='PackageRightsTransfer'>
        <form onSubmit={this.transferPackage}>
          <fieldset className={emailGroupClasses}>
            <FormattedMessage
              id='rights-transfer-header'
              description='Header text for transferring rights'
              defaultMessage='Siirrä urakka toiselle yritykselle'
            />

            {this.props.package.level === 1 && (
              [
                <br />,
                <small className='text-warning'>
                  <FormattedMessage
                    id='rights-transfer-root-warning'
                    description={'Warning text for transferring top level ' +
                      'package'}
                    defaultMessage='Huom! Olet siirtämässä koko työmaata!'
                  />
                </small>,
              ]
            )}


            <input
              type='text'
              className={emailFieldClasses}
              placeholder='Käyttäjätunnus, yleensä email esim: matti.meikalainen@oy.fi'
              value={this.state.emailValue}
              onChange={(event) => {
                this.setState({
                  emailValue: event.target.value,
                });
              }}
            />

            <small className='text-danger'>
              {this.state.emailError}
            </small>
          </fieldset>

          <div className='text-left'>
            <a
              href='#'
              className='btn btn-primary'
              onClick={this.transferPackage}
            >
              <i className='fa fa-exchange'></i>&nbsp;
              <FormattedMessage
                id='transfer-package-button'
                description='Transfer package button text'
                defaultMessage='Siirrä urakka'
              />
            </a>
          </div>
        </form>
      </div>
    );
  },
});

export default injectIntl(PackageRightsTransfer);
