/**
 * FavouriteIcon.js - A component that indicates the favourite status of
 *                    a product
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';

class FavouriteIcon extends React.Component {
  static propTypes = {
    productData: PropTypes.object.isRequired,
    favouritesActionCreators: PropTypes.object.isRequired,
  };

  _handleActions = (event) => {
    event.stopPropagation();
    const data = this.props.productData;
    if (!data.is_favourite) {
      this.props.favouritesActionCreators.addFavourite(
        data.id,
        data.manual
      );
    } else {
      this.props.favouritesActionCreators.removeFavourite(
        data.id
      );
    }
  };

  render() {
    const favouriteClassNames = classNames({
      'fa': true,
      'fa-star': this.props.productData.is_favourite,
      'fa-star-o': !this.props.productData.is_favourite,
      'FavouriteIcon': true,
    });

    return (
      <i
        className={favouriteClassNames}
        onClick={this._handleActions}
      />
    );
  }
}

export default FavouriteIcon;
