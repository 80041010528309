/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import SidePanel from './SidePanel.jsx';
import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import Header from '../Header/Header';
import Messages from '../Messages/Messages';
import AddPackageModal from '../AddPackageModal/AddPackageModal.jsx';
import DownloadSummaryModal from '../DownloadSummaryModal/DownloadSummaryModal';
import MassApproveModal from '../MassApproveModal/MassApproveModal';
import PackageHierarchy from '../PackageHierarchy/PackageHierarchy';
import AddFileModal from '../AddFileModal/AddFileModal';
import ProductInformationModal from '../ProductInformationModal/ProductInformationModal';

class HomeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this._getStoreData(),
      productPanel: false,
      package: null,
      open: true,
    };
  }

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );

    this._productStoreSubscription = this.props.productStore.addListener(
      this._onStoreChange
    );

    this._logStoreSubscription = this.props.logStore.addListener(
      this._onStoreChange
    );

    this._bulletinStoreSubscription = this.props.bulletinStore.addListener(
      this._onStoreChange
    );

    this._companyStoreSubscription = this.props.companyStore.addListener(
      this._onStoreChange
    );
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
    this._productStoreSubscription.remove();
    this._logStoreSubscription.remove();
    this._bulletinStoreSubscription.remove();
    this._companyStoreSubscription.remove();
  }

  _onStoreChange = () => {
    this.setState({ ...this._getStoreData() });
  };

  _getStoreData = () => ({
    packageState: this.props.packageStore.getState(),
    productState: this.props.productStore.getState(),
    rtState: this.props.rtStore.getState(),
    logState: this.props.logStore.getState(),
    bulletinState: this.props.bulletinStore.getState(),
    companyState: this.props.companyStore.getState(),
  });

  render() {
    const { rtState, packageState, productState, bulletinState } = this.state;

    return (
      <div className='HomeView'>
        {this.state.rtState.error !== null ||
        this.state.packageState.error !== null ? (
          <FormattedMessage
            id='error'
            description='Generic error'
            defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
            values={{
              logout: <a href='/logout'>kirjaudu ulos</a>,
            }}
          />
        ) : (
          <div className='container-fluid'>
            <Header
              user={rtState.user}
              packages={packageState.packageSearch}
              searchLoading={packageState.packageSearchLoading}
              packageActionCreators={this.props.packageActionCreators}
              rtActionCreators={this.props.rtActionCreators}
            />

            <Messages
              messageStore={this.props.messageStore}
              messageActionCreators={this.props.messageActionCreators}
            />

            <div className='HomeView-content row'>
              <PackageHierarchy
                packages={packageState.packages}
                packagesLoading={packageState.packagesLoading}
                package={packageState.package}
                packageLoading={packageState.packageLoading}
                onSelectPackage={(pkg, open = true) => {
                  this.setState({
                    productPanel: false,
                    package: pkg,
                    open,
                  });
                }}
                product={productState.product}
                productLoading={productState.productLoading}
                products={packageState.products}
                packageProducts={packageState.packageProducts}
                productsLoading={packageState.productsLoading}
                onSelectProduct={(pkg, open = true) => {
                  this.setState({
                    productPanel: true,
                    package: pkg,
                    open,
                  });
                }}
                isProductPanel={this.state.productPanel}
                bulletins={bulletinState.bulletins}
                bulletinsLoading={bulletinState.bulletinsLoading}
                archivedCount={packageState.archivedCount}
                user={rtState.user}
                messageActionCreators={this.props.messageActionCreators}
                packageActionCreators={this.props.packageActionCreators}
                productActionCreators={this.props.productActionCreators}
                logActionCreators={this.props.logActionCreators}
                bulletinActionCreators={this.props.bulletinActionCreators}
                fileActionCreators={this.props.fileActionCreators}
                openSidePanel={(open) => {
                  this.setState({ open });
                }}
                expanded={packageState.expanded}
                activePackage={packageState.activePackage}
                memoizedPackages={packageState.memoizedPackages}
              />

              {this.state.open ? (
                <SidePanel
                  isOpen={this.state.open}
                  isProductPanel={this.state.productPanel}
                  statePackage={this.state.package}
                  packageState={this.state.packageState}
                  productState={this.state.productState}
                  logState={this.state.logState}
                  rtState={this.state.rtState}
                  packageActionCreators={this.props.packageActionCreators}
                  productActionCreators={this.props.productActionCreators}
                  fileActionCreators={this.props.fileActionCreators}
                  messageActionCreators={this.props.messageActionCreators}
                  logActionCreators={this.props.logActionCreators}
                  approvers={this.state.companyState.approvers}
                />
              ) : null}
            </div>
          </div>
        )}

        <AddFileModal
          product={this.state.productState.product}
          fileActionCreators={this.props.fileActionCreators}
          messageActionCreators={this.props.messageActionCreators}
        />

        <AddPackageModal
          messageActionCreators={this.props.messageActionCreators}
          packageActionCreators={this.props.packageActionCreators}
          parent={this.state.packageState.addToParent}
        />

        <DownloadSummaryModal
          fileActionCreators={this.props.fileActionCreators}
          packageActionCreators={this.props.packageActionCreators}
          package={packageState.package}
          packageSummaryLoading={packageState.packageSummaryLoading}
        />

        <MassApproveModal
          productActionCreators={this.props.productActionCreators}
          packageActionCreators={this.props.packageActionCreators}
          package={packageState.package}
          packageProducts={packageState.packageProducts}
          loading={packageState.packageLoading || packageState.productsLoading}
          commandInProgress={productState.commandInProgress}
          installMode={packageState.installMode}
          count={productState.count}
        />

        <ProductInformationModal
          productActionCreators={this.props.productActionCreators}
          product={productState.product}
        />
      </div>
    );
  }
}

HomeView.propTypes = {
  rtStore: PropTypes.object.isRequired,
  messageStore: PropTypes.object.isRequired,
  packageStore: PropTypes.object.isRequired,
  productStore: PropTypes.object.isRequired,
  logStore: PropTypes.object.isRequired,
  bulletinStore: PropTypes.object.isRequired,
  rtActionCreators: PropTypes.object.isRequired,
  messageActionCreators: PropTypes.object.isRequired,
  packageActionCreators: PropTypes.object.isRequired,
  productActionCreators: PropTypes.object.isRequired,
  fileActionCreators: PropTypes.object.isRequired,
  logActionCreators: PropTypes.object.isRequired,
  bulletinActionCreators: PropTypes.object.isRequired,
};

export default AuthenticatedView(HomeView);
