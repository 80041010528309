/**
 * PublicChemicalsView.js - React component PublicChemicalsView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';
import React from 'react';
import BarcodeScanner from '../BarcodeScanner/BarcodeScanner.tsx';
import LanguageSelect from './LangSelect.tsx';
import { titles } from './translations.js';

function getCodeDescriptions(codes, dataCodes, defaultCodes) {
  const descriptions = [];

  if (!codes) return [];

  for (const code of codes.split(',')) {
    if (code === '-') {
      descriptions.push(code);
    } else {
      const match = dataCodes?.find((co) => co.value === code);
      if (match) {
        descriptions.push(match.label);
      } else {
        const defaultMatch = defaultCodes?.find((co) => co.value === code);
        if (defaultMatch) {
          descriptions.push(defaultMatch.label);
        }
      }
    }
  }

  return descriptions;
}

class PublicChemicalsView extends React.Component {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    packageStore: PropTypes.object.isRequired,
    productStore: PropTypes.object.isRequired,
    fileActionCreators: PropTypes.object.isRequired,
    productActionCreators: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this._getStoreData(),
      search: '',
      scanning: false,
    };
  }

  componentDidMount() {
    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );
    this._productStoreSubscription = this.props.productStore.addListener(
      this._onStoreChange
    );
  }

  componentWillUnmount() {
    this._packageStoreSubscription.remove();
    this._productStoreSubscription.remove();
  }

  _onStoreChange = () => {
    this.setState({ ...this._getStoreData() });
  };

  _getStoreData = () => {
    return {
      packageState: this.props.packageStore.getState(),
      productState: this.props.productStore.getState(),
    };
  };

  _formatTimestamp(timestring) {
    if (!timestring) return '';
    const timestamp = new Date(timestring);
    const dd = timestamp.getDate();
    const MM = timestamp.getMonth() + 1;
    const yyyy = timestamp.getFullYear();
    const hh = timestamp.getHours();
    const mm = timestamp.getMinutes();

    return `${dd}.${MM}.${yyyy}
       ${hh > 9 ? hh : `0${hh}`}:${mm > 9 ? mm : `0${mm}`}`;
  }

  _fileRows(ch) {
    /* eslint-disable */
    if (ch.boomiFiles) {
      return (
        <td>
          {ch.boomiFiles.map((file, idx) => (
            <div
              key={file.url}
              style={{
                marginTop: idx > 0 ? '1rem' : '0',
              }}
            >
              <a
                href='#'
                onClick={(event) => {
                  event.preventDefault();
                  window.open(file.url, '_blank').focus();
                }}
              >
                {file.name}
              </a>
            </div>
          ))}
        </td>
      );
    }

    const kttFiles = ch.files.filter(
      (file) => file.type === 'sds' || file.type === 'ktt'
    );

    return (
      <td>
        {kttFiles.map((file, idx) => (
          <div
            key={file.id}
            style={{
              marginTop: idx > 0 ? '1rem' : '0',
            }}
          >
            <a
              href='#'
              onClick={(event) => {
                event.preventDefault();
                window
                  .open(
                    `${process.env.API_BASE_PATH}/chemicalchart/${this.props.uuid}/file/${file.id}`,
                    '_blank'
                  )
                  .focus();
              }}
            >
              {file.name}
            </a>
          </div>
        ))}
      </td>
    );
  }

  _codeRows(codes) {
    return (
      <td className='coderow'>
        {codes.map((code, idx) => (
          <div
            key={code}
            style={{
              marginTop: idx > 0 ? '1rem' : '0',
            }}
          >
            {code}
          </div>
        ))}
      </td>
    );
  }

  _warningCodes(codes) {
    return (
      <td>
        {codes.map((code) =>
          code !== '-' ? (
            <div key={code} className='warning-sign'>
              <img src={`/images/warningsigns/${code.substring(0, 5)}.jpg`} />
              <p>{code}</p>
            </div>
          ) : (
            <div>-</div>
          )
        )}
      </td>
    );
  }

  _fileTimestamps(ch) {
    const kttFiles = ch.files
      ? ch.files.filter(
          (file) =>
            (file.type === 'sds' || file.type === 'ktt') && file.source !== 0
        )
      : [];

    const sdTimestamps = ch.sd_timestamp ? ch.sd_timestamp.split(' // ') : [];
    const timestamps = [
      ...sdTimestamps,
      ...kttFiles.map((file) => file.last_modified),
    ];

    return (
      <td>
        {timestamps.map((timestamp, idx) => (
          <div
            key={`${timestamp}-${idx}`}
            style={{
              marginTop: idx > 0 ? '1rem' : '0',
            }}
          >
            {this._formatTimestamp(timestamp)}
          </div>
        ))}
      </td>
    );
  }

  render() {
    if (this.state.packageState.chemicalChartDataLoading) {
      return (
        <div className='d-flex justify-content-center p-t-lg'>
          <div className='loading loading-green'>
            <div />
          </div>
        </div>
      );
    }

    const chemicalChartData = this.state.packageState.chemicalChartData;
    if (!chemicalChartData) return null;

    const packageName = chemicalChartData.packageName;
    const products = chemicalChartData.products || [];

    const dataRows = products.filter((p) => {
      const searchWords = this.state.search
        .toLowerCase()
        .split(/\s+/)
        .map((s) => s.trim())
        .filter((s) => s !== '');

      return (
        this.state.search === '' ||
        searchWords.every(
          (sw) =>
            (p.ean && p.ean.toLowerCase().includes(sw)) ||
            (p.manufacturer && p.manufacturer.toLowerCase().includes(sw)) ||
            (p.exposed_users && p.exposed_users.toLowerCase().includes(sw)) ||
            (p.name && p.name.toLowerCase().includes(sw))
        )
      );
    });

    // Commented fields may need to be re-enabled quickly (don't remove)
    return (
      <div className='PublicChemicalsView'>
        <div className='container-fluid'>
          <h1>Kemikaaliluettelo: {packageName}</h1>

          <fieldset className='form-group field-center'>
            <input
              type='text'
              value={this.state.search}
              onChange={(e) => {
                this.setState({
                  search: e.target.value,
                });
              }}
              className='searchfield'
              ref='search'
              placeholder={'Suodata hakusanalla'}
            />

            <button
              type='button'
              className='btn btn-outline-secondary scan-button'
              onClick={() => this.setState({ scanning: true })}
            >
              <i className='fa fa-barcode'></i>
            </button>

            {this.state.scanning ? (
              <BarcodeScanner
                setResult={(res) => {
                  this.setState({ search: res, scanning: false });
                }}
              />
            ) : null}

            <LanguageSelect
              lang={this.state.productState.langCode}
              onChange={(langCode) => {
                if (!this.state.productState.safetyData[langCode]) {
                  this.props.productActionCreators.getSafetyData(
                    langCode,
                    true
                  );
                } else {
                  this.props.productActionCreators.changeLanguage(langCode);
                }
              }}
            />
          </fieldset>

          <div className='chart'>
            <table className='table-bordered'>
              <tbody>
                <tr>
                  <th>
                    {titles.manufacturer[this.state.productState.langCode]}
                  </th>
                  <th>{titles.product[this.state.productState.langCode]}</th>
                  {/*<th>Kauppanimi</th>*/}
                  <th>{titles.file[this.state.productState.langCode]}</th>
                  <th>{titles.date[this.state.productState.langCode]}</th>
                  {/*<th>Käyttötarkoitus</th>*/}
                  <th>
                    {
                      titles.specialConsiderations[
                        this.state.productState.langCode
                      ]
                    }
                  </th>
                  <th>
                    {titles.warningCodes[this.state.productState.langCode]}
                  </th>
                  <th>
                    {titles.hazardCodes[this.state.productState.langCode]}
                  </th>
                  <th>
                    {titles.safetyCodes[this.state.productState.langCode]}
                  </th>
                  {/*<th>Lisätty työmaalle</th>*/}
                  {/*<th>Lisätiedot</th>*/}
                  <th>
                    {titles.exposedUsers[this.state.productState.langCode]}
                  </th>
                  <th>
                    {
                      titles.maximumStoredAmount[
                        this.state.productState.langCode
                      ]
                    }
                  </th>
                </tr>
                {dataRows.map((ch) => {
                  return (
                    <tr key={ch.id}>
                      <td>{ch.manufacturer}</td>
                      <td>{ch.name}</td>
                      {/*<td>{ch.commercial_name}</td>*/}
                      {this._fileRows(ch)}
                      {this._fileTimestamps(ch)}
                      {/*<td>{ch.intented_use}</td>*/}
                      <td>
                        {ch.svhc
                          ? titles.speciallyConcerning[
                              this.state.productState.langCode
                            ]
                          : ''}
                      </td>
                      {this._warningCodes(
                        getCodeDescriptions(
                          ch.warning_codes,
                          this.state.productState.safetyData[
                            this.state.productState.langCode
                          ]?.warningCodes,
                          this.state.productState.safetyData.en?.warningCodes
                        )
                      )}
                      {this._codeRows(
                        getCodeDescriptions(
                          ch.hazard_codes,
                          this.state.productState.safetyData[
                            this.state.productState.langCode
                          ]?.hazardCodes,
                          this.state.productState.safetyData.en?.hazardCodes
                        )
                      )}
                      {this._codeRows(
                        getCodeDescriptions(
                          ch.safety_codes,
                          this.state.productState.safetyData[
                            this.state.productState.langCode
                          ]?.safetyCodes,
                          this.state.productState.safetyData.en?.safetyCodes
                        )
                      )}
                      {/*<td>{this._formatTimestamp(ch.added_timestamp)}</td>*/}
                      {/*<td>{ch.comment}</td>*/}
                      <td>{ch.exposed_users}</td>
                      <td>{ch.maximum_stored_amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default PublicChemicalsView;
