import CommonActionCreators from './CommonActionCreators';
import ActionTypes from './ActionTypes';

class StatsActionCreators extends CommonActionCreators {
  constructor(dispatcher, apiUtils) {
    super(dispatcher, apiUtils);
  }

  getStats() {
    this._apiUtils.get('/stats')
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.STATS_RECEIVE,
          stats: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        this._dispatcher.dispatch({
          type: ActionTypes.STATS_RECEIVE_ERROR,
          error: error,
        });
      });
  }

  exportUsers() {
    this._apiUtils.get('/export/users')
      .then((response) => {
        this._downloadAsFile('usernames.csv', response.body,
          response.contentType);
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        this._dispatcher.dispatch({
          type: ActionTypes.STATS_RECEIVE_ERROR,
          error: error,
        });
      });
  }

  exportProducts() {
    this._apiUtils.get('/export/products')
      .then((response) => {
        this._downloadAsFile('products.csv', response.body,
          response.contentType);
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        this._dispatcher.dispatch({
          type: ActionTypes.STATS_RECEIVE_ERROR,
          error: error,
        });
      });
  }
}

export default StatsActionCreators;
