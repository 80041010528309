import PropTypes from 'prop-types';
import React from 'react';
import { DropTarget, DragSource } from 'react-dnd';
import classNames from 'classnames';
import { injectIntl, defineMessages } from 'react-intl';
import NewFolderRow from './NewFolderRow';

const messages = defineMessages({
  folderAddTooltip: {
    id: 'folder-add-tooltip',
    description: 'Tooltip for adding a folder',
    defaultMessage: 'Lisää tuoteryhmä',
  },
  folderDeleteTooltip: {
    id: 'folder-delete-tooltip',
    description: 'Tooltip for deleting a folder',
    defaultMessage: 'Poista tuoteryhmä',
  },
});

const folderDroppable = (target, source, nameCheck = true) => {
  if (target.id === source.id) return false;
  if (target.id === source.parent) return false;

  const isChild = source.children.reduce(
    (acc, child) => acc || !folderDroppable(target, child, false),
    false
  );

  if (isChild) return false;

  if (nameCheck) {
    const sameNameOnLevel = target.children.reduce(
      (acc, child) => acc || source.name === child.name,
      false
    );

    if (sameNameOnLevel) return false;
  }

  return true;
};

const productDroppable = (target, source) => {
  const existsOnLevel = target.products.reduce(
    (acc, child) =>
      acc ||
      (source.data.id === child.data.id &&
        source.data.manual === child.data.manual),
    false
  );

  if (existsOnLevel) return false;

  return true;
};

const folderTarget = {
  drop(props, monitor) {
    if (monitor.isOver() === monitor.isOver({ shallow: true })) {
      return props.folder;
    }
  },
  canDrop(props, monitor) {
    if (monitor.isOver() !== monitor.isOver({ shallow: true })) return false;

    const item = monitor.getItem();
    const itemType = monitor.getItemType();
    switch (itemType) {
      case 'folder':
        return folderDroppable(props.folder, item);
      case 'product':
        return productDroppable(props.folder, item);
      default:
        return false;
    }
  },
};

const folderSource = {
  beginDrag(props) {
    return props.folder;
  },
  endDrag(props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }

    const drop = monitor.getDropResult();
    props.onDrop(drop);
  },
  canDrag(props) {
    return props.editable && props.folder.parent;
  },
};

class FolderRow extends React.Component {
  static propTypes = {
    folder: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    editable: PropTypes.bool,
    selecting: PropTypes.bool,
    collapsed: PropTypes.bool,
    onClick: PropTypes.func,
    onAddFolder: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onRename: PropTypes.func.isRequired,
    onAddingFolder: PropTypes.func,
    connectDropTarget: PropTypes.func,
    isOver: PropTypes.bool,
    dragSource: PropTypes.object,
    dragSourceType: PropTypes.any,
    canDrop: PropTypes.bool.isRequired,
    onOver: PropTypes.func,
    canDrag: PropTypes.bool,
    onDrop: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func,
    isDragging: PropTypes.bool,
    intl: PropTypes.object.isRequired,
  };

  overTimer = null;

  constructor(props) {
    super(props);

    this.folderName = null;

    this.state = {
      renaming: false,
      hovering: false,
    };
  }

  render() {
    const {
      children,
      folder,
      editable,
      selecting,
      collapsed,
      onClick,
      onAddFolder,
      onAddingFolder,
      onDelete,
      onRename,
      connectDropTarget,
      connectDragSource,
      isOver,
      onOver,
      canDrop,
    } = this.props;

    const { renaming, hovering, addingFolder } = this.state;

    if (isOver && onOver) {
      this.overTimer = setTimeout(() => {
        this.overTimer = null;
        onOver();
      }, 500);
    } else if (this.overTimer) {
      clearTimeout(this.overTimer);
      this.overTimer = null;
    }

    const hasChildren = folder.children.length || folder.products.length;
    const toggleClasses = classNames('fa', {
      'fa-caret-down': collapsed,
      'fa-caret-right': !collapsed,
      invisible: !hasChildren,
    });

    const itemClasses = classNames('CompanyFolderTree-item', {
      success: isOver && editable && canDrop,
      warning: isOver && (!editable || !canDrop),
    });

    const connect = editable && !renaming ? connectDragSource : (comp) => comp;

    const dragComponent = connect(
      <div
        className={itemClasses}
        onClick={() => onClick && onClick()}
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
      >
        <i className={toggleClasses} />
        <i className='fa fa-lg fa-folder m-r' />
        {!renaming ? (
          <span>
            {folder.name}
            {folder.parent && hovering && editable && (
              <a
                className='m-l'
                onClick={(evt) => {
                  evt.stopPropagation();
                  this.setState({ renaming: true });
                }}
              >
                <i className='fa fa-pencil' />
              </a>
            )}
          </span>
        ) : (
          <form
            className='d-inline'
            onSubmit={(evt) => {
              evt.preventDefault();
              if (this.folderName && this.folderName.value) {
                onRename(this.folderName.value);
                this.setState({ renaming: false });
              }
            }}
          >
            <input
              type='text'
              ref={(input) => {
                this.folderName = input;
              }}
              defaultValue={folder.name}
              onFocus={(evt) => evt.target.select()}
              onClick={(evt) => evt.stopPropagation()}
              onBlur={() => {
                this.setState({ renaming: false });
              }}
              autoFocus
            />
          </form>
        )}

        {editable && hovering && !selecting && (
          <div className='CompanyFolderTree-item-right'>
            {folder.parent && (
              <a
                className='m-r-md'
                onClick={(evt) => {
                  evt.stopPropagation();
                  onDelete(folder.id);
                }}
                data-toggle='tooltip'
                data-placement='bottom'
                title={this.props.intl.formatMessage(
                  messages.folderDeleteTooltip
                )}
              >
                <i className='fa fa-trash fa-lg fa-fw text-danger' />
              </a>
            )}
            <a
              onClick={(evt) => {
                evt.stopPropagation();
                onAddingFolder(folder.id);
                this.setState({ addingFolder: true });
              }}
              data-toggle='tooltip'
              data-placement='bottom'
              title={this.props.intl.formatMessage(messages.folderAddTooltip)}
            >
              <i className='fa fa-plus fa-lg fa-fw' />
            </a>
          </div>
        )}
      </div>
    );

    const fullComponent = (
      <li
        className={classNames({
          'CompanyFolderTree-folder-target': isOver,
          success: editable && isOver && canDrop,
          warning: isOver && (!editable || !canDrop),
        })}
      >
        {dragComponent}
        <ul
          id={`collapse${folder.id}`}
          className='CompanyFolderTree-list collapse'
          data-parent='#collapseParent'
        >
          {addingFolder && (
            <NewFolderRow
              onAdd={(name) => {
                onAddFolder(name);
                this.setState({ addingFolder: false });
              }}
              onCancel={() => this.setState({ addingFolder: false })}
            />
          )}
          {children}
        </ul>
      </li>
    );

    return connectDropTarget ? connectDropTarget(fullComponent) : null;
  }
}

export default DropTarget(
  ['folder', 'product'],
  folderTarget,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
    dragSource: monitor.getItem(),
    dragSourceType: monitor.getItemType(),
    canDrop: monitor.canDrop(),
  })
)(
  DragSource('folder', folderSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))(injectIntl(FolderRow))
);
