export default function FileRows({ product, fileActionCreators }) {
  if (product.boomiFiles) {
    return (
      <>
        {product.boomiFiles.map((file, idx) => (
          <div
            key={file.url}
            style={{
              marginTop: idx > 0 ? '1rem' : '0',
            }}
          >
            <a
              href='#'
              onClick={(event) => {
                event.preventDefault();
                window.open(file.url, '_blank').focus();
              }}
            >
              {file.name}
            </a>
          </div>
        ))}
      </>
    );
  }

  if (!product.files) {
    return null;
  }

  const kttFiles = product.files.filter(
    (file) => file.type === 'sds' || file.type === 'ktt'
  );

  return (
    <>
      {kttFiles.map((file, idx) => (
        <div
          key={file.id}
          style={{
            marginTop: idx > 0 ? '1rem' : '0',
          }}
        >
          <a
            href='#'
            onClick={(event) => {
              event.preventDefault();
              fileActionCreators.downloadFile({ ...file, filename: file.name });
            }}
          >
            {file.name}
          </a>
        </div>
      ))}
    </>
  );
}
