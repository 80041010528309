export const knownTypes = [
  "att",
  "bim",
  "dop",
  "doc",
  "eta",
  "epd",
  "instr",
  "ktt",
  "m1",
  "ohje",
  "other",
  "docmanual",
  "docstoring",
  "rt",
  "sds",
  "w",
  "tds",
  "cert",
  "docce",
];

export default {
  dop: "fileTypeDOP",
  ktt: "fileTypeKTT",
  ohje: "fileTypeINSTR",
  epd: "fileTypeEPD",
  m1: "fileTypeM1",
  cert: "fileTypeCERT",
  other: "fileTypeATT",
};
