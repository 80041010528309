import CommonActionCreators from './CommonActionCreators';
import ActionTypes from './ActionTypes';

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  removeBulletinSuccess: {
    id: 'bulletin-remove-success',
    description: 'Bulletin removed.',
    defaultMessage: 'Tiedote poistettu.',
  },
  saveBulletinSuccess: {
    id: 'bulletin-save-success',
    description: 'Bulletin saved.',
    defaultMessage: 'Tiedote tallennettu.',
  },
});

class BulletinActionCreators extends CommonActionCreators {
  constructor(dispatcher, apiUtils) {
    super(dispatcher, apiUtils);
  }

  getRelevantBulletins() {
    this._apiUtils.get('/bulletins/relevant')
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.BULLETINS_RECEIVE,
          bulletins: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        this._dispatcher.dispatch({
          type: ActionTypes.BULLETINS_RECEIVE_ERROR,
          error: error,
        });
      });
  }

  getValidBulletins() {
    this._apiUtils.get('/bulletins/valid')
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.BULLETINS_RECEIVE,
          bulletins: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        this._dispatcher.dispatch({
          type: ActionTypes.BULLETINS_RECEIVE_ERROR,
          error: error,
        });
      });
  }

  getAllBulletins() {
    this._apiUtils.get('/bulletins/all')
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.BULLETINS_RECEIVE,
          bulletins: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        this._dispatcher.dispatch({
          type: ActionTypes.BULLETINS_RECEIVE_ERROR,
          error: error,
        });
      });
  }

  removeBulletin(bulletinId) {
    this._apiUtils.delete(`/bulletins/${bulletinId}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.BULLETIN_REMOVED,
          bulletin: response.body,
        });
        this._dispatchMessage(
          'success',
          messages.removeBulletinSuccess
        );

        this.getAllBulletins();
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        this._dispatcher.dispatch({
          type: ActionTypes.BULLETIN_REMOVE_ERROR,
          error: error,
        });
      });
  }

  saveBulletin(bulletin) {
    this._apiUtils.post(`/bulletins/${bulletin.id}`, bulletin)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.BULLETIN_SAVED,
          bulletin: response.body,
        });

        this._dispatchMessage(
          'success',
          messages.saveBulletinSuccess
        );

        this.getAllBulletins();
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        this._dispatcher.dispatch({
          type: ActionTypes.BULLETIN_SAVE_ERROR,
          error: error,
        });
      });
  }

  createBulletin(bulletin) {
    this._apiUtils.post(`/bulletins`, bulletin)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.BULLETIN_SAVED,
          bulletin: response.body,
        });

        this._dispatchMessage(
          'success',
          messages.saveBulletinSuccess
        );

        this.getAllBulletins();
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        this._dispatcher.dispatch({
          type: ActionTypes.BULLETIN_SAVE_ERROR,
          error: error,
        });
      });
  }
}

export default BulletinActionCreators;
