import { Store } from 'flux/utils';
import ActionTypes from '../actions/ActionTypes';
import { ProductType } from '../utils/enum';

class CompanyStore extends Store {
  constructor(dispatcher) {
    super(dispatcher);
    this._state = this.getInitialState();
  }

  getInitialState() {
    return {
      company: null,
      rights: null,
      approvers: null,
      error: null,
      folders: [],
      chemicalProductsLoading: false,
      chemicalProducts: null,
    };
  }

  getState() {
    return this._state;
  }

  clearState() {
    this._state = this.getInitialState();
  }

  __onDispatch(payload) {
    switch (payload.type) {
      case ActionTypes.CLEAR_STORES:
        this.clearState();
        this.__emitChange();
        break;
      case ActionTypes.COMPANY_RECEIVE:
        this._state.company = payload.company;
        this.__emitChange();
        break;
      case ActionTypes.COMPANY_RECEIVE_ERROR:
        this._state.error = payload.error;
        this.__emitChange();
        break;
      case ActionTypes.COMPANY_RIGHTS_RECEIVE:
        this._state.rights = payload.rights;
        this.__emitChange();
        break;
      case ActionTypes.COMPANY_APPROVERS_RECEIVE:
        this._state.approvers = payload.approvers;
        this.__emitChange();
        break;
      case ActionTypes.COMPANY_RIGHTS_RECEIVE_ERROR:
        this._state.error = payload.error;
        this.__emitChange();
        break;
      case ActionTypes.COMPANY_RIGHTS_ADDED: {
        const index = this._state.rights.findIndex((element) => {
          return element.username === payload.data.username;
        });

        this._state.rights[index].rights.push(payload.data.package);
        this.__emitChange();
        break;
      }
      case ActionTypes.COMPANY_ALL_RIGHTS_ADDED: {
        const index = this._state.rights.findIndex((element) => {
          return element.username === payload.data.username;
        });

        this._state.rights[index].role = payload.data.role;
        this.__emitChange();
        break;
      }
      case ActionTypes.COMPANY_ALL_RIGHTS_REMOVED: {
        const index = this._state.rights.findIndex((element) => {
          return element.username === payload.data.username;
        });

        this._state.rights[index].role = false;
        this.__emitChange();
        break;
      }
      case ActionTypes.COMPANY_FOLDERS_RECEIVE:
        this._state.folders = payload.folders;
        this.__emitChange();
        break;
      case ActionTypes.COMPANY_CHEMICALS_LOADING:
        this._state.chemicalProductsLoading = true;
        this.__emitChange();
        break;
      case ActionTypes.COMPANY_CHEMICALS_RECEIVE:
        this._state.chemicalProductsLoading = false;
        this._state.chemicalProducts = payload.products;
        this.__emitChange();
        break;
      case ActionTypes.COMPANY_CHEMICALS_RECEIVE_ERROR:
        this._state.error = payload.error;
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_MANUAL_REMOVED:
        this._state.folders.forEach(
          (folder) =>
            (folder.products = folder.products.filter((product) => {
              return (
                !product.data.manual ||
                Number(product.data.id) !== payload.product.data.id
              );
            }))
        );
        if (this._state.chemicalProducts) {
          this._state.chemicalProducts = this._state.chemicalProducts.filter(
            (product) => {
              return (
                !product.data.manual ||
                Number(product.data.id) !== payload.product.data.id
              );
            }
          );
        }
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_REMOVE:
        if (!payload.product || !payload.product.data) break;
        this._state.folders.forEach((folder) =>
          folder.products.forEach((product) => {
            if (
              product.data.id === payload.product.data.id &&
              !!product.data.manual === !!payload.product.data.manual
            ) {
              product.data = {
                ...product.data,
                ...payload.product.data,
              };
            }
          })
        );
        this.__emitChange();
        break;
      case ActionTypes.PRODUCT_MANUAL_ADDED:
        if (
          this._state.chemicalProducts &&
          (payload.product.data.variant === ProductType.CHEMICAL ||
            payload.product.data.variant === ProductType.CHEMICAL_PRODUCT)
        ) {
          this._state.chemicalProducts = [
            payload.product,
            ...this._state.chemicalProducts,
          ];
        }
        this.__emitChange();
        break;
      case ActionTypes.FILE_MANUAL_UPDATED: {
        this._state.folders.forEach((folder) =>
          folder.products.forEach((product) => {
            if (
              product.data &&
              product.data.manual &&
              Number(product.data.id) === payload.data.file.product_manual_id
            ) {
              product.file_types = [
                ...product.file_types.filter(
                  (t) => t !== payload.data.oldFile.type
                ),
                payload.data.file.type,
              ];
            }
          })
        );

        this._state.chemicalProducts.forEach((product) => {
          if (
            product.data &&
            product.data.manual &&
            Number(product.data.id) === payload.data.file.product_manual_id
          ) {
            product.file_types = [
              ...product.file_types.filter(
                (t) => t !== payload.data.oldFile.type
              ),
              payload.data.file.type,
            ];
          }
        });

        this.__emitChange();
        break;
      }
      case ActionTypes.FILE_MANUAL_ADDED: {
        const fileTypes = payload.data.files
          .filter(
            (file, idx, self) =>
              self.findIndex((f2) => f2.type === file.type) === idx
          )
          .map((file) => file.type);

        this._state.folders.forEach((folder) =>
          folder.products.forEach((product) => {
            if (
              product.data &&
              product.data.manual &&
              Number(product.data.id) === payload.data.product_id
            ) {
              product.data.files = payload.data.files;
              product.file_types = fileTypes;
            }
          })
        );

        this._state.chemicalProducts.forEach((product) => {
          if (
            product.data &&
            product.data.manual &&
            Number(product.data.id) === payload.data.product_id
          ) {
            product.data.files = payload.data.files;
            product.file_types = fileTypes;
          }
        });

        this.__emitChange();
        break;
      }
      case ActionTypes.PACKAGE_REMOVED_RIGHTS: {
        const index = this._state.rights.findIndex((element) => {
          return element.username === payload.rights.username;
        });

        const rights = this._state.rights[index].rights.filter((access) => {
          return access.id !== payload.rights.package.id;
        });

        this._state.rights[index].rights = rights;
        this.__emitChange();
        break;
      }
      default:
      // Ignoring other ActionTypes
    }
  }
}

export default CompanyStore;
