import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { formatDate } from './helpers.js';

const showBulletin = (event) => {
  // ugly
  window.location = `/bulletins/#${event.target.id}`;
};

const Bulletins = ({ bulletins, bulletinsLoading }) => {
  const bclassNames = classNames({
    'PackageHierarchy-row': true,
    'row-flex': true,
    'align-vert': true,
  });

  if (bulletinsLoading) {
    return (
      <div className='loading loading-green loading-center m-t-md row'>
        <div />
      </div>
    );
  } else if (bulletins && bulletins.length > 0) {
    return (
      <div>
        <div className='PackageHierarchy-header row'>
          <h3 className='PackageHierarchy-header-title'>
            <FormattedMessage
              id='relevant-bulletins'
              description='Bulletins header'
              defaultMessage='Tiedotteet'
            />
          </h3>
        </div>

        <ul className='row-margins'>
          {bulletins.map((bulletin) => (
            <li
              key={bulletin.id}
              id={bulletin.id}
              className={bclassNames}
              onClick={showBulletin}
            >
              <small>{formatDate(bulletin.validfrom)}</small> &nbsp;
              <div>{bulletin.title}</div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  // Defaults to empty
  return <div />;
};

Bulletins.propTypes = {
  bulletins: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      validfrom: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  bulletinsLoading: PropTypes.bool,
};

export default Bulletins;
