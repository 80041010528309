export const titles = {
  manufacturer: {
    ar: 'الشركة المصنعة',
    bg: 'Производител',
    zh: '制造商',
    hr: 'Proizvođač',
    cs: 'Výrobce',
    da: 'Producent',
    nl: 'Fabrikant',
    en: 'Manufacturer',
    et: 'Tootja',
    fi: 'Valmistaja',
    fr: 'Fabricant',
    de: 'Hersteller',
    el: 'Κατασκευαστής',
    hu: 'Gyártó',
    ga: 'Monaróir',
    it: 'Produttore',
    ja: 'メーカー',
    ko: '제조업체',
    lv: 'Ražotājs',
    lt: 'Gamintojas',
    mt: 'Manifattur',
    no: 'Produsent',
    pl: 'Producent',
    pt: 'Fabricante',
    ro: 'Producător',
    ru: 'Производитель',
    sk: 'Výrobca',
    sl: 'Proizvajalec',
    es: 'Fabricante',
    sv: 'Tillverkare',
    th: 'ผู้ผลิต',
    tr: 'Üretici',
  },
  product: {
    ar: 'المنتج',
    bg: 'Продукт',
    zh: '产品',
    hr: 'Proizvod',
    cs: 'Produkt',
    da: 'Produkt',
    nl: 'Product',
    en: 'Product',
    et: 'Toode',
    fi: 'Tuote',
    fr: 'Produit',
    de: 'Produkt',
    el: 'Προϊόν',
    hu: 'Termék',
    ga: 'Táirge',
    it: 'Prodotto',
    ja: '製品',
    ko: '제품',
    lv: 'Produkts',
    lt: 'Produktas',
    mt: 'Prodott',
    no: 'Produkt',
    pl: 'Produkt',
    pt: 'Produto',
    ro: 'Produs',
    ru: 'Продукт',
    sk: 'Produkt',
    sl: 'Izdelek',
    es: 'Producto',
    sv: 'Produkt',
    th: 'ผลิตภัณฑ์',
    tr: 'Ürün',
  },
  file: {
    ar: 'ملف',
    bg: 'Файл',
    zh: '文件',
    hr: 'Datoteka',
    cs: 'Soubor',
    da: 'Fil',
    nl: 'Bestand',
    en: 'File',
    et: 'Fail',
    fi: 'Tiedosto',
    fr: 'Fichier',
    de: 'Datei',
    el: 'Αρχείο',
    hu: 'Fájl',
    ga: 'Comhad',
    it: 'File',
    ja: 'ファイル',
    ko: '파일',
    lv: 'Fails',
    lt: 'Failas',
    mt: 'File',
    no: 'Fil',
    pl: 'Plik',
    pt: 'Arquivo',
    ro: 'Fișier',
    ru: 'Файл',
    sk: 'Súbor',
    sl: 'Datoteka',
    es: 'Archivo',
    sv: 'Fil',
    th: 'ไฟล์',
    tr: 'Dosya',
  },
  date: {
    ar: 'تاريخ',
    bg: 'Дата',
    zh: '日期',
    hr: 'Datum',
    cs: 'Datum',
    da: 'Dato',
    nl: 'Datum',
    en: 'Date',
    et: 'Kuupäev',
    fi: 'Päivämäärä',
    fr: 'Date',
    de: 'Datum',
    el: 'Ημερομηνία',
    hu: 'Dátum',
    ga: 'Dáta',
    it: 'Data',
    ja: '日付',
    ko: '날짜',
    lv: 'Datums',
    lt: 'Data',
    mt: 'Data',
    no: 'Dato',
    pl: 'Data',
    pt: 'Data',
    ro: 'Data',
    ru: 'Дата',
    sk: 'Dátum',
    sl: 'Datum',
    es: 'Fecha',
    sv: 'Datum',
    th: 'วันที่',
    tr: 'Tarih',
  },
  warningCodes: {
    ar: 'رموز التحذير',
    bg: 'Пиктограми за опасност',
    zh: '警示符号',
    hr: 'Piktogrami opasnosti',
    cs: 'Výstražné symboly nebezpečnosti',
    da: 'Farepiktogrammer',
    nl: 'Gevarenpictogrammen',
    en: 'Hazard pictograms',
    et: 'Ohupiktogrammid',
    fi: 'Varoitusmerkit',
    fr: 'Pictogrammes de danger',
    de: 'Gefahrenpiktogramme',
    el: 'Εικονογράμματα κινδύνου',
    hu: 'A veszélyt jelző piktogramok',
    ga: 'Picteagraim ghuaise',
    it: 'Pittogrammi di pericolo',
    ja: '警告シンボル',
    ko: '경고 심볼',
    lv: 'Bīstamības piktogrammas',
    lt: 'Pavojaus piktogramos',
    mt: "Pittogrammi ta' periklu",
    no: 'Advarselsymboler',
    pl: 'Piktogramy określające rodzaj zagrożenia',
    pt: 'Pictogramas de perigo',
    ro: 'Pictograme de pericol',
    ru: 'Предупреждающие символы',
    sk: 'Výstražné piktogramy',
    sl: 'Piktogrami za nevarnost',
    es: 'Pictogramas de peligro',
    sv: 'Faropiktogram',
    th: 'สัญลักษณ์เตือน',
    tr: 'Uyarı sembolleri',
  },
  hazardCodes: {
    ar: 'تصريح المخاطر',
    bg: 'Предупреждения за опасност',
    zh: '危险说明',
    hr: 'Oznake upozorenja',
    cs: 'Standardní věty o nebezpečnosti',
    da: 'Faresætninger',
    nl: 'Gevarenaanduidingen',
    en: 'Hazard statement',
    et: 'Ohulaused',
    fi: 'Vaaralausekkeet',
    fr: 'Mention de danger',
    de: 'Gefahrenhinweise',
    el: 'Δηλώσεις επικινδυνότητας',
    hu: 'A figyelmeztető mondatok',
    ga: 'Ráitis ghuaise',
    it: 'Indicazioni di pericolo',
    ja: '危険声明',
    ko: '위험성 진술',
    lv: 'Bīstamības apzīmējumi',
    lt: 'Pavojingumo frazės',
    mt: "Dikjarazzjonijiet ta' periklu",
    no: 'Fareutsagn',
    pl: 'Zwroty wskazujące rodzaj zagrożenia',
    pt: 'Advertências de perigo',
    ro: 'Fraze de pericol',
    ru: 'Описание опасности',
    sk: 'Výstražné upozornenia',
    sl: 'Stavki o nevarnosti',
    es: 'Indicaciones de peligro',
    sv: 'Faroangivelser',
    th: 'คำบรรยายความเสี่ยง',
    tr: 'Tehlike beyanı',
  },
  safetyCodes: {
    ar: 'تصريح الاحتياطات',
    bg: 'Препоръки за безопасност',
    zh: '预防性说明',
    hr: 'Oznake obavijesti',
    cs: 'Pokyny pro bezpečné zacházení',
    da: 'Sikkerhedssætninger',
    nl: 'Veiligheidsaanbevelingen',
    en: 'Precautionary statement',
    et: 'Hoiatuslaused',
    fi: 'Turvalausekkeet',
    fr: 'Conseils de prudence',
    de: 'Sicherheitshinweise',
    el: 'Δηλώσεις προφυλάξεων',
    hu: 'Óvintézkedésre vonatkozó mondatok',
    ga: 'Ráitis réamhchúraim',
    it: 'Consigli di prudenza',
    ja: '予防措置記述',
    ko: '예방 조치 진술',
    lv: 'Drošības prasību apzīmējums',
    lt: 'Atsargumo frazės',
    mt: "Dikjarazzjonijiet ta' prekawzjoni",
    no: 'Forsiktighetsuttalelse',
    pl: 'Zwroty wskazujące środki ostrożności',
    pt: 'Recomendações de prudência',
    ro: 'Fraze de precauţie',
    ru: 'Меры предосторожности',
    sk: 'Bezpečnostné upozornenia',
    sl: 'Previdnostni stavki',
    es: 'Consejos de prudencia',
    sv: 'Skyddsangivelser',
    th: 'คำแนะนำการปฏิบัติ',
    tr: 'Önlem beyanı',
  },
  exposedUsers: {
    ar: 'المستخدمون المعرضون',
    bg: 'Изложени потребители',
    zh: '暴露的用户',
    hr: 'Izloženi korisnici',
    cs: 'Vystavení uživatelé',
    da: 'Udsatte brugere',
    nl: 'Blootgestelde gebruikers',
    en: 'Exposed users',
    et: 'Kokkupuutunud kasutajad',
    fi: 'Altistuvat käyttäjät',
    fr: 'Utilisateurs exposés',
    de: 'Ausgesetzte Benutzer',
    el: 'Εκτεθειμένοι χρήστες',
    hu: 'Kitéve vannak a felhasználók',
    ga: 'Úsáideoirí taobhaithe',
    it: 'Utenti esposti',
    ja: '暴露された利用者',
    ko: '노출된 사용자',
    lv: 'Eksponētie lietotāji',
    lt: 'Paveikti vartotojai',
    mt: 'Utenti Eskożi',
    no: 'Eksponerte brukere',
    pl: 'Użytkownicy narażeni',
    pt: 'Utilizadores expostos',
    ro: 'Utilizatori expuși',
    ru: 'Изложенные пользователи',
    sk: 'Vystavení užívatelia',
    sl: 'Izpostavljeni uporabniki',
    es: 'Usuarios expuestos',
    sv: 'Exponerade användare',
    th: 'ผู้ใช้ที่เปิดเผย',
    tr: 'Maruz kalan kullanıcılar',
  },
  maximumStoredAmount: {
    ar: 'الحد الأقصى لكمية التخزين',
    bg: 'Максимално количество за съхранение',
    zh: '最大储存量',
    hr: 'Maksimalna količina skladištenja',
    cs: 'Maximální skladovací množství',
    da: 'Maksimal lagermængde',
    nl: 'Maximale opslaghoeveelheid',
    en: 'Maximum storage quantity',
    et: 'Maksimaalne ladustamiskogus',
    fi: 'Maksimi varastointimäärä',
    fr: 'Quantité maximale de stockage',
    de: 'Maximale Lagermenge',
    el: 'Μέγιστη ποσότητα αποθήκευσης',
    hu: 'Maximális tárolási mennyiség',
    ga: 'Uasmhéid stórála',
    it: 'Quantità massima di stoccaggio',
    ja: '最大貯蔵量',
    ko: '최대 저장 용량',
    lv: 'Maksimālais uzglabāšanas daudzums',
    lt: 'Maksimalus saugojimo kiekis',
    mt: "Kwantità massima ta' stokkaw",
    no: 'Maksimal lagringsmengde',
    pl: 'Maksymalna ilość przechowywania',
    pt: 'Quantidade máxima de armazenamento',
    ro: 'Cantitate maximă de depozitare',
    ru: 'Максимальный объем хранения',
    sk: 'Maximálne skladovacie množstvo',
    sl: 'Maksimalna količina shranjevanja',
    es: 'Cantidad máxima de almacenamiento',
    sv: 'Maximal lagringskvantitet',
    th: 'ปริมาณการจัดเก็บสูงสุด',
    tr: 'Maksimum depolama miktarı',
  },
  specialConsiderations: {
    ar: 'ملاحظات خاصة',
    bg: 'Специални забележки',
    zh: '特别注意事项',
    hr: 'Posebne napomene',
    cs: 'Zvláštní poznámky',
    da: 'Særlige bemærkninger',
    nl: 'Bijzondere aandachtspunten',
    en: 'Special considerations',
    et: 'Eriolulised märkused',
    fi: 'Erityishuomiot',
    fr: 'Remarques spéciales',
    de: 'Besondere Hinweise',
    el: 'Ειδικές παρατηρήσεις',
    hu: 'Különleges figyelmeztetések',
    ga: 'Nótaí speisialta',
    it: 'Considerazioni speciali',
    ja: '特記事項',
    ko: '특별한 주의사항',
    lv: 'Īpašas piezīmes',
    lt: 'Specialūs pastebėjimai',
    mt: 'Tagħrif specjali',
    no: 'Særlige hensyn',
    pl: 'Szczególne uwagi',
    pt: 'Considerações especiais',
    ro: 'Note speciale',
    ru: 'Особые замечания',
    sk: 'Špeciálne poznámky',
    sl: 'Posebne opombe',
    es: 'Consideraciones especiales',
    sv: 'Särskilda hänsyn',
    th: 'ข้อความพิเศษ',
    tr: 'Özel düşünceler',
  },
  speciallyConcerning: {
    ar: 'مثير للقلق الخاص',
    bg: 'Предизвикателно',
    zh: '引起特别关注',
    hr: 'Posebna briga',
    cs: 'Zvláštní obavy',
    da: 'Særlig bekymrende',
    nl: 'Bijzondere zorg',
    en: 'Very high concern',
    et: 'Erioluline mure',
    fi: 'Erityistä huolta aiheuttava',
    fr: 'Problème particulier',
    de: 'Besondere Sorge',
    el: 'Ειδική ανησυχία',
    hu: 'Különösen aggasztó',
    ga: 'Síceolú ar leith',
    it: 'Particolarmente preoccupante',
    ja: '特に心配',
    ko: '특별히 걱정되는',
    lv: 'Īpaši uztraucošs',
    lt: 'Speciali rūpestis',
    mt: 'Partikolarment preokkupanti',
    no: 'Særlig bekymringsfullt',
    pl: 'Szczególnie niepokojące',
    pt: 'Especialmente preocupante',
    ro: 'Îngrijorător special',
    ru: 'Особая озабоченность',
    sk: 'Špeciálna starostlivosť',
    sl: 'Posebna skrb',
    es: 'Especialmente preocupante',
    sv: 'Särskilt oroande',
    th: 'เป็นปัญหาเฉพาะ',
    tr: 'Özel endişe',
  },
};
