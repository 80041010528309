/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import PropertyTypes from '../../../shared/PropertyTypes.mjs';

const propTypes = {
  index: PropTypes.number.isRequired,
  pair: PropTypes.object,
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

class ProductSearchFilterRow extends Component {
  constructor(props) {
    super(props);
    this.getValueInput = this.getValueInput.bind(this);
    this.makeSelection = this.makeSelection.bind(this);
    this._handleSelectKey = this._handleSelectKey.bind(this);
    this._handleSelectValue = this._handleSelectValue.bind(this);
    this._handleMin = this._handleMin.bind(this);
    this._handleMax = this._handleMax.bind(this);
    this.state = {
      selectedKey: null,
      selectedValue: null,
      childOptions: null,
    };
  }

  componentWillReceiveProps(props) {
    if (props.pair) {
      this.setState({
        selectedKey: props.pair.key,
        selectedValue: props.pair.value,
        childOptions: props.data[props.pair.key],
      });
    }
  }

  getValueInput() {
    if (!this.state.selectedKey) return null;
    const type = this.props.data[this.state.selectedKey].type;
    if (type === PropertyTypes.TEXT) {
      return (
        <select
          className='form-control'
          onChange={this._handleSelectValue}
          value={this.state.selectedValue || ''}
        >
          <option value=''>Kaikki</option>
          {Object.keys(this.state.childOptions.values).map((key, index) => {
            return (
              <option key={index} value={key}>
                {key} ({this.state.childOptions.values[key]} kpl)
              </option>
            );
          }, this)}
        </select>
      );
    } else if (type === PropertyTypes.NUMERIC) {
      const values = Object.keys(this.state.childOptions.values).map(
        (value) => {
          return Number(value);
        }
      );
      const min = Math.min(...values);
      const max = Math.max(...values);
      const selectedValue = this.state.selectedValue || [min, max];
      return (
        <div className='ProductSearchFilterRow-range'>
          <span>Välillä:</span>
          <input
            className='form-control'
            type='number'
            value={selectedValue[0]}
            ref={(input) => {
              this.minInput = input;
            }}
            onChange={this._handleMin}
          />
          <input
            className='form-control'
            type='number'
            value={selectedValue[1]}
            ref={(input) => {
              this.maxInput = input;
            }}
            onChange={this._handleMax}
          />
        </div>
      );
    }
  }

  makeSelection(event, key, value) {
    this.props.onSelect(event, this.props.index, {
      key,
      type: this.props.data[key].type,
      value,
    });
  }

  _handleSelectKey(event) {
    event.preventDefault();
    this.setState({
      selectedKey: event.target.value,
      selectedValue: '',
      childOptions: this.props.data[event.target.value],
    });
    this.makeSelection(event, event.target.value, '');
  }

  _handleSelectValue(event) {
    event.preventDefault();
    this.setState({
      selectedValue: event.target.value,
    });
    this.makeSelection(event, this.state.selectedKey, event.target.value);
  }

  _handleMin(event) {
    event.preventDefault();
    const max = Number(this.maxInput.value);
    const min = Number(event.target.value) <= max ? event.target.value : max;
    this.setState({
      selectedValue: [min, max],
    });
    this.makeSelection(event, this.state.selectedKey, [min, max]);
  }

  _handleMax(event) {
    event.preventDefault();
    const min = Number(this.minInput.value);
    const max = Number(event.target.value) >= min ? event.target.value : min;
    this.setState({
      selectedValue: [min, max],
    });
    this.makeSelection(event, this.state.selectedKey, [min, max]);
  }

  render() {
    const data = this.props.data;
    return (
      <div className='ProductSearchFilterRow'>
        <a
          className='m-l'
          onClick={() => this.props.onDelete(event, this.props.index)}
        >
          <i className='fa fa-trash-o fa-lg text-danger' />
        </a>
        <select
          className='form-control'
          onChange={this._handleSelectKey}
          value={this.state.selectedKey || ''}
        >
          <option value='' disabled hidden>
            Valitse ominaisuus
          </option>
          {Object.keys(data).map((key, index) => {
            return (
              <option key={index} value={key}>
                {key} ({data[key].count} kpl)
              </option>
            );
          })}
        </select>
        {this.getValueInput()}
      </div>
    );
  }
}

ProductSearchFilterRow.propTypes = propTypes;

export default ProductSearchFilterRow;
