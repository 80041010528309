/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

/* eslint-disable */

import PropTypes from 'prop-types';

import React from 'react';

import { injectIntl, FormattedMessage } from 'react-intl';
import { ProductType } from '../../utils/enum';

class MassApproveModal extends React.Component {
  static propTypes = {
    productActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    package: PropTypes.object,
    packageProducts: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    commandInProgress: PropTypes.bool.isRequired,
    installMode: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.massApproveModal = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.commandInProgress !== prevProps.commandInProgress)
      if (this.props.count !== 99999 && !this.props.commandInProgress) {
        $(this.massApproveModal).modal('hide');
      }
  }

  approveAll = (event) => {
    event.preventDefault();
    this.props.productActionCreators.approveProducts(
      this.props.packageProducts[this.props.package.id]
        .filter(
          (product) =>
            !(
              !product.data ||
              !product.data.id ||
              product.data.variant === ProductType.CHEMICAL
            )
        )
        .map((product) => product.id)
    );
  };

  disapproveAll = (event) => {
    event.preventDefault();

    this.props.productActionCreators.disapproveProducts(
      this.props.packageProducts[this.props.package.id].map(
        (product) => product.id
      )
    );
  };

  installAll = (event) => {
    event.preventDefault();

    this.props.productActionCreators.installProducts(
      this.props.packageProducts[this.props.package.id]
        .filter(
          (product) =>
            !(
              !product.data ||
              !product.data.id ||
              product.data.variant === ProductType.CHEMICAL
            )
        )
        .map((product) => product.id)
    );
  };

  uninstallAll = (event) => {
    event.preventDefault();

    this.props.productActionCreators.uninstallProducts(
      this.props.packageProducts[this.props.package.id]
        .filter(
          (product) =>
            !(
              !product.data ||
              !product.data.id ||
              product.data.variant === ProductType.CHEMICAL
            )
        )
        .map((product) => product.id)
    );
  };

  render() {
    const approveMessage =
      this.props.package && this.props.package.approved
        ? 'Olet poistamassa kaikkien urakan {package} juuressa olevien tuotteiden tuotekelpoisuusmerkinnät, joiden tuotekelpoisuus on määriteltävissä. Muutoksen eivät koska aliurakoita.'
        : 'Olet merkitsemässä kaikkia urakan {package} juuressa olevia tuotteita tuotekelpoisiksi, joiden tuotekelpoisuus on määriteltävissä. Muutos koskee {notApproved} tuotetta, jotka eivät vielä ole tuotekelpoisia. Muutoksen eivät koska aliurakoita.';

    const installMessage =
      this.props.package && this.props.package.installed
        ? 'Olet poistamassa kaikkien urakan {package} juuressa olevien tuotteiden asennusmerkinnät. Muutoksen eivät koska aliurakoita.'
        : 'Olet merkitsemässä kaikkia urakan {package} juuressa olevia tuotteita asennetuiksi. Muutos koskee {notInstalled} tuotetta, jotka eivät vielä ole tuotekelpoisia. Muutoksen eivät koska aliurakoita.';

    const notApproved =
      this.props.package && this.props.packageProducts[this.props.package.id]
        ? this.props.packageProducts[this.props.package.id].filter(
            (product) =>
              !product.approved &&
              !(
                !product.data ||
                !product.data.id ||
                product.data.variant === ProductType.CHEMICAL
              )
          ).length
        : 0;
    const notInstalled =
      this.props.package && this.props.packageProducts[this.props.package.id]
        ? this.props.packageProducts[this.props.package.id].filter(
            (product) =>
              product.status !== 1 &&
              !(
                !product.data ||
                !product.data.id ||
                product.data.variant === ProductType.CHEMICAL
              )
          ).length
        : 0;

    return (
      <div
        className='modal fade MassApproveModal'
        ref={(div) => {
          this.massApproveModal = div;
        }}
        id='MassApproveModal'
        tabIndex='-1'
        data-backdrop='static'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                disabled={this.props.commandInProgress}
              >
                <span>&times;</span>
              </button>
              <h4 className='modal-title'>
                <FormattedMessage
                  id='create-construction-site-header'
                  description='Create construction site header'
                  defaultMessage='Haluatko varmasti muokata useaa tuotetta?'
                />
              </h4>
            </div>

            <div className='MassApproveModal-content modal-body'>
              <div>
                {this.props.count === 99999 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      margin: '8px',
                    }}
                  >
                    {'Yhden tai useamman tuotteen päivittäminen epäonnistui!'}
                  </div>
                ) : this.props.commandInProgress ||
                  this.props.loading ||
                  !this.props.package ? (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: '8px',
                      }}
                    >
                      {this.props.commandInProgress &&
                        'Päivitetään tuotteiden tilaa...'}
                    </div>
                    <div className='loading loading-center loading-green'>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ paddingBottom: '6px' }}>
                      <FormattedMessage
                        id='info-message'
                        description='Info message'
                        defaultMessage={
                          this.props.installMode
                            ? installMessage
                            : approveMessage
                        }
                        values={{
                          package: <b> {this.props.package.name} </b>,
                          notApproved:
                            this.props.packageProducts[this.props.package.id] &&
                            this.props.packageProducts[
                              this.props.package.id
                            ].filter(
                              (product) =>
                                !product.approved &&
                                product.data &&
                                product.data.variant !== ProductType.CHEMICAL &&
                                product.product_approvable
                            ).length,
                          notInstalled:
                            this.props.packageProducts[this.props.package.id] &&
                            this.props.packageProducts[
                              this.props.package.id
                            ].filter(
                              (product) =>
                                product.status !== 1 &&
                                product.data &&
                                product.data.variant !== ProductType.CHEMICAL
                            ).length, // && !product.product_approvable
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {!this.props.loading && (
              <div className='modal-footer text-center'>
                <input
                  type='button'
                  className='btn btn-danger'
                  data-dismiss='modal'
                  value='Sulje'
                  disabled={this.props.commandInProgress}
                />

                {this.props.count !== 99999 &&
                  this.props.package &&
                  !this.props.installMode &&
                  (this.props.package.approved ? (
                    <button
                      className='btn btn-warning'
                      onClick={this.disapproveAll}
                      disabled={this.props.commandInProgress}
                    >
                      <FormattedMessage
                        id='disapprove'
                        description='Button for disapprove'
                        defaultMessage='Poista tuotteiden tuotekelpoisuusmerkintä'
                      />
                    </button>
                  ) : (
                    <button
                      className='btn btn-success'
                      onClick={this.approveAll}
                      disabled={
                        this.props.commandInProgress || notApproved === 0
                      }
                    >
                      <FormattedMessage
                        id='approve'
                        description='Button for approve'
                        defaultMessage='Merkitse tuotteet tuotekelpoiseksi'
                      />
                    </button>
                  ))}
                {this.props.package &&
                  this.props.installMode &&
                  (!this.props.package.installed ? (
                    <button
                      className='btn btn-success'
                      onClick={this.installAll}
                      disabled={
                        this.props.commandInProgress || notInstalled === 0
                      }
                    >
                      <FormattedMessage
                        id='install'
                        description='Button for install'
                        defaultMessage='Merkitse tuotteet asennetuiksi'
                      />
                    </button>
                  ) : (
                    <button
                      className='btn btn-success'
                      onClick={this.uninstallAll}
                      disabled={this.props.commandInProgress}
                    >
                      <FormattedMessage
                        id='install'
                        description='Button for install'
                        defaultMessage='Poista tuotteiden asennusmerkintä'
                      />
                    </button>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(MassApproveModal);
