import CommonActionCreators from './CommonActionCreators';
import ActionTypes from './ActionTypes';
import ErrorTypes from '../../shared/ErrorTypes.mjs';

import { defineMessages } from 'react-intl';
import page from 'page';

const messages = defineMessages({
  loginSuccess: {
    id: 'login-success',
    description: 'Message displayed for successful login',
    defaultMessage: 'Kirjautuminen onnistui!',
  },

  loginInviteSuccess: {
    id: 'login-invite-success',
    description: 'Message displayed for successful invite on login',
    defaultMessage: 'Sinulle lisättiin oikeudet urakkaan "{name}"!',
  },

  loginFailed: {
    id: 'login-failed',
    description: 'Message displayed for incorrect login',
    defaultMessage: 'Virheellinen käyttäjätunnus tai salasana.',
  },

  logoutSuccess: {
    id: 'logout-success',
    description: 'Message displayed for successful logout',
    defaultMessage: 'Uloskirjautuminen onnistui!',
  },
});

class RTActionCreators extends CommonActionCreators {
  constructor(dispatcher, apiUtils) {
    super(dispatcher, apiUtils);
  }

  authenticate(inviteToken) {
    if (inviteToken) localStorage.setItem('invite-token', inviteToken);

    this._apiUtils.get('/auth/request').then((response) => {
      window.location = response.body.authUrl;
    });
  }

  onAuthorization(token) {
    if (token) {
      localStorage.setItem('jwt-token', token);
      const redirectPath = localStorage.getItem('login-redirect-path');
      localStorage.removeItem('login-redirect-path');
      if (redirectPath) {
        page.redirect(redirectPath);
      } else {
        page.redirect('/');
      }
    } else {
      page.redirect('/login');
    }
  }

  /* eslint-disable */

  logout() {
    this._dispatcher.dispatch({
      type: ActionTypes.RT_USER_LOGGED_OUT,
    });

    this._dispatcher.dispatch({
      type: ActionTypes.CLEAR_STORES,
    });

    this._dispatchFutureMessage('success', messages.logoutSuccess);
    localStorage.removeItem('jwt-token');
    window.location.replace(
      'https://auth.rakennustieto.fi/logout?client_id=gd5dr4gq5ooq6qaf1qq0okn64&logout_uri=https://www.rakennustieto.fi'
    );
  }

  changeUser() {
    this._apiUtils.get('/auth/change-user').then((response) => {
      const url = response.body.changeUserUrl;

      localStorage.removeItem('jwt-token');

      window.location = url;
    });
  }

  getCurrentUser() {
    const inviteToken = localStorage.getItem('invite-token');
    localStorage.removeItem('invite-token');

    this._apiUtils
      .get(`/users/me?token=${inviteToken}`)
      .then((response) => {
        // If we reach this point, we have a successful login
        if (page.current.match('^/login')) {
          page.redirect('/');
          return;
        }

        this._dispatcher.dispatch({
          type: ActionTypes.RT_RECEIVE_CURRENT_USER,
          user: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_CURRENT_USER_MISSING:
            // Current user was missing in database
            // Forcing re-login

            localStorage.removeItem('jwt-token');
            page.redirect('/login');
            break;

          default:
            this._dispatcher.dispatch({
              type: ActionTypes.RT_RECEIVE_CURRENT_USER_ERROR,
              error: error,
            });
        }
      });
  }
}

export default RTActionCreators;
