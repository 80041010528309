import React from 'react';

let stores;
let actionCreators;

export const initInjector = (applyStores, applyActionCreators) => {
  stores = applyStores;
  actionCreators = applyActionCreators;
};

export default function inject(Component) {
  return (props) =>
    <Component {...props} {...stores} {...actionCreators} />;
}
