export default {
  GIVE_ALL_RIGHTS: 1,
  REMOVE_ALL_RIGHTS: 2,
  ADD_PACKAGE: 3,
  EDIT_PACKAGE: 4,
  REMOVE_PACKAGE: 5,
  ARCHIVE_PACKAGE: 6,
  UNARCHIVE_PACKAGE: 7,
  GENERATE_REPORT: 8,
  ADD_PACKAGE_PRODUCT: 9,
  ADD_PACKAGE_MANUAL_PRODUCT: 10,
  INVITE_RIGHTS: 11,
  INVITE_TRANSFER: 12,
  GIVE_RIGHTS: 13,
  REMOVE_RIGHTS: 14,
  EDIT_PRODUCT: 15,
  REMOVE_PRODUCT: 16,
  GENERATE_DOWNLOAD_TOKEN: 17,
  DOWNLOAD_FILE: 18,
  ADD_FILE: 19,
  LOGIN: 20,
  COMPLETE_PACKAGE: 21,
  UPDATE_PRODUCT: 22,
  LINK_PRODUCT: 23,
  COMMENT_PRODUCT: 24,
  APPROVE_PRODUCT: 25,
  ADD_MANUAL_PRODUCT: 26,
  EDIT_MANUAL_PRODUCT: 27,
  REMOVE_MANUAL_PRODUCT: 28,
  REMOVE_FILE: 29,
  MOVE_PACKAGE: 30,
  MOVE_PACKAGE_PRODUCT: 31,
  APPROVE_PRODUCT_CARBON_FOOTPRINT: 32,
  APPROVE_PACKAGE_CARBON_FOOTPRINT: 33,
  COPY_PACKAGE_PRODUCT: 34,
  EDIT_FILE_METADATA: 35,
  API_GET_PROJECT_DATA: 36,
  API_GET_PROJECT_SUBCONTRACTOR_DATA: 37,
  API_ADD_PACKAGE_PRODUCT: 38,
};
