import { knownTypes } from '../../shared/FileTypes.mjs';

export function getExtension(input) {
  const filename = input || '';
  const extension = filename.split('.').pop();
  return extension === filename ? '' : `.${extension}`;
}

export function getProductFilename(product, file, index) {
  if (file.name) return file.name;

  const extension = getExtension(file.original_filename);
  const manufacturer = product.data.manufacturer.substring(0, 10);
  const name = product.data.name.substring(0, 50);
  const fileType = file.type || 'other';
  return `${manufacturer}-${name}-${fileType}-${index}${extension}`;
}

export function getFileTypePath(type) {
  let name =
    knownTypes.indexOf(type.toLowerCase()) === -1
      ? 'other'
      : type.toLowerCase();
  if (
    [
      'doccertificate',
      'typeapprovaldoc',
      'verificationcertificate',
      'docce',
    ].includes(type.toLowerCase())
  ) {
    name = 'cert';
  }
  if (['dop', 'doc', 'eta'].includes(type.toLowerCase())) {
    name = 'dop';
  }
  return `/images/filetypes/${name}.png`;
}

const recyclabilityIcons = {
  ConcreteWaste: {
    FI: 'Betoni - Saa sisältää teräkset.',
    icon: '/images/recyclability/concrete.png',
  },
  BrickWaste: {
    FI: 'Tiilet - Saa sisältää muurauslaastit.',
    icon: '/images/recyclability/bricks.png',
  },
  TileWaste: {
    FI: 'Kaakelit - Alueelliset erot keräämisessä.',
    icon: '/images/recyclability/tiles.png',
  },
  Aggregate: {
    FI: 'Kiviaines - Ei maa-ainesta.',
    icon: '/images/recyclability/atone-gravel.png',
  },
  AsphaltWaste: {
    FI: 'Asfaltti - Puhdas asfalttijäte, ei muuta maa-ainesta.',
    icon: '/images/recyclability/asphalt.png',
  },
  BituminousFelt: {
    FI: 'Bitumihuopa - Bitumihuoparullat ja -singelisorakatto. Nauloja ei tarvitse poistaa.',
    icon: '/images/recyclability/roofing-felt.png',
  },
  PlasterBoards: {
    FI: 'Kipsilevyt - Kipsilevy saa olla sademärkää. Pinnoittamatonta tai pinnoitettua kipsilevyä.',
    icon: '/images/recyclability/drywall.png',
  },
  CleanWoodWaste: {
    FI: 'Käsittelemätön puu - Puhdas käsittelemätön puu. Sallitaan pienet naulat, betoniroiskeet ja metalliosat. Ei maalattua tai muutoin käsiteltyä puuta.',
    icon: '/images/recyclability/untreated-wood.png',
  },
  MixedWoodWaste: {
    FI: 'Käsitelty puu - Maalattu ja pinnoitettu puu. Lastulevy, vaneri ja kovalevy. Sallitaan pienet naulat, betoniroiskeet ja metalliosat. Ei palosuojattua tai painekyllästettyä puuta. Ei kalustelevyjä, parkettia tai laminaattia.',
    icon: '/images/recyclability/treated-wood.png',
  },
  LoadingPallets: {
    FI: 'Puupakkaukset - Ehjät ja vähän rikkinäiset kaikki erikokoiset lavat. Samankokoiset lavat samaan nippuun.',
    icon: '/images/recyclability/wooden-packaging.png',
  },
  ImpregnatedWood: {
    FI: 'Kyllästetty puu - Ei muuta kuin kyllästettyä puuta.',
    icon: '/images/recyclability/impregnated-wood.png',
  },
  ScrapMetal: {
    FI: 'Metallit - Esim. Tyhjät ja kuivat maaliastiat sekä metalliset pakkausmateriaalit.',
    icon: '/images/recyclability/scrap-metal.png',
  },
  CablesAndWires: {
    FI: 'Kaapelit ja sähköjohdot - Sellaisenaan.',
    icon: '/images/recyclability/cables.png',
  },
  LevelGlass: {
    FI: 'Tasolasi - Ikkunalasit pokilla tai ilman (riippuen jätetoimijasta).',
    icon: '/images/recyclability/flat-glass.png',
  },
  MembranePlastic: {
    FI: 'Kalvomuovi - Mahdollista erilliskerätä värillinen ja väritön.',
    icon: '/images/recyclability/stretch-film.png',
  },
  BusinessPlasticPackaging: {
    FI: 'Muovipakkaukset - Vain tyhjiä ja puhtaita muovipakkauksia esim. pakkaus EPS, suursäkit, muoviastiat. Mahdollista erilliskerätä PP-kaapelikelat.',
    icon: '/images/recyclability/plastic-products.png',
  },
  PlasticInsulation: {
    FI: 'Muovieristeet - EPS, XPS, PIR, PUR, EPP, EPE, XPE ja polyeteeni- tai polyproneenivaahto. Tuotetoimittaja tai jätetoimija voi kerätä.',
    icon: '/images/recyclability/insulation.png',
  },
  CleanStoneWoolWaste: {
    FI: 'Kivivilla - Tiukat laatuvaatimukset: Villoituksesta tullut puhdas leikkuujäte, suljetussa pussissa kuivana.',
    icon: '/images/recyclability/insulation.png',
  },
  CleanGlassWoolWaste: {
    FI: 'Lasivilla - Tiukat laatuvaatimukset: Villoituksesta tullut puhdas leikkuujäte, suljetussa pussissa kuivana.',
    icon: '/images/recyclability/insulation.png',
  },
  MixedConstructionWaste: {
    FI: 'Sekajäte - Ei vaarallisia jätteitä, erikseen kerättäviä jätteitä, sähkö- ja elekronikkaromua, painekyllästettyä puuta, asbestia tai biojätettä. Kyllä: Cembrit-kovalevy, PVC-muovit, lakaisujäte ja hiontapöly pakattuna.',
    icon: '/images/recyclability/mixed-waste.png',
  },
  Cardboard: {
    FI: 'Pahvi - Kuivana ruskea aaltopahvi, pahvipakkaukset. Teippejä, etikettejä tai hakasia ei tarvii poistaa. Ei: märkä tai likainen pahvi, styrox eikä muovit.',
    icon: '/images/recyclability/cardboard.png',
  },
  AerosolWaste: {
    FI: 'Aerosolit - Ponnekaasupullot, joissa on tai ei ole punavalkoinen varoitusmerkki.',
    icon: '/images/recyclability/aerosols.png',
  },
  PaintWaste: {
    FI: 'Maalit - Kiinteä maali-, lakka- ja liimajäte. Maksimissaan 50cm korkeat astiat. Käytetyt pesurätit, joissa on imeytyneenä liuotinta.',
    icon: '/images/recyclability/paint.png',
  },
  NonHalogSolventWaste: {
    FI: 'Liuottimet - Kyllä: Liuotinpesuaineet, polttonesteet, asetoni, tinneri, tärpätti, ohenteet, lasinpesunesteet. EI: Klooripitoisia liuottimia, jarru- ja jäähdytinnesteitä, raskaspolttoöljyjätteitä, emäksisiä tai happamia pesuaineita.',
    icon: '/images/recyclability/solvent.png',
  },
  BatteriesAndSmallBatteries: {
    FI: 'Paristot - Teippaa navat, jos varausta on jäjellä. Kyllä: Paristot, ladattavat pienakut ja kuiva-akut. Ei: Lyijyhappoakkuja, litium- ja litiumioniakkuja tai -paristoja.',
    icon: '/images/recyclability/batteries.png',
  },
  ElecWaste: {
    FI: 'Sähkölaitteet - Kyllä: Pienkoneet ja sähkölaitteet, tietokoneet, näyttöpäätteet, näppäimistöt, televisiot, kodinkoneet, LED-putket.',
    icon: '/images/recyclability/electrical-waste.png',
  },
  PpHoneycombPanel: {
    FI: 'PP-kennolevy',
    icon: '/images/icon-search-green.png',
  },
};

export function getRecyclabilityIcon(name) {
  return (
    recyclabilityIcons[name] ?? {
      FI: name,
      icon: '/images/icon-search-green.png',
    }
  );
}

export function fileSorter(fa, fb) {
  const typea = fa.type || fa;
  const typeb = fb.type || fb;
  const fileOrder = [
    'w',
    'rt',
    'tds',
    'instr',
    'ohje',
    'docmanual',
    'docstoring',
    'm1',
    'dop',
    'doc',
    'eta',
    'epd',
    'sds',
    'ktt',
    'cert',
    'docce',
  ];
  return (
    fileOrder.indexOf(typeb.toLowerCase()) -
    fileOrder.indexOf(typea.toLowerCase())
  );
}
