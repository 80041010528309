/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import { useState, useEffect } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';

import ProductRow from '../PackageProductsLocal/ProductRow.tsx';
import ProductSearch from '../ProductSearch/ProductSearch.tsx';

const messages = defineMessages({
  searchTooltip: {
    id: 'search',
    description: 'Tooltip for phrase search',
    defaultMessage: 'Haussa voit käyttää lainausmerkkejä tarkentamaan hakua',
  },
});

function PackageProductsLocalSearch({
  packageActionCreators,
  logActionCreators,
  productActionCreators,
  currentPackage,
  activeProduct,
  packageProductSearchResult,
  packageProductSearchLoading,
  intl,
}) {
  const [keywords, setKeywords] = useState('');
  const [svhc, setSvhc] = useState(false);

  const openPanel = (product) => {
    packageActionCreators.getPackageProduct(product.package, product);

    logActionCreators.clearLog();
    logActionCreators.getProductLog(product.id);

    if (product.external_id) {
      productActionCreators.getExternalProduct(product);
    }
  };

  const handleSearch = (searchText, searchSvhc) => {
    productActionCreators.searchPackageProducts(
      currentPackage.id,
      searchText ?? keywords,
      searchSvhc ?? svhc
    );
  };

  useEffect(() => {
    // Flux dispatch needs this hack unfortunately
    setTimeout(() => {
      productActionCreators.clearSearchPackageProductsResult();
    }, 0);
  }, [currentPackage]);

  const filteredSearchResult = packageProductSearchResult?.filter(
    (r) => r.products.length > 0
  );

  return (
    <div className='PackageProductsLocalSearch m-t'>
      <ProductSearch
        minimal
        searchText={keywords}
        onSearchTextChange={(searchText) => {
          setKeywords(searchText);
        }}
        onScanResult={(searchText) => {
          setKeywords(searchText);
          handleSearch(searchText);
        }}
        onSubmit={() => {
          handleSearch();
        }}
      />

      <div className='checkbox'>
        <label>
          <input
            type='checkbox'
            checked={svhc}
            disabled={packageProductSearchLoading}
            onChange={(e) => {
              setSvhc(e.target.checked);
              handleSearch(keywords, e.target.checked);
            }}
          />
          <p>Erityistä huolta aiheuttava</p>
        </label>
      </div>

      {packageProductSearchLoading && (
        <div className='PackageProductsLocalSearch'>
          <div className='PackageProductsLocalSearch-content'>
            <div className='loading loading-green loading-center m-t-md m-b-md'>
              <div />
            </div>
          </div>
        </div>
      )}

      {filteredSearchResult && !packageProductSearchLoading && (
        <div>
          {!filteredSearchResult.length ? (
            <div className='m-t text-muted'>
              <FormattedMessage
                id='no-products'
                description='Placeholder when there are no products'
                defaultMessage='Ei tuotteita.'
              />
            </div>
          ) : (
            <div>
              {filteredSearchResult.map((res) => (
                <div
                  key={res.id}
                  className='PackageProductsLocalSearch-content'
                >
                  <p className='m-t'>{res.path}</p>
                  <ul className='PackageProductsLocalSearch-products'>
                    {res.products.map((product) => (
                      <ProductRow
                        key={product.id}
                        product={product}
                        isActive={activeProduct === product.id}
                        openPanel={openPanel}
                      />
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default injectIntl(PackageProductsLocalSearch);
