import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default (ComposedView) => {
  return class extends React.Component {
    static propTypes = {
      rtStore: PropTypes.object.isRequired,
      messageActionCreators: PropTypes.object.isRequired,
    };

    componentDidMount() {
      this._rtStoreSubscription = this.props.rtStore.addListener(
        this._onStoreChange
      );
    }

    componentWillUnmount() {
      this._rtStoreSubscription.remove();
    }

    _getStoreData = () => {
      return {
        rtState: this.props.rtStore.getState(),
      };
    };

    _onStoreChange = () => {
      this.setState(this._getStoreData());
    };

    _getContent = () => {
      return <ComposedView {...this.props} />;
    };

    state = this._getStoreData();

    render() {
      let content = null;

      if (this.state.rtState.error !== null) {
        /* eslint-disable */
        content = (
          <FormattedMessage
            id='error'
            description='Generic error'
            defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
            values={{
              logout: <a href='/logout'>kirjaudu ulos</a>,
            }}
          />
        );
      } else if (this.state.rtState.user) {
        content = (
          <p className='text-danger'>
            <FormattedMessage
              id='redirect-authenticated-user'
              description='Redirect authenticated user to the home page'
              defaultMessage='Olet jo kirjautunut sisään, uudelleenohjataan...'
            />
          </p>
        );
      } else {
        content = this._getContent();
      }

      return content;
    }
  };
};
