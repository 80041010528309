import download from 'downloadjs';
import ActionTypes from './ActionTypes';
import ErrorTypes from '../../shared/ErrorTypes.mjs';

class CommonActionCreators {
  constructor(dispatcher, apiUtils) {
    this._dispatcher = dispatcher;
    this._apiUtils = apiUtils;
  }

  _isSuccess(code) {
    return code >= 200 && code < 400;
  }

  _isAuthorizationError(error) {
    return (
      error &&
      error.code === 'InvalidCredentials' &&
      error.message !== ErrorTypes.ERROR_INVALID_CREDENTIALS
    );
  }

  _dispatchMessage(type, content, values = null) {
    this._dispatcher.dispatch({
      type: ActionTypes.MESSAGES_ADD,
      message: {
        type: type,
        content: content,
        values: values,
      },
    });
  }

  _dispatchFutureMessage(type, content, values = null) {
    this._dispatcher.dispatch({
      type: ActionTypes.MESSAGES_ADD,
      message: {
        type: type,
        content: content,
        values: values,
        future: true,
      },
    });
  }

  _downloadAsFile(filename, content, contentType = 'text/csv') {
    download(content, filename, contentType);
  }
}

export default CommonActionCreators;
