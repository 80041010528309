function formatTimestamp(timestring) {
  if (!timestring) return '';
  const timestamp = new Date(timestring);
  const dd = timestamp.getDate();
  const MM = timestamp.getMonth() + 1;
  const yyyy = timestamp.getFullYear();
  const hh = timestamp.getHours();
  const mm = timestamp.getMinutes();

  return `${dd}.${MM}.${yyyy}
     ${hh > 9 ? hh : `0${hh}`}:${mm > 9 ? mm : `0${mm}`}`;
}

export default function FileTimestamps({ product }) {
  const kttFiles = product.files
    ? product.files.filter(
        (file) =>
          (file.type === 'sds' || file.type === 'ktt') && file.source !== 0
      )
    : [];

  const sdTimestamps = product.sd_timestamp
    ? product.sd_timestamp.split(' // ')
    : [];
  const timestamps = [
    ...sdTimestamps,
    ...kttFiles.map((file) => file.last_modified),
  ];

  return (
    <>
      {timestamps.map((timestamp, idx) => (
        <div
          key={`${timestamp}-${idx}`}
          style={{
            marginTop: idx > 0 ? '1rem' : '0',
          }}
        >
          {formatTimestamp(timestamp)}
        </div>
      ))}
    </>
  );
}
