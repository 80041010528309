/**
 * MissingProductLink.js - React component MissingProductLink
 *
 * Copyright 2023 Taito United Oy
 * All rights reserved.
 */

import React from 'react';

import { FormattedMessage } from 'react-intl';

class MissingProductLink extends React.Component {
  render() {
    return (
      <div>
        <p className='text-muted m-l'>
          <FormattedMessage
            id='no-products-found'
            description='No products found when searching'
            defaultMessage='Etkö löydä etsimääsi?'
          />
        </p>
        <a className='m-l' href="mailto:tuotetieto@rakennustieto.fi?subject=En%20löydä%20etsimääni%20tuotetta&body=Hei,%0D%0A%0D%0AYritin%20etsiä%20tuotetta:%0D%0A%0D%0AVoisitteko%20pyytää%20kyseistä%20tuotetoimittajaa%20lisäämään%20tuotteen%20tietokantaan,%20jotta%20se%20löytyy%20suoraan%20haulla?">Ota meihin yhteyttä</a>
      </div>
    );
  }
}

export default MissingProductLink;
