import CommonActionCreators from './CommonActionCreators';
import ActionTypes from './ActionTypes';
import ErrorTypes from '../../shared/ErrorTypes.mjs';

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  fileDownloadNotFound: {
    id: 'file-download-not-found',
    description: 'File not found while trying to download',
    defaultMessage:
      'Pyytämääsi tiedostoa ei ole olemassa!' + ' Kokeile päivittää sivu.',
  },

  fileUploadPackageNoAccess: {
    id: 'file-upload-package-no-access',
    description: 'No access to package while trying to upload file',
    defaultMessage:
      'Sinulla ei ole oikeuksia kyseiseen urakkaan.' +
      ' Kokeile päivittää sivu.',
  },

  fileUploadPackageNotFound: {
    id: 'file-upload-package-not-found',
    description: 'Invalid package while trying to upload file',
    defaultMessage: 'Urakkaa ei löytynyt!' + ' Kokeile päivittää sivu.',
  },

  fileUploadProductNotFound: {
    id: 'file-upload-product-not-found',
    description: 'Invalid product while trying to upload file',
    defaultMessage:
      'Tuotetta johon yritit lisätä tiedoston ei löytynyt' +
      '! Kokeile päivittää sivu.',
  },

  fileUploadEmptyFilename: {
    id: 'file-upload-empty-filename',
    description: 'Empty filename while trying to upload file',
    defaultMessage: 'Tiedoston nimi ei voi olla tyhjä!',
  },

  fileUploadInvalidType: {
    id: 'file-upload-invalid-type',
    description: 'Invalid type while trying to upload file',
    defaultMessage:
      'Virheellinen tiedoston tyyppi!' + ' Kokeile päivittää sivu.',
  },

  fileUploadPartialFailure: {
    id: 'file-upload-partial-failure',
    description: 'Some of the files failed to upload.',
    defaultMessage: 'Tiedostojen lataamisessa tapahtui virhe. Tarkista tuote.',
  },

  removeFileNotFound: {
    id: 'remove-file-not-found',
    description: 'File not found',
    defaultMessage: 'Poistettavaa tiedostoa ei löytynyt',
  },

  removeFilePackageNotFound: {
    id: 'remove-file-package-not-found',
    description: 'Package for file to be removed not found',
    defaultMessage: 'Poistettavan tiedoston urakkaa ei löytynyt',
  },

  removeFileNoAccess: {
    id: 'remove-file-no-access',
    description: 'Insufficient rights to access file',
    defaultMessage:
      'Sinulla ei ole tarvittavia oikeuksia' + ' poistaa tätä tiedostoa',
  },

  removeFileProductNotFound: {
    id: 'remove-file-product-not-found',
    description:
      'Product from which the file is' + 'being removed from was not found',
    defaultMessage: 'Tuotetta, josta yrititte poistaa tiedoston, ei löytynyt',
  },

  removeFileProductAlreadyInstalled: {
    id: 'remove-file-product-already-installed',
    description:
      'Product from which the file is' + 'being removed is already installed',
    defaultMessage: 'Tuote, josta yrititte poistaa tiedoston, on jo asennettu',
  },
});

class FileActionCreators extends CommonActionCreators {
  constructor(dispatcher, apiUtils) {
    super(dispatcher, apiUtils);
    this.apiBaseUrl = apiUtils._apiBaseUrl;
  }

  downloadFile(file) {
    this._apiUtils
      .get(`/files/${file.id}/token`)
      .then((response) => {
        const token = response.body.token;
        let path = this.apiBaseUrl;
        path += `/files/${file.id}/download/${token}/${file.filename}`;
        window.location.assign(path);
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_FILE_NOT_FOUND:
            this._dispatchMessage('danger', messages.fileDownloadNotFound);

            break;

          default:
            console.log(error);
        }
      });
  }

  addFiles(productID, packageID, files) {
    const data = new FormData();
    data.append('productId', productID);
    data.append('packageId', packageID);
    files.forEach((file, index) => {
      const fileData = JSON.stringify({ name: file.name, type: file.type });
      data.append(index, file.file);
      data.append('file' + index, fileData);
    });

    this._apiUtils
      .post('/files', data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.FILE_ADDED,
          data: response.body,
        });
        if (!response.body.success) {
          this._dispatchFutureMessage(
            'danger',
            messages.fileUploadPartialFailure
          );
        }
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.fileUploadPackageNoAccess);
            break;

          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchFutureMessage(
              'danger',
              messages.fileUploadPackageNotFound
            );

            break;

          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchFutureMessage(
              'danger',
              messages.fileUploadProductNotFound
            );

            break;

          case ErrorTypes.ERROR_FILE_EMPTY_NAME:
            this._dispatchFutureMessage(
              'danger',
              messages.fileUploadEmptyFilename
            );

            break;

          case ErrorTypes.ERROR_FILE_INVALID_TYPE:
            this._dispatchFutureMessage(
              'danger',
              messages.fileUploadInvalidType
            );

            break;

          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PACKAGE_RECEIVE_ERROR,
              error,
            });
        }
      });
  }

  editFileMetadata(file, name, type) {
    this._apiUtils
      .patch(`/files/${file.id}`, { name, type })
      .then((response) => {
        if (file.product_manual_id) {
          this._dispatcher.dispatch({
            type: ActionTypes.FILE_MANUAL_UPDATED,
            data: {
              file: response.body.file,
              oldFile: file,
            },
          });
        } else {
          this._dispatcher.dispatch({
            type: ActionTypes.FILE_UPDATED,
            data: response.body.file,
          });
        }
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_FILE_NOT_FOUND:
            this._dispatchFutureMessage('danger', messages.removeFileNotFound);
            break;
          case ErrorTypes.ERROR_FILE_EMPTY_NAME:
            this._dispatchFutureMessage(
              'danger',
              messages.fileUploadEmptyFilename
            );
            break;
          case ErrorTypes.ERROR_FILE_INVALID_TYPE:
            this._dispatchFutureMessage(
              'danger',
              messages.fileUploadInvalidType
            );
            break;
          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchFutureMessage(
              'danger',
              messages.fileUploadPackageNoAccess
            );
            break;
          case ErrorTypes.ERROR_PRODUCT_NO_ACCESS:
            this._dispatchFutureMessage('danger', messages.removeFileNoAccess);
            break;
          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchFutureMessage(
              'danger',
              messages.removeFileProductNotFound
            );
            break;
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PACKAGE_RECEIVE_ERROR,
              error,
            });
        }
      });
  }

  removePackageSpecificFile(fileID) {
    this._apiUtils
      .delete(`/files/${fileID}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.FILE_ADDED, // HACK lazy but works
          data: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_FILE_NOT_FOUND:
            this._dispatchFutureMessage('danger', messages.removeFileNotFound);
            break;
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchFutureMessage(
              'danger',
              messages.removeFilePackageNotFound
            );
            break;
          case ErrorTypes.ERROR_PRODUCT_NO_ACCESS:
            this._dispatchFutureMessage('danger', messages.removeFileNoAccess);
            break;
          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchFutureMessage(
              'danger',
              messages.removeFileProductNotFound
            );
            break;
          case ErrorTypes.ERROR_FILE_PRODUCT_ALREADY_INSTALLED:
            this._dispatchFutureMessage(
              'danger',
              messages.removeFileProductAlreadyInstalled
            );
            break;
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PACKAGE_RECEIVE_ERROR,
              error,
            });
        }
      });
  }

  addManualFiles(productID, files) {
    const data = new FormData();
    files.forEach((file, index) => {
      const fileData = JSON.stringify({ name: file.name, type: file.type });
      data.append(index, file.file);
      data.append('file' + index, fileData);
    });

    this._apiUtils
      .post(`/products/manual/${productID}/files`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.FILE_MANUAL_ADDED,
          data: response.body,
        });
        if (!response.body.success) {
          this._dispatchFutureMessage(
            'danger',
            messages.fileUploadPartialFailure
          );
        }
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchFutureMessage(
              'danger',
              messages.fileUploadProductNotFound
            );

            break;

          case ErrorTypes.ERROR_FILE_EMPTY_NAME:
            this._dispatchFutureMessage(
              'danger',
              messages.fileUploadEmptyFilename
            );

            break;

          case ErrorTypes.ERROR_FILE_INVALID_TYPE:
            this._dispatchFutureMessage(
              'danger',
              messages.fileUploadInvalidType
            );

            break;

          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PACKAGE_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  removeManualFile(productID, fileID) {
    this._apiUtils
      .delete(`/products/manual/${productID}/files/${fileID}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.FILE_MANUAL_ADDED,
          data: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PACKAGE_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  downloadKTT(externalID) {
    const path = `${this.apiBaseUrl}/products/${externalID}/safety`;
    window.location.assign(path);
  }

  downloadImportProductsTemplate() {
    const path = `${this.apiBaseUrl}/import-products-sheet/download`;
    window.location.assign(path);
  }
}

export default FileActionCreators;
