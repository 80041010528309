import constructionPhases from './phases.js';

const packageForPhase = (phase, pkgs) =>
  pkgs.find(pkg => {
    const sameParent = phase.parent && phase.parent.pkgId === pkg.parent;

    // Match parent id or top-level packages
    return (sameParent || pkg.level === 2) &&
      pkg.name === phase.name &&
      pkg.level === phase.level + 1;
  });


// Depth-first
export const getPhaseTrees = (pkgs) => {
  const stack = constructionPhases.map(
    (phase, index) => ({
      ...phase,
      name: `${index + 1}. ${phase.name}`,
      id: `${index + 1}`,
      level: 1,
    })
  );

  const trees = Array.from(stack);

  while (stack.length > 0) {
    const phase = stack.pop();

    phase.children = phase.children.map((child, index) => ({
      ...child,
      id: `${phase.id}-${index + 1}`,
      parent: phase,
      level: phase.level + 1,
    }));

    const existingPkg = packageForPhase(phase, pkgs);
    phase.pkgId = existingPkg ? existingPkg.id : null;

    stack.push(...phase.children);
  }

  return trees;
};

export const groupSelected = (selected) => {
  const groups = {};

  const ids = Object.keys(selected);
  const values = Object.values(selected);

  values.forEach((phase) => {
    const parent = phase.parent;
    if (parent) {
      if (parent.pkgId) {
        groups[parent.id] = { ...parent };
      }
    } else {
      if (groups.root) {
        groups.root.children.push({ ...phase });
      } else {
        groups.root = { id: 'root', children: [{...phase }] };
      }
    }
  });

  const stack = Array.from(Object.values(groups));

  while (stack.length > 0) {
    const phase = stack.pop();

    /* eslint-disable no-loop-func */
    const children = phase.children.filter((child) => ids.includes(child.id));
    /* eslint-enable no-loop-func */
    phase.children = children;
    stack.push(...children);
  }

  return groups;
};

export const formCsv = (phases) => {
  const rows = [',,,,'];

  phases.forEach((phase) => {
    if (phase.id === 'root') return;
    const parent = phase.parent;
    const parentId = (parent && !parent.pkgId) ? parent.id : '';
    rows.push(`${phase.id},${parentId},"${phase.name}",,`);
  });

  return rows.join('\n');
};

export const flattenPhases = (phases) =>
  phases.reduce((acc, phase) => {
    acc.push(phase);
    if (phase.children.length > 0) acc.push(...flattenPhases(phase.children));
    return acc;
  }, []);

