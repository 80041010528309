import { useZxing } from 'react-zxing';
import { BarcodeFormat, DecodeHintType } from '@zxing/library';
import { useMediaDevices } from 'react-media-devices';
import { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';

interface Props {
  setResult(result: string): void;
}

const hints = new Map();
const formats = [BarcodeFormat.EAN_13, BarcodeFormat.EAN_8];
hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

const constraints: MediaStreamConstraints = {
  video: { facingMode: 'environment' },
  audio: false,
};

const BarcodeScanner = ({ setResult }: Props) => {
  const { devices } = useMediaDevices({ constraints });

  const videoDevices = useMemo(
    () =>
      devices
        ? devices
            .filter(({ kind }) => kind === 'videoinput')
            .map((d, i) => ({
              label: d.label || `Kamera ${i}`,
              value: d.deviceId,
            }))
        : [],
    [devices]
  );

  const [selectedDevice, setSelectedDevice] = useState<null | {
    value: string;
    label: string;
  }>(null);

  useEffect(() => {
    const backCamera = videoDevices?.find((d) =>
      d.label.toLowerCase().includes('back')
    );
    if (videoDevices && videoDevices.length > 0) {
      setSelectedDevice({
        label: backCamera
          ? backCamera.label
          : videoDevices[videoDevices.length - 1].label,
        value: backCamera
          ? backCamera.value
          : videoDevices[videoDevices.length - 1].value,
      });
    }
  }, [videoDevices]);

  const {
    ref,
    torch: { on, off, isOn, isAvailable },
  } = useZxing({
    timeBetweenDecodingAttempts: 50,
    hints,
    paused: !selectedDevice,
    deviceId: selectedDevice?.value,
    onResult(result) {
      if (isOn) off();
      setResult(result.getText());
    },
  });

  const close = () => {
    if (isOn) off();
    setResult('');
  };

  return (
    <div className='scanner'>
      <div className='backdrop' />

      <div className='content-wrapper'>
        <div className='top-controls'>
          {isAvailable ? (
            <button
              onClick={() => (isOn ? off() : on())}
              className='torch-button'
              style={{
                color: isOn ? 'yellow' : '#dddddd',
              }}
            >
              <i className='fa fa-lightbulb-o m-r' />
              {isOn ? 'Turn off' : 'Turn on'} torch
            </button>
          ) : null}
          <button
            className='btn btn-outline-secondary close-button'
            onClick={close}
          >
            <i className='fa fa-close'></i>
          </button>
        </div>
        <div className='video-controls'>
          <div className='media-select'>
            <Select
              value={selectedDevice}
              onChange={(value) => setSelectedDevice(value)}
              options={videoDevices}
              placeholder='Valitse kamera'
              className='camera-select'
            />
          </div>

          <div>
            <video id='video' ref={ref} />
            <div className='scanline'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarcodeScanner;
