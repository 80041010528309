// NOTE: hardcoded to Finnish collation, probably fine?
const locale = 'fi';
const options = { numeric: true, sensitivity: 'base' };
const collator = new Intl.Collator(locale, options);

/**
 * Compares two elements naturally
 * @param {string} first
 * @param {string} second
 */
export function naturalCompare(first, second) {
  return collator.compare(first, second);
}

/**
 * Sorts package tree naturally
 * @param {Array} pkgs tree of packages to sort
 */
export function sortPackageTree(pkgs) {
  if (!pkgs) return null;
  const sorted = pkgs.sort((pA, pB) => naturalCompare(pA.name, pB.name));
  sorted.forEach(pkg => pkg.children = sortPackageTree(pkg.children));
  return sorted;
}

export function sortFolderTree(tree) {
  if (!tree) return null;
  const sorted = tree.sort((fA, fB) => naturalCompare(fA.name, fB.name));
  sorted.forEach(folder => {
    folder.children = sortFolderTree(folder.children);
    folder.products = folder.products.sort(
      (pA, pB) => naturalCompare(pA.data.name, pB.data.name)
  );
  });
  return sorted;
}
