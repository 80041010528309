/* eslint-disable max-len */

// The meta fields for projects are defined here
// The fields are different for roots (pääurakka) and other nodes (aliurakka)
// They are stored in the database in a jsonb field

// NOTE If you add something here, be sure to add it to the worker's pdf.go
// as well for it to be visible in the report
const fields = {
  root: {
    projectNumber: {
      id: 'project-number',
      description: 'Project number',
      defaultMessage: 'Projektinumero',
    },
    constructionPermitId: {
      id: 'project-construction-permit-id',
      description: 'Construction permit id',
      defaultMessage: 'Rakennuslupatunnus',
    },
    realEstateId: {
      id: 'project-real-estate-id',
      description: 'Real estate id',
      defaultMessage: 'Kiinteistötunnus',
    },
    constructionPeriod: {
      id: 'project-construction-period',
      description: 'Construction period mm/yyyy - mm/yyyy',
      defaultMessage: 'Rakennusaika',
    },
    owner: {
      id: 'project-owner',
      description: 'Owner of the project',
      defaultMessage: 'Rakennushankkeeseen ryhtyvä',
    },
    overseer: {
      id: 'project-overseer',
      description: 'Overseer of the project',
      defaultMessage: 'Rakennushankkeen työnjohtaja',
    },
    other: {
      id: 'project-other',
      description: 'Other information about the project',
      defaultMessage: 'Muu lisätietokenttä',
    },
  },
  node: {
    subcontractor: {
      id: 'project-subcontractor',
      description: 'Subcontractor',
      defaultMessage: 'Aliurakoitsija',
    },
    yId: {
      id: 'project-yid',
      description: 'Y-id (Y-tunnus)',
      defaultMessage: 'Y-tunnus',
    },
    subcontractorNumber: {
      id: 'project-subcontractor-number',
      description: 'Subcontractor work or project id',
      defaultMessage: 'Aliurakointiyrityksen oma työnumero tai projektinumero',
    },
    generalContractorAgreementNumber: {
      id: 'project-general-contractor-agreement-number',
      description: 'General contractor\'s agreement number for this subcontract',
      defaultMessage: 'Pääurakoitsijan sopimusnumero ko. aliurakan osalta',
    },
    contactPersonGuaranteePeriod: {
      id: 'project-contact-person-guarantee-period',
      description: 'Contact person for the subcontractor during the guarantee period',
      defaultMessage: 'Aliurakoitsijan yhteyshenkilö takuuaikana',
    },
    contactPersonEmail: {
      id: 'project-contact-person-email',
      description: 'Email for the contact person',
      defaultMessage: 'Aliurakoitsijan yhteyshenkilön sähköposti',
    },
    contactPersonPhoneNumber: {
      id: 'project-contact-person-phone-number',
      description: 'Phone number for the contact person',
      defaultMessage: 'Aliurakoitsijan yhteyshenkilön puhelinnumero',
    },
    other: {
      id: 'project-other',
      description: 'Other information about the project',
      defaultMessage: 'Muu lisätietokenttä',
    },
  },
};

export function getMetaEmptyFields(isRoot) {
  const emptyFields = {};
  Object.keys(isRoot ? fields.root : fields.node).forEach(
    (field) => emptyFields[field] = ''
  );
  return emptyFields;
}

export function getMetaMessages() {
  return { ...fields.root, ...fields.node };
}
