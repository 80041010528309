import PropTypes from 'prop-types';

import { Component } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import path from 'path';
import ConfirmActionModal from '../ConfirmActionModal/ConfirmActionModal.tsx';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';
import { injectIntl, FormattedMessage } from 'react-intl';

import {
  getProductFilename,
  getFileTypePath,
  fileSorter,
  getRecyclabilityIcon,
} from '../../utils/fileUtil';
import {
  getProductData,
  getSafetyCodes,
  hasSdsFile,
  gwpOptions,
} from '../../utils/productUtils';
import { ProductType } from '../../utils/enum';
import ProductRemovalButton from './ProductRemovalButton.jsx';
import ProductDetails from './ProductDetails.tsx';
import EditFileModal from '../EditFileModal/EditFileModal.js';
import EditProductModal from '../EditProductModal/EditProductModal.js';
import { ApproverSelector } from '../ApproverSelector/ApproverSelector.jsx';
import ProductComments from './ProductComments.jsx';

class ProductInformationPanel extends Component {
  constructor(props, context) {
    super(props, context);
    const errors = this.getErrors();

    let openMenu = false;
    if (window.matchMedia('screen and (max-width: 34em)').matches) {
      openMenu = !!props.product;
    }

    let maximumStoredAmount = '';
    let exposedUsers = '';

    let gwpValueA1A3 = (props.product && props.product.gwp_value_a1a3) || '';
    let gwpUnit =
      (props.product && props.product.gwp_unit) ||
      gwpOptions.gwpUnitOptions[0].value;
    let gwpVerification =
      (props.product && props.product.gwp_verification) ||
      gwpOptions.gwpVerificationOptions[0].value;
    let gwpStandard =
      (props.product && props.product.gwp_standard) ||
      gwpOptions.gwpStandardOptions[0].value;

    if (props.product) {
      maximumStoredAmount = props.product.maximum_stored_amount || '';

      if (props.product.exposed_users) {
        exposedUsers = props.product.exposed_users;
      }

      if (
        props.product.data &&
        (props.product.data.from_company ||
          (!props.product.data.manual && !props.product.id))
      ) {
        gwpValueA1A3 = props.product.data.gwp_value_a1a3 || '';
        gwpUnit =
          props.product.data.gwp_unit || gwpOptions.gwpUnitOptions[0].value;
        gwpVerification =
          props.product.data.gwp_verification ||
          gwpOptions.gwpVerificationOptions[0].value;
        gwpStandard =
          props.product.data.gwp_standard ||
          gwpOptions.gwpStandardOptions[0].value;
      }
    }

    const safetyData = this.props.safetyData || {
      hazardCodes: [],
      safetyCodes: [],
      warningCodes: [],
    };

    const productSafetyData = props.product
      ? getSafetyCodes(props.product, safetyData)
      : null;

    this.state = {
      openMenu,
      maximumStoredAmount,
      exposedUsers,
      safetyCodes: productSafetyData ? productSafetyData.safetyCodes : null,
      hazardCodes: productSafetyData ? productSafetyData.hazardCodes : null,
      warningCodes: productSafetyData ? productSafetyData.warningCodes : null,
      file: undefined,
      gwpLimit: (props.product && props.product.gwp_limit) || '',
      gwpValueA1A3,
      gwpUnit,
      gwpVerification,
      gwpStandard,
      selectedFile: null,
      ...errors,
    };
  }

  componentWillUnmount() {
    this._enablePageScroll();
  }

  getErrors = () => ({
    error: null,
    noNameError: null,
    noFileError: null,
    noTypeError: null,
  });

  _enablePageScroll = () => {
    $('html').removeClass('no-scroll');
  };

  _disablePageScroll = () => {
    $('html').addClass('no-scroll');
  };

  _closeMenu = () => {
    // Checks for mobile view
    if (window.matchMedia('screen and (max-width: 34em)').matches) {
      // HACK: willreceiveprops will run a bit too often, and if product
      //       is set, then the menu will open again... so clear the product.
      this.props.productActionCreators.clearProduct();
      this.setState({
        openMenu: false,
      });
    }
  };

  _openLink = () => {
    this.props.productActionCreators.setProductInformationModalOpen();
  };

  _addPackageProduct = (event) => {
    event.preventDefault();

    this.props.packageActionCreators.addPackageProduct(
      this.props.package.id,
      this.props.product
    );
  };

  _instaddProduct = (event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    this.props.packageActionCreators
      .instaddProduct(this.props.package.id, this.props.product)
      .then((product) => {
        this.props.logActionCreators.getProductLog(product.id);
      });
  };

  _installProduct = (event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    this.props.productActionCreators
      .installProduct(this.props.product)
      .then(() => {
        this.props.logActionCreators.getProductLog(this.props.product.id);
      });
  };

  _uninstallProduct = (event) => {
    if (event) event.preventDefault();
    this.props.productActionCreators
      .uninstallProduct(this.props.product)
      .then(() => {
        this.props.logActionCreators.getProductLog(this.props.product.id);
      });
  };

  _removeProduct = () => {
    this.props.messageActionCreators.clearMessages();
    this.props.productActionCreators.removeProduct(
      this.props.product.id,
      this.props.package.id
    );

    this.props.productActionCreators.clearProduct();
  };

  _approveCarbonFootprint = (event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    this.props.productActionCreators.approveProductCarbonFootprint(
      this.props.product.id,
      !this.props.product.gwp_approved
    );
  };

  _approveProduct = (event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    this.props.productActionCreators.approveProduct(this.props.product.id);
  };

  _disapproveProduct = (event) => {
    event.preventDefault();

    this.props.messageActionCreators.clearMessages();
    this.props.productActionCreators.disapproveProduct(this.props.product.id);
  };

  _setProductApprovableTrue = () => {
    this.props.messageActionCreators.clearMessages();
    this.props.productActionCreators.setProductApprovable(
      this.props.product.id,
      true
    );
  };

  _setProductApprovableFalse = () => {
    this.props.messageActionCreators.clearMessages();
    this.props.productActionCreators.setProductApprovable(
      this.props.product.id,
      false
    );
  };

  _downloadKTT = (event) => {
    event.preventDefault();
    this.props.fileActionCreators.downloadKTT(this.props.product.data.id);
  };

  _downloadFile = (file, event) => {
    event.preventDefault();
    this.props.fileActionCreators.downloadFile(file);
  };

  _removeFile = (file) => {
    this.props.fileActionCreators.removePackageSpecificFile(file.id);
  };

  _productStatus = (product) => {
    let content = null;
    if (!product.id) {
      return content;
    }
    const data = getProductData(product);

    if (product.status === 0) {
      content = (
        <div>
          {product.approved ? (
            <FormattedMessage
              id='product-status-planned-approved'
              description='Status text for planned and approved product'
              defaultMessage={
                data.variant !== ProductType.CHEMICAL
                  ? 'Suunnitelmassa, tuotekelpoisuus todettu'
                  : 'Suunnitelmassa'
              }
            />
          ) : (
            <FormattedMessage
              id='product-status-planned'
              description='Status text for a planned product'
              defaultMessage={
                data.variant !== ProductType.CHEMICAL
                  ? 'Suunnitelmassa, tuotekelpoisuutta ei todettu'
                  : 'Suunnitelmassa'
              }
            />
          )}
        </div>
      );
    } else if (product.status === 1) {
      content = (
        <div>
          {product.approved ? (
            <FormattedMessage
              id='product-status-installed-approved'
              description='Status text for a installed and approved product'
              defaultMessage='Asennettu, tuotekelpoisuus todettu'
            />
          ) : (
            <FormattedMessage
              id='product-status-installed'
              description='Status text for a installed product'
              defaultMessage='Asennettu, tuotekelpoisuutta ei todettu'
            />
          )}
        </div>
      );
    }

    return [
      <dt key='product-status' className='col-xs-12 col-sm-5'>
        <FormattedMessage
          id='product-status'
          description='Label text for product status'
          defaultMessage='Tuotteen tila'
        />
        :
      </dt>,
      <dd key='product-status-value' className='col-xs-12 col-sm-7'>
        {content}
      </dd>,
    ];
  };

  _productAction = (product) => {
    if (this.props.package.state === 2) return null;
    let content = null;
    const data = getProductData(product);

    if (!product.id) {
      return (
        <div>
          <button
            className='btn btn-warning m-b'
            onClick={this._addPackageProduct}
          >
            <FormattedMessage
              id='add-product-button'
              description='Button text for adding a product to a package'
              defaultMessage='Lisää suunnitelmaan'
            />
          </button>

          {data.variant !== ProductType.CHEMICAL && (
            <button
              className={
                'ProductInformationPanel-content-instadd btn btn-success'
              }
              onClick={this._instaddProduct}
            >
              <FormattedMessage
                id='instadd-product-button'
                description='Button text for installing and adding a product'
                defaultMessage='Merkitse suoraan asennetuksi'
              />
            </button>
          )}
        </div>
      );
    }

    content =
      product.status === 0 ? (
        <div>
          {data.variant !== ProductType.CHEMICAL && (
            <button
              className={'btn btn-success'}
              onClick={this._installProduct}
            >
              <FormattedMessage
                id='install-product-button'
                description='Button text for installing a product'
                defaultMessage='Merkitse asennetuksi'
              />
            </button>
          )}
        </div>
      ) : (
        <div>
          <button
            className='btn btn-danger'
            onClick={(event) => {
              event.preventDefault();
              if (product.snapshot && (!product.data || !product.data.id)) {
                $('#UninstallDeletedProductPanelModal').modal('show');
              } else {
                this._uninstallProduct();
              }
            }}
          >
            <FormattedMessage
              id='product-uninstall-button'
              description='Button label for uninstalling a product'
              defaultMessage='Peru asennus'
            />
          </button>
          <label className='switch'>
            {/* <input type="checkbox"> */}
            <span className='slider round'></span>
          </label>
        </div>
      );

    return <div>{content}</div>;
  };

  _setProductApprovable = (product) => {
    let content = null;
    const data = getProductData(product);
    if (data.variant === ProductType.CHEMICAL) return null;

    content = (
      <div className='switch'>
        <ToggleSwitch
          id='set_product_approvable'
          small={true}
          checked={product.product_approvable}
          onChange={
            product.product_approvable
              ? this._setProductApprovableFalse
              : this._setProductApprovableTrue
          }
        />
        <FormattedMessage
          id='set_product_approvable'
          description='Button label to add the approve check box for a product'
          defaultMessage='Tuotekelpoisuus todennettava'
        />
      </div>
    );

    return product.id ? content : null;
  };

  _productApproval = (product) => {
    let content = null;
    if (!product.id || !this.props.package.gwp_can_approve) return content;
    const data = getProductData(product);
    if (data.variant === ProductType.CHEMICAL) return null;

    content = (
      <div>
        {!product.approved ? (
          <button className='btn btn-success' onClick={this._approveProduct}>
            <FormattedMessage
              id='product-approve-button'
              description='Button label for approving a product'
              defaultMessage='Totea tuotekelpoisuus'
            />
          </button>
        ) : (
          <button className='btn btn-danger' onClick={this._disapproveProduct}>
            <FormattedMessage
              id='product-disapprove-button'
              description='Button label for disapproving a product'
              defaultMessage='Peru tuotekelpoisuus'
            />
          </button>
        )}
      </div>
    );

    return content;
  };

  _patchProduct = (data) => {
    this.props.messageActionCreators.clearMessages();
    this.props.productActionCreators.patchProduct(this.props.product.id, data);
  };

  _updateChemicalInformation = () => {
    const productType = this.props.product.type;

    // Codes cannot be updated for company products. However exposed_users
    // and maximum_stored_amount can still be updated
    const data = {
      exposed_users: this.state.exposedUsers,
      maximum_stored_amount: this.state.maximumStoredAmount,
      hazard_codes:
        productType === 'company-product'
          ? undefined
          : this.state.hazardCodes.map(({ value }) => value).join(','),
      safety_codes:
        productType === 'company-product'
          ? undefined
          : this.state.safetyCodes.map(({ value }) => value).join(','),
      warning_codes:
        productType === 'company-product'
          ? undefined
          : this.state.warningCodes.map(({ value }) => value).join(','),
    };

    this.props.productActionCreators.updatePackageProductChemicalInformation(
      this.props.package.id,
      this.props.product.id,
      data
    );
  };

  _manualProductWarning = (product) => {
    if (!product.id) return null;
    if (product.external_id) return null;
    if (product.id.toString().includes('&')) return null;
    return (
      <div className='container-fixed alert alert-warning'>
        <div className='row'>
          <div className='col-xs-1'>
            <i className='fa fa-exclamation-triangle'></i>
          </div>

          <div className='col-xs-11'>
            <FormattedMessage
              id='manual-product-warning'
              description='Warning for manual products'
              defaultMessage={'Tuotetieto on käyttäjän lisäämä.'}
            />
          </div>
        </div>
      </div>
    );
  };

  _chemicalProductWarning = (product) => {
    const data = getProductData(product);
    if (
      data.variant !== ProductType.CHEMICAL &&
      data.variant !== ProductType.CHEMICAL_PRODUCT
    ) {
      return null;
    }

    const safetyData = this.props.safetyData || {
      hazardCodes: [],
      safetyCodes: [],
      warningCodes: [],
    };

    const productSafetyData = getSafetyCodes(product, safetyData);

    if (
      productSafetyData.hazardCodes &&
      productSafetyData.hazardCodes.length > 0 &&
      productSafetyData.safetyCodes &&
      productSafetyData.safetyCodes.length > 0 &&
      productSafetyData.warningCodes &&
      productSafetyData.warningCodes.length > 0 &&
      hasSdsFile(product)
    ) {
      return null;
    }

    return (
      <div className='container-fixed alert alert-warning'>
        <div className='row'>
          <div className='col-xs-1'>
            <i className='fa fa-exclamation-triangle'></i>
          </div>

          <div className='col-xs-11'>
            <FormattedMessage
              id='chemical-product-warning'
              description='Warning for incomplete chemical products'
              defaultMessage='Kemikaalitiedot puutteelliset.'
            />
          </div>
        </div>
      </div>
    );
  };

  _svhcWarning = (product) => {
    const data = getProductData(product);
    if (
      data.variant !== ProductType.CHEMICAL &&
      data.variant !== ProductType.CHEMICAL_PRODUCT
    ) {
      return null;
    }

    if (
      product.data?.safety_data?.SafetyDataSvhc !== 'YES' &&
      !product.data?.snapshot_data?.svhc
    ) {
      return null;
    }

    return (
      <div className='container-fixed alert alert-warning'>
        <div className='row'>
          <div className='col-xs-1'>
            <i className='fa fa-exclamation-triangle'></i>
          </div>

          <div className='col-xs-11'>
            <FormattedMessage
              id='svhc-product-warning'
              description='Warning for svhcs chemical products'
              defaultMessage='Erityistä huolta aiheuttava.'
            />
          </div>
        </div>
      </div>
    );
  };

  _removedProductWarning = (product) => {
    if ((product.data && product.data.id) || product.status === 0) return null;
    return (
      <div className='container-fixed alert alert-warning'>
        <div className='row'>
          <div className='col-xs-1'>
            <i className='fa fa-exclamation-triangle'></i>
          </div>

          <div className='col-xs-11'>
            <FormattedMessage
              id='removed-product-warning'
              description='Warning for removed products'
              defaultMessage={
                'Alkuperäistä tuotetta ei löytynyt järjestelmästä. ' +
                'Urakassa oleva tuote on asennushetkeltä.'
              }
            />
          </div>
        </div>
      </div>
    );
  };

  _productFiles = (product) => {
    if (!product) {
      return null;
    }

    let content = null;
    const data = getProductData(product);
    if (
      (!product.files || !product.files.length) &&
      (!data.files || !data.files.length)
    ) {
      content = (
        <small className='text-muted'>
          <FormattedMessage
            id='no-files-placeholder'
            description='Placeholder when there are no files'
            defaultMessage='Ei tiedostoja.'
          />
        </small>
      );
    } else {
      const deduplicate = {};

      content = (
        <div className='ProductInformationPanel-content-files list-group'>
          {(product.files || []).sort(fileSorter).map((file, index) => {
            // if file.name contains %E4, replace all %E4 with %C3%A4 to fix decoding issue
            const fixedFile = {
              ...file,
              name: file.name.replaceAll('%E4', '%C3%A4'),
            };
            const key = JSON.stringify({
              name: fixedFile.name,
              filename: fixedFile.original_filename,
              type: fixedFile.type,
            });

            if (deduplicate.hasOwnProperty(key)) return null;
            deduplicate[key] = true;

            return (
              <button
                key={key}
                onClick={(event) => this._downloadFile(fixedFile, event)}
                className={classNames(
                  'list-group-item',
                  'ProductInformationPanel-content-files-row'
                )}
              >
                <span>{getProductFilename(product, fixedFile, index)}</span>
                <div className='floater'>
                  {product.status === 0 ? (
                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({
                          selectedFile: fixedFile,
                        });
                        $('#EditFileModal').modal('show');
                      }}
                    >
                      <i className='text-danger fa fa-pencil fa-lg'></i>
                    </a>
                  ) : null}
                  {product.status === 0 ? (
                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({
                          selectedFile: fixedFile,
                        });
                        $('#RemoveFileModal').modal('show');
                      }}
                    >
                      <i className='text-danger fa fa-trash fa-lg'></i>
                    </a>
                  ) : null}
                  <img src={getFileTypePath(fixedFile.type)} />
                </div>
              </button>
            );
          })}

          {data.files &&
            data.files.length > 0 &&
            product.files &&
            product.files.length > 0 && <hr />}

          {(data.files || []).sort(fileSorter).map((file, index) => {
            // if file.name contains %E4, replace all %E4 with %C3%A4 to fix decoding issue
            const fixedFile = {
              ...file,
              name: file.name.replaceAll('%E4', '%C3%A4'),
            };

            const key = JSON.stringify({
              name: fixedFile.name,
              filename: path.basename(fixedFile.url),
              type: fixedFile.type,
            });
            if (product.status === 1) return null;

            if (deduplicate.hasOwnProperty(key)) return null;
            deduplicate[key] = true;
            return (
              <button
                key={key}
                onClick={(event) => {
                  if (fixedFile.url) {
                    window.open(fixedFile.url, '_blank').focus();
                  } else {
                    this._downloadFile(fixedFile, event);
                  }
                }}
                className={classNames(
                  'list-group-item',
                  'ProductInformationPanel-content-files-row'
                )}
              >
                <span>{getProductFilename(product, fixedFile, index)}</span>
                <img src={getFileTypePath(fixedFile.type)} />
              </button>
            );
          })}
        </div>
      );
    }

    return (
      <div>
        {content}

        <div className='maxpanel ProductInformationPanel-content-actions'>
          {product.id ? (
            <button
              className='btn btn-success'
              data-toggle='modal'
              data-target='#AddFileModal'
            >
              <FormattedMessage
                id='add-file-button'
                description='Label for add file button'
                defaultMessage='Lisää tiedosto'
              />
            </button>
          ) : null}
          {this._productKTT(product)}
        </div>
      </div>
    );
  };

  _productKTT = (product) => {
    if (!product) return null;
    if (!product.data || !product.data.safety_data) return null;
    return (
      <button className='btn btn-success' onClick={this._downloadKTT}>
        <FormattedMessage
          id='download-ktt-button'
          description='Label for KTT download button'
          defaultMessage='Lataa KTT-tiivistelmä'
        />
      </button>
    );
  };

  _onDrop = (files) => {
    this.setState({
      file: files[0],
    });
  };

  _fullyDeletedInfo = (product) => (
    <div className='m-t'>
      <em className='text-muted'>
        <FormattedMessage
          id='product-has-been-deleted'
          description='Product has been deleted or no longer available'
          defaultMessage={`Tuote on poistunut tuotetietokannasta ja sitä
              ei ole merkitty asennetuksi, minkä vuoksi tarkempia tietoja
              ei ole saatavilla.`}
        />
      </em>
      {product.id ? (
        <ProductRemovalButton onRemoveClick={this._removeProduct} />
      ) : null}
    </div>
  );

  _recyclabilityIcon(name) {
    const icon = getRecyclabilityIcon(name);

    return (
      <a
        onClick={(evt) => evt.stopPropagation()}
        data-toggle='tooltip'
        data-placement='bottom'
        title={icon.FI}
      >
        <img src={icon.icon} alt={icon.FI} height='40' />
      </a>
    );
  }

  _getContent = () => {
    const { product } = this.props;

    const fullyDeleted =
      product.external_id &&
      !product.snapshot &&
      (!product.data || !product.data.id);

    if (fullyDeleted) {
      return this._fullyDeletedInfo(product);
    }

    const data = getProductData(product);

    const safetyData = this.props.safetyData || {
      hazardCodes: [],
      safetyCodes: [],
      warningCodes: [],
    };

    const productSafetyData = getSafetyCodes(product, safetyData);

    // Disable modification of products that are:
    // - not added to a package
    // - company products
    // - installed
    const modificationDisabled =
      product.status === 1 || (data.manual && data.from_company) || !product.id;

    const productGwpLimit = product.gwp_limit || '';

    let productGwpValueA1A3 = product.gwp_value_a1a3 || '';
    let productGwpUnit = product.gwp_unit || gwpOptions.gwpUnitOptions[0].value;
    let productGwpVerification =
      product.gwp_verification || gwpOptions.gwpVerificationOptions[0].value;
    let productGwpStandard =
      product.gwp_standard || gwpOptions.gwpStandardOptions[0].value;

    if (product.data && product.data.from_company) {
      productGwpValueA1A3 = product.data.gwp_value_a1a3 || '';
      productGwpUnit =
        product.data.gwp_unit || gwpOptions.gwpUnitOptions[0].value;
      productGwpVerification =
        product.data.gwp_verification ||
        gwpOptions.gwpVerificationOptions[0].value;
      productGwpStandard =
        product.data.gwp_standard || gwpOptions.gwpStandardOptions[0].value;
    }

    const productApproverUsername =
      this.props.product.request_approval_username;
    const productExposedUsers = this.props.product.exposed_users || '';
    const productMaximumStoredAmount =
      this.props.product.maximum_stored_amount || '';

    const hazardCodesMatch =
      productSafetyData.hazardCodes.map(({ value }) => value).join(',') ===
      this.state.hazardCodes.map(({ value }) => value).join(',');

    const safetyCodesMatch =
      productSafetyData.safetyCodes.map(({ value }) => value).join(',') ===
      this.state.safetyCodes.map(({ value }) => value).join(',');

    const warningCodesMatch =
      productSafetyData.warningCodes.map(({ value }) => value).join(',') ===
      this.state.warningCodes.map(({ value }) => value).join(',');

    const approverRights =
      this.props.user?.admin === true ||
      this.props.user?.role === 1 ||
      this.props.user?.role === 0;

    const hasMaterialRecyclabilityData =
      product.data?.material_recyclability !== undefined ||
      product.data?.material_recyclability_icon !== undefined;

    const hasPackageRecyclabilityData =
      product.data?.package_recyclability !== undefined ||
      product.data?.package_recyclability_icon !== undefined;

    return (
      <div>
        <ConfirmActionModal
          id='UninstallDeletedProductPanelModal'
          title='Peru asennus'
          info={
            <FormattedMessage
              id='uninstall-info-message'
              description='Uninstall info message'
              defaultMessage={`Tuote on poistunut tuotetietokannasta.
              Et voi enää muuttaa/nähdä tuotteen tietoja jos perut
              tuotteen asennuksen.
              `}
            />
          }
          onConfirm={this._uninstallProduct}
        />

        <ConfirmActionModal
          id='RemoveFileModal'
          title='Poista tiedosto'
          info={
            <div>
              <FormattedMessage
                id='remove-file-info-message'
                description='Remove file info message'
                defaultMessage={`Haluatko varmasti poistaa seuraavan tiedoston?`}
              />
              {this.state.selectedFile && (
                <div
                  className={classNames(
                    'list-group-item',
                    'ProductInformationPanel-content-files-row',
                    'switch'
                  )}
                >
                  <span>
                    {getProductFilename(product, this.state.selectedFile, 0)}
                  </span>
                  <div className='floater'>
                    <img src={getFileTypePath(this.state.selectedFile.type)} />
                  </div>
                </div>
              )}
            </div>
          }
          onConfirm={() => this._removeFile(this.state.selectedFile)}
        />

        <EditFileModal
          file={this.state.selectedFile}
          product={this.props.product}
          fileActionCreators={this.props.fileActionCreators}
          messageActionCreators={this.props.messageActionCreators}
        />

        <EditProductModal
          product={this.props.product}
          productActionCreators={this.props.productActionCreators}
          messageActionCreators={this.props.messageActionCreators}
        />

        <h4 className='sidepanel-header'>{data.name}</h4>

        {this._svhcWarning(product)}
        {this._manualProductWarning(product)}
        {this._chemicalProductWarning(product)}
        {this._removedProductWarning(product)}

        <ProductDetails
          product={product}
          log={this.props.log}
          validityMethods={data.validity_methods}
        />

        {product &&
          product.status !== 1 &&
          product.data &&
          product.data.manual &&
          !product.data.from_company && (
            <button
              className='btn btn-success m-t'
              data-toggle='modal'
              data-target='#EditProductModal'
            >
              <i className='fa fa-pencil-square-o' />
              <FormattedMessage
                id='product-manual-edit'
                description='Label for manual product edit'
                defaultMessage='Muokkaa'
              />
            </button>
          )}

        <hr />

        <div>
          <dl className='dl-horizontal row'>{this._productStatus(product)}</dl>
          <div className='ProductInformationPanel-content-actions'>
            {this._productAction(product)}
            {product.product_approvable &&
              (approverRights || this.props.package.can_approve) &&
              this._productApproval(product)}

            {!!product.gwp_limit && this.props.package.gwp_can_approve && (
              <div>
                {product.gwp_approved ? (
                  <button
                    className='btn btn-danger m-b'
                    onClick={this._approveCarbonFootprint}
                  >
                    <FormattedMessage
                      id='product-approve-carbon-footprint-button'
                      description='Button label for approving carbon footprint'
                      defaultMessage='Peru hiilijalanjälki'
                    />
                  </button>
                ) : (
                  <button
                    className='btn btn-eco m-b'
                    onClick={this._approveCarbonFootprint}
                  >
                    <FormattedMessage
                      id='product-approve-carbon-footprint-button'
                      description='Button label for approving carbon footprint'
                      defaultMessage='Totea hiilijalanjälki'
                    />
                  </button>
                )}
              </div>
            )}
          </div>
          {product.id ? (
            <ProductRemovalButton onRemoveClick={this._removeProduct} />
          ) : null}

          {(approverRights || this.props.package.can_approve) &&
            this._setProductApprovable(product)}

          {product.product_approvable &&
            product.data?.variant !== ProductType.CHEMICAL && (
              <ApproverSelector
                user={this.props.user}
                packageId={this.props.package?.id}
                approvers={this.props.approvers}
                getApproverData={() => {
                  if (product) {
                    return this.props.productActionCreators.getProductApprover(
                      product.id
                    );
                  }
                }}
                setApproverData={(username, email) => {
                  if (product) {
                    return this.props.productActionCreators.setProductApprover(
                      product.id,
                      username,
                      email
                    );
                  }
                }}
                refetchApproverIds={() =>
                  this.props.productActionCreators.getUserApproverIds()
                }
              />
            )}

          {product.id && (
            <div>
              <hr />
              <h4 className='m-b'>
                <FormattedMessage
                  id='comment-header'
                  description='Header text for product comment'
                  defaultMessage='Kommentit'
                />
              </h4>

              <ProductComments
                product={product}
                productActionCreators={this.props.productActionCreators}
                messageActionCreators={this.props.messageActionCreators}
                disabled={this.props.package.state === 2}
              />
            </div>
          )}

          <div>
            <hr />
            <h4 className='m-b'>
              <FormattedMessage
                id='files-header'
                description='Header text for product files'
                defaultMessage='Tiedostot'
              />
            </h4>

            {this._productFiles(product)}
          </div>

          {(hasMaterialRecyclabilityData || hasPackageRecyclabilityData) && (
            <>
              <hr />
              <h4 className='m-b'>
                <FormattedMessage
                  id='recyclability-header'
                  description='Header text for recyclability'
                  defaultMessage='Kierrätys'
                />
              </h4>

              {hasMaterialRecyclabilityData && (
                <>
                  <p>
                    <strong>Materiaali</strong>
                  </p>

                  {product.data.material_recyclability_icon && (
                    <div className='recyclability-icons'>
                      {product.data.material_recyclability_icon
                        .split(',')
                        .map((name) => this._recyclabilityIcon(name))}
                    </div>
                  )}

                  <p>{product.data.material_recyclability}</p>
                </>
              )}

              {hasPackageRecyclabilityData && (
                <>
                  <p>
                    <strong>Pakkaus</strong>
                  </p>

                  {product.data.package_recyclability_icon && (
                    <div className='recyclability-icons'>
                      {product.data.package_recyclability_icon
                        .split(',')
                        .map((name) => this._recyclabilityIcon(name))}
                    </div>
                  )}

                  <p>{product.data.package_recyclability}</p>
                </>
              )}
            </>
          )}

          {(data.variant === ProductType.CHEMICAL ||
            data.variant === ProductType.CHEMICAL_PRODUCT) && (
            <div>
              <hr />
              <h4 className='m-b m-t'>
                <FormattedMessage
                  id='safety-codes-header'
                  description='Header text for safety codes'
                  defaultMessage='Kemikaalitiedot'
                />
              </h4>

              <FormattedMessage
                id='safety-codes'
                description='Multiselect safety codes'
                defaultMessage='Vaaralauseke (yksi tai useampi)'
              />
              <Select
                className='m-t m-b maxpanel'
                isMulti
                isDisabled={modificationDisabled}
                value={this.state.hazardCodes}
                onChange={(value) => {
                  if (value.length > 1) {
                    value = value.filter((e) =>
                      value[value.length - 1].value !== '-'
                        ? e.value !== '-'
                        : e.value === '-'
                    );
                  }
                  this.setState({
                    hazardCodes: value,
                  });
                }}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                noOptionsMessage={() => 'None found'}
                placeholder='Vaaralauseke...'
                options={safetyData.hazardCodes}
              />

              <FormattedMessage
                id='safety-codes'
                description='Multiselect safety codes'
                defaultMessage='Turvalauseke (yksi tai useampi)'
              />
              <Select
                className='m-t m-b maxpanel'
                isMulti
                isDisabled={modificationDisabled}
                value={this.state.safetyCodes}
                onChange={(value) => {
                  if (value.length > 1) {
                    value = value.filter((e) =>
                      value[value.length - 1].value !== '-'
                        ? e.value !== '-'
                        : e.value === '-'
                    );
                  }
                  this.setState({
                    safetyCodes: value,
                  });
                }}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                noOptionsMessage={() => 'None found'}
                placeholder='Turvalauseke...'
                options={safetyData.safetyCodes}
              />

              <FormattedMessage
                id='safety-codes'
                description='Multiselect safety codes'
                defaultMessage='Varoitusmerkki (yksi tai useampi)'
              />
              <Select
                className='m-t m-b maxpanel'
                isMulti
                isDisabled={modificationDisabled}
                value={this.state.warningCodes}
                onChange={(value) => {
                  if (value.length > 1) {
                    value = value.filter((e) =>
                      value[value.length - 1].value !== '-'
                        ? e.value !== '-'
                        : e.value === '-'
                    );
                  }
                  this.setState({
                    warningCodes: value,
                  });
                }}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                noOptionsMessage={() => 'None found'}
                placeholder='Varoitusmerkki...'
                options={safetyData.warningCodes}
              />
              {product.package && (
                <div>
                  <FormattedMessage
                    id='comment-header'
                    description='Header text for exposed users'
                    defaultMessage='Altistuvat käyttäjät'
                  />
                  <input
                    className='m-t m-b'
                    type='text'
                    disabled={this.props.package.state === 2}
                    value={this.state.exposedUsers}
                    onChange={(event) => {
                      this.setState({
                        exposedUsers: event.target.value,
                      });
                    }}
                  />
                  <FormattedMessage
                    id='maximum-stored-amount'
                    description='maximum-stored-amount'
                    defaultMessage='Maksimi varastointimäärä'
                  />
                  <input
                    className='m-t m-b'
                    type='text'
                    disabled={this.props.package.state === 2}
                    value={this.state.maximumStoredAmount}
                    onChange={(event) => {
                      this.setState({
                        maximumStoredAmount: event.target.value,
                      });
                    }}
                  />
                  <button
                    type='button'
                    className='btn btn-success'
                    disabled={
                      hazardCodesMatch &&
                      safetyCodesMatch &&
                      warningCodesMatch &&
                      productExposedUsers === this.state.exposedUsers &&
                      productMaximumStoredAmount ===
                        this.state.maximumStoredAmount
                    }
                    onClick={() => {
                      this._updateChemicalInformation();
                    }}
                  >
                    <FormattedMessage
                      id='chemical-product-submit'
                      description='Submit chemical info'
                      defaultMessage='Tallenna tiedot'
                    />
                  </button>
                </div>
              )}
            </div>
          )}

          <hr />
          <h4 className='m-b'>
            <FormattedMessage
              id='carbon-footprint-header'
              description='Header text for carbon footprint info'
              defaultMessage='Hiilijalanjälki'
            />
          </h4>
          {product.package && (
            <div>
              <FormattedMessage
                id='gwp-limit'
                description='gwp-limit'
                defaultMessage='GWP A1-A3 raja-arvo yksikköineen
                (lisäämällä arvon voit todentaa hiilijalanjäljen)'
              />
              <input
                className='m-t m-b'
                type='text'
                value={this.state.gwpLimit}
                disabled={!product.package}
                onChange={(e) => {
                  this.setState({
                    gwpLimit: e.currentTarget.value,
                  });
                }}
              />
              <hr />
            </div>
          )}
          <div>
            <FormattedMessage
              id='gwp-a1a3'
              description='gwp-a1a3'
              defaultMessage='GWP A1-A3'
            />
            <input
              className='m-t m-b'
              type='text'
              value={this.state.gwpValueA1A3}
              onChange={(e) => {
                this.setState({
                  gwpValueA1A3: e.currentTarget.value,
                });
              }}
              disabled={
                (product.data && product.data.from_company) || !product.package
              }
            />

            {this.state.gwpValueA1A3 !== '' && (
              <div>
                <FormattedMessage
                  id='gwp-unit'
                  description='gwp-unit'
                  defaultMessage='Yksikkö'
                />
                <Select
                  className='m-t m-b maxpanel'
                  value={gwpOptions.gwpUnitOptions.filter(
                    (o) => o.value === this.state.gwpUnit
                  )}
                  onChange={({ value }) => {
                    this.setState({
                      gwpUnit: value,
                    });
                  }}
                  backspaceRemovesValue={false}
                  noOptionsMessage={() => 'None found'}
                  placeholder='Yksikkö...'
                  options={gwpOptions.gwpUnitOptions}
                  isDisabled={
                    (product.data && product.data.from_company) ||
                    !product.package
                  }
                />

                <FormattedMessage
                  id='gwp-verification'
                  description='gwp-verification'
                  defaultMessage='3. osapuolen verifiointi'
                />
                <Select
                  className='m-t m-b maxpanel'
                  value={gwpOptions.gwpVerificationOptions.filter(
                    (o) => o.value === this.state.gwpVerification
                  )}
                  onChange={({ value }) => {
                    this.setState({
                      gwpVerification: value,
                    });
                  }}
                  backspaceRemovesValue={false}
                  noOptionsMessage={() => 'None found'}
                  placeholder='Verifikaatio...'
                  options={gwpOptions.gwpVerificationOptions}
                  isDisabled={
                    (product.data && product.data.from_company) ||
                    !product.package
                  }
                />

                <FormattedMessage
                  id='gwp-standard'
                  description='gwp-standard'
                  defaultMessage='Standardi'
                />
                <Select
                  className='m-t m-b maxpanel'
                  value={gwpOptions.gwpStandardOptions.filter(
                    (o) => o.value === this.state.gwpStandard
                  )}
                  onChange={({ value }) => {
                    this.setState({
                      gwpStandard: value,
                    });
                  }}
                  backspaceRemovesValue={false}
                  noOptionsMessage={() => 'None found'}
                  placeholder='Standardi...'
                  options={gwpOptions.gwpStandardOptions}
                  isDisabled={
                    (product.data && product.data.from_company) ||
                    !product.package
                  }
                />
              </div>
            )}
          </div>

          {product.package && (
            <div>
              <button
                className='btn btn-success'
                disabled={
                  !product.package ||
                  (this.state.gwpLimit === productGwpLimit &&
                    this.state.gwpValueA1A3 === productGwpValueA1A3 &&
                    this.state.gwpUnit === productGwpUnit &&
                    this.state.gwpVerification === productGwpVerification &&
                    this.state.gwpStandard === productGwpStandard)
                }
                onClick={() => {
                  this._patchProduct({
                    gwpLimit: this.state.gwpLimit,
                    gwpValueA1A3: this.state.gwpValueA1A3,
                    gwpUnit: this.state.gwpUnit,
                    gwpVerification: this.state.gwpVerification,
                    gwpStandard: this.state.gwpStandard,
                  });
                }}
              >
                <FormattedMessage
                  id='carbonFootprint-product-submit'
                  description='Submit carbon footprint info'
                  defaultMessage='Tallenna tiedot'
                />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    if (this.state.openMenu) {
      this._disablePageScroll();
    } else {
      this._enablePageScroll();
    }

    let content = null;
    const classes = classNames(
      'ProductInformationPanel-content',
      'stretch-vert',
      'sidepanel',
      {
        open: !!this.props.product && this.state.openMenu,
      }
    );

    const backdropClasses = classNames('ProductInformationPanel-backdrop', {
      'hidden-sm-up': this.state.openMenu,
      'hidden-xs-up': !this.state.openMenu,
    });

    if (this.props.productLoading || !this.props.package) {
      content = (
        <div className='loading loading-green loading-center m-t-md'>
          <div />
        </div>
      );
    } else if (!this.props.product) {
      content = (
        <p className='text-muted padding'>
          <FormattedMessage
            id='product-information-panel-placeholder'
            description='Placeholder for product information panel'
            defaultMessage='Klikkaa tuotetta nähdäksesi sen tiedot.'
          />
        </p>
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='ProductInformationPanel col-xs-12 col-sm-4'>
        <div onClick={this._closeMenu} className={backdropClasses} />

        <div className={classes}>
          <div className='ProductInformationPanel-content-menu'>
            <div
              className={classNames(
                'sidepanel-close-icon',
                'hidden-sm-up',
                'pull-left'
              )}
            >
              <i className='fa fa-times pull-left' onClick={this._closeMenu} />
            </div>

            {content}
          </div>
        </div>
      </div>
    );
  }
}

ProductInformationPanel.propTypes = {
  package: PropTypes.object,
  product: PropTypes.object,
  productLoading: PropTypes.bool.isRequired,
  log: PropTypes.object,
  externalProduct: PropTypes.object,
  globalSearch: PropTypes.array,
  packageActionCreators: PropTypes.object.isRequired,
  productActionCreators: PropTypes.object.isRequired,
  fileActionCreators: PropTypes.object.isRequired,
  messageActionCreators: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  safetyData: PropTypes.object,
  logActionCreators: PropTypes.object.isRequired,
  approvers: PropTypes.array,
  user: PropTypes.object,
};

export default injectIntl(ProductInformationPanel);
