import PropTypes from 'prop-types';
import React from 'react';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Messages from '../Messages/Messages';
import PackageRightsList from '../PackageRightsList/PackageRightsList';
import PackageRightsAdd from '../PackageRightsAdd/PackageRightsAdd';
import PackageRightsTransfer from '../PackageRightsTransfer/PackageRightsTransfer';

import { FormattedMessage } from 'react-intl';

class PackageRightsView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    rtActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this._getStoreData();
  }

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
  }

  _onStoreChange = () => {
    this.setState(this._getStoreData());
  };

  _getStoreData = () => {
    return {
      packageState: this.props.packageStore.getState(),
      rtState: this.props.rtStore.getState(),
    };
  };

  _getHeader = () => {
    if (!this.state.packageState.package) {
      return (
        <h2 className='m-b-lg'>
          <div className='loading loading-green'>
            <div></div>
          </div>
        </h2>
      );
    }

    return (
      <h2 className='m-b-lg'>
        <FormattedMessage
          id='package-rights-header'
          description='Header for the package rights page'
          defaultMessage='Urakan "{name}" oikeuksien hallinta'
          values={{ name: this.state.packageState.package.name }}
        />
      </h2>
    );
  };

  _getContent = () => {
    const rtState = this.state.rtState;
    const packageState = this.state.packageState;

    return (
      <div className='container-fluid'>
        <Header
          user={rtState.user}
          packages={packageState.packageSearch}
          searchLoading={packageState.packageSearchLoading}
          packageActionCreators={this.props.packageActionCreators}
        />

        <Messages
          messageStore={this.props.messageStore}
          messageActionCreators={this.props.messageActionCreators}
        />

        <div className='PackageRightsView-content row'>
          <div className='col-xs-12 col-sm-10 col-sm-offset-1'>
            {this._getHeader()}

            <div className='row'>
              <div className='col-xs-12 col-sm-5 col-md-6'>
                <PackageRightsList
                  package={packageState.package}
                  rights={packageState.rights}
                  invites={packageState.invites}
                  user={rtState.user}
                  packageActionCreators={this.props.packageActionCreators}
                  messageActionCreators={this.props.messageActionCreators}
                />
              </div>

              <div className='col-xs-12 col-sm-6 col-sm-offset-1 col-md-5'>
                <div className='card'>
                  <div className='card-header'>
                    <FormattedMessage
                      id='manage-rights-header'
                      description='Header text for managing rights'
                      defaultMessage='Hallitse urakan oikeuksia'
                    />
                  </div>

                  <div className='card-block'>
                    <div className='card-text'>
                      <PackageRightsAdd
                        package={packageState.package}
                        messageActionCreators={this.props.messageActionCreators}
                        packageActionCreators={this.props.packageActionCreators}
                      />

                      <br />
                      <hr />
                      <br />

                      <PackageRightsTransfer
                        package={packageState.package}
                        messageActionCreators={this.props.messageActionCreators}
                        packageActionCreators={this.props.packageActionCreators}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let content = null;

    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='PackageRightsView'>
        {content}
        <Footer />
      </div>
    );
  }
}

export default PackageRightsView;
