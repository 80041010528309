/**
 * importProductsModal.js - React component importProductsModal
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import { injectIntl, FormattedMessage } from 'react-intl';

const initialState = {
  file: null,
  fileTypeError: null,
};

class ImportProductsModal extends React.Component {
  static propTypes = {
    intl: PropTypes.any.isRequired,
    title: PropTypes.string.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    fileActionCreators: PropTypes.object.isRequired,
    validateImportProductsLoading: PropTypes.bool.isRequired,
    importProducts: PropTypes.array,
    packageId: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.importProductsModal = null;

    this.state = initialState;
  }

  componentDidMount() {
    $(this.importProductsModal).on('hidden.bs.modal', () => {
      this.props.packageActionCreators.clearImportProducts();
      this.setState(initialState);
    });
  }

  componentWillUnmount() {
    $(this.importProductsModal).unbind('hidden.bs.modal');
  }

  _onDrop = (files) => {
    this.setState({
      file: files[0],
      fileTypeError: null,
    });

    const importFileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!files[0] || files[0].type !== importFileType) {
      this.setState({
        fileTypeError: 'Invalid file type',
      });
      return;
    }

    this.props.packageActionCreators.validateImportProducts(
      this.props.packageId,
      files[0]
    );
  };

  _statusTag(vr) {
    if (!vr.valid) {
      return <span className='error-tag'>{vr.error}</span>;
    }

    if (vr.manual) {
      return <span className='manual-tag'>Uusi tuote</span>;
    }

    return <span className='boomi-tag'>Tuotetieto</span>;
  }

  render() {
    const validProductCount = this.props.importProducts
      ? this.props.importProducts.filter((prod) => prod.valid).length
      : 0;

    const invalidProductCount = this.props.importProducts
      ? this.props.importProducts.length - validProductCount
      : 0;

    return (
      <div
        className='modal fade ImportProductsModal'
        ref={(div) => {
          this.importProductsModal = div;
        }}
        id='importProductsModal'
        tabIndex='-1'
        data-backdrop='static'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' data-dismiss='modal'>
                <span>&times;</span>
              </button>

              <h4 className='modal-title'>
                <FormattedMessage
                  id='add-product-header'
                  description='Add product modal header'
                  defaultMessage={this.props.title}
                />
              </h4>
            </div>
            <div className='modal-body'>
              <form>
                <div className='dropzone-container m-t'>
                  <Dropzone onDrop={this._onDrop} className='dropzone'>
                    <i
                      className='fa fa-file-text-o'
                      aria-hidden='true'
                      style={{
                        fontSize: 32,
                        color: this.state.file ? '#02bc01' : '#000',
                      }}
                    />
                    <br />
                    {!!this.state.file ? (
                      <p className='wrap'>{this.state.file.name}</p>
                    ) : (
                      <p>
                        Pudota tiedosto tähän tai klikkaa valitaksesi tiedosto
                      </p>
                    )}
                  </Dropzone>
                </div>
                <div className='flexrow m-t'>
                  <button
                    className='btn btn-primary-outline'
                    onClick={(event) => {
                      event.preventDefault();
                      this.props.fileActionCreators.downloadImportProductsTemplate();
                    }}
                  >
                    <FormattedMessage
                      id='download-import-product-template'
                      description='Button for downloading import template'
                      defaultMessage='Lataa tuotepohja'
                    />
                  </button>
                  <div>
                    {this.state.fileTypeError && (
                      <FormattedMessage
                        id='invalid-file-type-message'
                        description='Label for invalid file type'
                        defaultMessage={
                          'Virheellinen tiedosto, käytä tuotepohjaa..'
                        }
                      />
                    )}
                  </div>
                </div>

                {this.props.validateImportProductsLoading && (
                  <div className='loading loading-green loading-center m-t-md'>
                    <div></div>
                  </div>
                )}

                {!this.props.validateImportProductsLoading &&
                  this.props.importProducts &&
                  !this.state.fileTypeError && (
                    <div>
                      <dl className='dl-horizontal row m-t'>
                        <dt className='col-xs-4'>
                          <FormattedMessage
                            id='total'
                            description='Label for valid product count'
                            defaultMessage='Lisättäviä tuotteita'
                          />
                          :
                        </dt>
                        <dd className='col-xs-8'>
                          <FormattedMessage
                            id='pieces'
                            description='Valid product count.'
                            defaultMessage='{count, number} kpl'
                            values={{
                              count: validProductCount,
                            }}
                          />
                        </dd>

                        <dt className='col-xs-4'>
                          <FormattedMessage
                            id='installed'
                            description='Label for invalid product count'
                            defaultMessage='Virheellisiä tuotteita'
                          />
                          :
                        </dt>
                        <dd className='col-xs-8'>
                          <FormattedMessage
                            id='pieces'
                            description='Invalid product count.'
                            defaultMessage='{count, number} kpl'
                            values={{
                              count: invalidProductCount,
                            }}
                          />
                        </dd>
                      </dl>
                      <div className='chart m-t'>
                        <table className='table-bordered'>
                          <tbody>
                            <tr>
                              <th></th>
                              <th>Tuotteen GTIN-koodi</th>
                              <th>Tuotteen nimi</th>
                              <th>Tuotteen yritys</th>
                            </tr>
                            {this.props.importProducts.map((vr, idx) => {
                              return (
                                <tr
                                  key={`${vr.ean}-${idx}`}
                                  style={{
                                    ...(!vr.valid && {
                                      backgroundColor: '#e57373',
                                    }),
                                  }}
                                >
                                  <td>{this._statusTag(vr)}</td>
                                  <td>{vr.ean}</td>
                                  <td>{vr.name}</td>
                                  <td>{vr.manufacturer}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
              </form>
            </div>
            <div className='modal-footer'>
              <div className='row justify-content-center d-xs-block'>
                <button className='btn btn-warning' data-dismiss='modal'>
                  <FormattedMessage
                    id='manual-product-cancel'
                    description='Cancel button text when adding manual product'
                    defaultMessage='Peruuta'
                  />
                </button>

                <button
                  className='btn btn-success'
                  onClick={() => {
                    this.props.packageActionCreators.importSheetProducts(
                      this.props.packageId,
                      this.props.importProducts.filter((prod) => prod.valid)
                    );
                    $(this.importProductsModal).modal('hide');
                  }}
                  disabled={
                    this.props.validateImportProductsLoading ||
                    validProductCount === 0
                  }
                >
                  <FormattedMessage
                    id='import-products-accept'
                    description={
                      'Accept button for importing validated products'
                    }
                    defaultMessage='Tuo {validProductCount} tuotetta'
                    values={{
                      validProductCount,
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(ImportProductsModal);
